import { Button, Card, Col, Empty, Modal, Row, Select, Space, Typography } from "antd"
import Link from "antd/es/typography/Link"
import { FORM_API, FORM_ATTACH_API, FORM_DETACH_API, FORM_LIST_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { FormPreview } from "../form/FormPreview"

const { Text } = Typography

export const DiseaseFormSelect = ({ disease, forceUpdate }) => {
  const [showFormModal, setShowFormModal] = useState(false)
  const [formList, setFormList] = useState([] as any[])
  const [filterTerm, setFilterTerm] = useState('');
  const [filterTermDebounce] = useDebounce(filterTerm, 600);
  const [selectedForm, setSelectedForm] = useState(undefined as any)
  const [previewData, setPreviewData] = useState(undefined as any)
  const navigate = useNavigate()

  const handleSearch = (value) => {
    setFilterTerm(value)
  }

  const handleSelect = (value) => {
    setSelectedForm(value)
  }

  useEffect(() => {
    axios.get(FORM_API, { params: { form_id: selectedForm } })
      .then(response => response.data.data)
      .then(response => {
        setPreviewData(response)
      })
  }, [selectedForm])

  useEffect(() => {
    if (filterTermDebounce.length === 0) {
      setFormList([])
      setSelectedForm(null)
    }
    else {
      filterTermDebounce.length > 0 && axios.get(FORM_LIST_API, { params: { page: 1, title: filterTermDebounce } })
        .then(response => response.data.data.items)
        .then(data => {
          let forms = data.map(item => {
            return {
              value: item.uid,
              label: (
                <Space>
                  <Text>{item.title}</Text>
                </Space>
              ),
            }
          })
          setFormList(forms);
        })
    }
  }, [filterTermDebounce])


  const handleOk = () => {
    axios.post(FORM_ATTACH_API, { disease_id: disease.uid, form_id: selectedForm })
      .then(() => {
        setSelectedForm(null)
        setFormList([])
        setFilterTerm('')
        setShowFormModal(false)
        forceUpdate()
      })
  }

  const handleClose = () => {
    setSelectedForm(null)
    setFormList([])
    setFilterTerm('')
    setShowFormModal(false)
  }

  const handleRemove = () => {
    axios.post(FORM_DETACH_API, { disease_id: disease.uid, form_id: disease?.form_registered?.uid })
      .then(() => {
        forceUpdate()
      })
  }

  return (
    <Card
      title="Biểu mẫu"
      style={{ marginTop: 32 }}
      extra={
        <Space>
          <Button onClick={() => setShowFormModal(true)}>Chọn mẫu khác</Button>
          {disease?.form_registered?.uid && <Button onClick={handleRemove}>Xoá</Button>}
        </Space>
      }
    >
      <Row gutter={[10, 10]}>
        <Col span={6}><Text strong>Biểu mẫu</Text></Col>
        <Col span={18}>
          {disease?.form_registered?.title ?
            <Link role='button' onClick={() => navigate(`/form/id/${disease?.form_registered?.uid}`)}>
              {disease?.form_registered?.title}
            </Link>
            : <Text >Không có biểu mẫu được đăng ký</Text>
          }
        </Col>
      </Row>
      <Modal
        open={showFormModal}
        title='Chọn biểu mẫu'
        onCancel={handleClose}
        onOk={handleOk}
        okText='Chọn'
        cancelText='Huỷ'
        destroyOnClose
      >
        <Select
          placeholder='Tên biểu mẫu...'
          options={formList}
          showSearch
          // filterOption={filterOption}
          filterOption={false}
          onSearch={handleSearch}
          popupMatchSelectWidth={false}
          listHeight={400}
          style={{ width: '100%' }}
          // suffixIcon={<i className="fa-solid fa-magnifying-glass fa-fw text-muted"></i>}
          onSelect={handleSelect}
          notFoundContent={null}
          value={selectedForm}
          allowClear={true}
          onClear={() => {
            setSelectedForm(null)
            setFormList([])
          }}
        />
        <Row style={{ maxHeight: 500, overflowY: 'auto' }} className="mt-4">
          <Card style={{ width: '100%' }}>
            {previewData ? <FormPreview form={previewData} /> : <Empty description='Xem trước biểu mẫu' />}
          </Card>
        </Row>
      </Modal>
    </Card>
  )
}