import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'

import axios from 'axios'
/* import * as yup from 'yup'*/
/* import {useFormik} from 'formik'*/

import clsx from 'clsx'
import {AudioOutlined, AudioMutedOutlined, CameraOutlined, PhoneOutlined} from '@ant-design/icons'

import {Button} from 'antd'

/* import type {ILocalAudioTrack, ILocalTrack, ILocalVideoTrack} from 'agora-rtc-react'*/

import {
  useJoin,
  usePublish,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  useRemoteUsers,
  useRemoteVideoTracks,
  useRemoteAudioTracks,
  useRTCClient,
  LocalVideoTrack,
  LocalAudioTrack,
  RemoteVideoTrack,
} from 'agora-rtc-react'

import {run, runLayout, transformProgram} from 'utils/functional/BuildLayout'

import {CalendarDetailprogram} from 'app/modules/apps/patient-management/calendar/calendar-detail'

import {
  callGeneric,
  MARK_JOIN_CONFERENCE_URL,
} from 'app/modules/apps/patient-management/calendar/core/_requests'

import './Call.scss'

function* CallProgram(inject): Generator<any, any, any> {
  const {localCameraTrack, localMicrophoneTrack, videoTracks, client} = inject

  const {cameraOn, muted, setMuted, micOn, setMic} = inject

  const {joinError, joinResult, localError} = inject

  yield {
    anchor: true,
  }

  yield (
    <div>
      <Button
        className={clsx({micOff: !micOn})}
        type='link'
        size='large'
        style={{color: micOn ? 'white' : 'red'}}
        onClick={() => {
          setMic(!micOn)
        }}
      >
        {micOn ? <AudioOutlined /> : <AudioMutedOutlined />}
      </Button>
    </div>
  )

  yield (
    <div className={clsx({muted: muted})}>
      <Button
        type='link'
        size='large'
        style={{color: !muted ? 'white' : 'red'}}
        onClick={() => {
          setMuted(!muted)
        }}
      >
        <CameraOutlined />
      </Button>
    </div>
  )

  yield (
    <div className='leave'>
      <Button
        onClick={async () => {
          await client?.leave()
        }}
      >
        <PhoneOutlined /> Rời cuộc gọi
      </Button>
    </div>
  )

  yield {
    classes: ['header-row'].join(' '),
  }
  yield {
    anchor: true,
  }

  yield {
    anchor: true,
  }

  yield <RemoteVideoTrack play={true} track={videoTracks[0]} className='remote-video' />

  yield (
    <div>
      <LocalVideoTrack
        play={true}
        disabled={!cameraOn}
        muted={muted}
        style={{width: '300px', height: '300px'}}
        track={localCameraTrack}
        className='local-video'
      />
      <LocalAudioTrack disabled={!micOn} track={localMicrophoneTrack}></LocalAudioTrack>
    </div>
  )

  yield (
    <div className='debug'>
      <div>{`je: ${joinError}`}</div>
      <div>{`jr: ${joinResult}`}</div>
      <div>{`le: ${localError}`}</div>
    </div>
  )

  yield {
    classes: ['video-panel'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['info-start'].join(' '),
  }

  yield {
    classes: ['info-panel'].join(' '),
  }

  yield {
    classes: ['main-row'].join(' '),
  }

  yield {
    classes: ['call-window'].join(' '),
  }
}

const Call = () => {
  const [calling] = useState(true)
  const [cameraOn, setCamera] = useState(true)
  const [muted, setMuted] = useState(true)
  const [micOn, setMic] = useState(false)
  const [data, setData] = useState({})

  const intl = useIntl()

  const translate = (id, extra = {}) => intl.formatMessage({id, ...extra})

  const location = useLocation()

  const {pathname} = location

  const pathSegmentList = pathname.split('/')
  const call_params = pathSegmentList[pathSegmentList.length - 1]

  const parsedParams = new URLSearchParams(call_params)

  const channel = parsedParams.get('channel_id') || ''
  const appId = parsedParams.get('app_id') || ''
  const token = parsedParams.get('token') || ''

  const info = {
    appid: appId,
    channel: channel,
    token: token,
  }

  const client = useRTCClient()

  const {error: joinError, data: joinResult} = useJoin(info, calling)

  /* debugger;*/

  const {localCameraTrack, error: localError} = useLocalCameraTrack(cameraOn, {})
  const {localMicrophoneTrack} = useLocalMicrophoneTrack(micOn, {})

  usePublish([localCameraTrack])
  usePublish([localMicrophoneTrack])

  const remoteUsers = useRemoteUsers()

  /* debugger*/

  const {videoTracks, error: remoteError} = useRemoteVideoTracks(
    /* remoteUsers.filter(user => user.uid !== appConfig.ShareScreenUID)*/
    remoteUsers
  )

  const {audioTracks} = useRemoteAudioTracks(
    /* remoteUsers.filter(user => user.uid !== appConfig.ShareScreenUID)*/
    remoteUsers
  )

  audioTracks.map((track) => track.play())

  useEffect(() => {
    const e = localError

    if (e) {
      /* debugger;*/
    }
  }, [localError, remoteError, remoteUsers])

  useEffect(() => {
    const updateData = async (e) => {
      setData(e?.detail)

      /*
      / Change appointment status 
      /
      /*/

      if (e.detail) {
        const {uid: appointment_id} = e.detail

        const res = await callGeneric(
          axios({
            method: 'put',
            /* headers: {'content-type': 'application/json'},*/
            data: {appointment_id},
            url: MARK_JOIN_CONFERENCE_URL,
          })
        )

        if (true || (res && res.uid)) {
          window.dispatchEvent(new CustomEvent('joinConferenceSuccess', {detail: res}))
        }
      }
    }

    window.addEventListener('appointmentInfo', updateData)

    window.dispatchEvent(new CustomEvent('callWindowLoaded'))
  }, [])

  const calendarInfoProgram = transformProgram(
    CalendarDetailprogram({info: data, translate}),
    (pl) => {
      let res = pl

      const start = pl.findIndex((e) => e?.classes?.includes('info-row-start'))
      const end = pl.findIndex((e, i) => {
        return e?.classes?.includes('info-row') && i > start
      })

      if (start > -1) {
        res = pl.slice(start, end + 1)

        const startStatus = res.findIndex((e) => e?.classes?.includes('status-outlet'))
        const endStatus = res.findIndex((e, i) => {
          return e?.classes?.includes('status') && i > startStatus
        })

        if (start > -1) {
          res.splice(startStatus, endStatus - startStatus + 1)
        }
      }

      const startS = pl.findIndex((e) => e?.classes?.includes('symptom-outlet'))
      const endS = pl.findIndex((e, i) => {
        return e?.classes?.includes('symptom-outlet-end') && i > startS
      })

      if (startS > -1) {
        res = [...res, ...pl.slice(startS, endS + 1)]
      }

      return res
    }
  )

  const program = transformProgram(
    CallProgram({
      info: data,
      localCameraTrack,
      localMicrophoneTrack,
      videoTracks,
      translate,
      joinError,
      joinResult,
      localError,
      cameraOn,
      muted,
      setCamera,
      setMuted,
      micOn,
      setMic,
      client,
    }),
    (pl) => {
      let res = pl

      const info = calendarInfoProgram()

      const start = pl.findIndex((e) => e?.classes?.includes('info-start'))

      if (start > -1) {
        res.splice(start + 1, 0, ...info)
      }

      return res
    }
  )

  const res = run(runLayout(program()))

  return <>{res}</>

  /* (
     * <div>
     *   <LocalVideoTrack
     *     play={true}
     *     style={{width: '300px', height: '300px'}}
     *     track={localCameraTrack}
     *   />

     *   <RemoteVideoTrack
     *     play={true}
     *     style={{width: '300px', height: '300px'}}
     *     track={videoTracks[0]}
     *   />

     *   <div>{`info: ${JSON.stringify(info)}`}</div>
     *   <div>{`le: ${localError}`}</div>
     *   <div>{`re: ${remoteError}`}</div>

     *   <div>{`je: ${joinError}`}</div>
     *   <div>{`jr: ${joinResult}`}</div>

     *   <div>{`ru: ${JSON.stringify(remoteUsers)}`}</div>

     *   <button
     *     onClick={() => {
     *       setCalling(!calling)
     *     }}
     *   >
     *     {`set calling: ${calling}`}
     *   </button>

     *   <button
     *     onClick={async () => {
     *       const res = await client.join(appId, channel, token)

     *       const a = appId
     *     }}
     *   >
     *     join3
     *   </button>

     *   <button
     *     onClick={async () => {
     *       const res = await client.leave()

     *       debugger
     *     }}
     *   >
     *     leave
     *   </button>
     *   <button
     *     onClick={async () => {
     *       const i = data

     *       debugger
     *     }}
     *   >
     *     leave
     *   </button>
     * </div>
       )*/
}

export {Call}
