import { Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import PatientPage from './patient/PatientPage'
import { PatientListPage } from './patients-list/PatientListPage'

import { PatientProvider } from 'app/modules/apps/patient-management/patient/PatientProvider'
import { NewPatientPage } from './patient/NewPatientPage'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Khách hàng',
    path: '/patient/list',
  },
]

export const PatientRoutes = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Danh sách khách hàng</PageTitle>
            <PatientListPage />
          </>
        }
      />
      <Route
        path='id/:uid/*'
        element={
          <>
            <PatientProvider>
              <PatientPage />
            </PatientProvider>
          </>
        }
      />
      <Route
        path='new'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Tài khoản khách hàng mới</PageTitle>
            <NewPatientPage />
          </>
        }
      />
    </Routes>
  )
}
