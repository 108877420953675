import { Avatar, Button, Card, Col, ConfigProvider, Input, Popover, Row, Select, Space, Table, Typography } from "antd"
import Column from "antd/es/table/Column"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { EMPLOYEE_API, formatPhone, getRelativeTime, getRoleDescription } from "../../../helpers/Common"
import { useAuth } from "app/modules/auth"
import { Permission } from "app/modules/helpers/Permission"

const { Text } = Typography

const allRoles = ['SUPER_ADMIN_BRANCH', 'CUSTOMER_CARE', 'DOCTOR', 'NURSE', 'ALL']
const allStatusOptions = [{ label: 'Hoạt động', value: 'ACTIVE' }, { label: 'Đã khoá', value: 'INACTIVE' }, { label: 'Tất cả', value: 'ALL' }]
const allRoleOptions = allRoles.map((role) => ({ label: getRoleDescription(role), value: role }))
const pageSize = 10

export const AccountListPage = () => {
  const [accountList, setAccountList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState('ACTIVE')
  const [filter, setFilter] = useState('')
  const [roleFilter, setRoleFilter] = useState('ALL')
  const [isRoleFilterOpen, setIsRoleFilterOpen] = useState(false)

  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('')
  const { auth, permission } = useAuth()

  const navigate = useNavigate()

  useEffect(() => {
    setPage(1)
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  useEffect(() => {
    let params = {
      page: page,
      fullname: searchTerm ? searchTerm : undefined,
      contact: searchTerm ? searchTerm : undefined,
      status: statusFilter === 'ALL' ? undefined : statusFilter,
      role: roleFilter === 'ALL' ? undefined : JSON.stringify([roleFilter])
    }
    axios.get(EMPLOYEE_API, { params: params })
      .then(response => response.data.data)
      .then(response => {
        console.log('account list', response)
        setAccountList(response.items)
        setMaxPage(response.max_page)
      })
  }, [page, statusFilter, searchTerm, roleFilter])

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  const canViewReport = auth?.role === 'SUPER_ADMIN_SYS' || auth?.role === 'OWNER' || auth?.role === 'SUPER_ADMIN_BRANCH'


  return (
    <Card
      extra={permission(Permission.CreateNewAccount) &&
        <Button
          className="text-hover-primary"
          icon={<i className="fa-solid fa-user-plus"></i>}
          onClick={() => navigate('../new')}>
          Thêm tài khoản
        </Button>
      }
      title={
        <Space>
          <Input style={{ maxWidth: '200pt' }} suffix={<i className="fa-solid fa-magnifying-glass text-muted"></i>} placeholder="Tên hoặc số điện thoại..." value={filter} onChange={handleFilter} />
          <Popover
            trigger='click'
            placement='bottom'
            open={isRoleFilterOpen}
            destroyTooltipOnHide={true}
            onOpenChange={(open) => {
              setIsRoleFilterOpen(open)
            }}
            content={
              <Col style={{ minWidth: '200pt' }}>
                <Space direction="vertical" style={{ width: '100%', marginBottom: 30 }}>
                  <Text strong>Loại tài khoản:</Text>
                  <Select options={allRoleOptions} value={roleFilter} style={{ width: '100%', marginBottom: 5 }} onSelect={(value) => setRoleFilter(value)} />
                  <Text strong>Trạng thái:</Text>
                  <Select options={allStatusOptions} value={statusFilter} style={{ width: '100%' }} onSelect={(value) => setStatusFilter(value)} />
                </Space>
                <Row justify='end'>
                  <Button type='primary' onClick={() => setIsRoleFilterOpen(false)}>Đóng</Button>
                </Row>
              </Col>
            }>
            <Button icon={<i className="fa-solid fa-filter"></i>}>Lọc</Button>
          </Popover>
        </Space>
      }
    >
      <ConfigProvider
        theme={{ components: { Table: { headerBg: '#fff' } } }}>
        <Table
          dataSource={accountList}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fs-7 text-gray-600' })}
          size='small'
        >
          <Column title='Tài khoản' dataIndex="fullname" render={
            (_, record: any) =>
              <Space role="button" onClick={() => {
                if (record.role === 'DOCTOR') {
                  navigate(`/doctor/id/${record.uid}`)
                } else {
                  navigate(`/account/id/${record.uid}`)
                }
              }}>
                <Avatar src={record.avatar ? record.avatar : '/media/avatars/blank.png'} size={48} style={{ marginRight: 5 }} />
                <Space.Compact direction='vertical' size='small'>
                  <Text className="fs-7 text-primary">{record.fullname}</Text>
                  <Text className="fs-7 text-gray-600">{record.phone ? formatPhone(record.phone) : 'Không có thông tin'}</Text>
                </Space.Compact>
              </Space>
          } />
          <Column title="Email" dataIndex="email" responsive={['xl']} />
          <Column title="Loại tài khoản" dataIndex="role" render={(value) => getRoleDescription(value)} responsive={['xl']} />
          <Column title="Truy cập" dataIndex="last_online_at" render={(value) => getRelativeTime(value)} responsive={['lg']} />
          <Column title="Ngày tạo" dataIndex="created_at" render={(value) => getRelativeTime(value)} responsive={['lg']} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              {canViewReport && record.role === 'DOCTOR' && <Button className="text-hover-primary" icon={<i className="fa-solid fa-chart-simple fs-5"></i>} onClick={() => navigate(`/doctor/id/${record.uid}/report`)} />}
              <Button className="text-hover-primary" icon={<i className="fa-regular fa-pen-to-square fs-5"></i>}
                onClick={() => {
                  if (record.role === 'DOCTOR') {
                    navigate(`/doctor/id/${record.uid}`)
                  } else {
                    navigate(`/account/id/${record.uid}`)
                  }
                }} />
            </Space>
          }
          />
        </Table>
      </ConfigProvider>
    </Card>
  )
}