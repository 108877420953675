export interface AccountModel {
  avatar?: string
  fullname?: string
  phone?: string
  email?: string
  gender?: string
  birthday?: string
  role: string
  password?: string
}

export const accountModelInitValues: AccountModel = {
  fullname: '',
  phone: '',
  email: '',
  gender: '',
  birthday: '',
  role: ''
}

export interface PasswordUpdateModel {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export const updatePassword: PasswordUpdateModel = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}