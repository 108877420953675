import { PageTitle } from '_metronic/layout/core'
import { Route, Routes } from 'react-router-dom'
import { NotificationListPage } from './NotificationListPage'

export const NotificationRoutes = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <>
            <PageTitle description='Danh sách thông báo'>Thông báo</PageTitle>
            <NotificationListPage />
          </>
        }>
      </Route>
    </Routes>
  )
}
