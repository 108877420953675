import { Col, Row, Typography } from "antd"

const { Text } = Typography

export const DiseaseInfo = ({ disease }) => {

    return (
        <>
            <Row gutter={[10, 10]}>
                <Col span={6}><Text strong>Nhóm bệnh</Text></Col>
                <Col span={18}><Text >{disease?.name}</Text></Col>
                <Col span={6}><Text strong>Mô tả:</Text></Col>
                <Col span={18}><Text >{disease?.description || 'Không có thông tin'}</Text></Col>
            </Row>
        </>
    )
}