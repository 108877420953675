import {AuthModel} from './_models'

import {jwtDecode} from 'jwt-decode'

import moment from 'moment'

const REFRESH_ACCESS_TOKEN_URL = `${process.env.REACT_APP_API_URL}/api/auth/v2/token/refresh`

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: {headers: {Authorization: string}}) => {
      const auth = getAuth()
      if (auth && auth.accessToken) {
        const {accessToken: access_token} = auth

        // @ts-ignore
        const {expire} = jwtDecode(access_token)

        const duration = expire - moment().unix()

        if (duration < 0 || duration / 60 < 5) {
          const instance = axios.create()
          const refreshRes = await instance.get(REFRESH_ACCESS_TOKEN_URL, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })

          const {
            data: {
              success,
              data: {token_refresh},
            },
          } = refreshRes

          if (success) {
            setAuth({...auth, accessToken: token_refresh})
            const {headers} = config

            const newConfig = {
              ...config,
              headers: {
                ...headers,
                Authorization: `Bearer ${token_refresh}`,
              },
            }

            return newConfig
          }
        }

        config.headers.Authorization = `Bearer ${auth.accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use((response: any) => {
    if (response.data.success && response.data.access_token) {
      const auth = getAuth()
      if (!auth || !auth.accessToken || auth.accessToken !== response.data.access_token) {
        setAuth({...auth, accessToken: response.data.access_token})
        console.log('ACCESS TOKEN REFRESHED')
      }
    } else if (!response.data.success) {
      console.log('Reponse error', response.data)
    }
    return response
  })
}

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
