import { Modal, Select, Space, Typography } from "antd";
import { DISEASE_EMPLOYEE_API, DISEASE_EMPLOYEE_OUTSIDE_API } from "app/modules/helpers/Common";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

const { Text } = Typography

export const DiseaseEmployeeAddModal = ({ open, close, disease }) => {
  const [selectedEmployee, setSelectedEmployee] = useState(undefined as any)
  const [employeeList, setEmployeeList] = useState([] as any[]);
  const [filterTerm, setFilterTerm] = useState('');
  const [filterTermDebounce] = useDebounce(filterTerm, 600);

  const handleSearch = (value) => {
    setFilterTerm(value)
  }

  useEffect(() => {
    if (filterTermDebounce.length === 0) {
      setEmployeeList([])
      setSelectedEmployee(null)
    }
    else {
      filterTermDebounce.length > 0 && axios.get(DISEASE_EMPLOYEE_OUTSIDE_API, { params: { page: 1, disease_id: disease.uid, name_or_email: filterTermDebounce } })
        .then(response => response.data.data.items)
        .then(data => {
          let employees = data.map(item => {
            return {
              value: item.uid,
              label: (
                <Space>
                  <i className="fa-solid fa-user-doctor"></i>
                  <Text>
                    {item.fullname} ({item.email})
                  </Text>
                </Space>
              ),
            }
          })
          setEmployeeList(employees);
        })
    }
  }, [filterTermDebounce, disease])

  const handleOk = () => {
    console.log('selectedEmployee', selectedEmployee)
    axios.post(DISEASE_EMPLOYEE_API, { disease_id: disease.uid, target_user_id: selectedEmployee })
      .then(() => {
        setSelectedEmployee(null)
        setEmployeeList([])
        setFilterTerm('')
        close(true)
      })
  }

  const handleSelect = (value) => {
    setSelectedEmployee(value)
  }

  const handleClose = () => {
    setSelectedEmployee(null)
    setEmployeeList([])
    setFilterTerm('')
    close(false)
  }

  return (
    <Modal
      title='Thêm nhân sự hỗ trợ nhóm bệnh'
      open={open}
      centered
      // style={{ maxWidth: '1000pt', minWidth: '600pt' }}
      onOk={handleOk}
      okText='Thêm'
      okButtonProps={{ disabled: !selectedEmployee }}
      cancelText='Huỷ'
      onCancel={handleClose}
      destroyOnClose={true}
      styles={{ body: { marginTop: 20, marginBottom: 35 } }}
    >
      <Select
        placeholder='Nhân viên...'
        options={employeeList}
        showSearch
        // filterOption={filterOption}
        filterOption={false}
        onSearch={handleSearch}
        popupMatchSelectWidth={false}
        listHeight={400}
        style={{ width: '100%' }}
        // suffixIcon={<i className="fa-solid fa-magnifying-glass fa-fw text-muted"></i>}
        onSelect={handleSelect}
        notFoundContent={null}
        value={selectedEmployee}
        allowClear={true}
        onClear={() => {
          setSelectedEmployee(null)
          setEmployeeList([])
        }}
      />
    </Modal>
  )
}