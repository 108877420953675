import {Route, Routes} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import Scheduler from './Schedule'
const profileBreadCrumbs: Array<PageLink> = [
  // {
  //   title: 'Lịch Làm Việc',
  //   path: '/schedule/*',
  //   isSeparator: false,
  //   isActive: false,
  // },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const SchedulePage = () => {
  return (
    <Routes>
      <Route
        path='plan'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Lịch Làm Việc</PageTitle>
            <Scheduler />
          </>
        }
      />
    </Routes>
  )
}
