import { Col, Row } from "antd"
import Link from "antd/es/typography/Link"
import { DEPARTMENT_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const DepartmentList = ({ org }) => {
    const [departments, setDepartments] = useState([] as any[])
    const navigate = useNavigate()

    // const closeSettingModal = () => {
    //     setIsDepartmentSettingModelOpen(false)
    // }

    useEffect(() => {
        axios.get(DEPARTMENT_API, { params: { target_org_id: org.uid } })
            .then(response => response.data.data)
            .then(
                data => {
                    setDepartments(data)
                }
            )
    }, [org])

    return (
        <>
            <Row gutter={[10, 10]}>
                {
                    departments.map(department => (
                        <Col xxl={{ span: 8 }} xl={{ span: 12 }} md={{ span: 24 }} key={department.uid}>
                            {/* <Text strong>{department.name}</Text> */}
                            <Link onClick={() => {
                                // setSelectedDepartment(department)
                                // setIsDepartmentSettingModelOpen(true)
                                navigate(`department/${department.uid}`)
                            }}
                            >
                                {department.name} ({department.count})
                            </Link>
                            {/* <Button type="primary" ghost>{department.name}</Button> */}
                        </Col>
                    ))
                }
            </Row>
            {/* <DepartmentSettingModal
                org={org}
                department={selectedDepartment}
                open={isDepartmentSettingModelOpen}
                close={closeSettingModal}
            /> */}
        </>
    )
}