import { Modal, Select, Space, Tag, Typography } from "antd";
import { DEPARTMENT_EMPLOYEE_UPDATE_API, DEPARTMENT_EMPLOYEE_OUTSIDE_API, getShortRoleDescription } from "app/modules/helpers/Common";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

const { Text } = Typography

export const DepartmentEmployeeAddModal = ({ open, close, department }) => {
    const [selectedEmployee, setSelectedEmployee] = useState(undefined as any)
    const [employeeList, setEmployeeList] = useState([] as any[]);
    const [filterTerm, setFilterTerm] = useState('');
    const [filterTermDebounce] = useDebounce(filterTerm, 600);

    const handleSearch = (value) => {
        setFilterTerm(value)
    }

    useEffect(() => {
        if (filterTermDebounce.length === 0) {
            setEmployeeList([])
            setSelectedEmployee(null)
        }
        else {
            filterTermDebounce.length > 0 && axios.get(DEPARTMENT_EMPLOYEE_OUTSIDE_API, { params: { page: 1, department_id: department.uid, email: filterTermDebounce } })
                .then(response => response.data.data.items)
                .then(data => {
                    let employees = data.map(item => {
                        return {
                            value: item.uid,
                            label: (
                                <Space>
                                    <Tag color="blue">{getShortRoleDescription(item.role)}</Tag>
                                    <Text>
                                        {item.fullname} ({item.email})
                                    </Text>
                                </Space>
                            ),
                        }
                    })
                    setEmployeeList(employees);
                })
        }
    }, [filterTermDebounce, department])

    const handleOk = () => {
        console.log('selectedEmployee', selectedEmployee)
        axios.post(DEPARTMENT_EMPLOYEE_UPDATE_API, { department_id: department.uid, employee_id: selectedEmployee })
            .then(() => {
                setSelectedEmployee(null)
                setEmployeeList([])
                setFilterTerm('')
                close(true)
            })
    }

    const handleSelect = (value) => {
        setSelectedEmployee(value)
    }

    const handleClose = () => {
        setSelectedEmployee(null)
        setEmployeeList([])
        setFilterTerm('')
        close(false)
    }

    return (
        <Modal
            title='Thêm nhân sự vào khoa'
            open={open}
            centered
            // style={{ maxWidth: '1000pt', minWidth: '600pt' }}
            onOk={handleOk}
            okText='Thêm'
            okButtonProps={{ disabled: !selectedEmployee }}
            cancelText='Huỷ'
            onCancel={handleClose}
            destroyOnClose={true}
            styles={{ body: { marginTop: 20, marginBottom: 35 } }}
        >
            <Select
                placeholder='Nhân viên...'
                options={employeeList}
                showSearch
                // filterOption={filterOption}
                filterOption={false}
                onSearch={handleSearch}
                popupMatchSelectWidth={false}
                listHeight={400}
                style={{ width: '100%' }}
                // suffixIcon={<i className="fa-solid fa-magnifying-glass fa-fw text-muted"></i>}
                onSelect={handleSelect}
                notFoundContent={null}
                value={selectedEmployee}
                allowClear={true}
                onClear={() => {
                    setSelectedEmployee(null)
                    setEmployeeList([])
                }}
            />
        </Modal>
    )
}