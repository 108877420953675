/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Modal, DatePicker, TimePicker, Flex, DatePickerProps, Checkbox, CheckboxProps} from 'antd'
import type {Dayjs} from 'dayjs'
import './RemoveScheduleModal.scss'
import dayjs from 'dayjs'
import {RemoveWorkPlanRequestParam} from '../core/Model'
import moment from 'moment'

interface Props {
  open: boolean
  toggleModal: () => void
  handleRemoveWorkplan: (param: RemoveWorkPlanRequestParam) => void
  removeWorkplanError: String
  setRemoveWorkplanError: (text: any) => void
  userId : string
  userName: string
}

export const RemoveScheduleModal: React.FunctionComponent<Props> = (props:Props) => {
  const fromDateI = new Date();
  fromDateI.setHours(0);
  fromDateI.setMinutes(0)
  fromDateI.setSeconds(0);
  const initialState = {
    errorText: '',
    fromDate: dayjs(fromDateI),
    fromTime: dayjs(fromDateI),
    toTime: dayjs(fromDateI),
    onBlurFromSet: false,
    onBlurToSet: false,
    onBlurDate: false,
    allDay: false
  }

  const [{fromDate, fromTime, toTime, errorText,
    onBlurFromSet, onBlurToSet, onBlurDate, allDay: isRemoveAllDay
  }, setState] = useState(initialState)

  useEffect(() => {
    if (errorText === '' && props.removeWorkplanError !== '') {
      onChangeState('errorText', props.removeWorkplanError)
      props.setRemoveWorkplanError('')
    }
  }, [props.removeWorkplanError])
  const onChangeState = (name, value) => {
    if (errorText !== '' && name !== "errorText") {
      console.log('clean errorText')
      setState(prevState => ({...prevState, errorText: ''}))
    }
    setState(prevState => ({...prevState, [name]: value}))
  }

  const dateFormat = 'DD/MM/YYYY';
  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setState(prevState => ({...prevState, fromDate: date, onBlurDate: true, errorText: ''}))
  };
  const onChangeStartTime = (date: Dayjs) => {
    setState(prevState => ({...prevState, fromTime: date, onBlurFromSet: true, errorText: ''}))
  }
  const onChangeEndTime = (date: Dayjs) => {
    // onChangeState("toDate", toDate.set('hour', date.get('hour')))
    setState(prevState => ({...prevState, toTime: date, onBlurToSet: true, errorText: ''}))
  }

  const disabledHours = () => {
    const hours: number[] = [];
    const currentHour = fromTime.get('hour')
    for (let i = currentHour - 1; i >=0; i--) {
      hours.push(i);
    }
    return hours;
  };

  const disabledFromHours = () => {
    const hours: number[] = [];
    if (!onBlurToSet) return hours;
    const currentHour = toTime.get('hour')
    for (let i = 23; i > currentHour; i--) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes: number[] = [];
    const currentMinute = fromTime.minute();
    console.log(selectedHour, fromTime.hour())
    if (selectedHour === fromTime.hour()) {
      if (currentMinute === 0) {
        minutes.push(currentMinute);
      }
      for (let i = currentMinute + 1; i >=     0; i--) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  const disabledFromMinutes = (selectedHour) => {
    const minutes: number[] = [];
    if (!onBlurToSet) return minutes;
    const currentMinute = toTime.minute();
    if (selectedHour === toTime.hour()) {
      for (let i = currentMinute; i <= 45  ; i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const isValidData = () => {
    return !(!onBlurDate || (!isRemoveAllDay && (!onBlurToSet || !onBlurFromSet)) || errorText !== '');
  }
  const onChangeAllDay: CheckboxProps['onChange'] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setState(prevState => ({...prevState, allDay: e.target.checked, errorText: '' }))
  };

  return (
    <Modal
      title={<span className={'ant-modal-header'}>Xoá lịch làm việc</span>}
      centered
      open={props.open}
      closable={true}
      maskClosable={false}
      onOk={() => {
        if (isValidData()) {
          if (isRemoveAllDay) {
            props.handleRemoveWorkplan({
              date: moment(fromDate.toISOString()).format('YYYY/MM/DD')
            })
          } else {
            props.handleRemoveWorkplan({
              date: moment(fromDate.toISOString()).format('YYYY/MM/DD'),//fromDate.format('YYYY/MM/DD'), //24/04/2024 => 2024/04/24
              start_time: moment(fromTime.toISOString()).format('HH:mm:ss'), // -7 : 05:00 => 22:00
              end_time: moment(toTime.toISOString()).format('HH:mm:ss')
            })
          }
        }
      }}
      okButtonProps={{disabled: !onBlurDate || (!isRemoveAllDay && (!onBlurToSet || !onBlurFromSet))}}
      destroyOnClose={true}
      afterClose={() => setState({...initialState})}
      onCancel={props.toggleModal}
      width={1000}
      closeIcon={true}
      className={'modalStyle'}
      cancelText={"Huỷ"}
      okText={"Xác nhận"}
    >
      <h3>{props.userName}</h3>
      <p>Chọn ngày:</p>
      <DatePicker
        format={dateFormat}
        defaultValue={null}
        minDate={dayjs(new Date())}
        // maxDate={dayjs(new Date()).endOf('week')}
        onChange={onChangeDate}
        inputReadOnly={true}
        placeholder={'Chọn ngày'}
      />
      <p>Xoá cả ngày: <Checkbox value={isRemoveAllDay} onChange={onChangeAllDay}></Checkbox></p>

      <p>Chọn giờ:</p>
      <Flex gap={'24px'}>
        <TimePicker
          disabled={isRemoveAllDay}
          defaultOpenValue={dayjs('00:00', 'HH:mm')}
          inputReadOnly={true} use12Hours={false} minuteStep={15} format={"HH:mm"}
          showNow={false}
          clearIcon={false}
          onChange={(date) => onChangeStartTime(date)} placeholder={'Giờ bắt đầu'} needConfirm={false}
          disabledMinutes={disabledFromMinutes}
          disabledHours={disabledFromHours}
        />
        <TimePicker
          disabled={isRemoveAllDay}
          defaultOpenValue={dayjs('00:00', 'HH:mm')}
          showNow={false}
          clearIcon={false}
          inputReadOnly={true} use12Hours={false} minuteStep={15} format={"HH:mm"}
          onChange={onChangeEndTime} placeholder={'Giờ kết thúc'} needConfirm={false}
          disabledHours={disabledHours}
          disabledMinutes={disabledMinutes}
        />
      </Flex>
      <p className={'error-text'}>{errorText}</p>
    </Modal>
  )
}
