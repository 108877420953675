import { Avatar, Button, ConfigProvider, Space, Table, Typography } from "antd"
import Column from "antd/es/table/Column"
import { DEPARTMENT_EMPLOYEE_API, DEPARTMENT_EMPLOYEE_UPDATE_API, getRoleDescription } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useReducer, useState } from "react"
import { useNavigate } from "react-router-dom"

const { Text } = Typography

export const DepartmentEmployeeList = ({ department, update }) => {

    const [employeeList, setEmployeeList] = useState([] as any[])
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const pageSize = 5
    const navigate = useNavigate()
    const [updateCounter, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        axios.get(DEPARTMENT_EMPLOYEE_API, { params: { department_id: department.uid, page: page, per_page: pageSize } })
            .then(response => response.data.data)
            .then(data => {
                setEmployeeList(data.items)
                setMaxPage(data.max_page)
            })
    }, [department, page, update, updateCounter])

    const handleDelete = (employee) => {
        axios.delete(DEPARTMENT_EMPLOYEE_UPDATE_API, { data: { department_id: department.uid, employee_id: employee } })
            .then(() => {
                forceUpdate()
            })
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: '#fff',
                        colorText: '#78829d',
                    }
                },
            }}>
            <Table
                dataSource={employeeList}
                rowKey="uid"
                pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page, pageSize: pageSize }}
                onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
                onRow={() => ({ className: 'fw-bolder fs-7' })}
                size='small'
                locale={{ emptyText: 'Chưa có nhân sự thuộc khoa' }}
            >
                <Column title='Tài khoản' dataIndex="fullname" render={
                    (_, record: any) =>
                        <Space role="button" onClick={() => navigate(`/account/id/${record.uid}`)}>
                            <Avatar src={record.avatar ? record.avatar : '/media/avatars/blank.png'} size={32} style={{ marginRight: 5 }} />
                            <Space.Compact direction='vertical' size='small'>
                                <Text strong>{record.fullname}</Text>
                                {/* <Text type='secondary'>{record.phone ? formatPhone(record.phone) : 'Không có thông tin'}</Text> */}
                            </Space.Compact>
                        </Space>
                } />
                <Column title="Email" dataIndex="email" />
                <Column title="Loại tài khoản" dataIndex="role" render={(value) => getRoleDescription(value)} />
                {/* <Column title="Truy cập" dataIndex="last_online_at" render={(value) => getRelativeTime(value)} /> */}
                {/* <Column title="Ngày tạo" dataIndex="created_at" render={(value) => getRelativeTime(value)} /> */}
                <Column title="Thao tác" align="end" render={(_, record: any) =>
                    <Space>
                        {/* <Button className="text-hover-primary" icon={<i className="fa-solid fa-comments fs-5"></i>} disabled={!record.livechat.is_connected} /> */}
                        <Button className="text-hover-primary" icon={<i className="fa-regular fa-trash-can fs-5"></i>} onClick={() => handleDelete(record.uid)} />
                    </Space>
                }
                />
            </Table>
        </ConfigProvider>
    )

}