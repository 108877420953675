import React, {useEffect, useState, useCallback} from 'react'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'

import axios from 'axios'

import {PageLink, PageTitle} from '_metronic/layout/core'
import {
  calendarBCrs,
  FilePreview,
  dateFormat,
  DropdownWithSearch as CommonDropdownWithSearch,
} from 'app/modules/apps/patient-management/calendar/calendar'
import {run, runLayout, transformProgram} from 'utils/functional/BuildLayout'

import {useAuth} from 'app/modules/auth/core/Auth'

/* import {toAbsoluteUrl} from '@utils/helpers'*/

import {
  Input as AntdInput,
  Space,
  DatePicker,
  Card,
  Button,
  Avatar,
  Typography,
  Divider,
  Flex,
  Col,
  Row,
  Tabs,
  Spin,
  notification,
} from 'antd'
import {LeftOutlined, VideoCameraOutlined} from '@ant-design/icons'

import clsx from 'clsx'
import moment from 'moment'

import * as yup from 'yup'
import {useFormik} from 'formik'
import dayjs from 'dayjs'

import {trie, insert} from 'utils/functional/tree'

import {group} from 'utils/functional/misc'

import {useCalendar} from 'app/modules/apps/patient-management/calendar/CalendarProvider'
import {usePatient} from 'app/modules/apps/patient-management/patient/PatientProvider'

import {
  getGeneric,
  callGeneric,
  AGORA_TOKEN_URL,
  APPOINTMENT_INFO_URL,
  DISEASE_GROUP_URL,
  CONCLUSION_URL,
  MARK_JOIN_CONFERENCE_URL,
  getAppointmentWithExtraInfo,
  getMedicalHistory,
} from 'app/modules/apps/patient-management/calendar/core/_requests'

import {getGenderDescription, MEDICINE_LIST_API, ICD9_LIST_API} from '../../../helpers/Common'
import MedicalHistory from './MedicalHistory'

import {WithShareDataPage} from 'app/modules/apps/patient-management/patient/ShareDataPage'

/* import './calendar-detail.scss'*/
import './detail.scss'

const {TextArea} = AntdInput
const {Text, Title} = Typography

let store = trie()

const controlButtonStyle = {
  style: {
    /* width: `5rem`,*/
  },
}

const controlsButtonWidth = '70px'

function* dataRowProgram(inject): Generator<any, any, any> {
  const {
    fc,
    field,
    controlFc,
    colList,
    colSpan,
    justify = '',
    /* translate,*/
    disabled,
    disabledAddAll,
    onEdit = () => {},
    transformDisplayData = (e) => e,
  } = inject

  const {
    values: {_editIndex},
  } = controlFc

  yield {
    anchor: true,
  }

  const data = transformDisplayData(fc.getFieldMeta(field).value)

  /* if(field!=='suggestion')
   * debugger;*/
  for (const si in data) {
    const i = parseInt(si)
    const d = data[i]

    const numCol = d.length + 1
    const span = colSpan || Math.floor(24 / numCol)

    yield {
      anchor: true,
      classes: [`row-${i}`].join(' '),
    }

    yield {
      anchor: true,
      classes: [`col-data-ahead`].join(' '),
    }

    for (const e of d) {
      yield (
        <Col span={span} style={{paddingLeft: 11}} className=''>
          <Text>{e}</Text>
        </Col>
      )
    }

    yield {
      classes: [`col-data-container`, 'tw-grow'].join(' '),
      type: Row,
      attributes: {
        key: `col-data-container-${i}`,
        justify: 'space-between',
      },
    }

    yield (
      <Col className='controls'>
        <Flex gap={'0.5rem'}>
          <Button
            {...controlButtonStyle}
            disabled={disabled || disabledAddAll || i === _editIndex}
            type='primary'
            onClick={() => {
              const dict = colList
                .map((c, i) => {
                  let res = {[c]: d[i]}

                  if (c.includes('time')) {
                    res = {[c]: dayjs(d[i], dateFormat)}
                  }

                  return res
                })
                .reduce((acc, e) => {
                  return {...acc, ...e}
                })

              controlFc.setValues({...dict, _editIndex: i})

              onEdit(dict)
            }}
            icon={<i className='fa-regular fa-pen-to-square fs-5'></i>}
          ></Button>

          <Button
            style={{
              ...controlButtonStyle,
              ...(disabled || disabledAddAll || i === _editIndex ? {} : {color: 'red'}),
            }}
            disabled={disabled || disabledAddAll || i === _editIndex}
            onClick={() => {
              const {value: data} = fc.getFieldMeta(field)
              const left = data.slice(0, i)
              const right = data.slice(i + 1)

              fc.getFieldHelpers(field).setValue([...left, ...right])
            }}
            icon={<i className='fa-solid fa-trash fs-5'></i>}
          ></Button>
        </Flex>
      </Col>
    )

    yield {
      type: Row,
      attributes: {
        justify: 'space-between',
      },
      classes: clsx(`data-row tw-gap-2 data-row-${i}`, {
        edit: i === _editIndex,
      }),
    }
  }

  yield {
    type: Flex,

    attributes: {
      gap: '0.5rem',
      vertical: true,
      style: {
        justify,
        marginTop: '0.5rem',
        /* display: 'none'*/
      },
    },
  }
}

function* headerRowControlProgram(inject): Generator<any, any, any> {
  const {
    fc,
    field,
    controlFc,
    colList,
    disabled,
    disabledAdd,
    translate,
    disabledAddAll,
    onInput = () => {},
    onSave = () => {},
    onAbort = () => {},
  } = inject

  const {
    values: {_editIndex},
  } = controlFc

  yield {
    anchor: true,
  }

  if (_editIndex < 0) {
    yield (
      <Button
        {...controlButtonStyle}
        /* Ensure Input button width is equal to Edit + Delete buttons  */
        style={{width: 'calc(32px * 2 + 0.5rem)'}}
        type='primary'
        disabled={disabledAdd || disabledAddAll}
        onClick={() => {
          const {values} = controlFc

          const datum = colList.map((e) => {
            let res = values[e] || ''

            /*  Timestamp data */
            if (res.format) {
              res = res.format(dateFormat)
            }

            return res
          })

          const {value} = fc.getFieldMeta(field)

          fc.getFieldHelpers(field).setValue([...value, datum])

          controlFc.setValues({_editIndex: -1})

          onInput({})
        }}
      >
        <div>{translate('input')}</div>
      </Button>
    )
  } else {
    yield (
      <Button
        {...controlButtonStyle}
        disabled={disabledAdd}
        onClick={() => {
          const {values} = controlFc

          const datum = colList.map((e) => {
            let res = values[e] || ''

            if (res.format) {
              res = res.format(dateFormat)
            }

            return res
          })

          const {value} = fc.getFieldMeta(field)

          const left = value.slice(0, _editIndex)

          const right = value.slice(_editIndex + 1)

          fc.getFieldHelpers(field).setValue([...left, datum, ...right])

          controlFc.setValues({_editIndex: -1})

          onSave({})
        }}
      >
        <div>{translate('save')}</div>
      </Button>
    )

    yield (
      <Button
        disabled={disabled}
        onClick={() => {
          controlFc.setValues({_editIndex: -1})

          onAbort({})
        }}
      >
        <div>{translate('abort')}</div>
      </Button>
    )
  }

  yield {
    classes: ['commit-header'].join(' '),
    type: Flex,
    attributes: {
      gap: '0.5rem',
    },
  }
}

function* suggestionProgram(inject): Generator<any, any, any> {
  const {controlFc, colList, translate, disabled, title, transformData} = inject

  yield {
    anchor: true,
  }

  yield (
    <Text style={baseText} className='title'>
      {title}
    </Text>
  )

  yield {
    anchor: true,
    classes: ['header-outlet'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['input-with-controls-ahead'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['col-input-ahead'].join(' '),
  }

  const nameColSpan = 10

  const numCol = colList.length
  const colSpan = Math.floor((24 - nameColSpan) / numCol)
  for (const col of colList) {
    /* yield <div>{col}</div>*/ const placeholder = translate(`${col}_placeholder`) || 'default'

    const fontSize = 12

    const width = placeholder.length * fontSize

    yield {anchor: true}

    yield <div className='tw-truncate'>{translate(col)}</div>

    yield (
      <AntdInput
        className={col}
        placeholder={translate(`${col}_placeholder`)}
        {...controlFc.getFieldProps(col)}
        status={controlFc.getFieldMeta(col).error ? 'error' : ''}
        style={{width}}
        disabled={disabled}
      ></AntdInput>
    )

    if (col === 'drug_name') {
      yield {
        type: Col,
        attributes: {span: nameColSpan},
        classes: [`${col}`].join(' '),
      }
    } else {
      yield {
        /* type: Col,*/
        /* attributes: {width, minWidth: width, maxWidth: width, span: colSpan},*/
        attributes: {span: colSpan},
        classes: [`${col}`].join(' '),
      }
    }
  }

  yield {
    type: Row,
    classes: ['input-only-container'].join(' '),
    attributes: {
      justify: 'space-between',
      style: {
        maxWidth: '85%',
        width: '85%',
      },
    },
  }

  yield {
    anchor: true,
    classes: ['controls-ahead'].join(' '),
  }
  yield {
    anchor: true,
  }

  yield <div style={{height: '22px'}} className='title'></div>

  yield* headerRowControlProgram(inject)

  yield {
    classes: ['tw-flex', 'tw-flex-col'].join(' '),
  }

  yield {
    type: Col,
    classes: ['tw-flex', 'tw-items-start', 'controls-container'].join(' '),
    attributes: {
      //span: colSpan,
    },
  }

  yield {
    classes: ['header-row', 'input-with-controls-container'].join(' '),
    type: Row,
    attributes: {
      gap: '0.5rem',
      justify: 'space-between',
      align: 'start',
    },
  }

  if (transformData) {
    const p = transformProgram(dataRowProgram({...inject, justify: 'space-between'}), transformData)

    yield* p()
  } else {
    yield* dataRowProgram({...inject, justify: 'space-between'})
  }

  yield {
    classes: ['suggestion'].join(' '),
    attributes: {
      style: {...baseItem},
    },
  }
}

function* designationProgram(inject): Generator<any, any, any> {
  const {title} = inject

  yield {
    anchor: true,
  }

  yield (
    <Text style={baseText} className='title'>
      {title}
    </Text>
  )

  yield {
    anchor: true,
    classes: ['header-outlet'].join(' '),
  }

  yield {
    anchor: true,
  }

  yield* headerRowControlProgram(inject)

  yield {
    type: Col,
    attributes: {
      //span: colSpan,
    },
  }

  yield {
    classes: ['header-row'].join(' '),
    type: Row,
    attributes: {
      justify: 'space-between',
      style: {
        gap: '12px',
      },
      /* gutter: 8,*/
    },
  }

  yield* dataRowProgram({
    ...inject,
    colSpan: 20,
    transformDisplayData: (data) => {
      return (data || []).map((e) => e.slice(0, 1))
    },
  })

  yield {
    classes: ['designation'].join(' '),

    attributes: {
      //style: {...baseItem},
    },
  }
}

const baseText: any = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px',
  textAlign: 'left',
  color: '#000000D9',
}

const titleText: any = {
  ...baseText,
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '24px',
}

const baseItem: any = {
  marginBottom: '24px',
}

function* CalendarDetailprogram(extra: any = {}): Generator<any, any, any> {
  /* 1st row START */

  const {
    setMode,
    info,
    translate,
    setUID,
    refetch,
    disabledCall,
    icdFilter,
    currentUser,
    setDisabledAddAll,
    pathname,
  } = extra

  const {
    start_time,
    end_time,
    patientInfo = {},
    reason,
    status,
    uid: appointmentId,
    convertedImages = [],
    convertedVideos = [],
    convertedDocuments = [],
  } = info

  const {avatar} = patientInfo

  yield (
    <Button
      type='link'
      className='back'
      onClick={() => {
        refetch()
        setMode('list')
      }}
    >
      <Flex gap='0.5rem'>
        <LeftOutlined />
        <Text strong>{'Danh sách lịch hẹn'}</Text>
      </Flex>
    </Button>
  )

  yield {
    anchor: true,
  }

  yield <Title level={3}>Thông tin khách hàng</Title>

  yield {
    anchor: true,
    classes: ['time-start'].join(' '),
  }

  const time = [start_time, end_time].map((e) => moment(e * 1000).format('HH:mm'))
  const date = moment(start_time * 1000).format('DD/MM/YYYY')

  yield <Text strong>{time.join('-')}</Text>

  yield <Divider type='vertical' orientation='center' style={{height: '2rem'}}></Divider>

  yield <Text strong>{date}</Text>

  yield {
    classes: ['time'].join(' '),
  }

  yield {
    classes: ['header-row', 'tw-hidden'].join(' '),
    type: Flex,
    attributes: {
      justify: 'space-between',
    },
  }

  /* 1st row END */

  /* 2nd row START */

  yield {
    anchor: true,
    classes: ['info-row-start'].join(' '),
  }

  yield (
    <Flex align='center' justify='center'>
      {avatar ? (
        <Avatar src={avatar} style={{height: '94px', width: '94px'}} alt='' />
      ) : (
        <div
          className='tw-rounded-full tw-flex tw-justify-center tw-items-center'
          style={{width: '94px', height: '94px', background: '#FFD8BF'}}
        >
          <Title level={4} style={{color: '#FA541C'}}>
            {patientInfo?.fullname?.split(' ').slice(-1)[0]?.charAt('0')}
          </Title>
        </div>
      )}
    </Flex>
  )

  yield {
    anchor: true,
  }

  yield <Text style={titleText}>{translate('info')}</Text>

  const fieldList = ['fullname', 'gender', 'birthday', 'phone']

  for (const field of fieldList) {
    const value = patientInfo[field]

    yield {
      anchor: true,
    }

    yield <Text style={{...baseText, color: '#00000073'}}>{translate(field)}:</Text>

    if (field.includes('gender')) {
      yield <Text style={baseText}>{getGenderDescription(value || '')}</Text>
    } else if (field.includes('birthday')) {
      const dob = dayjs(value, 'YYYY/MM/DD').format('DD/MM/YYYY')

      yield <Text style={baseText}>{dob || value}</Text>
    } else if (field.includes('phone')) {
      const phone = (value || '').replace(/^(\+?84)(.*)/, `0$2`)

      yield <Text style={baseText}>{phone}</Text>
    } else {
      yield <Text style={baseText}>{value}</Text>
    }

    yield {
      classes: ['gender-dob', 'tw-grid', 'tw-grid-cols-2'].join(' '),
      //type: Flex,
      attributes: {
        justify: 'space-between',
      },
    }
  }

  yield {
    classes: ['name'].join(' '),
    type: Space,
    attributes: {
      direction: 'vertical',
      //style: {minWidth: '235px'},
    },
  }

  yield {
    anchor: true,
  }

  yield <Text style={titleText}>Lý do đặt khám</Text>

  yield <Text style={baseText}>{reason}</Text>

  yield {
    classes: ['reason'].join(' '),
    type: Space,
    attributes: {
      direction: 'vertical',
    },
  }

  yield {
    anchor: true,
  }

  yield <Text style={titleText}>fFile đính kèm</Text>

  yield (
    <Flex gap='0.5rem' className='hello'>
      {[...convertedImages, ...convertedVideos, ...convertedDocuments].map((e, i) => {
        return <FilePreview url={e} text={`file${i + 1}`}></FilePreview>
      })}
    </Flex>
  )

  yield {
    classes: ['attachment'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['status-outlet'].join(' '),
  }

  yield <Text style={titleText}>Trạng thái</Text>

  yield (
    <div>
      <Button
        disabled={disabledCall}
        style={disabledCall ? {} : {background: '#52C41A', color: 'white'}}
        onClick={async () => {
          if (pathname?.includes('medicine')) return

          const res = await getGeneric(AGORA_TOKEN_URL, {
            channel_name: appointmentId,
            uid: currentUser?.uid || 0,
          })

          /* allow call when still in meeting time */
          const {app_id = 'allow', token} = res

          if (app_id) {
            let params = new URLSearchParams({
              channel_id: appointmentId,
              app_id,
              token,
            })

            const callWindow = window.open(`/telemedicine/${appointmentId}`, '_blank', 'popup=no')

            callWindow?.addEventListener('load', async (e) => {
              const now = moment().unix()

              if (start_time <= now) {
                await callGeneric(
                  axios({
                    method: 'put',
                    /* headers: {'content-type': 'application/json'},*/
                    data: {appointment_id: appointmentId},
                    url: MARK_JOIN_CONFERENCE_URL,
                  })
                )

                setDisabledAddAll(false)
                refetch()
              }
            })

            callWindow?.addEventListener('callWindowLoaded', () => {
              callWindow?.dispatchEvent(new CustomEvent('appointmentInfo', {detail: info}))
            })

            callWindow?.addEventListener('joinConferenceSuccess', (e: any) => {
              const {detail: {uid = ''} = {}} = e
              if (uid) {
                /*  Trigger React update */
                setUID('')
                setUID(uid)
                params = {...params}
              }
              refetch()
            })
          }
        }}
      >
        <Flex gap='0.5rem'>
          {!disabledCall ? <VideoCameraOutlined style={{color: 'white'}} /> : null}
          <Text
            style={{...titleText, ...(!disabledCall ? {color: 'white'} : {}), fontWeight: '400'}}
          >
            {disabledCall ? translate(status || 'default') : translate('Call')}
          </Text>
        </Flex>
      </Button>
    </div>
  )

  yield {
    classes: ['status'].join(' '),
  }

  yield {
    classes: ['info-row', 'tw-grid', 'tw-grid-cols-5'].join(' '),
    //type: Flex,
    attributes: {
      style: {
        gap: '2rem',
        /* paddingLeft: '24px',*/
        /* paddingRight: '24px',*/
        marginBottom: '64px',
      },
      /* justify: 'space-between',*/
    },
  }

  /* 2nd row END */

  /* 3rd row START */

  yield {
    anchor: true,
  }

  yield (
    <Text style={{...titleText}} strong className='title'>
      Nhập kết quả
    </Text>
  )

  yield {
    classes: ['control-row', 'blank'].join(' '),
    type: Space,
    attributes: {
      style: {
        marginBottom: '16px',
      },
    },
  }

  /* 3rd row END */

  /* 4th row START */

  yield {
    anchor: true,
    classes: ['symptom-outlet'].join(' '),
  }

  yield* inputProgram({
    title: 'appointment_symptom',
    classes: ['symptom'].join(' '),
    ...extra,
    field: 'symptom',
  })

  yield {
    classes: ['control-row', 'symptom-outlet-end'].join(' '),
    attributes: {
      style: {...baseItem},
    },
  }

  /* 4th row END */

  yield {
    anchor: true,
  }

  /*   yield DropDownWithSearch({...extra, title: 'appointment_diagnostic', field: 'diagnostic'})*/

  yield icdFilter

  yield {
    classes: ['control-row'].join(' '),

    attributes: {
      style: {...baseItem},
    },
  }

  yield {
    anchor: true,
  }

  yield* inputProgram({
    title: 'appointment_explain_diagnostic',
    classes: ['explain'].join(' '),
    ...extra,
    field: 'explain_diagnostic',
  })

  yield {
    classes: ['control-row', 'explain-outlet'].join(' '),

    attributes: {
      style: {...baseItem},
    },
  }

  /* yield**/

  /* yield <div>{drugSuggestion.length}</div>*/

  yield {
    anchor: true,
  }

  yield* freetext({title: 'appointment_note', classes: ['note'].join(' '), ...extra, field: 'note'})

  /* yield <div>{drugSuggestion.length}</div>*/

  yield {
    classes: ['control-row'].join(' '),

    attributes: {
      style: {...baseItem},
    },
  }

  yield {
    classes: ['calendar-detail'].join(' '),
  }
}

const baseInput = {
  /* border: '1px solid #D9D9D9',*/
  /* background: '#FFFFFF'*/
  marginTop: '8px',
}

function* freetext(inject): Generator<any, any, any> {
  const {title, classes, fc, field, translate, disabled = false} = inject

  yield {
    anchor: true,
  }

  yield (
    <Text style={baseText} className={clsx('')}>
      {translate(title)}
    </Text>
  )

  yield (
    <TextArea
      showCount
      maxLength={100}
      {...fc?.getFieldProps(field)}
      placeholder={translate(`${title}_placeholder`)}
      disabled={disabled}
      style={baseInput}
    ></TextArea>
  )

  yield {
    classes,
  }
}

const inputProgram = (inject) => {
  return transformProgram(freetext(inject), (pl) => {
    let res = pl
    const {field, disabled, title, fc, translate} = inject

    const i = (
      <AntdInput
        {...fc?.getFieldProps(field)}
        placeholder={translate(`${title}_placeholder`)}
        style={{...baseInput, color: '#4B5675'}}
        disabled={disabled}
        variant='outlined'
      ></AntdInput>
    )

    res.splice(2, 1, i)
    return res
  })()
}

function* submitProgram(inject): Generator<any, any, any> {
  const {submit, saveDraft, translate, disabled} = inject

  yield {
    anchor: true,
  }

  yield (
    <div className='submit'>
      <Button type='primary' onClick={submit} disabled={disabled}>
        {translate('submit-result')}
      </Button>
    </div>
  )

  yield (
    <div className='draft' onClick={saveDraft}>
      <Button disabled={disabled}>{translate('save-draft')}</Button>
    </div>
  )

  yield {
    classes: ['commit-row'].join(' '),
    type: Flex,
    attributes: {
      justify: 'flex-end',
      gap: '0.5rem',
    },
  }
}

const getICDList = (term, page, info = {}) => {
  return callGeneric(
    axios({
      method: 'get',
      params: {
        vi_name: term,
        code: term,
        /* phone: term,*/
        page: page,
        /* role: JSON.stringify(['ICD']),*/
        ...info,
      },
      url: DISEASE_GROUP_URL,
    })
  )
}

const getMedicineList = (term, page, info = {}) => {
  return callGeneric(
    axios({
      method: 'get',
      params: {
        name_or_active_ingredient: term,
        page: page,
        ...info,
      },
      url: MEDICINE_LIST_API,
    })
  )
}

const getServiceList = (term, page, info = {}) => {
  return callGeneric(
    axios({
      method: 'get',
      params: {
        name: term,
        page: page,
        ...info,
      },
      url: ICD9_LIST_API,
    })
  )
}

const IcdFilter = ({disabled = false}) => {
  const intl = useIntl()

  const translate = (id, extra = {}) => intl.formatMessage({id, ...extra})

  const {
    icdFilterList: optionList,
    setIcdFilterList: setOptionList,
    icdSelectedList: checkedList,
    setIcdSelectedList: setCheckedList,
  } = useCalendar()

  /* const setCheckedList = useCallback((list) => {
   *     setDoctorSelectedList(list.map(e => JSON.parse(e)))
   * }, [setDoctorSelectedList]);*/

  /* const [disabled] = useState(false)*/

  const getDisplay = (e) => {
    let info = e

    if (!info.disease_code) info = JSON.parse(info)
    const {disease_code, vi_disease_name} = info

    return `${disease_code} - ${vi_disease_name}`
  }

  const transformDropdown = (pl) => {
    let res = pl

    /*
           /  Add Flex Wrap for selection Container
           /*/
    const containerIndex = res.findIndex((e) =>
      e?.classes
        ?.split(/\s+/)
        ?.map((e) => e.trim())
        ?.filter((e) => e)
        .includes('selection-container')
    )

    if (containerIndex > -1) {
      res.splice(containerIndex, 1, {
        type: Flex,
        classes: ['selection-container', 'tw-flex-wrap'].join(' '),
        attributes: {
          gap: '0.5rem',
          style: {
            paddingLeft: '0.5rem',
            width: '100%',
            //height: '2rem',
          },
        },
      })
    }

    /*
           / Remove fixed height
           /*/
    const dataContainerIndex = res.findIndex((e) =>
      e?.classes?.includes('selection-data-container')
    )

    if (dataContainerIndex > -1) {
      res.splice(dataContainerIndex, 1, {
        classes: [
          'selection-data-container',
          'tw-rounded-lg',
          'tw-overflow-hidden',
          'tw-flex',
          'tw-items-center',
        ].join(' '),
        attributes: {
          style: {
            background: disabled ? 'rgba(0, 0, 0, 0.04)' : '#F9F9F9',
            //border: '1px solid  #d3d8dd',
            /* height: '32px',*/
            padding: '2px',
          },
        },
      })
    }

    return res
  }

  return CommonDropdownWithSearch({
    optionList,
    setOptionList,
    checkedList,
    setCheckedList,
    api: getICDList,
    getDisplay,
    transformDropdown,
    title: 'appointment_diagnostic',
    disabled,
    attributes: {
      trigger: ['click'],
      style: {
        width: '100%',
      },
    },
    placeholder: translate('appointment_diagnostic_2'),
    multiple: true,
  })
}

const getCustomizeSelection = (sel, getDisplay, checkedList, setCheckedList) => {
  return (
    <Flex className='tw-max-w-full tw-whitespace-normal tw-border-none' gap='0.5rem'>
      {getDisplay(sel)}

      <i
        className='fa-solid fa-xmark tw-text-gray-400 tw-cursor-pointer'
        onClick={(event) => {
          const i = checkedList.findIndex((e) => {
            event.preventDefault()
            let res = e === sel
            if (e.value) {
              res = e.value === sel.value
            }

            return res
          })

          if (i > -1) {
            const left = checkedList.slice(0, i)
            const right = checkedList.slice(i + 1)

            setCheckedList([...left, ...right])
          }
        }}
      ></i>
    </Flex>
  )
}

const getDrugNameDisplay = (info) => {
  const {drug_name, active_ingredient, drug_id_code} = info

  return (
    <div className='tw-max-w-4xl' key={`${drug_id_code}`}>
      <span
        className='tw-font-bold tw-break-words'
        key={`${drug_id_code}_name`}
      >{`${drug_name} `}</span>
      <span
        className='tw-italic tw-break-words'
        key={`${drug_id_code}_ingredient`}
      >{`[${active_ingredient}] `}</span>
    </div>
  )
}

const MedicineListFilter = ({disabled = false}) => {
  const intl = useIntl()

  const translate = (id, extra = {}) => intl.formatMessage({id, ...extra})

  const {
    medicineListFilterList: optionList,
    setMedicineListFilterList: setOptionList,
    medicineListSelectedList: checkedList,
    setMedicineListSelectedList: setCheckedList,
  } = useCalendar()

  /* const setCheckedList = useCallback((list) => {
   *     setDoctorSelectedList(list.map(e => JSON.parse(e)))
   * }, [setDoctorSelectedList]);*/

  /* const [disabled] = useState(false)*/

  const getDisplay = (e) => {
    let info = e

    if (!info.drug_id_code) info = JSON.parse(info)

    return getDrugNameDisplay(info)
  }

  const transformDropdown = useCallback(
    (pl) => {
      let res = pl

      /*
           /  Add Flex Wrap for selection Container
           /*/
      const containerIndex = res.findIndex((e) =>
        e?.classes
          ?.split(/\s+/)
          ?.map((e) => e.trim())
          ?.filter((e) => e)
          .includes('selection-container')
      )

      if (containerIndex > -1) {
        res.splice(containerIndex, 1, {
          type: Flex,
          classes: ['selection-container', 'tw-flex-wrap'].join(' '),
          attributes: {
            gap: '0.5rem',
            style: {
              paddingLeft: '0.5rem',
              width: '100%',
              //height: '2rem',
            },
          },
        })
      }

      /*
           / Remove fixed height
           /*/
      const dataContainerIndex = res.findIndex((e) =>
        e?.classes?.includes('selection-data-container')
      )

      if (dataContainerIndex > -1) {
        res.splice(dataContainerIndex, 1, {
          classes: [
            'selection-data-container',
            'tw-rounded-lg',
            'tw-overflow-hidden',
            'tw-flex',
            'tw-items-center',
          ].join(' '),
          attributes: {
            style: {
              background: disabled ? 'rgba(0, 0, 0, 0.04)' : '#F9F9F9',
              //border: '1px solid  #d3d8dd',
              /* height: '32px',*/
              padding: '2px',
            },
          },
        })
      }

      /*  customize data  */
      const selectionListIndex = pl.findIndex((e) => e?.classes?.includes('selection-outlet'))

      if (selectionListIndex > -1 && checkedList.length) {
        const data = checkedList.map((sel) =>
          getCustomizeSelection(sel, getDisplay, checkedList, setCheckedList)
        )

        res.splice(selectionListIndex + 1, checkedList.length, ...data)
      }

      return res
    },
    [checkedList, setCheckedList, disabled]
  )

  useEffect(() => {
    return () => {
      setCheckedList([])
    }
  }, [setCheckedList])

  return CommonDropdownWithSearch({
    optionList,
    setOptionList,
    checkedList,
    setCheckedList,
    api: getMedicineList,
    getDisplay,
    transformDropdown,
    title: 'drug_name_placeholder',
    disabled,
    attributes: {
      trigger: ['click'],
      style: {
        width: '100%',
      },
    },
    placeholder: translate('drug_name_placeholder'),
    multiple: false,
  })
}

const ServiceListFilter = ({disabled = false}) => {
  const intl = useIntl()

  const translate = (id, extra = {}) => intl.formatMessage({id, ...extra})

  const {
    serviceListFilterList: optionList,
    setServiceListFilterList: setOptionList,
    serviceListSelectedList: checkedList,
    setServiceListSelectedList: setCheckedList,
  } = useCalendar()

  /* const setCheckedList = useCallback((list) => {
   *     setDoctorSelectedList(list.map(e => JSON.parse(e)))
   * }, [setDoctorSelectedList]);*/

  /* const [disabled] = useState(false)*/

  const getDisplay = (e) => {
    let info = e

    if (!info.code_43_50_21) info = JSON.parse(info)
    const {name_43_50_21} = info

    return name_43_50_21
  }

  const transformDropdown = (pl) => {
    let res = pl

    /*
                 /  Add Flex Wrap for selection Container
                 /*/
    const containerIndex = res.findIndex((e) =>
      e?.classes
        ?.split(/\s+/)
        ?.map((e) => e.trim())
        ?.filter((e) => e)
        .includes('selection-container')
    )

    if (containerIndex > -1) {
      res.splice(containerIndex, 1, {
        type: Flex,
        classes: ['selection-container', 'tw-flex-wrap'].join(' '),
        attributes: {
          gap: '0.5rem',
          style: {
            paddingLeft: '0.5rem',
            width: '100%',
            height: '2rem',
          },
        },
      })
    }

    /*
                 / Remove fixed height
                 /*/
    const dataContainerIndex = res.findIndex((e) =>
      e?.classes?.includes('selection-data-container')
    )

    if (dataContainerIndex > -1) {
      res.splice(dataContainerIndex, 1, {
        classes: [
          'selection-data-container',
          'tw-rounded-lg',
          'tw-overflow-hidden',
          'tw-flex',
          'tw-items-center',
        ].join(' '),
        attributes: {
          style: {
            background: disabled ? 'rgba(0, 0, 0, 0.04)' : '#F9F9F9',
            //border: '1px solid  #d3d8dd',
            /* height: '32px',*/
            padding: '2px',
          },
        },
      })
    }

    /*  Remove title */
    const titleAheadIndex = res.findIndex((e) =>
      e?.classes
        ?.split(/\s+/)
        ?.map((e) => e.trim())
        ?.filter((e) => e)
        .includes('selection-root-outlet')
    )

    if (titleAheadIndex > -1) {
      res.splice(titleAheadIndex + 1, 1)
    }

    /*  customize data  */
    const selectionListIndex = pl.findIndex((e) => e?.classes?.includes('selection-outlet'))

    if (selectionListIndex > -1 && checkedList.length) {
      const data = checkedList.map((sel) =>
        getCustomizeSelection(sel, getDisplay, checkedList, setCheckedList)
      )

      res.splice(selectionListIndex + 1, checkedList.length, ...data)
    }

    return res
  }

  useEffect(() => {
    return () => {
      setCheckedList([])
    }
  }, [setCheckedList])

  return CommonDropdownWithSearch({
    optionList,
    setOptionList,
    checkedList,
    setCheckedList,
    api: getServiceList,
    getDisplay,
    transformDropdown,
    title: 'appoinment_test_service_select',
    disabled,
    attributes: {
      trigger: ['click'],
      style: {
        width: '100%',
      },
    },
    placeholder: translate('appoinment_test_service_select'),
    multiple: false,
  })
}

const updateUI = ({disabled = false}) => {
  const list = Array.from(
    document.querySelectorAll(
      '.calendar-detail textarea, .calendar-detail input, .calendar-detail .selection-container'
    )
  )

  for (const el of list) {
    if (disabled) {
      /* @ts-ignore */
      el.style.cursor = 'not-allowed'
    } else {
      /* @ts-ignore */
      el.style.cursor = 'auto'
    }
  }
}

const CalendarDetail = (props) => {
  /* const [layout, setLayout] = useState(<div>detail 2</div>);*/

  const [showNotification, contextHolder] = notification.useNotification()

  const {uid: paramsUID} = useParams()

  const {pathname} = useLocation()

  const {currentUser} = useAuth()

  const intl = useIntl()

  const translate = useCallback((id, extra = {}) => intl.formatMessage({id, ...extra}), [intl])

  const navigate = useNavigate()

  const [disabled, setDisabled] = useState(true)
  const [disabledCall, setDisabledCall] = useState(true)
  const [disabledAddSuggestion, setDisabledAddSuggestion] = useState(true)
  const [disabledAddService, setDisabledAddService] = useState(true)

  const [disabledAddAll, setDisabledAddAll] = useState(true)

  const [disabledRemainDate, setDisabledRemainDate] = useState(true)

  const [initialLoad, setInitialLoad] = useState(true)

  const {
    extra: {
      setMode = () => {
        navigate(-1)
      },
      refetch = () => {},
      transformLayout = (pl) => pl,
    } = {},
  } = props || {}

  const data = props.data
  const {
    detailBookingInfo,
    setDetailBookingInfo,
    icdSelectedList,
    setIcdSelectedList,
    medicineListSelectedList,
    setMedicineListSelectedList,
    serviceListSelectedList,
    setServiceListSelectedList,
  } = useCalendar()

  console.log('detailBookingInfo: ', detailBookingInfo, data)

  const {
    conclusion: {
      symptom = '',
      explain_diagnostic = '',
      prescriptions = [],
      suggest_service = [],
      deadline_waiting = null,
      note = '',
      diagnostic: {icd: icd10_code = '', diagnostic_ex: name = ''} = {},
      diagnostic: diagnosticList = [],
      remain_date = null,
    } = {},
    status,
    //    diseaseInfo: diagnostic = {},
  } = data || detailBookingInfo

  const prescriptionList = prescriptions.map((e) => {
    const {drug_name, quantity, unit, drug_route, drug_frequence, take_medicine} = e

    return [drug_name, quantity, unit, drug_route, drug_frequence, take_medicine]
  })

  /*
    / Regex to parse <p> HTML tag
    /*/

  /*
    / Regex lookbehind
    /*/
  /* const suggestServiceMatchPattern = /<p>((?<!<\/p>).)*<\/p>/g

     * const suggestServiceMapPattern = /<p>(.*)<\/p>/

     * let splitted = (suggest_service || '').trim().match(suggestServiceMatchPattern)

     * if (!splitted) {
     *   splitted = (suggest_service || '').trim().split(',')
     * }

     * const suggestServiceList = splitted
     *   .filter((e) => e)
     *   .map((e) => {
     *     let res = e
     *     const execRes = suggestServiceMapPattern.exec(e)

     *     if (execRes && execRes.length) {
     *       const data = execRes[1]
     *       res = data
     *     }

     *     return [res]
     *   })*/

  const [drugSuggestion, setDrugSuggestion] = useState(prescriptionList)

  store = insert(store, 'setDrugSuggestion', setDrugSuggestion)

  store = insert(store, 'drugSuggestion', drugSuggestion)

  const schema = {
    suggestion: yup.array(),
    designation: yup.array(),

    symptom: yup.string(),
    diagnostic: yup.object().required(),
    explain_diagnostic: yup.string(),
    note: yup.string(),
  }

  /* debugger;*/

  const modelFormik = useFormik({
    initialValues: {
      suggestion: prescriptionList,

      /* designation: [['massage', '2024/12/12']],*/
      designation: suggest_service.map((e) => {
        const {name: service, code} = e

        return [service, code]
      }),

      symptom,
      diagnostic: {icd10_code, name, uid: icd10_code},
      //diagnostic: {...diagnostic, icd10_code, name},
      explain_diagnostic,
      note,
      remain_date: remain_date && dayjs.unix(remain_date),
    },

    validationSchema: yup.object().shape(schema),
    enableReinitialize: true,

    onSubmit: () => {},
  })
  /*
               / Form Control for suggestion
               /
               /*/
  const suggestionSchema = {
    drug_name: yup.string(),
    quantity: yup.number(),
    unit: yup.string(),
    drug_frequence: yup.string(),
    drug_route: yup.string(),
    take_medicine: yup.string(),

    /* private control fields */
    _editIndex: yup.number(),
  }

  const sFieldList = Object.keys(suggestionSchema).filter((e) => !e.match(/^_/))

  const sFieldOrder = {
    drug_name: 0,
    quantity: 1,
    unit: 2,
    drug_route: 3,
    drug_frequence: 4,
    take_medicine: 5,
  }

  sFieldList.sort((a, b) => sFieldOrder[a] - sFieldOrder[b])

  const sFormik = useFormik({
    initialValues: {_editIndex: -1},
    validationSchema: yup.object().shape(suggestionSchema),

    onSubmit: () => {},
    enableReinitialize: true,
  })

  /*
               / Form Control for designation
               /
               /*/

  const designationSchema = {
    service: yup.string(),
    code: yup.string(),
    time: yup.string(),

    _editIndex: yup.number(),
  }

  const dFieldList = Object.keys(designationSchema).filter((e) => !e.match(/(^_|time)/))

  const dFormik = useFormik({
    initialValues: {
      _editIndex: -1,
      ...(deadline_waiting ? {time: dayjs.unix(deadline_waiting)} : {}),
    },
    validationSchema: yup.object().shape(designationSchema),
    enableReinitialize: true,

    onSubmit: () => {},
  })

  const deadlineWaitingFormik = useFormik({
    initialValues: deadline_waiting ? {time: dayjs.unix(deadline_waiting)} : {},
    validationSchema: yup.object().shape(designationSchema),

    onSubmit: () => {},
  })

  const prepareDataWithKeyList = (keyList, dss) => {
    const res = dss.map((ds) => {
      const v = {}
      keyList.forEach((k, i) => {
        v[k] = ds[i]
      })
      return v
    })

    return res
  }

  /* Submit functions */

  const addConclusion = useCallback(
    async (inject) => {
      const {is_draft = true} = inject

      const {values, errors} = modelFormik

      if (!Object.keys(errors).length) {
        const record = data || detailBookingInfo

        const {designation: des, suggestion: sug} = values

        const suggestion = prepareDataWithKeyList(dFieldList, des)
        const prescriptions = prepareDataWithKeyList(sFieldList, sug).map((e) => {
          return {...e, quantity: parseInt(e.quantity)}
        })

        const {uid: appointment_id} = record

        const {
          symptom,
          /* diagnostic: {icd10_code: icd, name: diagnostic},*/
          explain_diagnostic,
          note,
          remain_date,
        } = values

        /* const deadline_waiting = suggestion?.length
         *   ? suggestion
         *       .map((e) => moment(e.time, dateFormat))
         *       .sort((a, b) => b.unix() - a.unix())[0]
         *       .add(1, 'day')
         *       .subtract(1, 'second')
         *       .format('YYYY/MM/DD HH:mm:ss')
         *   : null;*/

        /* @ts-ignore*/
        const deadline_waiting = deadlineWaitingFormik.values?.time
          ? deadlineWaitingFormik.values.time.format('YYYY/MM/DD HH:mm:ss')
          : ''

        const deadline_waiting_info = deadline_waiting ? {deadline_waiting} : {}

        const remain_date_info = remain_date
          ? {remain_date: remain_date.format('YYYY/MM/DD HH:mm:ss')}
          : {}

        const response = {
          appointment_id,
          is_draft,
          symptom,
          //icd,
          diagnostic: icdSelectedList
            .map((e) => JSON.parse(e))
            .map((e) => {
              const {disease_code, vi_disease_name} = e

              return {disease_code, vi_disease_name}
            }),
          explain_diagnostic,

          suggest_service: suggestion.map((e) => {
            const {service: name, code} = e

            return {name, code}
          }),
          /* .map((e) => e.service)
           * .map((e) => `<p>${e}</p>`)
           * .join(''),*/
          /* .join(','),*/
          ...deadline_waiting_info,
          ...remain_date_info,
          note,

          prescriptions,
        }

        console.log('submit ', response)

        let method = 'post'

        if (status.includes('WAITING_TEST_RESULT')) {
          const {deadline_waiting} = deadline_waiting_info
          const dw = moment(deadline_waiting, 'YYYY/MM/DD HH:mm:ss').unix()

          const today = moment().unix()

          if (today > dw) {
            /* method = 'put'*/
          }
        }

        const res = await axios({
          method,
          headers: {'content-type': 'application/json'},
          data: JSON.stringify(response),
          url: CONCLUSION_URL,
        })

        if (res?.data) {
          if (res.data.success) {
            showNotification.open({
              message: is_draft ? 'Lưu nháp' : 'Trả kết quả',
              description: is_draft
                ? 'Bạn đã lưu nháp thành công'
                : 'Bạn đã trả kết quả thành công',
              placement: 'topRight',
              icon: <i className='fa-solid fa-circle-check tw-text-green-500'></i>,
              
            })

            if (props.afterAddConclusion) {
              props.afterAddConclusion()
            }
          } else {
            const {
              error: {message = ''},
            } = res.data

            const requiredPattern = /field\s*\[(?<required>.*)\].*null/

            const matchRes = message.match(requiredPattern)

            let translated = message
            if (matchRes) {
              const {required} = matchRes.groups

              translated = translate(`appointment_${required.trim()}`).replace('*', '').trim()

              translated = `Vui lòng nhập ${translated}!`
            }

            showNotification.open({
              message: 'Lỗi',
              description: translated,
              placement: 'topRight',
              icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
              
            })
          }
        }

        console.log('submit res: ', res)
      }
    },
    [
      icdSelectedList,
      data,
      detailBookingInfo,
      modelFormik,
      dFieldList,
      sFieldList,
      status,
      deadlineWaitingFormik,
      showNotification,
      translate,
      props,
    ]
  )

  /* const nav = () => {
   *   if (data?.uid) {
   *     navigate('/appointment/list')
   *   } else {
   *     refetch()
   *     setMode('list')
   *   }
   * }*/

  const submit = async () => {
    await addConclusion({is_draft: false})
    /* nav()*/
  }

  const saveDraft = async () => {
    await addConclusion({is_draft: true})
    /* nav()*/
  }

  /*
    /
    /
    /*/

  const onEditSuggestion = useCallback(
    async (values) => {
      const {drug_name: dn} = values

      if (dn) {
        const pattern = /(.*) (\[.*\])/

        const drug_name = dn.replace(pattern, '$1')

        const res = await getMedicineList(drug_name, 1)

        const {items} = res

        if (items.length) {
          const [i] = items

          setMedicineListSelectedList([JSON.stringify(i)])
        }
      }
    },
    [setMedicineListSelectedList]
  )

  const onInputSuggestion = useCallback(
    (values) => {
      setMedicineListSelectedList([])
    },
    [setMedicineListSelectedList]
  )

  /*
    / Build UI
    /
    /*/

  const transformSuggestionData = useCallback(
    (pl) => {
      let res = pl

      const {
        values: {suggestion = []},
      } = modelFormik

      if (suggestion.length) {
        const processed = suggestion.reduce(
          (accList, data) => {
            const [acc, fromIndex] = accList

            const dataAheadIndex = acc.findIndex(
              (e, i) => i > fromIndex && e?.classes?.includes('col-data-ahead')
            )

            const dataContainerIndex = acc.findIndex(
              (e, i) => i > fromIndex && e?.classes?.includes('col-data-container')
            )

            if (dataAheadIndex > -1 && dataContainerIndex > -1) {
              let drugNameInfo = {drug_name: data[0], active_ingredient: '', drug_id_code: data[0]}

              const drugSplitPattern = /(?<drug_name>.*) \[(?<active_ingredient>.*)\]/

              const splitted = drugSplitPattern.exec(data[0])
              if (splitted) {
                drugNameInfo = {...drugNameInfo, ...splitted.groups}
              }

              const drugName = <Col span={15}>{getDrugNameDisplay(drugNameInfo)}</Col>

              const quantityWithUnit = (
                <Col span={3}>
                  <div>{`${data.slice(1, 3).join(' ')}`}</div>
                </Col>
              )

              const rest = (
                <Col span={6}>
                  <div>{data.slice(3).join(', ')}</div>
                </Col>
              )

              acc.splice(dataAheadIndex + 1, data.length, drugName, quantityWithUnit, rest)
            }

            return [acc, dataAheadIndex]
          },
          [res, -1]
        )

        res = processed[0]

        res.forEach((e) => {
          if (e?.classes?.includes('col-data-container')) {
            if (e.attributes) {
              e.attributes.style = {maxWidth: `calc(100% - ${controlsButtonWidth} - 12px)`}
            }
          }
        })
      }

      return res
    },
    [modelFormik]
  )

  const sp = transformProgram(
    suggestionProgram({
      /* colList: ['Gợi ý toa thuốc', 'Số lượng', 'Đơn vị', 'Đường', 'Liều dùng', 'Cách dùng'],*/
      colList: sFieldList,
      /* data: drugSuggestion,*/
      /* setData: setDrugSuggestion,*/
      title: translate('appointment_diagnostic_placeholder'),
      fc: modelFormik,
      field: 'suggestion',
      controlFc: sFormik,
      translate,
      disabled: disabled,
      disabledAdd: disabledAddSuggestion,
      disabledAddAll: false,
      onEdit: onEditSuggestion,
      onInput: onInputSuggestion,
      onSave: onInputSuggestion,
      onAbort: onInputSuggestion,

      transformData: transformSuggestionData,
    }),
    (pl) => {
      let res = pl

      const drugNameIndex = pl.findIndex((e) => e?.props?.className?.includes('drug_name'))

      if (drugNameIndex > -1) {
        /* Remove the title also */
        res.splice(drugNameIndex - 1, 2, <MedicineListFilter disabled={disabled} />)
      }

      /*
        / Responsive 
        /*/

      if (pathname?.includes('medicine')) {
        const inputWithControlsAheadIndex = res.findIndex((e) =>
          e?.classes?.includes('input-with-controls-ahead')
        )

        const inputAheadIndex = res.findIndex((e) => e?.classes?.includes('col-input-ahead'))

        const inputOnlyContainer = res.findIndex((e) =>
          e?.classes?.includes('input-only-container')
        )

        if (inputWithControlsAheadIndex > -1 && inputAheadIndex > -1 && inputOnlyContainer > -1) {
          const widgetList = res.slice(inputAheadIndex + 1, inputOnlyContainer)

          const medicineFilter = widgetList.slice(0, 3)

          const groupList = [medicineFilter, ...group(widgetList.slice(3), 4)]

          const nameColSpan = 16

          const firstRow = groupList.slice(0, 3)

          const drugName = firstRow.slice(0, 1).map((e) => {
            const [a, b, col] = e

            return [
              a,
              b,
              {
                ...col,
                type: Col,
                attributes: {
                  ...col.attributes,
                  span: nameColSpan - 1,
                },
              },
            ]
          })

          const quantityWithUnit = firstRow.slice(1).map((e) => {
            const [a, b, input, col] = e

            /* debugger;*/

            if (input?.props?.style?.width) {
              /* Reset style width */
              delete input.props.style.width
            }

            return [
              a,
              b,
              input,
              {
                ...col,
                type: Col,
                attributes: {
                  ...col.attributes,
                  span: 22 / 2,
                },
              },
            ]
          })

          const quantityWithUnitCol = [
            {anchor: true},

            {anchor: true},

            ...quantityWithUnit.flat(),
            {
              type: Row,
              attributes: {
                justify: 'space-between',
                style: {},
              },
            },
            {
              type: Col,
              attributes: {
                span: 24 - nameColSpan,
                justify: 'space-between',
                style: {},
              },
            },
          ]

          /*
                / column in 2nd row have equal span value 
                /*/
          const secondRow = groupList.slice(3).map((e, i) => {
            const [a, b, input, col] = e

            const colSpan = i < 2 ? 11 : 24

            if (input?.props?.style?.width) {
              /* Reset style width */
              delete input.props.style.width
            }

            return [
              a,
              b,
              input,
              {
                ...col,
                type: Col,
                attributes: {
                  ...col.attributes,
                  span: colSpan,
                },
              },
            ]
          })

          res.splice(
            inputAheadIndex + 1,
            widgetList.length,

            {anchor: true},
            {anchor: true},

            ...secondRow.slice(0, 2).flat(),

            {
              type: Row,
              attributes: {
                justify: 'space-between',
                style: {},
              },
            },

            {
              type: Col,
              attributes: {
                span: nameColSpan - 1,
              },
            },

            {anchor: true},

            ...secondRow.slice(2).flat(),

            {
              type: Col,
              attributes: {
                span: 24 - nameColSpan,
              },
            }
          )

          const {
            values: {_editIndex},
          } = sFormik

          const mw =
            _editIndex > -1
              ? `calc(100% - ${controlsButtonWidth}*2 - 12px)`
              : `calc(100% - ${controlsButtonWidth} - 12px)`

          res.splice(
            inputWithControlsAheadIndex + 1,
            0,
            {anchor: true},
            ...drugName.flat(),
            ...quantityWithUnitCol,

            {
              type: Row,
              attributes: {
                justify: 'space-between',
                style: {
                  maxWidth: mw,
                  width: mw,
                },
              },
            }
          )
        }
      }
      const inputOnlyContainer = res.findIndex((e) => e?.classes?.includes('input-only-container'))

      const {
        values: {_editIndex},
      } = sFormik

      if (inputOnlyContainer > -1) {
        res.splice(inputOnlyContainer, 1, {
          type: Row,
          classes: ['input-only-container'].join(' '),
          attributes: {
            justify: 'space-between',
            style: {
              flexGrow: 1,
              maxWidth:
                _editIndex > -1
                  ? `calc(100% - ${controlsButtonWidth}*2 - 12px)`
                  : `calc(100% - ${controlsButtonWidth} - 12px)`,
            },
          },
        })
      }

      return res
    }
  )

  const onEditService = useCallback(
    async (values) => {
      const {service: name} = values

      if (name) {
        const res = await getServiceList(name, 1)

        const {items} = res

        if (items.length) {
          const [i] = items

          setServiceListSelectedList([JSON.stringify(i)])
        }
      }
    },
    [setServiceListSelectedList]
  )

  const onInputService = useCallback(
    (values) => {
      setServiceListSelectedList([])
    },
    [setServiceListSelectedList]
  )

  const xDesignationProgram = transformProgram(
    designationProgram({
      title: 'Chỉ định dịch vụ',
      colList: dFieldList,
      /* data: [['massage', 'n2023/14/07']],*/
      fc: modelFormik,
      field: 'designation',
      controlFc: dFormik,
      translate,
      colSpan: 2,
      disabled,
      disabledAdd: disabledAddService,
      disabledAddAll,

      onEdit: onEditService,
      onInput: onInputService,
      onSave: onInputService,
      onAbort: onInputService,
    }),
    (pl) => {
      const headerIndex = pl.findIndex((e) => e.classes?.includes('header-outlet'))

      let res = pl

      if (headerIndex > -1) {
        const sel = (
          <Col className='tw-grow'>
            <ServiceListFilter disabled={disabled || disabledAddAll} />
          </Col>
        )

        res.splice(headerIndex + 1, 0, sel)

        /*
          / Add time below selected service
          /*/

        const timeTitle = (
          <div style={{marginTop: '24px'}}>{translate('appoinment_test_date_select')}</div>
        )

        const time = (
          <Col span={2 * 1.75} style={baseItem}>
            <DatePicker
              placeholder={translate('appoinment_test_date_select')}
              disabled={disabled || disabledAddAll}
              {...deadlineWaitingFormik.getFieldProps('time')}
              onChange={(e) => {
                deadlineWaitingFormik.getFieldHelpers('time').setValue(e)
              }}
              style={{width: '175px'}}
              format={dateFormat}
              minDate={dayjs(dayjs().add(1, 'days'), dateFormat)}
            ></DatePicker>
          </Col>
        )

        res.splice(res.length + 1, 0, timeTitle, time)
        /* res.splice(headerIndex + 1, 0, sel, time)*/
      }

      return res
    }
  )

  const dp = xDesignationProgram()

  const icdFilter = IcdFilter({disabled})

  const program = transformProgram(
    CalendarDetailprogram({
      setMode,
      refetch,
      fc: modelFormik,
      info: data || detailBookingInfo,
      translate,
      disabled,
      disabledCall,
      icdFilter,
      currentUser,
      setDisabledAddAll,
      pathname,
    }),
    (itemList: any[]) => {
      const i = itemList.findIndex((e) => e?.classes?.includes('explain-outlet'))

      let res = itemList

      if (i > -1) {
        /* Add suggestion UI */
        res.splice(i + 1, 0, ...sp())
      }

      /* Add designation UI */

      res = [...res, ...dp]

      /* Add remain_date */

      const remainTitle = (
        <Text style={baseText} className={clsx('')}>
          {translate('appoinment_recurrence')}
        </Text>
      )

      const remainDate = (
        <DatePicker
          placeholder={translate('appoinment_recurrence')}
          disabled={disabled || disabledRemainDate}
          {...modelFormik.getFieldProps('remain_date')}
          onChange={(e) => {
            modelFormik.getFieldHelpers('remain_date').setValue(e)
          }}
          style={{width: '175px'}}
          format={dateFormat}
          minDate={dayjs(dayjs().add(1, 'days'), dateFormat)}
        ></DatePicker>
      )

      res = [
        ...res,
        {anchor: true},
        remainTitle,
        remainDate,
        {
          type: Flex,
          classes: [].join(' '),
          attributes: {
            vertical: true,
          },
        },
      ]

      /* Add submit UI */

      const sb = submitProgram({submit, saveDraft, translate, disabled})

      res = [
        ...res,
        ...sb,
        {
          type: Card,
          attributes: {
            style: {
              paddingBottom: '40px',
            },
          },
        },
      ]

      return transformLayout(res)
    }
  )
  const res = run(runLayout(program()))

  useEffect(() => {
    if (!['WAITING_TEST_RESULT'].includes(status)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }

    if (!['WAITING_CONCLUSION'].includes(status)) {
      setDisabledAddAll(true)
    } else {
      setDisabledAddAll(false)
    }
  }, [status])

  /*
        / Handle call status based on time 
        /
        /*/

  /* const updateStatus = useCallback(, [status, detailBookingInfo, data]);*/

  /* useEffect(() => {
     *   const {values} = modelFormik

     *   const {designation} = values

     *   if (designation?.length) {
     *     setDisabledRemainDate(true)
     *   } else {
     *     setDisabledRemainDate(false)
     *   }
     * }, [modelFormik])*/

  useEffect(() => {
    const updateStatus = () => {
      const {start_time, end_time} = data || detailBookingInfo

      if (start_time && end_time) {
        const now = moment().unix()

        const duration = moment.duration(start_time - now, 'seconds')

        const delta = duration.as('minutes')

        if (['PAYMENT_FAIL', 'PAYMENT_PROCESS', 'FINISHED', 'MISSING_CALL'].includes(status)) {
          setDisabled(true)
          setDisabledCall(true)
        } else {
          if (start_time <= now) {
            setDisabled(false)
          } else {
            /*
              / Only set timeout when MTG is near
              /*/
            if (delta < 15) {
              setTimeout(() => {
                setDisabled(false)
              }, duration.as('milliseconds'))
            }
          }

          if (start_time <= now && now <= end_time) {
            setDisabled(false)
            setDisabledCall(false)
          } else {
            setDisabledCall(true)
          }
        }

        if (['FINISHED', 'PAYMENT_SUCCESS'].includes(status)) {
          const start_time_ahead = moment.unix(start_time).subtract(5, 'minutes').unix()
          if (start_time_ahead <= now && now <= end_time) {
            if (start_time <= now) {
              setDisabled(false)
            }
            setDisabledCall(false)
          } else {
            /*
                 / Only set timeout when MTG is near
                 /*/
            if (start_time_ahead > now && delta < 15) {
              setTimeout(() => {
                if (start_time <= now) {
                  setDisabled(false)
                }
                setDisabledCall(false)
              }, duration.as('milliseconds'))
            }
          }
        }
      }

      if (['WAITING_CONCLUSION', 'WAITING_TEST_RESULT', 'SUBMITTED_TEST_RESULT'].includes(status)) {
        setDisabledRemainDate(false)
      } else {
        setDisabledRemainDate(true)
      }
    }

    updateStatus()

    window.addEventListener('visibilitychange', updateStatus, false)

    return () => {
      window.removeEventListener('visibilitychange', updateStatus, false)
    }
  }, [status, detailBookingInfo, data, setDisabledRemainDate])

  useEffect(
    () => {
      if (sFormik && medicineListSelectedList.length) {
        const [medicine] = medicineListSelectedList

        const {drug_name, active_ingredient, unit} = JSON.parse(medicine)

        const {values} = sFormik

        /* @ts-ignore*/
        const {drug_name: currentDrugName} = values

        if (currentDrugName !== drug_name) {
          sFormik.getFieldHelpers('drug_name').setValue(`${drug_name} [${active_ingredient}]`)
          sFormik.getFieldHelpers('unit').setValue(unit)
        }
      }

      if (medicineListSelectedList?.length === 0) {
        sFormik.getFieldHelpers('drug_name').setValue(``)
        sFormik.getFieldHelpers('unit').setValue('')
      }
    },
    /* eslint-disable-next-line*/
    [medicineListSelectedList]
  )

  useEffect(
    () => {
      if (dFormik && serviceListSelectedList.length) {
        const [service] = serviceListSelectedList

        const {name_43_50_21: newName, code} = JSON.parse(service)

        const {values} = dFormik

        /* @ts-ignore*/
        const {service: currentName} = values

        if (currentName !== newName) {
          dFormik.getFieldHelpers('service').setValue(newName)
          dFormik.getFieldHelpers('code').setValue(code)
        }
      }

      if (serviceListSelectedList?.length === 0) {
        dFormik.getFieldHelpers('service').setValue('')
        dFormik.getFieldHelpers('code').setValue('')
      }
    },
    /* eslint-disable-next-line*/
    [serviceListSelectedList]
  )

  useEffect(() => {
    const {values} = sFormik

    if (Array.isArray(sFieldList)) {
      if (sFieldList.map((e) => values[e]).every((e) => e)) {
        setDisabledAddSuggestion(false)
      } else {
        setDisabledAddSuggestion(true)
      }
    }
  }, [sFormik, sFormik.values, sFieldList])

  useEffect(() => {
    const {values} = dFormik

    if (Array.isArray(dFieldList)) {
      if (dFieldList.map((e) => values[e]).every((e) => e)) {
        setDisabledAddService(false)
      } else {
        setDisabledAddService(true)
      }
    }
  }, [dFormik, dFormik.values, dFieldList])

  const cbList = useCallback(
    async (dList) => {
      const list = Array.isArray(dList) ? dList : [dList]

      const resList: any[] = []

      for (const diagnostic of list) {
        const {icd, icd10_code, disease_code} = diagnostic

        const code = icd || icd10_code || disease_code

        const res = await getICDList('', 1, {code})

        const {items} = res

        if (items.length) {
          const [i] = items
          const data = JSON.stringify(i)
          resList.push(data)
        }
      }

      setIcdSelectedList(resList)
    },
    [setIcdSelectedList]
  )

  useEffect(() => {
    /*
        / Display initial Selected ICD
        /*/

    if (diagnosticList.length) {
      /* debugger;*/
    }

    if (initialLoad) {
      if (diagnosticList) {
        cbList(diagnosticList)
      } else {
        setIcdSelectedList([])
      }

      setInitialLoad(false)
    }
  }, [setIcdSelectedList, diagnosticList, initialLoad, setInitialLoad, cbList])

  useEffect(() => {
    if (paramsUID) {
      setIcdSelectedList([])
      setMedicineListSelectedList([])
      setServiceListSelectedList([])
    }

    if (data?.uid === paramsUID) {
      if (data?.conclusion?.uid) {
        const {
          conclusion: {diagnostic = []},
        } = data

        if (diagnostic.length) {
          if (diagnostic) {
            cbList(diagnostic)
          }
        }
      }
    }
  }, [
    paramsUID,
    data,
    cbList,
    setIcdSelectedList,
    setMedicineListSelectedList,
    setServiceListSelectedList,
  ])

  useEffect(() => {
    updateUI({disabled})
  }, [disabled])

  useEffect(
    () => {
      /* window.addEventListener('visibilitychange', updateStatus, false);*/

      /*
        / Init call
        /*/
      /* updateStatus();*/

      return () => {
        setDetailBookingInfo({})
      }
    },
    //eslint-disable-next-line
    []
  )

  const bcrs: Array<PageLink> = [...calendarBCrs]

  return (
    <>
      {data?.patientInfo && <PageTitle breadcrumbs={bcrs}>{data?.patientInfo?.fullname}</PageTitle>}
      {contextHolder}
      {res}

      <div>
        <Button
          onClick={() => {
            const mf = modelFormik
            console.log(mf)
            debugger
          }}
          className='tw-hidden'
        >
          debug
        </Button>
      </div>
    </>
  )
}

const CalendarDetailHeaderOnly = ({
  appointmentId = '',
  extraContent = <div></div>,
  data = {},
  medicalHistoryData = {} as any,
}) => {
  const transformLayout = (pl) => {
    let res = pl

    const start = res.findIndex((e) => e?.classes?.includes('info-row-start'))
    const end = res.findIndex((e, i) => i > start && e?.classes?.includes('info-row'))

    if (start > -1 && end > -1) {
      const anchor = {anchor: true}
      /* const container = {type: Card, classes: ['calendar-detail-header-only'].join(' ')}*/

      const rootContainer = {
        type: 'div',
        classes: ['calendar-detail-header-only-with-extra'].join(' '),
      }

      /*
         / Update style for header container  
         /
         /*/

      /* const headerContainer = {
       *   classes: ['info-row', 'tw-grid', 'tw-grid-cols-5'].join(' '),
       *   attributes: {
       *     style: {
       *       gap: '2rem',
       *     },
       *   },
       * }*/

      res = [anchor, extraContent, rootContainer]
    }

    return res
  }

  return (
    <CalendarDetail
      extra={{transformLayout}}
      appointmentId={appointmentId}
      data={data}
    ></CalendarDetail>
  )
}

const CalendarDetailTimeOnly = ({appointmentId = '', extraContent = <div></div>, data = {}}) => {
  const transformLayout = (pl) => {
    let res = pl

    const start = res.findIndex((e) => e?.classes?.includes('time-start'))
    const end = res.findIndex((e, i) => i > start && e?.classes?.includes('time'))

    if (start > -1 && end > -1) {
      const body = res.slice(start, end)

      res = [...body, extraContent, {type: Space, classes: ['time'].join(' ')}]
    }

    return res
  }

  return (
    <CalendarDetail
      extra={{transformLayout}}
      appointmentId={appointmentId}
      data={data}
    ></CalendarDetail>
  )
}

/* const CalendarDetailBackButtonOnly = ({appointmentId = '', extraContent = <div></div>}) => {
 *   const transformLayout = (pl) => {
 *     let res = pl
 *
 *     const start = 0
 *     const end = res.findIndex((e, i) => e?.props?.className?.includes('back'))
 *
 *     if (start > -1 && end > -1) {
 *       const body = res.slice(start, end + 1)
 *
 *       res = [...body, extraContent]
 *     }
 *
 *     return res
 *   }
 *
 *   return <CalendarDetail extra={{transformLayout}} appointmentId={appointmentId}></CalendarDetail>
 * }*/

const CalendarDetailInputOnly = ({
  appointmentId = '',
  extraContent = <div></div>,
  data = {},
  afterAddConclusion = () => {},
}) => {
  const transformLayout = (pl) => {
    let res = pl

    const start = res.findIndex((e) => e?.classes?.includes('symptom-outlet'))

    if (start > -1) {
      const body = res.slice(start)

      res = [...body, extraContent]
    }

    return res
  }

  return (
    <CalendarDetail
      extra={{transformLayout}}
      appointmentId={appointmentId}
      data={data}
      afterAddConclusion={afterAddConclusion}
    ></CalendarDetail>
  )
}

const CalendarDetailStatusOnly = ({appointmentId = '', extraContent = <div></div>, data = {}}) => {
  const transformLayout = (pl) => {
    let res = pl

    const statusAheadIndex = res.findIndex((e) => e?.classes?.includes('status-outlet'))

    if (statusAheadIndex > -1) {
      const body = res.slice(statusAheadIndex + 2, statusAheadIndex + 3)

      res = [...body, extraContent]
    }

    return res
  }

  return (
    <CalendarDetail
      extra={{transformLayout}}
      appointmentId={appointmentId}
      data={data}
    ></CalendarDetail>
  )
}

const TabbedCalendarDetail = ({appointmentId = ''}) => {
  const {setAccount} = usePatient()
  /*
    / TODO: Include implementation for HealthHistory + HealthForm
    /*/
  const {uid: paramsUID} = useParams()

  const [uid, setUID] = useState(paramsUID || appointmentId)
  const [data, setData] = useState<any>()
  const [medicalHistoryData, setMedicalHistoryData] = useState<any>()
  const timeInfo = <CalendarDetailTimeOnly appointmentId={appointmentId} data={data} />

  const [activeKey, setActiveKey] = useState('1')

  const status = <CalendarDetailStatusOnly appointmentId={appointmentId} data={data} />

  const afterAddConclusion = useCallback(() => {
    setTimeout(() => {
      setUID('')
      setTimeout(() => {
        if (paramsUID) {
          setUID(paramsUID)
        }
      }, 200)
    }, 200)
  }, [paramsUID, setUID])

  useEffect(() => {
    const getData = async () => {
      if (uid) {
        const appointmentDetail = await getGeneric(APPOINTMENT_INFO_URL, {appointment_id: uid})

        const res = await getAppointmentWithExtraInfo({data: {items: [appointmentDetail]}})

        const {items = []} = res

        if (items.length) {
          if (items[0].patient_id) {
            const resData = await getMedicalHistory(items[0].patient_id)
            console.log("getMedicalHistory", resData)
            if (resData) {
              setMedicalHistoryData(resData)
            }
          }
          setData(items[0])
        }
      }
    }

    getData()
  }, [uid, setAccount])

  useEffect(() => {
    if (paramsUID) {
      setUID(paramsUID)
    }
  }, [paramsUID, setUID])

  useEffect(() => {
    if (data) {
      setAccount(data.patient_info)
    }
  }, [data, setAccount])

  if (!data) {
    return <Spin spinning={!data} size={'large'} fullscreen></Spin>
  }

  const healthHistory = (
    <WithShareDataPage
      account={data.patient_info || {}}
      extra={
        <MedicalHistory
          showSpinner={false}
          targetUserId={data.patient_id}
          medicalHistoryData={medicalHistoryData}
        />
      }
    />
  )
  const healthForm = <h1>TODO: Form Khai báo trước khám</h1>
  const items = [
    {
      key: '1',
      label: 'Nhập kết quả',
      children: (
        <CalendarDetailInputOnly
          appointmentId={appointmentId}
          data={data}
          afterAddConclusion={afterAddConclusion}
        />
      ),
    },
    {
      key: '2',
      label: 'Khai báo trước khám',
      children: healthForm,
    },
    {
      key: '3',
      label: 'Tiền sử bệnh',
      children: (
        <CalendarDetailHeaderOnly
          appointmentId={appointmentId}
          extraContent={healthHistory}
          data={data}
          medicalHistoryData={medicalHistoryData}
        />
      ),
    },
  ]

  const renderTabBar = () => {
    const {
      patientInfo: account = {},
      convertedImages = [],
      convertedVideos = [],

      convertedDocuments = [],
    } = data

    return (
      <>
        <Card
          style={{marginBottom: '2rem'}}
          styles={{body: {paddingBottom: 0}}}
          title={
            <Flex
              wrap='wrap'
              justify='space-between'
              style={{paddingTop: '4px', paddingBottom: '4px'}}
            >
              <Space>
                <Button icon={<i className='fa-solid fa-hospital-user'></i>} type='text'>
                  <Text strong>
                    {account.fullname ? account.fullname : `Không tên (${account.phone})`}
                  </Text>
                </Button>

                {account.gender && (
                  <>
                    <Divider type='vertical' />
                    <Text strong>{`${getGenderDescription(account.gender)}`}</Text>
                  </>
                )}

                {account.birthday && (
                  <>
                    <Divider type='vertical' />
                    <Text strong>{`${moment(account.birthday, 'YYYY/MM/DD').format(
                      dateFormat
                    )}`}</Text>
                  </>
                )}
              </Space>
              <Flex align='center' gap='0.5rem' style={{paddingLeft: '15px'}}>
                {timeInfo}
                {status}
              </Flex>
            </Flex>
          }
        >
          <Flex gap='20px'>
            <div style={{width: 160}}>
              <Avatar
                src={account.avatar ? account.avatar : '/media/avatars/blank.png'}
                size={160}
                shape='square'
              />
            </div>

            <Flex vertical={true} gap='1rem'>
              <Flex vertical={true} gap='0.5rem'>
                <Text strong>Lý do tư vấn:</Text>
                <Text style={baseText}>{data?.reason}</Text>
              </Flex>

              <Flex vertical={true} gap='0.5rem'>
                <Text strong>Nhóm bệnh:</Text>
                <Text style={baseText}>{data?.disease_info?.name}</Text>
              </Flex>

              <Flex vertical={true} gap='0.5rem'>
                <Text strong>File đính kèm:</Text>

                <Flex gap='0.5rem' className='hello'>
                  {[...convertedImages, ...convertedVideos, ...convertedDocuments].map((e, i) => {
                    return <FilePreview url={e} text={`file${i + 1}`}></FilePreview>
                  })}
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Tabs
            defaultActiveKey='1'
            items={items.map((e) => {
              return {...e, children: null}
            })}
            activeKey={activeKey}
            onChange={(key) => setActiveKey(key)}
          />
        </Card>
      </>
    )
  }

  return (
    <Tabs
      defaultActiveKey='1'
      items={items}
      //tabBarExtraContent={{left: back, right: timeInfo}}

      //tabBarExtraContent={{left: back}}
      activeKey={activeKey}
      onChange={(key) => setActiveKey(key)}
      renderTabBar={renderTabBar}
    />
  )
}

export {CalendarDetail, CalendarDetailprogram, CalendarDetailHeaderOnly, TabbedCalendarDetail}
