import {Button, Card, Col, Row, Flex, Radio, Select} from 'antd'

import {useEffect, useState, useCallback} from 'react'
import axios from 'axios'

import {callGeneric} from 'app/modules/apps/patient-management/calendar/core/_requests'

import {usePatient} from 'app/modules/apps/patient-management/patient/PatientProvider'

import {
  /* SHARE_DATA_PERMISSION_STATUS,*/
  /* SHARE_DATA_REQUEST_LIST,*/
  SHARE_DATA_PERMISSION_REQUEST,
  SHARE_DATA_PERMISSION_CANCEL,
} from 'app/modules/helpers/Common'

export const ShareDataRequest = ({account, reload}) => {
  const foreverValue = 1000

  const [timeOptionList] = useState([
    {value: 30, label: '1 tháng'},
    {value: 90, label: '3 tháng'},
    {value: 180, label: '6 tháng'},
    {value: 365, label: '1 năm'},
    {value: foreverValue, label: 'Vĩnh viễn'},
  ])

  const [targetType, setTargetType] = useState('')
  const [time, setTime] = useState(timeOptionList[0].value)

  const [forever, setForever] = useState(false)

  const [touched, setTouched] = useState(false)
  const [error, setError] = useState('')

  const [targetTypeOptionList] = useState([
    {value: 'INDIVIDUAL', label: 'Cấp cho riêng tôi'},
    {value: 'ORGANIZATION', label: 'Cấp cho CSYT'},
  ])

  const onTargetTypeChange = useCallback(
    (e) => {
      const value = e?.target?.value

      setTargetType(value)
    },
    [setTargetType]
  )

  const onTimeChange = useCallback(
    (e) => {
      const value = e?.target?.value || e

      setTime(value)

      if (value === foreverValue) {
        setForever(true)
      } else {
        setForever(false)
      }
    },
    [setTime, setForever]
  )

  const showError = useCallback(() => {
    const errorList: any[] = []

    if (!targetType) {
      errorList.push('Bắt buộc chọn kiểu chia sẻ')
    }

    if (!time) {
      errorList.push('Bắt buộc chọn thời gian chia sẻ')
    }

    setError(errorList.join(', '))
  }, [targetType, time, setError])

  const requestPermission = useCallback(async () => {
    showError()
    if (targetType && time) {
      const res = await callGeneric(
        axios({
          method: 'post',
          data: {
            target_user_id: account?.uid,
            target_type: targetType,
            ...(forever ? {is_forever: true} : {expire_days: time}),
          },

          url: SHARE_DATA_PERMISSION_REQUEST,
        })
      )

      reload()

      return res
    }
  }, [account, time, forever, targetType, reload, showError])

  useEffect(() => {
    if (touched) {
      showError()
    }
  }, [targetType, time, touched, showError])

  /* const timeDropdownRender = useCallback(
   *   (menus) => {
   *     return (
   *       <Card>
   *         <Radio.Group onChange={onTimeChange}>
   *           <Flex vertical={true} justify='start'>
   *             {timeOptionList.map((e) => {
   *               const {value, label} = e
   *               return <Radio value={value}>{label}</Radio>
   *             })}
   *           </Flex>
   *         </Radio.Group>
   *       </Card>
   *     )
   *   },
   *   [timeOptionList, onTimeChange]
   * )*/

  /* const getTimeSelectionLabel = useCallback(() => {
     *   let res = ''
     *   for (const o of timeOptionList) {
     *     const {value, label} = o

     *     if (value === time) {
     *       res = label
     *       break
     *     }
     *   }
     *   return res
     * }, [time, timeOptionList])*/

  return (
    <Card>
      <Flex justify='center' align='center' vertical={true} gap='1.5rem'>
        <Flex justify='center' align='center' vertical={true}>
          <div style={{fontWeight: 'bolder'}}>Yêu cầu cấp quyền truy cập hồ sơ</div>
          <div>
            Hồ sơ này không được quản lý bởi CSYT của bạn và bệnh nhân chưa chia sẻ quyền truy cập
            hồ sơ cho bạn.
          </div>
        </Flex>

        <Radio.Group
          options={targetTypeOptionList}
          onChange={onTargetTypeChange}
          style={{width: '406px'}}
          className='tw-flex tw-justify-between'
        ></Radio.Group>

        <Flex vertical={true} justify='start' gap='0.5rem' style={{width: '406px'}}>
          <div style={{fontWeight: 'bolder'}}>Thời gian truy cập</div>

          <Flex className='tw-justify-between' gap='0.5rem'>
            <Select
              options={timeOptionList}
              onChange={onTimeChange}
              defaultValue={timeOptionList[0]}
              className='tw-grow'
            ></Select>
            <Button
              type='primary'
              onClick={() => {
                requestPermission()
                setTouched(true)
              }}
            >
              Yêu cầu
            </Button>
          </Flex>

          <div style={{fontWeight: 'bolder', color: 'red'}}>{error}</div>
        </Flex>
      </Flex>
    </Card>
  )
}

export const ShareDataPending = ({requestStatus, reload}) => {
  const cancelPermission = useCallback(async () => {
    /* const [statusRes] = await getRequestedList();*/

    const {uid} = requestStatus

    const res = await callGeneric(
      axios({
        method: 'delete',
        data: {
          request_id: uid,
        },

        url: SHARE_DATA_PERMISSION_CANCEL,
      })
    )

    reload()

    return res
  }, [requestStatus, reload])

  return (
    <Card>
      <Flex justify='center' align='center' vertical={true} gap='1.5rem'>
        <div>Gửi yêu cầu truy cập thành công!</div>
        <Button onClick={cancelPermission} danger className='tw-bg-white'>
          Hủy yêu cầu
        </Button>
      </Flex>
    </Card>
  )
}

export const ShareDataPage = ({account}) => {
  const {shareDataAllowed: allowed, requestStatus, reload} = usePatient()

  return (
    <>
      {allowed === false && !requestStatus && (
        <Row>
          <Col span={24}>
            <ShareDataRequest account={account} reload={reload} />
          </Col>
        </Row>
      )}

      {allowed === false && requestStatus && (
        <Row>
          <Col span={24}>
            <ShareDataPending requestStatus={requestStatus} reload={reload} />
          </Col>
        </Row>
      )}
    </>
  )
}

export const WithShareDataPage = ({account, extra}) => {
  const {shareDataAllowed, setAccount, reload} = usePatient()

  useEffect(() => {
    setAccount(account)
  }, [account, setAccount])

  useEffect(() => {
    reload()
  }, [reload])

  return <>{shareDataAllowed ? <>{extra}</> : <ShareDataPage account={account} />}</>
}
