import { Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { RatingListPage } from './RatingListPage'

export const RatingRoutes = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <>
            <PageTitle>Danh sách đánh giá</PageTitle>
            <RatingListPage/>
          </>
        }
      />
    </Routes >
  )
}
