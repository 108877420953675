import { Button, Card, DatePicker, Form, Input, notification, Row, Select, Space } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { PATIENT_API } from '../../../helpers/Common'

export const NewPatientPage = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [showNotification, contextHolder] = notification.useNotification()
  const passwordMinLength = 6
  const handleFormSubbmit = () => {
    form.validateFields().then((values) => {
      console.log('form', values)
      axios.post(PATIENT_API, { ...values, birthday: values.birthday?.format('YYYY/MM/DD'), role: 'PATIENT' })
        .then(response => {
          if (response.data.success) {
            navigate(`/patient/id/${response.data.data.uid}`)
            // axios.post(MEDICAL_HISTORY_API, { pathological_medical_history: values.pathological_medical_history.join(';'), target_user_id: response.data.data.uid })
            //   .then(response => {
            //     if (response.data.success) {
            //       navigate(`/account/id/${response.data.data.uid}`)
            //     }
            //   })
          } else {
            const { data: { error: { message } } } = response;

            showNotification.open({
              message: 'Lỗi',
              description: message,
              placement: 'topRight',
              icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,

            })
          }
        })
    }).catch(() => { })
  }

  return (
    <Card title='Thông tin tài khoản mới' actions={[
      <Row justify="end" style={{ paddingRight: 25 }}>
        <Space>
          <Button onClick={() => navigate(-1)}>Huỷ</Button>
          <Button type='primary' onClick={handleFormSubbmit}>Tạo tài khoản</Button>
        </Space>
      </Row>
    ]} >
      {contextHolder}
      <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} >
        <Form.Item name='fullname' label="Họ và tên" rules={[{ required: true, whitespace: true, message: 'Bắt buộc điền thông tin' }]}>
          <Input placeholder='Nguyễn Văn A' />
        </Form.Item>
        <Form.Item name="birthday" label="Ngày sinh">
          <DatePicker placeholder='Chọn ngày sinh' showNow={false} maxDate={dayjs()} />
        </Form.Item>
        <Form.Item name='phone' label="Số điện thoại" rules={[{ message: 'Thông tin chưa đúng định dạng', pattern: /^\d+$/ }, { required: true, message: 'Bắt buộc điền thông tin' }]}>
          <Input placeholder='0123456789' />
        </Form.Item>
        <Form.Item name='email' label="Email" rules={[{ message: 'Thông tin chưa đúng định dạng', type: "email" }]}>
          <Input placeholder='benhnhan@metadoc.vn' autoComplete={'off'} />
        </Form.Item>
        <Form.Item name='address' label="Địa chỉ">
          <Input placeholder="186 Điện Biên Phủ, P6, Q3, TP Hồ Chí Minh" />
        </Form.Item>
        <Form.Item name='nationality' label="Quốc tịch">
          <Input placeholder="Việt Nam" />
        </Form.Item>
        <Form.Item name='id_card' label="CCCD">
          <Input placeholder="" />
        </Form.Item>
        <Form.Item name='ethnic' label="Dân tộc">
          <Input placeholder="Kinh" />
        </Form.Item>
        <Form.Item name='gender' label="Giới tính">
          <Select options={[{ label: 'Nam', value: 'MALE' }, { label: 'Nữ', value: 'FEMALE' }]} placeholder="Chọn giới tính" allowClear={true} />
        </Form.Item>
        <Form.Item name="password" label="Mật khẩu" rules={[{ required: true, message: 'Bắt buộc điền thông tin' }, { min: passwordMinLength, message: `Mật khẩu có độ dài ít nhất ${passwordMinLength} ký tự` }]}>
          <Input.Password autoComplete="new-password" placeholder={"Nhập mật khẩu"} />
        </Form.Item>
        <Form.Item name="password2" label="Nhập lại mật khẩu" dependencies={['password']}
          rules={[{ required: true, message: 'Bắt buộc điền thông tin' }, { min: passwordMinLength, message: `Mật khẩu có độ dài ít nhất ${passwordMinLength} ký tự` }, ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Mật khẩu không khớp'));
            },
          }),
          ]}>
          <Input.Password autoComplete={'off'} />
        </Form.Item>
      </Form >
    </Card >
  )
}
