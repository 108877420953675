import { Avatar, Button, Card, Col, Row, Tooltip } from "antd"
import { useAuth } from "app/modules/auth"
import { useNavigate } from "react-router-dom"

export const FollowerMenu = ({ close }) => {
  const { following } = useAuth()
  const extra = following.length <= 9 ? 0 : following.length - 8
  const shownItem = following.length <= 9 ? following.length : 8
  const navigate = useNavigate()

  return (
    <Card
      bordered={false}
      title='Đang theo dõi'
      actions={[<Row><Button className="ms-auto me-8"
        onClick={() => {
          navigate(`/patient/list`, {state: {filter : 'FOLLOW'}})
          close()
        }}
      >Danh sách đầy đủ</Button></Row>]}
      style={{ maxWidth: 300 }}
      styles={{ body: { padding: 24 } }}
    >
      <Row gutter={[20, 20]} >
        {following.slice(0, shownItem).map((follower, index) => (
          <Col span={8} key={index} style={{ alignContent: 'center', textAlign: 'center', flexGrow: 0 }}>
            <Tooltip title={follower.fullname} placement="top" key={follower.uid}>
              <Avatar src={follower.avatar} style={{ backgroundColor: '#1677ff', cursor: 'pointer' }} size={72}
                onClick={() => {
                  navigate(`/patient/id/${follower.uid}`)
                  close()
                }}>
                {follower.fullname.charAt(0)}
              </Avatar>
            </Tooltip>
          </Col>
        ))}
        {following.length < 9 && [...Array(9 - following.length)].map((_, index) => (
          <Col span={8} key={index + following.length} style={{ alignContent: 'center', textAlign: 'center', flexGrow: 0 }}>
            <Tooltip title="Chưa có" placement="top">
              <Avatar style={{ backgroundColor: '#eee', borderStyle: 'dashed' }} size={72} ><i className="fa-solid fa-plus text-primary"></i></Avatar>
            </Tooltip>
          </Col>
        ))}
        {extra > 0 &&
          <Col span={8} key='extra' style={{ alignContent: 'center', textAlign: 'center', flexGrow: 0 }}>
            <Tooltip title={`và ${extra} người khác`} placement="top">
              <Avatar style={{ backgroundColor: '#1677ff', cursor: 'pointer' }} size={72}
                onClick={() => {
                  navigate(`/patient/list`)
                  close()
                }}
              >{`+${extra}`}
              </Avatar>
            </Tooltip>
          </Col>
        }
      </Row>
    </Card>
  )
}
