import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { AVATAR_API, PROFILE_API } from '../../../helpers/Common'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { AccountModel, accountModelInitValues as initialValues } from './AccountModel'
import { useAuth } from '../../../auth/core/Auth'
import { ImageInputComponent } from '../../../../../_metronic/assets/ts/components'

const accountSchema = Yup.object().shape({
    fullname: Yup.string().required('Họ và tên là bắt buộc'),
    phone: Yup.string().required('Số điện thoại là bắt buộc'),
    // email: Yup.string().required('Email là bắt buộc'),
    gender: Yup.string().required('Giới tính là bắt buộc'),
    birthday: Yup.string()
})

const AccountSettings = ({ account }) => {
    // console.log('setting', account)
    const navigate = useNavigate()
    const { setCurrentUser } = useAuth()

    useEffect(() => {
        ImageInputComponent.bootstrap();
    }, [])

    // const [data, setData] = useState<AccountModel>(initialValues)
    // const updateData = (fieldsToUpdate: Partial<AccountModel>): void => {
    //     const updatedData = Object.assign(data, fieldsToUpdate)
    //     setData(updatedData)
    // }

    const changeAvatar = (e) => {
        console.log(e.target.files[0])
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        axios.put(AVATAR_API, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                console.log(response)
            })
    }

    const [loading, setLoading] = useState(false)
    const formik = useFormik<AccountModel>({
        initialValues: { ...initialValues, ...account },
        validationSchema: accountSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            setLoading(true)
            console.log(values)
            axios.put(PROFILE_API, {
                fullname: values.fullname,
                phone: values.phone,
                email: values.email,
                gender: values.gender,
                birthday: values.birthday?.replaceAll('-', '/'),
            }).then(response => {
                console.log('rep', response)
                if (response.data.success) {
                    setCurrentUser({
                        fullname: response.data.data.fullname,
                        phone: response.data.data.phone,
                        email: response.data.data.email,
                        avatar: response.data.data.avatar,
                        birthday: response.data.data.birthday ? response.data.data.birthday.replaceAll('/', '-') : '',
                        gender: response.data.data.gender,
                    })
                };
                setLoading(false)
                // requestUpdate()
                navigate('../')
            })
        }
    })
    // setTimeout(() => {
    //     const updatedData = Object.assign(data, values)
    //     // setData(updatedData)
    //     console.log(updateData)
    //     setLoading(false)
    // }, 1000)
    // },
    // })

    return (
        account && <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Thông tin tài khoản</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
                            <div className='col-lg-8'>
                                <div
                                    className='image-input image-input-outline'
                                    data-kt-image-input='true'
                                    style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                    onChange={changeAvatar}
                                >
                                    <div
                                        className='image-input-wrapper w-125px h-125px'
                                        style={{ backgroundImage: `url(${account.avatar ? account.avatar : toAbsoluteUrl('/media/avatars/blank.png')})` }}
                                    ></div>
                                    <label className="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
                                        data-kt-image-input-action="change"
                                        data-bs-toggle="tooltip"
                                        data-bs-dismiss="click"
                                        title="Change avatar">
                                        <i className="ki-duotone ki-pencil fs-6"><span className="path1"></span><span className="path2"></span></i>

                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                        <input type="hidden" name="avatar_remove" />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Họ và tên</label>

                            <div className='col-lg-8'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Nguyễn Văn A'
                                    {...formik.getFieldProps('fullname')}
                                />
                                {formik.touched.fullname && formik.errors.fullname && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.fullname}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Ngày sinh</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='date'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='0123456789'
                                    {...formik.getFieldProps('birthday')}
                                />
                                {formik.touched.birthday && formik.errors.birthday && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.birthday}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Số điện thoại</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='0123456789'
                                    {...formik.getFieldProps('phone')}
                                />
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.phone}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='doctor@metadoc.vn'
                                    {...formik.getFieldProps('email')}
                                />
                                {formik.touched.email && formik.errors.email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email}</div>
                                    </div>
                                )}
                            </div>
                        </div> */}

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                            <div className='col-lg-8 fv-row'>
                                <div className="input-group">
                                    <span className="input-group-text" id="basic-addon3">
                                        <i className="ki-duotone ki-lock-3 fs-1"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>
                                    </span>
                                    <input type="text" className="form-control form-control-lg" id="basic-url" value={account && account.email} aria-describedby="basic-addon3" disabled />
                                </div>
                                {/* <div className="form-floating">
                                    <input type="text" className="form-control form-control-lg form-control-solid" id="floatingPassword" value={account && account.email} disabled/>
                                    <label htmlFor="floatingPassword" className="text-danger">Không cho đổi email tài khoản quản trị</label>
                                </div> */}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Giới tính</label>
                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg'
                                    {...formik.getFieldProps('gender')}
                                >
                                    <option value=''>Chọn giới tính...</option>
                                    <option value='MALE'>Nam</option>
                                    <option value='FEMALE'>Nữ</option>
                                </select>
                                {formik.touched.gender && formik.errors.gender && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.gender}</div>
                                    </div>
                                )}

                                <div className='form-text'>
                                    Điền giới tính sinh học của khách hàng
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Lưu thông tin'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export { AccountSettings }
