import { Button, Card, Form, Input, Row, Space } from "antd"
import { FORGOT_PASSWORD_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useState } from "react"
import { Link } from "react-router-dom"

export const ForgotPassword = () => {
  const [requested, setRequested] = useState(false)
  const [form] = Form.useForm()

  const handleSubmit = () => {
    form.validateFields().then(values => {
      axios.post(FORGOT_PASSWORD_API, { email: values.email })
        .then(() => {
          setRequested(true)
        })
    })
  }

  return (requested ?
    <Card title={<span className="text-primary">Quên mật khẩu</span>} >
      <span className="text-gray-600">
        Email hướng dẫn đặt lại mật khẩu đã được gửi. Vui lòng kiểm tra hộp thư đến của bạn.
      </span>
      <Row className="pt-6">
        <Space className="ms-auto">
          <Link to="/auth/login">
            <Button type="link" >Quay lại đăng nhập</Button>
          </Link>
        </Space>
      </Row>
    </Card>
    :
    <Card title={<span className="text-primary">Quên mật khẩu</span>} >
      <span className="text-gray-600">Vui lòng nhập email của bạn. Nếu hệ thống tìm thấy tài khoản, chúng tôi sẽ gửi email hướng dẫn đặt lại mật khẩu.</span>
      <Form
        form={form}
        layout='vertical'
        className="pt-6"
      >
        <Form.Item
          label='Email'
          name='email'
          className="fw-bold"
          rules={[{ required: true, message: 'Vui lòng nhập email', type: 'email' }]}
        >
          <Input />
        </Form.Item>
      </Form>
      <Row className="pt-4">
        <Space className="ms-auto">
          <Link to="/auth/login">
            <Button type="link" >Quay lại đăng nhập</Button>
          </Link>
          <Button type="primary" onClick={handleSubmit}>Đặt lại mật khẩu</Button>
        </Space>
      </Row>
    </Card >
  )
}