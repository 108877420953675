import React from 'react'

const run = (program: Generator<any, any, any>) => {
  let res = {value: undefined, done: false}

  while (!res.done) {
    const temp = program.next()

    if (temp.done) {
      const v = temp.value || res.value
      res = {...temp, value: v}
    } else {
      res = {...res, ...temp}
    }
  }

  return res.value
}

function* runLayout(program: Generator<any, any, any>, bind: Function = bindLayoutWithAnchor) {
  let genRes = {value: undefined, done: false}

  let res: any[] = unitLayout()

  while (!genRes.done) {
    genRes = {...genRes, ...program.next()}
    const {value} = genRes

    if (value) res = bind(res, value)

    yield res
  }

  yield res
}

const unitLayout = () => {
  return []
}

const bindLayout = (l1: any[], l2: any) => {
  if (Array.isArray(l2)) {
    return [...l1, ...l2]
  } else if (typeof l2 === 'function') {
    return [...l1, l2()]
  } else if (l2['$$typeof']) {
    return [...l1, l2]
  } else {
    const {reduceRight = l1.length} = l2

    let pivot = Math.max(l1.length - reduceRight, 0)

    let left: any[] = l1.slice(0, pivot)

    let right: any[] = l1.slice(pivot)

    return [...left, makeContainer({children: right, ...l2})]
  }
}

const bindLayoutWithAnchor = (l1: any[], l2: any) => {
  if (Array.isArray(l2)) {
    return [...l1, ...l2]
  } else if (typeof l2 === 'function') {
    return [...l1, l2()]
  } else if (l2['$$typeof'] || l2.anchor) {
    return [...l1, l2]
  } else {
    const {reduceRight = l1.length} = l2

    let anchor = l1.reduceRight((acc, e, i) => {
      if (acc > -1) return acc

      let res = acc
      if (e.anchor) {
        res = i
      }

      return res
    }, -1)
    const hasAnchor = anchor > -1

    /*
        / We prioritize anchor over reduceRight length 
        /*/
    let pivot = hasAnchor ? anchor : Math.max(l1.length - reduceRight, 0)

    let left: any[] = l1.slice(0, pivot)

    /*
        / Remove anchor object from actual building phase
        /*/
    let right: any[] = hasAnchor ? l1.slice(pivot + 1) : l1.slice(pivot)

    return [...left, makeContainer({children: right, ...l2})]
  }
}

const makeContainer = (inject: any) => {
  const type = inject.type || 'div'

  const attributes = inject.attributes || {}

  const key = inject.key || inject.classes

  const keyInfo = key ? {key} : {}

  const child = inject.children?.length === 1 ? inject.children[0] : inject.children

  return React.createElement(
    type,
    {className: inject.classes, ...attributes, ...keyInfo},
    child
    //inject.children
  )
}

/*
/ utilities
/
/*/

const transformProgram = (p: any, process = (p) => p) => {
  const processed = process([...p])

  function* program(): Generator<any, any, any> {
    for (const e of processed) {
      yield e
    }
  }
  return program
}

const buildProgram = (arr: any[]) => {
  function* program(): Generator<any, any, any> {
    for (const e of arr) {
      yield e
    }
  }
  return program
}

export {run, runLayout, bindLayout, transformProgram, buildProgram}
