import React, { useEffect, useState } from "react"
import MedicalHistory from "../patient-management/calendar/MedicalHistory"
import {getMedicalHistory} from '../patient-management/calendar/core/_requests'
import {WithShareDataPage} from 'app/modules/apps/patient-management/patient/ShareDataPage'

export const AppointmentMedicalHistory = ({ appointment }) => {
  const [medicalHistory, setMedicalHistory] = useState([] as any[])
  const [showSpinner, setShowSpinner] = useState(false)

  useEffect(() => {
    setShowSpinner(true)
    getMedicalHistory(appointment.patient_info.uid).then(data => {
      setMedicalHistory(data)
      setTimeout(() => setShowSpinner(false), 500)
    })
  }, [appointment])

  return (
    <WithShareDataPage
        account={appointment.patient_info || {}}
        extra={
            <MedicalHistory
            showSpinner={showSpinner}
            medicalHistoryData={medicalHistory}
            targetUserId={appointment.patient_info.uid}
                         />

              }
    />

  )
}
