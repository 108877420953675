import {Route, Routes} from 'react-router-dom'

/* import {PageLink} from '_metronic/layout/core/'*/

import {CalendarPage} from 'app/modules/apps/patient-management/calendar/calendar'
import {TabbedCalendarDetail} from 'app/modules/apps/patient-management/calendar/calendar-detail'

import {PatientProvider} from 'app/modules/apps/patient-management/patient/PatientProvider'

/* const profileBreadCrumbs: Array<PageLink> = [
 *   {
 *     title: 'appointment',
 *     path: '/appointment/list',
 *     isSeparator: false,
 *     isActive: false,
 *   },
 *   {
 *     title: '',
 *     path: '',
 *     isSeparator: true,
 *     isActive: false,
 *   },
 * ]*/

export const AppointmentRoutes = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <>
            <CalendarPage />
          </>
        }
      />

      <Route
        path='list/:uid'
        element={
          <>
            <PatientProvider>
              <TabbedCalendarDetail />
            </PatientProvider>
          </>
        }
      />
    </Routes>
  )
}
