import {
  /* Avatar,*/
  Button,
  Card,
  ConfigProvider,
  Table,
  Tag,
  Typography,
  Image,
  Flex,
  Modal,
  Dropdown,
  Checkbox,
  Radio,
  Input,
  Pagination,
  DatePicker,
} from 'antd'
import Column from 'antd/es/table/Column'
import { useIntl } from 'react-intl'
import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
/* import {useDebounce} from 'use-debounce'*/
import clsx from 'clsx'
import moment from 'moment'

import axios from 'axios'

import { PageLink, PageTitle } from '_metronic/layout/core'

import { getGenderDescription } from '../../../helpers/Common'

import { trie, insert, lookupValue } from 'utils/functional/tree'

import { CalendarDetail } from 'app/modules/apps/patient-management/calendar/calendar-detail'

import { useCalendar } from 'app/modules/apps/patient-management/calendar/CalendarProvider'

import { CalendarFileModal } from 'app/modules/apps/patient-management/calendar/CalendarFileModal'

import { useAuth } from 'app/modules/auth/core/Auth'

import { unique, guessFileMime } from 'utils/functional/misc'

import {
  useQueryRequest,
  QueryRequestProvider,
} from 'app/modules/apps/patient-management/calendar/core/QueryRequestProvider'

import {
  useQueryResponse,
  QueryResponseProvider,
} from 'app/modules/apps/patient-management/calendar/core/QueryResponseProvider'

import { run, runLayout, transformProgram } from 'utils/functional/BuildLayout'

import { EMPLOYEE_API, PATIENT_API, ORG_API } from 'app/modules/helpers/Common'

import { callGeneric } from 'app/modules/apps/patient-management/calendar/core/_requests'

import './calendar.scss'

const CheckboxGroup = Checkbox.Group
const { Search } = Input
const { RangePicker } = DatePicker

const { Text } = Typography

const calendarBCrs: Array<PageLink> = [
  {
    title: 'Danh sách lịch hẹn',
    path: '/appointment/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const calendarDetailBCrs: Array<PageLink> = [
  ...calendarBCrs,
  {
    title: 'Danh sách lịch hẹn',
    path: '/appointment/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const pageSize = 10

const genderStyle = (value) => {
  let gender = getGenderDescription(value)
  switch (gender) {
    case 'Nam':
    case 'Male':
    case 'MALE':
      return <Tag color='blue'>Nam</Tag>
    case 'Nữ':
    case 'FEMALE':
    case 'Female':
      return <Tag color='red'>Nữ</Tag>
    default:
      return null
  }
}

const fieldList = [
  'fullname',
  //'id',
  /* 'phone',*/
  'gender',
  'dob',
  'time',
  'disease',
  'attachments',

  'status',
  'actions',
]

let store = trie()

/*
   / Install render function for each field
   /*/
store = insert(
  store,
  (value, record) => {
    return genderStyle(record?.patientInfo?.gender)
  },
  'render',
  'gender'
)

store = insert(
  store,
  (value, record) => {
    return <div>{record?.patientInfo?.fullname}</div>
  },
  'render',
  'fullname'
)

store = insert(
  store,
  (value, record) => {
    return <div>{record?.patientInfo?.phone}</div>
  },
  'render',
  'phone'
)

store = insert(
  store,
  (value, record) => {
    return <div>{record?.patient_id}</div>
  },
  'render',
  'id'
)

store = insert(
  store,
  (value, record) => {
    const dob = record?.patientInfo?.birthday
    return <div>{dob ? moment(dob, 'YYYY/MM/DD').format(dateFormat) : dob}</div>
  },
  'render',
  'dob'
)

store = insert(
  store,
  (value, record) => {
    const { start_time, end_time } = record

    const timeList = [start_time, end_time]

    const [st, et] = timeList.map((e) => moment(e * 1000).format('HH:mm'))

    const d = moment(start_time * 1000).format('DD/MM/YYYY')

    const res = `${st} - ${et} ${d}`

    return <div>{res}</div>
  },
  'render',
  'time'
)

store = insert(
  store,
  (value, record, extra: any = {}) => {
    const { setFileURL, setShowFile } = extra

    return (
      <div>
        {record?.documents.map((e, i) => {
          if (e.match(/\.(png|jpeg)/)) {
            return (
              <button
                onClick={(e) => {
                  e.preventDefault()
                  setFileURL(e)
                  setShowFile(true)
                }}
              >{`file_${i}`}</button>
            )
          } else {
            return <a target='_self' type='application/pdf' href={e}>{`file_${i}`}</a>
          }
        })}
      </div>
    )
  },
  'render',
  'attachments'
)

const fileIcon = (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M13.17 2C13.7 2 14.21 2.21 14.59 2.59L19.41 7.42C19.79 7.79 20 8.3 20 8.83V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20V4C4 2.9 4.9 2 6 2H13.17ZM9 18H15C15.55 18 16 17.55 16 17C16 16.45 15.55 16 15 16H9C8.45 16 8 16.45 8 17C8 17.55 8.45 18 9 18ZM15 14H9C8.45 14 8 13.55 8 13C8 12.45 8.45 12 9 12H15C15.55 12 16 12.45 16 13C16 13.55 15.55 14 15 14ZM13 3.5V8C13 8.55 13.45 9 14 9H18.5L13 3.5Z'
      fill='#009ef7'
      fill-opacity='1'
    />
  </svg>
)

store = insert(
  store,
  (value, record, extra: any = {}) => {
    const { convertedImages = [], convertedVideos = [], convertedDocuments = [] } = record

    return (
      <Flex gap='0.5rem'>
        {[...convertedImages, ...convertedVideos, ...convertedDocuments].slice(0, 1).map((e, i) => {
          return <div className='calendar-attachments'>{fileIcon}</div>
        })}
      </Flex>
    )
  },
  'render',
  'attachments'
)

store = insert(
  store,
  (value, record) => {
    return <div>{record?.diseaseInfo?.name}</div>
  },
  'render',
  'disease'
)

store = insert(
  store,
  (value, record, extra: any = {}) => {
    const { setDetailBookingInfo, translate, navigate } = extra

    return (
      <div className='actions'>
        <a
          href='_self'
          onClick={async (e) => {
            e.preventDefault()
            setDetailBookingInfo(record)

            navigate(`/appointment/list/${record.uid}`)
          }}
        >
          {translate('detail')}
        </a>
      </div>
    )
  },
  'render',
  'actions'
)

const getRender = (field, s = store, extra: any = {}) => {
  const translate = extra.translate || ((e) => e)
  const f =
    lookupValue(s, 'render', field) || ((value, record) => <div>{translate(record[field])}</div>)

  return (value, record) => f(value, record, extra)
}

const FilePreview = ({ url, text, mime = '' }) => {
  const [visible, setVisible] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)

  const fmime = mime || guessFileMime(url)

  function* image(): Generator<any, any, any> {
    yield {
      anchor: true,
    }

    yield (
      <div className='text' style={{ display: 'none' }}>
        {text}
      </div>
    )

    yield {
      anchor: true,
      classes: ['thumbnail-outlet'].join(' '),
    }

    yield (
      <img
        src={url}
        loading='lazy'
        alt='fail'
        style={{
          borderRadius: '0.7rem',
          height: '40px',
          marginRight: '8',
          marginTop: '5',
          paddingTop: '5',
          paddingBottom: '6',
          paddingLeft: '9',
          paddingRight: '9',
          backgroundSize: 'cover',
          aspectRatio: '1/1',
          border: '2px solid #3e97ff',
        }}
        className='thumbnail-container'
      />
    )

    yield {
      type: Button,
      attributes: {
        type: 'link',
        style: {
          padding: 0,
        },
        onClick: () => setVisible(true),
      },
    }
    yield (
      <Image
        style={{ display: 'none' }}
        preview={{ visible, src: url, onVisibleChange: (value) => setVisible(value) }}
      />
    )

    yield {}
  }

  const video = transformProgram(image(), (pl) => {
    let res = pl

    const s = pl.findIndex((e) => e?.className?.includes('thumbnail-container') ||
      e?.props?.className?.includes('thumbnail-container') ||
      e?.classes?.includes('thumbnail-container'))

    if (s > -1) {
      pl.splice(
        s,
        1,
        <i
          className='fa-regular fa-file-video'
          style={{ color: '#009ef7', fontSize: 36, marginTop: '0px' }}
        ></i>
      )

      const left = pl.slice(0, s + 2)

      const vid = (
        <Modal open={modalOpen} closable={true} footer={null} onCancel={() => setModalOpen(false)}>
          <video width='100%' height='500px' controls src={url}></video>
        </Modal>
      )

      const button = {
        type: Button,
        attributes: {
          type: 'link',
          style: {
            padding: 0,
          },
          onClick: () => setModalOpen(true),
        },
      }

      res = [...left, button, vid]
    }
    return res
  })
  const docs = transformProgram(image(), (pl) => {
    let res = pl

    const s = pl.findIndex(
      (e) =>
        e?.className?.includes('thumbnail-container') ||
        e?.props?.className?.includes('thumbnail-container') ||
        e?.classes?.includes('thumbnail-container')
    )

    /* debugger;*/

    if (s > -1) {
      /* Add pdf icon to the end of thumbnail-container */
      pl.splice(
        s,
        1,
        <i
          className='fa-regular fa-file-pdf'
          style={{ color: '#009ef7', fontSize: 36, marginTop: '10px' }}
        ></i>
      )
      const left = pl.slice(0, s + 2)

      const link = {
        type: 'a',
        attributes: {
          href: url,
          target: '_blank',
          rel: 'noreferrer',
        },
      }

      const button = {
        type: Button,
        attributes: {
          type: 'link',
          style: {
            padding: 0,
          },
        },
      }

      res = [...left, link, button]
    }

    return res
  })

  let res = <div></div>
  if (fmime.includes('image')) {
    /* @ts-ignore*/
    res = run(runLayout(image()))
  } else if (fmime.includes('video')) {
    /* @ts-ignore*/
    res = run(runLayout(video()))
  } else {
    /* @ts-ignore*/
    res = run(runLayout(docs()))
  }

  return res
}

const AllOption = 'All'
function* checkListProgram({
  optionList: plainOptions,
  checkedList,
  setCheckedList,
  multiple = false,
  translate = (e) => e,
  onWheel = (e: any) => { },
  singleWithAll = false,
}): Generator<any, any, any> {
  const indeterminate = checkedList.length > 0 && checkedList.length < plainOptions.length
  const checkAll = plainOptions.length === checkedList.length

  const radioCheckAll = checkedList.length === 1 && checkedList[0] === AllOption

  const onChange = (list) => {
    if (multiple) {
      setCheckedList(list)
    } else {
      setCheckedList([list.target.value])
    }
  }

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? plainOptions.map((e) => e.value || e) : [])
  }

  const onRadioCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? [AllOption] : [])
  }

  yield {
    anchor: true,
    classes: ['check-list-anchor'].join(' '),
  }

  if (multiple) {
    yield (
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
        className={clsx({ 'tw-hidden': !plainOptions.length || !multiple })}
      >
        {translate('All')}
      </Checkbox>
    )
  } else if (singleWithAll) {
    yield (
      <Radio onChange={onRadioCheckAllChange} checked={radioCheckAll}>
        {translate('All')}
      </Radio>
    )
  }

  if (multiple) {
    yield (
      <CheckboxGroup
        options={plainOptions}
        value={checkedList}
        onChange={onChange}
        className='tw-flex tw-flex-col'
      ></CheckboxGroup>
    )
  } else {
    yield (
      <Radio.Group
        options={plainOptions}
        value={checkedList[0]}
        onChange={onChange}
        className='tw-flex tw-flex-col'
      ></Radio.Group>
    )
  }

  yield {
    classes: 'tw-max-h-56 tw-flex-nowrap tw-overflow-scroll',
    attributes: {
      onWheel,
      style: {
        marginTop: '9px',
      },
    },
  }

  yield {
    type: Card,

    classes: ['check-list-root'].join(' '),
  }
}

const selectionStyle = {
  width: '300px',
  //  height: '4rem',
  overflow: 'hidden',
}

const fontSize = 13

const textToLength = (text, fs = fontSize) => {
  return ((text.length + 1) * fs) / 1.5
}

function* dropdownProgram(inject): Generator<any, any, any> {
  const {
    optionList,
    checkedList,
    setCheckedList,
    onClick,
    getDisplay = (e) => e,
    attributes = {},
    title = 'Filter',
    translate = (e) => e,
    multiple = false,
    placeholder = 'default',
    disabled = false,
  } = inject

  const _onClick = (sel, event) => {
    const i = checkedList.findIndex((e) => {
      event.preventDefault()
      let res = e === sel
      if (e.value) {
        res = e.value === sel.value
      }

      return res
    })

    if (i > -1) {
      const left = checkedList.slice(0, i)
      const right = checkedList.slice(i + 1)

      if (multiple) {
        setCheckedList([sel])
      } else {
        setCheckedList([...left, ...right])
      }
    }
  }

  yield {
    anchor: true,
    classes: ['dropdown-outlet'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['selection-root-outlet'].join(' '),
  }

  yield <Text>{title}</Text>

  yield {
    anchor: true,
    classes: ['selection-container-outlet'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['selection-outlet'].join(' '),
  }

  if (checkedList.length === 0) {
    yield (
      <Input
        placeholder={placeholder}
        variant='borderless'
        value=''
        style={{ paddingLeft: 0, width: '100%' }}
        disabled={disabled}
      ></Input>
    )
  } else if (checkedList.length && checkedList.length === optionList.length) {
    yield (
      <Tag
        onClose={(e) => {
          setCheckedList([])
        }}
        style={{ height: '1.75rem' }}
      >
        {translate('All')}
      </Tag>
    )
  } else {
    for (const c of checkedList) {
      yield (
        <Tag
          closeIcon
          onClose={(e) => (onClick ? onClick(c, e) : _onClick(c, e))}
          style={{ height: '1.75rem' }}
        >
          {getDisplay(translate(c))}
        </Tag>
      )
    }
  }

  yield {
    type: Flex,
    classes: ['selection-container'].join(' '),
    attributes: {
      gap: '0.5rem',
      style: {
        paddingLeft: '0.5rem',
        width: '100%',
        //height: '2rem',
      },
    },
  }

  yield {
    classes: [
      'selection-data-container',
      'tw-rounded-lg',
      'tw-overflow-hidden',
      'tw-flex',
      'tw-items-center',
    ].join(' '),
    attributes: {
      style: {
        background: disabled ? 'rgba(0, 0, 0, 0.04)' : '#F9F9F9',
        //border: '1px solid  #d3d8dd',
        height: '32px',
        padding: '2px',
      },
    },
  }

  yield {
    classes: ['selection-root', 'tw-w-full'].join(' '),

    attributes: {
      style: selectionStyle,
      ...attributes,
    },
  }

  yield {
    type: Dropdown,
    attributes,
  }
}

const maxTagLength = 200

const StatusFilter = () => {
  const [open, setOpen] = useState(false)

  /* const [checkedList, setCheckedList] = useState<any[]>([]);*/

  const [selectionClassname, setSelectionClassname] = useState('')

  const intl = useIntl()

  const translate = useCallback((id, extra = {}) => intl.formatMessage({ id, ...extra }), [intl])

  const {
    appointmentStatusList,
    statusSelectedList: checkedList,
    setStatusSelectedList: setCheckedList,
  } = useCalendar()

  const dropdownRender = useCallback(() => {
    const res = run(
      runLayout(
        checkListProgram({
          checkedList,
          setCheckedList,
          optionList: appointmentStatusList,
          multiple: true,
          translate,
        })
      )
    )

    return <>{res}</>
  }, [appointmentStatusList, checkedList, setCheckedList, translate])

  useEffect(() => {
    setCheckedList(appointmentStatusList.map((e) => e.value))
  }, [setCheckedList, appointmentStatusList])

  useEffect(() => {
    if (checkedList.length && checkedList.length !== appointmentStatusList.length) {
      const allText = checkedList.reduce((acc, v) => acc + v, '')

      const textLength = textToLength(allText)

      if (textLength > maxTagLength) {
        setSelectionClassname('tw-justify-end')
      } else {
        setSelectionClassname('')
      }
    } else {
      setSelectionClassname('')
    }
  }, [checkedList, setSelectionClassname, appointmentStatusList])

  const program = transformProgram(
    dropdownProgram({
      checkedList,
      setCheckedList,
      optionList: appointmentStatusList,

      title: translate('status'),
      placeholder: '',
      translate,
      attributes: {
        dropdownRender,
        trigger: ['click'],
        open,
        onOpenChange: (open) => setOpen(open),
      },
    }),
    (pl) => {
      let res = pl

      const selectionContainerIndex = pl.findIndex((e) =>
        e?.classes?.split(' ')?.includes('selection-container')
      )

      if (selectionContainerIndex > -1) {
        const newContainer = {
          type: Flex,
          classes: ['selection-container', selectionClassname].join(' '),
          attributes: {
            gap: '0.5rem',
            style: {
              paddingLeft: '0.5rem',
              width: '100%',
              //height: '2rem',
            },
          },
        }

        res.splice(selectionContainerIndex, 1, newContainer)
      }

      return res
    }
  )

  const res = run(runLayout(program()))

  return <>{res}</>
}

const DropdownWithSearch = ({
  optionList,
  setOptionList,
  checkedList,
  setCheckedList,
  api,
  getDisplay = (e) => e,
  title = 'Filter',
  disabled = false,
  multiple = false,
  attributes = {},
  placeholder = '',
  transformDropdown = (pl) => pl,
  searchTrigger = true,
  singleWithAll = false,
}) => {
  const [open, setOpen] = useState(false)

  const intl = useIntl()

  const translate = useCallback(
    (id, extra = {}) => intl.formatMessage({ id, ...extra }),

    [intl]
  )

  /* const [checkedList, setCheckedList] = useState<any[]>([]);*/

  const [maxPage, setMaxPage] = useState(0)
  const [searchTerm, setSearchTerm] = useState('')
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  const [selectionClassname, setSelectionClassname] = useState('')

  const onSearch = useCallback(
    async ({ searchTerm: term = '', page = 1 }) => {
      if (loading) {
        const res = await api(term, page)

        const { items = [], max_page } = res

        const checkedListAsOption = checkedList
          .filter((e) => e !== AllOption)
          .map((e) => JSON.parse(e))
          .map((e) => {
            const label = getDisplay(e)

            return {
              ...e,
              value: JSON.stringify(e),
              label,
            }
          })

        const currentList = page === 1 ? checkedListAsOption : optionList

        setOptionList(
          unique(
            [
              ...currentList,
              ...items.map((e) => {
                const label = getDisplay(e)

                return {
                  ...e,
                  value: JSON.stringify(e),
                  label,
                }
              }),
            ],
            (e) => e.drug_id_code || e.uid || e.value
          )
        )

        if (max_page) {
          setMaxPage(max_page)
        }

        setLoading(false)
      }
    },
    /* getDisplay
      / dependency create infinite update
      /*/
    //eslint-disable-next-line
    [loading, optionList, setOptionList, checkedList, setLoading]
  )

  const onWheel = useCallback(
    (e) => {
      if (!loading) {
        const { currentTarget: target, deltaY } = e

        const scrollPercent = (target.scrollTop + target.clientHeight) / target.scrollHeight

        const newPage = page + 1

        if (scrollPercent > 0.9 && deltaY > 0 && newPage <= maxPage) {
          setLoading(true)
          setPage(page + 1)
        }
      }
    },
    [loading, page, maxPage, setPage, setLoading]
  )

  useEffect(() => {
    onSearch({ searchTerm, page })
  }, [searchTerm, page, onSearch])

  useEffect(() => {
    setSearchTerm('')
    setPage(1)
    setLoading(true)
  }, [setSearchTerm, setPage, setLoading, searchTrigger])

  useEffect(() => {
    if (checkedList.length) {
      const ll = checkedList.filter((e) => e !== AllOption).map((e) => getDisplay(e))

      const allText = ll.reduce((acc, v) => acc + v, '')

      const textLength = textToLength(allText)

      if (textLength > maxTagLength) {
        setSelectionClassname('tw-justify-end')
      } else {
        setSelectionClassname('')
      }
    } else {
      setSelectionClassname('')
    }
  }, [checkedList, getDisplay, setSelectionClassname])

  const dropdownRender = useCallback(() => {
    const search = (
      <Search
        placeholder={translate('search')}
        onSearch={(term) => {
          setSearchTerm(term)
          setPage(1)
          setLoading(true)
        }}
        style={selectionStyle}
      />
    )

    const pagination = (
      <Pagination
        defaultCurrent={1}
        current={page}
        total={maxPage * 10}
        showSizeChanger={false}
        size={'small'}
        style={{ marginTop: '0.5rem', display: 'none' }}
        onChange={(page) => {
          setPage(page)
        }}
      />
    )

    const program = transformProgram(
      checkListProgram({
        checkedList,
        setCheckedList,
        optionList: optionList,
        multiple,
        onWheel,
        translate,
        singleWithAll,
      }),
      (pl) => {
        let res = pl
        res = [search, ...res]
        res.splice(-1, 0, pagination)

        return res
        /* return [search, ...pl, pagination]*/
      }
    )
    const res = run(runLayout(program()))

    return <>{res}</>
  }, [
    optionList,
    checkedList,
    setCheckedList,
    maxPage,
    page,
    multiple,
    translate,
    onWheel,
    singleWithAll,
  ])

  const program = transformProgram(
    dropdownProgram({
      checkedList,
      setCheckedList,

      optionList,
      placeholder,

      title: translate(title),
      multiple,
      disabled,
      translate,

      attributes: {
        ...attributes,
        dropdownRender,
        open,
        onOpenChange: (open) => setOpen(open),
        disabled,
      },
    }),
    (pl) => {
      let res = pl

      const selectionListIndex = pl.findIndex((e) => e?.classes?.includes('selection-outlet'))

      const selectionContainerIndex = pl.findIndex((e) =>
        e?.classes?.split(' ')?.includes('selection-container')
      )

      if (selectionListIndex > -1 && selectionContainerIndex > -1) {
        const data = checkedList.map((sel) => {
          const display = sel === AllOption ? translate(sel) : getDisplay(sel)

          return (
            <Tag
              className='tw-truncate'
              style={{ minWidth: Math.min(Math.round(textToLength(display)), maxTagLength) }}
              closeIcon={disabled || sel === AllOption ? null : true}
              onClose={(event) => {
                const i = checkedList.findIndex((e) => {
                  event.preventDefault()
                  let res = e === sel
                  if (e.value) {
                    res = e.value === sel.value
                  }

                  return res
                })

                if (i > -1) {
                  const left = checkedList.slice(0, i)
                  const right = checkedList.slice(i + 1)

                  setCheckedList([...left, ...right])
                }
              }}
            >
              {display}
            </Tag>
          )
        })

        if (!multiple || checkedList.length !== optionList.length) {
          const newContainer = {
            type: Flex,
            classes: ['selection-container', selectionClassname].join(' '),
            attributes: {
              gap: '0.5rem',
              style: {
                paddingLeft: '0.5rem',
                width: '100%',
                //height: '2rem',
              },
            },
          }

          res.splice(selectionContainerIndex, 1, newContainer)
          res.splice(selectionListIndex + 1, checkedList.length, ...data)
        }
      }

      return transformDropdown(res)
    }
  )

  const res = run(runLayout(program()))

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultBg: '#FFFFFF',
            defaultColor: '#FFFFFF',
            defaultBorderColor: '#F9F9F9',
            defaultShadow: '0 0 0 0 #FFF0',
            fontWeight: 500,
            // lineWidth: 0,
            colorPrimary: '#3E97FF',
            primaryShadow: '0 0 0 0 #FFF0',
            colorPrimaryHover: '#0095e8',
          },
          Input: {
            activeBg: '#FFFFF',
            colorBgContainer: '#FFFFFF',
            // lineWidth: 0,
            /* hoverBg: '#F9F9F9',
             * colorText: '#4B5675',
             * colorBorder: '#F9F9F9',*/
          },
        },
      }}
    >
      {res}
    </ConfigProvider>
  )
}

const OrganizationFilter = () => {
  const {
    organizationFilterList: optionList,
    setOrganizationFilterList: setOptionList,
    organizationSelectedList: checkedList,
    setOrganizationSelectedList: setCheckedList,
  } = useCalendar()

  /* const setCheckedList = useCallback((list) => {
   *     setDoctorSelectedList(list.map(e => JSON.parse(e)))
   * }, [setDoctorSelectedList]);*/

  const { currentUser, auth } = useAuth()

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (['SUPER_ADMIN_SYS', 'ROOT'].includes(currentUser?.role || '')) {
      const items = [auth?.organization]

      setCheckedList(items.map((e) => JSON.stringify(e)))

      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [currentUser, auth, setCheckedList])

  /* if(currentUser?.role) debugger;*/

  const getDisplay = (e) => {
    let info = e
    if (!info.uid) info = JSON.parse(info)
    const { name } = info

    const res = [name]
      .map((e) => e.trim())
      .filter((e) => e)
      .join(' - ')

    return res
  }
  const api = useCallback(
    (term, page) => {
      if (!['SUPER_ADMIN_SYS', 'ROOT'].includes(currentUser?.role || '')) {
        const items = [auth?.organization]
        setCheckedList(items.map((e) => JSON.stringify(e)))
        return Promise.resolve({ items, max_page: 1 })
      } else {
        return callGeneric(
          axios({
            method: 'get',
            params: {
              name: term,
              page: page,
            },
            url: ORG_API,
          })
        )
      }
    },
    [currentUser, setCheckedList, auth]
  )

  return DropdownWithSearch({
    multiple: !disabled,
    optionList,
    setOptionList,
    checkedList,
    setCheckedList,
    api,
    getDisplay,
    title: 'organization',
    disabled,
    attributes: {
      trigger: ['click'],
    },
  })
}

const DoctorFilter = () => {
  const {
    doctorFilterList: optionList,
    setDoctorFilterList: setOptionList,
    doctorSelectedList: checkedList,
    setDoctorSelectedList: setCheckedList,
    organizationSelectedList,
  } = useCalendar()

  /* const setCheckedList = useCallback((list) => {
   *     setDoctorSelectedList(list.map(e => JSON.parse(e)))
   * }, [setDoctorSelectedList]);*/

  const { currentUser } = useAuth()

  const [disabled, setDisabled] = useState(false)

  const getDisplay = (e) => {
    let info = e
    if (!info.uid) info = JSON.parse(info)
    const { email, fullname } = info

    const res = [fullname, email]
      .map((e) => e.trim())
      .filter((e) => e)
      .join(' - ')

    return res
  }
  const api = useCallback(
    (term, page) => {
      if (currentUser?.role === 'DOCTOR') {
        const items = [currentUser]
        setCheckedList(items.map((e) => JSON.stringify(e)))
        setDisabled(true)
        return Promise.resolve({ items, max_page: 1 })
      } else {
        /* if(organizationSelectedList?.length) debugger;*/
        return callGeneric(
          axios({
            method: 'get',
            params: {
              fullname: term,
              email: term,
              page: page,
              role: JSON.stringify(['DOCTOR']),
              ...(organizationSelectedList?.length
                ? {
                  org: JSON.stringify(
                    organizationSelectedList.map((e) => JSON.parse(e)).map((e) => e?.uid)
                  ),
                }
                : {}),
            },
            url: EMPLOYEE_API,
          })
        )
      }
    },
    [currentUser, setCheckedList, organizationSelectedList]
  )

  useEffect(() => {
    setCheckedList([AllOption])
  }, [setCheckedList])

  return DropdownWithSearch({
    optionList,
    setOptionList,
    checkedList,
    setCheckedList,
    api,
    getDisplay,
    title: 'doctor',
    disabled,
    attributes: {
      trigger: ['click'],
    },
    searchTrigger: organizationSelectedList,
    singleWithAll: true,
  })
}

const PatientFilter = ({ disabled = false }) => {
  const {
    patientFilterList: optionList,
    setPatientFilterList: setOptionList,
    patientSelectedList: checkedList,
    setPatientSelectedList: setCheckedList,
  } = useCalendar()

  /* const setCheckedList = useCallback((list) => {
   *     setDoctorSelectedList(list.map(e => JSON.parse(e)))
   * }, [setDoctorSelectedList]);*/

  /* const {currentUser} = useAuth()*/

  const getDisplay = (e) => {
    let info = e
    if (!info.uid) info = JSON.parse(info)
    const { birthday, fullname } = info

    const dob = birthday && moment(birthday, 'YYYY/MM/DD').format(dateFormat)

    const res = [fullname, dob]
      .map((e) => e.trim())
      .filter((e) => e)
      .join(' - ')

    return res
  }
  const api = useCallback((term, page) => {
    return callGeneric(
      axios({
        method: 'get',
        params: {
          fullname: term,
          /* phone: term,*/
          page: page,
          /* role: JSON.stringify(['PATIENT']),*/
        },
        url: PATIENT_API,
      })
    )
  }, [])

  useEffect(() => {
    setCheckedList([AllOption])
  }, [setCheckedList])

  return DropdownWithSearch({
    optionList,
    setOptionList,
    checkedList,
    setCheckedList,
    api,
    getDisplay,
    title: 'patient',
    disabled,
    attributes: {
      trigger: ['click'],
    },
    singleWithAll: true,
  })
}

const dateFormat = 'DD/MM/YYYY'

const TimeFilter = () => {
  const intl = useIntl()

  const translate = (id, extra = {}) => intl.formatMessage({ id, ...extra })

  const { timeSelectedList, setTimeSelectedList } = useCalendar()

  const onChange = useCallback(
    (dates, dateStrings) => {
      /* setTimeSelectedList(dates ? dates.map((d) => d.format('YYYY/MM/DD HH:mm:ss')) : [])*/
      setTimeSelectedList(dates || [])
    },
    [setTimeSelectedList]
  )

  return (
    <div style={{ minWidth: '254px' }}>
      <Text style={{ display: 'block' }}>{translate('time')}</Text>
      <RangePicker
        defaultValue={timeSelectedList}
        onChange={onChange}
        format={dateFormat}
      ></RangePicker>
    </div>
  )
}

const Calendar = () => {
  const {
    showFile,
    setDetailBookingInfo,
    detailBookingInfo,
    setFileURL,
    setShowFile,
    appointmentListPage,
    setAppointmentListPage,
  } = useCalendar()

  const of = OrganizationFilter()
  const sf = StatusFilter()
  const df = DoctorFilter()

  const pf = PatientFilter({ disabled: false })

  const tf = TimeFilter()

  /*
    / Location for breadcrumbs
    /*/
  const cal_uid = detailBookingInfo?.uid

  const [mode, setMode] = useState('list')

  const {
    response: { items = [], max_page },
    refetch,
  } = useQueryResponse()

  const intl = useIntl()

  const translate = (id, extra = {}) => intl.formatMessage({ id, ...extra })

  const { updateState, putState } = useQueryRequest()

  const {
    statusSelectedList,
    timeSelectedList,
    doctorSelectedList,
    patientSelectedList,
    initialLoad,
    organizationSelectedList,
  } = useCalendar()

  const getData = useCallback(
    ({ auto = true }) => {
      let query: any = { page: auto ? appointmentListPage : 1 }

      if (!auto) {
        setAppointmentListPage(1)
      }

      const [from_datetime, to_datetime] = timeSelectedList.map((e, i) => {
        let res = e.format('YYYY/MM/DD HH:mm:ss')
        if (i !== 0) {
          res = e.format('YYYY/MM/DD 23:59:59')
        }
        return res
      })
      const [doctor] = doctorSelectedList.filter((e) => e !== AllOption).map((e) => JSON.parse(e))

      query = { ...query, status: JSON.stringify(statusSelectedList) }

      if (from_datetime && to_datetime) {
        query = { ...query, from_datetime, to_datetime }
      }

      if (doctor?.uid) {
        query = { ...query, target_doctor_id: doctor.uid }
      } else {
        if (organizationSelectedList?.length) {
          const [org] = organizationSelectedList
          query = { ...query, target_org_id: JSON.parse(org).uid }
        }
      }

      const [patient] = patientSelectedList.filter((e) => e !== AllOption).map((e) => JSON.parse(e))

      if (patient?.uid) {
        query = { ...query, target_patient_id: patient.uid }
      }

      /* debugger;*/
      putState(query)
    },
    [
      putState,
      statusSelectedList,
      timeSelectedList,
      doctorSelectedList,
      patientSelectedList,
      appointmentListPage,
      setAppointmentListPage,
      organizationSelectedList,
    ]
  )

  const trigger = <Button onClick={() => getData({ auto: false })}>{translate('search')}</Button>

  const navigate = useNavigate()

  useEffect(
    () => {
      if (initialLoad) getData({ auto: true })
    },
    // eslint-disable-next-line
    [initialLoad]
  )

  useEffect(() => {
    if (max_page && appointmentListPage > max_page) {
      setAppointmentListPage(max_page)
      getData({ auto: false })
    }
  }, [max_page, appointmentListPage, setAppointmentListPage, getData])

  useEffect(() => {
    window.addEventListener('visibilitychange', refetch, false)

    return () => {
      window.removeEventListener('visibilitychange', refetch, false)
    }
  }, [refetch])

  return (
    <>
      {mode === 'list' && <PageTitle breadcrumbs={[]}>Danh sách lịch hẹn</PageTitle>}
      {mode === 'detail' && <PageTitle breadcrumbs={calendarDetailBCrs}>{cal_uid}</PageTitle>}
      {mode === 'medical-history' && <PageTitle breadcrumbs={calendarBCrs}>Tiền sử bệnh</PageTitle>}

      <div className={clsx({ 'tw-hidden': mode !== 'list' })}>
        {/* <div className='calendar-title'>{intl.formatMessage({id: 'calendar-list-title'})}</div> */}

        <ConfigProvider
          theme={{
            components: {
              Card: {
                headerHeight: 64,
              },
            },
          }}
        >
          <Card
            title={
              <Flex gap='0.5rem' align='start' style={{ paddingTop: '12px' }}>
                {of}
                {sf}
                {df}
                {pf}
                {tf}

                <Flex vertical={true}>
                  <div style={{ height: '22px' }}></div>
                  {trigger}
                </Flex>
              </Flex>
            }
          >
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: '#fff',
                    colorText: '#78829d',
                  },
                },
              }}
            >
              <Table
                dataSource={items}
                rowKey='uid'
                pagination={{
                  defaultCurrent: 1,
                  total: max_page * pageSize,
                  showSizeChanger: false,
                  current: appointmentListPage,
                  onChange: (page) => {
                    updateState({ page })
                    setAppointmentListPage(page)
                  },
                }}
                onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
                onRow={() => ({ className: 'fw-bolder fs-7' })}
                size='middle'
              >
                {fieldList.map((field) => {
                  return (
                    <Column
                      title={intl.formatMessage({ id: field })}
                      dataIndex={field}
                      render={getRender(field, store, {
                        setMode,
                        setDetailBookingInfo,
                        setFileURL,
                        setShowFile,
                        translate,
                        navigate,
                      })}
                    />
                  )
                })}
              </Table>
            </ConfigProvider>
          </Card>
        </ConfigProvider>
      </div>

      <div className={clsx({ 'tw-hidden': mode !== 'detail' })}>
        <CalendarDetail extra={{ setMode, refetch }}></CalendarDetail>
      </div>

      {showFile && <CalendarFileModal />}
    </>
  )
}

const CalendarPage = () => {
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <Calendar />
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}

export {
  CalendarPage,
  calendarBCrs,
  calendarDetailBCrs,
  FilePreview,
  genderStyle,
  dateFormat,
  DropdownWithSearch,
}
