/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { PULSEOX_SUMMARY_API, RESPIRATION_SUMMARY_API, HEARTRATE_SUMMARY_API, STEP_SUMMARY_API } from '../../../helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs';
import { DatePicker, Button, Space, Flex } from 'antd';

import { Chart } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4';

import { Legend, TimeScale } from 'chart.js';

Chart.register(
    TimeScale,
    Legend,
);

let options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                beforeLabel: function (context) { return ''; },
                label: function (context) {
                    let label = '';
                    switch (context.datasetIndex) {
                        case 0: {
                            label += `SpO2: ${context.raw.y}%`;
                            break;
                        }
                        case 1: {
                            label += `Nhịp thở: ${context.raw.y} nhịp/phút`;
                            break;
                        }
                        case 2: {
                            label += `Nhịp tim: ${context.raw.y} nhịp/phút`;
                            break;
                        }
                        case 3: {
                            label += `Bước chân: ${context.raw.y} bước`;
                            break;
                        }
                    }
                    return label;
                },
                title: function () {
                    return '';
                }
            }
        }
    },
    scales: {
        x: {
            type: 'linear' as const,
            ticks: {
                count: 24,
                callback: function (val, index) {
                    // Hide every 2nd tick label
                    return `${String(index).padStart(2, '0')}:00`;
                },
            },
            time: {
                unit: 'hour' as const,
                displayFormats: {
                    minute: 'HH' as const,
                    hour: 'HH:mm' as const,
                },
                round: 'hour' as const,
                tooltipFormat: 'DD T' as const,
            },
            title: {
                display: false,
            },
            max: 23,
            min: 0,
        },
        y: {
            type: 'linear' as const,

        },
        y1: {
            type: 'linear' as const,
            position: 'right' as const,
        }
        // y: {
        //     min: 70,
        // },
    },
}

const ComboChart = ({ className, uid }) => {
    let initData: any[] = []
    const [series, setSeries] = useState({ datasets: initData })
    const [date, setDate] = useState(dayjs())

    const handleBack = () => {
        setDate(date.subtract(1, 'day'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'day'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }

    let legends = [['SpO2 (%)', '#F1416C'],
    ['Nhịp thở (lần/phút)', '#3E97FF'], ['Nhịp tim (nhịp/phút)', '#75CC68'], ['Bước chân (bước)', '#FFAD0F']];

    useEffect(() => {
        let pulseOxData = axios.get(PULSEOX_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })
        let respirationData = axios.get(RESPIRATION_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })
        let heartRateData = axios.get(HEARTRATE_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })
        let stepData = axios.get(STEP_SUMMARY_API, { params: { local_date: date.format('YYYY/MM/DD'), target_user_id: uid } })

        Promise.all([pulseOxData, respirationData, heartRateData, stepData]).then((values) => {
            let [pulseOxData, respirationData, heartRateData, stepData] = values
            let pulseChart = pulseOxData.data.data.map(item => {
                return {
                    x_: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.avg_spo2,
                    x: item.ref_time
                }
            })

            let respirationChart = respirationData.data.data.map(item => {
                return {
                    x_: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.avg_respiration,
                    x: item.ref_time
                }
            });

            let heartRateChart = heartRateData.data.data.map(item => {
                return {
                    x_: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.average_heart_rate_in_beats_per_minute,
                    x: item.ref_time
                }
            })

            let stepChart = stepData.data.data.map(item => {
                return {
                    x_: dayjs(item.start_datetime * 1000).hour(item.ref_time).valueOf(),
                    y: item.total_steps,
                    x: item.ref_time
                }
            })

            setSeries({
                datasets: [
                    {
                        type: 'line',
                        data: pulseChart,
                        backgroundColor: '#F1416C',
                        label: 'SpO2'
                    },
                    {
                        type: 'line',
                        data: respirationChart,
                        backgroundColor: '#3E97FF',
                        label: 'Nhịp thở'
                    },
                    {
                        type: 'line',
                        data: heartRateChart,
                        backgroundColor: '#75CC68',
                        label: 'Nhịp tim'
                    },
                    {
                        type: 'bar',
                        data: stepChart,
                        backgroundColor: '#FFAD0F',
                        yAxisID: 'y1',
                        borderRadius: Number.MAX_VALUE,
                        categoryPercentage: 0.4,
                        label: 'Bước chân'
                    }
                ]
            })
        })
    }, [uid, date])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Tổng hợp</span>

                    <span className='text-muted fw-semibold fs-7'></span>
                </h3>

                {/* begin::Toolbar */}
                <div className='card-toolbar' data-kt-buttons='true'>
                    <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker value={date} onChange={handleDateSelect} allowClear={false} />
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body' style={{ height: "500px"}}>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <div></div>
                    <Flex style={{ justifyContent: 'center' }}>
                        {legends.map((value, index) => {
                            return <Flex align='center' key={index}>
                                <div style={{
                                    width: '20px',
                                    height: '10px',
                                    background: value[1],
                                    marginLeft: '10px',
                                    marginRight: '10px'
                                }}>
                                </div>
                                <span>
                                    {value[0]}
                                </span>
                            </Flex>
                        })}
                    </Flex>
                    <div></div>
                </Flex>
                <Bar
                    options={options}
                    data={series}
                />
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { ComboChart }