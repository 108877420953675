import { SwapRightOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, DatePicker, Form, Row, Select, Space, Switch, notification } from "antd"
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { APPOINTMENT_API, DISEASE_EMPLOYEE_CHANGE_BOOKING_API, DOCTOR_SHIFT_AVAILABLE_API } from "app/modules/helpers/Common"
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"

export const Reschedule = ({ appointment, forceRefresh }) => {

  const [showNotification, contextHolder] = notification.useNotification()

  const [form] = useForm()
  const [doctorList, setDoctorList] = useState([{ value: appointment.doctor_info.uid, label: appointment.doctor_info.fullname }] as any[])
  const [selectedDoctor, setSelectedDoctor] = useState(appointment.doctor_info.uid as any)
  const [selectedDate, setSelectedDate] = useState(dayjs(appointment.start_time * 1000) as any)
  const [availableTime, setAvailableTime] = useState([{
    value: appointment.start_time * 1000, label: `${dayjs(appointment.start_time * 1000).format('HH:mm')} - ${dayjs(appointment.end_time * 1000).format('HH:mm')}`
  }] as any[])
  const [selectedTime, setSelectedTime] = useState()
  const [filter, setFilter] = useState('')
  const [filterDebounce] = useDebounce(filter, 600)
  const [samePrice, setSamePrice] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    console.log(appointment)
    let params = {
      page: 1,
      name: filterDebounce,
      disease_id: appointment.disease_info.uid,
      org_id: appointment.doctor_info.org_id,
      price: samePrice ? appointment.price : undefined
    }
    axios.get(DISEASE_EMPLOYEE_CHANGE_BOOKING_API, { params: params })
      .then(response => {
        setDoctorList(response.data.data.items.map(doctor => ({ value: doctor.uid, label: doctor.fullname })))
      })
  }, [filterDebounce, appointment, samePrice])


  useEffect(() => {
    selectedDate && axios.get(DOCTOR_SHIFT_AVAILABLE_API, { params: { target_user_id: selectedDoctor, date: selectedDate.format('YYYY/MM/DD') } })
      .then(response => {
        setAvailableTime(response.data.data.blocks.map(block => ({
          value: block.start_time * 1000, label: `${dayjs(block.start_time * 1000).format('HH:mm')
            } - ${dayjs(block.end_time * 1000).format('HH:mm')} `
        })))
      })

  }, [selectedDate, selectedDoctor])

  const handleUpdate = () => {
    form.validateFields().then((values) => {
      const start_time = dayjs(selectedDate).set('hour', dayjs(selectedTime).hour()).set('minute', dayjs(selectedTime).minute()).set('second', 0)

      axios.put(APPOINTMENT_API, {
        appointment_id: appointment.uid,
        doctor_id: selectedDoctor,
        start_datetime: dayjs(start_time).format('YYYY/MM/DD HH:mm:ss'),
        end_datetime: dayjs(start_time).add(15, 'minute').format('YYYY/MM/DD HH:mm:ss'),
        reason_change: values.reason_change,
        verify_price: samePrice ? 1 : 0
      }).then((response) => {

        const { data: { error, success } } = response;

        if (success) {
          navigate(`/calendar/id/${appointment.uid}`)
          forceRefresh()
        } else {
          const { message } = error;

          showNotification.open({
            message: 'Lỗi',
            description: message,
            placement: 'topRight',
            icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
          })
        }

      })
    }).catch(() => { })
  }

  return (
    <Card title='Thay đổi lịch khám' actions={[
      <Row justify="end" style={{ paddingRight: 25 }}>
        <Space>
          <Button onClick={() => navigate(-1)}>Huỷ</Button>
          <Button type='primary' onClick={handleUpdate}>Đổi lịch</Button>
        </Space>
      </Row>
    ]} >
      {contextHolder}
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        colon={false}
        form={form}
        // requiredMark={false}
        initialValues={{ doctor: selectedDoctor, date: selectedDate, shift: selectedTime, confirmed: false }}
      >
        <Form.Item label='Chuyển cùng mức giá' required name='same_price'>
          <Switch checked={samePrice} checkedChildren='Cùng giá' unCheckedChildren='Khác giá'
            onChange={(checked) => {
              setSamePrice(checked)
              setSelectedDoctor(undefined)
              setSelectedDate(undefined)
              setSelectedTime(undefined)
              setFilter('')
              setAvailableTime([])
              form.setFieldsValue({ doctor: undefined, date: undefined, shift: undefined })
            }}
          />
        </Form.Item>
        <Form.Item label={<>{`Bác sĩ [${appointment.doctor_info.fullname}]`}<SwapRightOutlined className="ms-5 me-3" /></>} name="doctor" required>
          <Select
            options={doctorList}
            placeholder="Chọn bác sĩ"
            style={{ maxWidth: 300 }}
            allowClear={false}
            showSearch
            filterOption={false}
            onSearch={(value) => setFilter(value)}
            onSelect={(value) => {
              setSelectedDoctor(value)
              form.setFieldsValue({ date: undefined, shift: undefined })
              setSelectedDate(undefined)
              setSelectedTime(undefined)
              setFilter('')
              setAvailableTime([])
            }}
            value={selectedDoctor}
          />

        </Form.Item>
        <Form.Item label={<>{`Ngày khám [${dayjs(appointment.start_time * 1000).format('DD-MM-YYYY')}]`}<SwapRightOutlined className="ms-5 me-3" /></>} rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]} name="date">
          <DatePicker
            value={selectedDate}
            allowClear={false}
            minDate={dayjs()}
            format={'DD-MM-YYYY'}
            placeholder='Chọn ngày khám'
            onChange={(value) => {
              setSelectedTime(undefined)
              setSelectedDate(value)
              form.setFieldsValue({ shift: undefined })
            }} />
        </Form.Item>
        <Form.Item label={<>
          {`Ca khám [${dayjs(appointment.start_time * 1000).format('HH:mm')} -${dayjs(appointment.end_time * 1000).format('HH:mm')}]`}
          <SwapRightOutlined className="ms-5 me-3" />
        </>}
          name="shift"
          rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]}
        >
          <Select
            options={availableTime}
            // allowClear={false}
            style={{ maxWidth: 300 }}
            placeholder="Chọn ca khám"
            notFoundContent='Không có ca khám phù hợp'
            value={selectedTime}
            onSelect={(value) => setSelectedTime(value)}
          >
          </Select>
        </Form.Item>
        <Form.Item label='Lý do đổi' name='reason_change' rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]}>
          <TextArea placeholder='Bệnh nhân hẹn lại lịch khác' autoSize={{ minRows: 3 }} />
        </Form.Item>

        <Form.Item label='Đã xác nhận với bệnh nhân' required rules={[
          () => ({
            validator(_, value) {
              console.log(value)
              if (!value || value === false) {
                return Promise.reject(new Error('Cần xác nhận trước với bệnh nhân'))
              }
              return Promise.resolve()
            },
          }),
        ]}
          name="confirmed"
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>
      </Form>
    </Card>
  )
}