import { useParams } from "react-router-dom"
import { OrgReport } from "../dashboard/OrgReport"
import { PageLink, PageTitle } from "_metronic/layout/core"

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tài khoản',
    path: '/account/list',
  }]

export const DoctorReportPage = () => {
  const { uid } = useParams()
  return (
    <>
      <PageTitle description="Báo cáo" breadcrumbs={accountBreadCrumbs}>Báo cáo</PageTitle>
      <OrgReport doctor={uid} />
    </>)

}