import { Button, Col, List, Row, Space, Typography, theme } from "antd"
import dayjs from "dayjs"
import { Link, useNavigate } from "react-router-dom"

const { Text } = Typography
const { useToken } = theme

export const CalendarDay = ({ appointments }) => {
  const navigate = useNavigate()
  const { token } = useToken()

  return (
    <Row className="mt-2 mh-100 h-100" style={{ overflowY: 'scroll' }}>
      <List
        style={{ marginTop: '16px' }}
        dataSource={appointments as any[]}
        // pagination={{ position: 'bottom', align: 'end', pageSize: 3, total: maxPage * pageSize, current: currentPage, onChange: (page) => setCurrentPage(page) }}
        split={false}
        grid={{ column: 1 }}
        className="w-100"
        locale={{ emptyText: 'Không có lịch hẹn nào' }}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Row className="mx-7 py-4 px-5 rounded border">
              <Col flex="auto">
                <Space size="middle" align='start'>
                  <div style={{ width: '4px', minHeight: '45px', background: item.end_time < dayjs().unix() ? 'repeating-linear-gradient(-45deg, #ccc, #ccc 5px, #aaa 5px, #aaa 10px)' : token.colorPrimary, borderRadius: '4px' }}></div>
                  <Space direction="vertical" size={0} style={{ minWidth: 80 }}>
                    <span className="fs-6 fw-bold">{dayjs(item.start_time * 1000).format('HH:mm')}</span>
                    <span className="fs-7 text-gray-600">{(item.end_time - item.start_time) / 60} phút</span>
                  </Space>
                  <Space direction="vertical" size={0}>
                    <span className="fs-5 fw-bold">Khám tư vấn bệnh từ xa</span>
                    <Space>
                      <Text className="fs-6 fw-bold text-gray-600">Nhóm bệnh: </Text>
                      <Link to={`/disease/id/${item.disease_info.uid}`}>{item.disease_info.name}</Link>
                    </Space>
                    <Space>
                      <Text className="fs-6 fw-bold text-gray-600">Khách hàng: </Text>
                      <Link to={`/patient/id/${item.patient_info.uid}`}>{item.patient_info.fullname}</Link>
                    </Space>
                    <Space>
                      <Text className="fs-6 fw-bold text-gray-600">Lý do khám: </Text>
                      <Text className="fs-6" ellipsis={true} style={{maxWidth: '500px'}}>{item.reason}</Text>
                    </Space>
                    <Space>
                      <i className="fa-solid fa-users text-gray-600 fs-6"></i>
                      <Text className="fs-6 text-gray-600">{item.doctor_info.fullname} và {item.patient_info.fullname}</Text>
                    </Space>
                  </Space>
                </Space>
              </Col>
              <Col flex="100px">
                <Space>
                  <Button onClick={() => navigate(`/patient/id/${item.patient_info.uid}`)}>Hồ sơ bệnh nhân</Button>
                  <Button onClick={() => navigate(`/calendar/id/${item.uid}`)}>Xem chi tiết</Button>
                </Space>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Row>
  )
}