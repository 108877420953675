import { PageTitle } from '_metronic/layout/core'
import { Route, Routes } from 'react-router-dom'
import { ChatBar } from './ChatBar'
import { ChatWidgetWrapper } from './ChatWidgetWrapper'

export const ChatRoutes = () => {
  return (
    <Routes>
      <Route element={
        <>
          <PageTitle description='Danh sách tin nhắn'>Tin nhắn</PageTitle>
          <ChatBar />
        </>
      }>
        <Route
          path='id/:uid/*'
          element={<ChatWidgetWrapper />}
        />
        <Route index element={<></>} />
      </Route>
    </Routes>
  )
}
