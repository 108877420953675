import { Col, Row, Typography } from "antd"
import { formatPhone, getGenderDescription } from "../../../helpers/Common"

const { Text, Paragraph } = Typography

export const AccountOverviewForm = ({ account }) => {
    return (
        <>
            <Row gutter={[10, 10]}>
                <Col span={6}><Text strong>Họ và tên:</Text></Col>
                <Col span={18}><Text >{account && account.fullname}</Text></Col>
                <Col span={6}><Text strong>Ngày sinh:</Text></Col>
                <Col span={18}><Text>{account && account.birthday}</Text></Col>

                {account.role === 'PATIENT' ?
                    <>
                        <Col span={6}><Text strong>Email:</Text></Col>
                        <Col span={18}><Text >{account && account.email}</Text></Col>
                    </>
                    :
                    <>
                        <Col span={6}><Text strong>Số điện thoại:</Text></Col>
                        <Col span={18}><Text >{account && formatPhone(account.phone)}</Text></Col>
                        <Col span={6}><Text strong>Chứng chỉ hành nghề:</Text></Col>
                        <Col span={18}><Text >{account && account.practising_cert}</Text></Col>
                        <Col span={6}><Text strong>Chức danh:</Text></Col>
                        <Col span={18}><Text >{account && account.title.join(", ")}</Text></Col>
                        <Col span={6}><Text strong>Học hàm, học vị:</Text></Col>
                        <Col span={18}><Text >{account && account.degree}</Text></Col>
                        <Col span={6}><Text strong>Tiểu sử:</Text></Col>
                        <Col span={18}><Paragraph ellipsis={{ rows: 2, expandable: 'collapsible', symbol: (expand) => expand ? 'Thu gọn' : 'Xem thêm' }} >{account && account.summary}</Paragraph></Col>
                    </>
                }

                <Col span={6}><Text strong>Địa chỉ:</Text></Col>
                <Col span={18}><Text >{account && account.address}</Text></Col>

                <Col span={6}><Text strong>Giới tính:</Text></Col>
                <Col span={18}><Text >{account && getGenderDescription(account.gender || '')}</Text></Col>

                <Col span={6}><Text strong>Quốc tịch:</Text></Col>
                <Col span={18}><Text >{account && account.nationality}</Text></Col>

                <Col span={6}><Text strong>CCCD:</Text></Col>
                <Col span={18}><Text >{account && account.id_card}</Text></Col>

                <Col span={6}><Text strong>Dân tộc:</Text></Col>
                <Col span={18}><Text >{account && account.ethnic}</Text></Col>
            </Row>
        </>
    )
}
