/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {Modal, DatePicker, TimePicker, Flex} from 'antd'
import type {Dayjs} from 'dayjs'
import './CreateScheduleModal.scss'
import WeekBubble from './WeekBubble'
import _ from 'lodash'
import dayjs from 'dayjs'
import {CreateWorkPlanRequestParam} from '../core/Model'
import moment from 'moment'

const {RangePicker} = DatePicker
interface Props {
  open: boolean
  toggleModal: () => void
  handleCreateWorkplan: (param: CreateWorkPlanRequestParam) => void
  createWorkplanError: String
  setCreateWorkplanError: (text: any) => void
  userId : string
  userName: string
}

export const CreateScheduleModal: React.FunctionComponent<Props> = (props:Props) => {
  const fromDateI = new Date();
  fromDateI.setHours(0);
  fromDateI.setMinutes(0)
  fromDateI.setSeconds(0);
  const toDateI = new Date();
  toDateI.setHours(0);
  toDateI.setMinutes(0)
  toDateI.setSeconds(0);
  const initialState = {
    errorText: '',
    workingDays: Array<string>(),
    fromDate: dayjs(fromDateI),
    fromTime: dayjs(fromDateI),
    toDate: dayjs(toDateI),
    toTime: dayjs(toDateI),
    onBlurFromSet: false,
    onBlurToSet: false,
    onBlurDate: false,
    onBlurDay: false
  }

  const [{workingDays, fromDate, fromTime, toDate, toTime, errorText,
    onBlurFromSet, onBlurToSet, onBlurDate, onBlurDay
  }, setState] = useState(initialState)

  useEffect(() => {
    if (errorText === '' && props.createWorkplanError !== '') {
      onChangeState('errorText', props.createWorkplanError)
      props.setCreateWorkplanError('')
    }
  }, [props.createWorkplanError])
  const onChangeState = (name, value) => {
    if (errorText !== '' && name !== "errorText") {
      console.log('clean errorText')
      setState(prevState => ({...prevState, errorText: ''}))
    }
    setState(prevState => ({...prevState, [name]: value}))
  }

  const dateFormat = 'DD/MM/YYYY';
  const onChangeWorkingDays = (workingDay: string) => {
    const newWorkingDays : [] = _.xorBy(workingDays, [workingDay])
    onChangeState("workingDays" , newWorkingDays)
    if (newWorkingDays.length > 0) {
      onChangeState("onBlurDay", true)
    } else {
      onChangeState("onBlurDay", false)
    }
  }
  const convertWeekdaysShortToISONumber = (weekdaysShort: string[]) => {
    return weekdaysShort.map(day => {
      let dayNumber = moment().day(day).day() - 1
      if(dayNumber < 0) {
        dayNumber = 6
      }
      return dayNumber
    }).sort()
  }
  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates && dates[0] && dates[1]) {
      const newFromDate = dates[0]?.set('hours', fromTime.get('hour'))
      const newToDate = dates[1]?.set('hours', toDate.get('hour'))
      setState(prevState => ({...prevState, fromDate: newFromDate, toDate: newToDate, onBlurDate: true, errorText: ''}))
    } else {
      console.log('Not enough dates');
      setState(prevState => ({...prevState, fromDate: initialState.fromDate, toDate: initialState.toDate, onBlurDate: false}))
    }
  };
  const onChangeStartTime = (date: Dayjs) => {
    // onChangeState("fromDate", fromDate.set('hour', date.get('hour')))
    setState(prevState => ({...prevState, fromTime: date, onBlurFromSet: true, errorText: ''}))
  }
  const onChangeEndTime = (date: Dayjs) => {
    // onChangeState("toDate", toDate.set('hour', date.get('hour')))
    setState(prevState => ({...prevState, toTime: date, onBlurToSet: true, errorText: ''}))
  }

  const disabledHours = () => {
    const hours: number[] = [];
    const currentHour = fromTime.get('hour')
    for (let i = currentHour - 1; i >=0; i--) {
      hours.push(i);
    }
    return hours;
  };

  const disabledFromHours = () => {
    const hours: number[] = [];
    if (!onBlurToSet) return hours;
    const currentHour = toTime.get('hour')
    for (let i = 23; i > currentHour; i--) {
      hours.push(i);
    }
    return hours;
  };

  const disabledMinutes = (selectedHour) => {
    const minutes: number[] = [];
    const currentMinute = fromTime.minute();
    console.log(selectedHour, fromTime.hour())
    if (selectedHour === fromTime.hour()) {
      if (currentMinute === 0) {
        minutes.push(currentMinute);
      }
      for (let i = currentMinute + 1; i >=     0; i--) {
        minutes.push(i);
      }
    }
    return minutes;
  };
  const disabledFromMinutes = (selectedHour) => {
    const minutes: number[] = [];
    if (!onBlurToSet) return minutes;
    const currentMinute = toTime.minute();
    if (selectedHour === toTime.hour()) {
      for (let i = currentMinute; i <= 45  ; i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const isValidData = () => {
    if (!onBlurFromSet || !onBlurToSet || errorText !== '') return false;
    let isValid = false;
    if (toDate.diff(fromDate, 'day') + 1 < 7) {
      const rangeDate = toDate.diff(fromDate, 'date') === 0 ? 0 : 1
      for (let i = 0; i <= toDate.diff(fromDate, 'day') + rangeDate; i++) {
        if (!isValid) {
          isValid = workingDays.includes(fromDate.add(i, 'day').format('dd'))
        } else {
          break
        }
      }
      if (!isValid && workingDays.length > 0 && errorText === '') {
        onChangeState("errorText", "Vui lòng chọn lại lịch làm việc.")
        return false
      }
    } else {
      isValid = true
    }
    // const currentDate = dayjs(new Date())
    // if (fromDate.diff(currentDate, 'date') < 0 && workingDays.includes(fromDate.format('dd')) && fromTime.isBefore(currentDate)) {
    //   onChangeState("errorText", "Vui lòng chọn lại thời gian. Không thể tạo lịch làm việc ở quá khứ.")
    //   return false
    // }
    return isValid
  }

  return (
    <Modal
      title={<span className={'ant-modal-header'}>Tạo lịch làm việc</span>}
      centered
      open={props.open}
      closable={true}
      maskClosable={false}
      onOk={() => {
        if ( isValidData()) {
          props.handleCreateWorkplan({
            target_user_id: props.userId,
            start_date: moment(fromDate.toISOString()).format('YYYY/MM/DD'),//fromDate.format('YYYY/MM/DD'), //24/04/2024 => 2024/04/24
            end_date: moment(toDate.toISOString()).format('YYYY/MM/DD'),
            start_time: moment(fromTime.toISOString()).format('HH:mm:ss'), // -7 : 05:00 => 22:00
            end_time: moment(toTime.toISOString()).format('HH:mm:ss'),
            days_of_week: convertWeekdaysShortToISONumber(workingDays),
          })
        }
      }}
      okButtonProps={{disabled: !onBlurDate || !onBlurDay || !onBlurToSet || !onBlurFromSet}}
      destroyOnClose={true}
      afterClose={() => setState({...initialState})}
      onCancel={props.toggleModal}
      width={1000}
      closeIcon={true}
      className={'modalStyle'}
      cancelText={"Huỷ"}
      okText={"Xác nhận"}
    >
      <h3>{props.userName}</h3>
      <p>Chọn lịch:</p>
      <WeekBubble workingDays={workingDays} onChangeWorkingDays={onChangeWorkingDays}/>
      <p>Chọn ngày:</p>
      <RangePicker
        format={dateFormat}
        defaultValue={[null, null]}
        minDate={dayjs(new Date())}
        // maxDate={dayjs(new Date()).endOf('week')}
        onChange={(dates: null | (Dayjs | null)[], dateStrings: string[]) => onRangeChange(dates, dateStrings)}
        inputReadOnly={true}
        placeholder={["Từ ngày", "Đến ngày"]}
      />
      <p>Chọn giờ:</p>
      <Flex gap={'24px'}>
        <TimePicker
          defaultOpenValue={dayjs('00:00', 'HH:mm')}
          inputReadOnly={true} use12Hours={false} minuteStep={15} format={"HH:mm"}
          showNow={false}
          clearIcon={false}
          onChange={(date) => onChangeStartTime(date)} placeholder={'Giờ bắt đầu'} needConfirm={false}
          disabledMinutes={disabledFromMinutes}
          disabledHours={disabledFromHours}
        />
        <TimePicker
          defaultOpenValue={dayjs('00:00', 'HH:mm')}
          showNow={false}
          clearIcon={false}
          inputReadOnly={true} use12Hours={false} minuteStep={15} format={"HH:mm"}
                    onChange={onChangeEndTime} placeholder={'Giờ kết thúc'} needConfirm={false}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
        />
      </Flex>
      <p className={'error-text'}>{errorText}</p>
    </Modal>
  )
}
