import axios from 'axios'
import {unique} from 'utils/functional/misc'

import {MEDIA_CONVERT_URL} from 'app/modules/helpers/Common'

const GET_APPOINTMENT_LIST_URL = `${process.env.REACT_APP_API_URL}/api/telehealth/v2/appointment`
const GET_MEDICAL_HISTORY_URL = `${process.env.REACT_APP_API_URL}/api/healthprofile/v2/medicalhistory`

const ACCOUNT_URL = `${process.env.REACT_APP_API_URL}/api/portal/v2/account/profile`

const DISEASE_URL = `${process.env.REACT_APP_API_URL}/api/telehealth/v2/disease/group/id`

const DISEASE_GROUP_URL = `${process.env.REACT_APP_API_URL}/api/catalogue/v2/icd10`

const APPOINTMENT_INFO_URL = `${process.env.REACT_APP_API_URL}/api/telehealth/v2/appointment/id`

const AGORA_TOKEN_URL = `${process.env.REACT_APP_API_URL}/api/telehealth/v2/agora/account/token`

const CONCLUSION_URL = `${process.env.REACT_APP_API_URL}/api/telehealth/v2/appointment/conclusion`

const MARK_JOIN_CONFERENCE_URL = `${process.env.REACT_APP_API_URL}/api/telehealth/v2/appointment/id/call/join`

const getGeneric = async (
  url: string,
  info: any,
  errReturn = () => {
    return {
      data: {
        success: false,
      },
    }
  }
) => {
  const query = new URLSearchParams(info).toString()

  let res: any = {}
  const response = await axios.get(`${url}?${query}`).catch((err) => errReturn())
  if (response.data.success) {
    res = response.data.data
  }
  return res
}

const callGeneric = async (
  call: Promise<any>,
  errReturn = () => {
    return {
      data: {
        success: false,
      },
    }
  }
) => {
  let res: any = {}
  const response = await call.catch((err) => errReturn())

  if (response.data.success) {
    res = response.data.data
  }
  return res
}

const getAppointmentWithExtraInfo = async (info: any = {}) => {
  const {data, state} = info

  let res = data

  if (state) {
    res = await getGeneric(GET_APPOINTMENT_LIST_URL, state)
  }

  let {items} = res

  if (items.length) {
    /*
            / Join PatientInfo
            /*/
    // const patientIdList = items.map((e) => e.patient_id)

    // const patientUserInfoList = await getGeneric(ACCOUNT_URL, {
    //   target_user_id_list: JSON.stringify(unique(patientIdList)),
    // })

    // let infoList = patientUserInfoList

    // if (!Array.isArray(infoList)) {
    //   infoList = [infoList]
    // }

    // const infoDict = {}

    // infoList.forEach((e) => {
    //   const {uid} = e
    //   infoDict[uid] = e
    // })

    // const patch = patientIdList.map((e) => infoDict[e])

    // items = items.map((e, i) => {
    //   return {...e, patientInfo: patch[i]}
    // })

    items = items.map((e, i) => {
      return {...e, patientInfo: e.patient_info}
    })

    /*
            / Join DiseaseGroup Info
            /*/

    const diseaseIdList = items.map((e) => e.disease_id)

    const diseaseInfoList: any[] = []
    await Promise.all(
      unique(diseaseIdList).map(async (disease_id) => {
        const res = await getGeneric(DISEASE_URL, {disease_id})

        diseaseInfoList.push(res)
      })
    )

    const diseaseDict = {}

    diseaseInfoList.forEach((e) => {
      const {uid} = e
      diseaseDict[uid] = e
    })

    const patchDisease = diseaseIdList.map((e) => diseaseDict[e])

    items = items.map((e, i) => {
      return {...e, diseaseInfo: patchDisease[i]}
    })

    // join converted images
    //
    //

    const images = items.map((e) => {
      const il = e.media || []
      const conclusionImages = e?.conclusion?.media || []

      // if(conclusionImages.length) debugger;
      return [...il, ...conclusionImages]
        .filter((e) => {
          const {file_type = ''} = e

          return file_type === 'image'
        })
        .map((e) => e.path)
    })

    const convertedImagesList = await Promise.all(
      images.map(async (imageList) => {
        let res = imageList

        if (imageList?.length) {
          res = await getGeneric(MEDIA_CONVERT_URL, {list_path: JSON.stringify(imageList)})
        }

        return res
      })
    )

    items = items.map((e, i) => {
      return {...e, convertedImages: convertedImagesList[i]}
    })

    // join converted videos
    //
    //

    // const videos = items.map((e) => e.videos)

    const videos = items.map((e) => {
      const il = e.media || []
      const conclusionVideos = e?.conclusion?.media || []

      return [...il, ...conclusionVideos]
        .filter((e) => {
          const {file_type = ''} = e

          return file_type === 'video'
        })
        .map((e) => e.path)
    })

    const convertedVideosList = await Promise.all(
      videos.map(async (videoList) => {
        let res = videoList

        if (videoList?.length) {
          res = await getGeneric(MEDIA_CONVERT_URL, {list_path: JSON.stringify(videoList)})
        }

        return res
      })
    )

    items = items.map((e, i) => {
      return {...e, convertedVideos: convertedVideosList[i]}
    })

    // join converted documents
    //
    //

    // const documents = items.map((e) => e.documents)

    const documents = items.map((e) => {
      const il = e.media || []
      const conclusionDocuments = e?.conclusion?.media || []

      return [...il, ...conclusionDocuments]
        .filter((e) => {
          const {file_type = ''} = e

          return file_type !== 'image' && file_type !== 'video'
        })
        .map((e) => e.path)
    })

    const convertedDocumentsList = await Promise.all(
      documents.map(async (documentList) => {
        let res = documentList

        if (documentList?.length) {
          res = await getGeneric(MEDIA_CONVERT_URL, {list_path: JSON.stringify(documentList)})
        }

        return res
      })
    )

    items = items.map((e, i) => {
      return {...e, convertedDocuments: convertedDocumentsList[i]}
    })

    /* diseaseInfoList*/
  }

  return {...res, items}
  /* setResponse({...res, items})*/
}

const getMedicalHistory = async (targetUserId: any = {}) => {
  if (targetUserId) {
    const resData = await axios.get(GET_MEDICAL_HISTORY_URL, {params: {target_user_id: targetUserId}})
    if (resData && resData.data.success === true) {
      return Promise.resolve(resData.data.data)
    }
  }
  return Promise.resolve(null)
}

export {
  getGeneric,
  callGeneric,
  getAppointmentWithExtraInfo,
  getMedicalHistory,
  GET_APPOINTMENT_LIST_URL,
  ACCOUNT_URL,
  DISEASE_GROUP_URL,
  APPOINTMENT_INFO_URL,
  AGORA_TOKEN_URL,
  CONCLUSION_URL,
  MARK_JOIN_CONFERENCE_URL,
}
