import { PageTitle } from "_metronic/layout/core"
import { APPOINTMENT_DETAIL_API } from "app/modules/helpers/Common"
import { Button, Result } from 'antd';
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useReducer, useState } from "react"
import { Outlet, Route, Routes, useParams } from "react-router-dom"
import { AppointmentFiles } from "./AppointmentFiles"
import { AppointmentHeader } from "./AppointmentHeader"
import { AppointmentInfo } from "./AppointmentInfo"
import { AppointmentMedicalHistory } from "./AppointmentMedicalHistory"
import { AppointmentActivity } from "./AppointmentActivity"
import { AppointmentConclusion } from "./AppointmentConclusion"
import { AppointmentForm } from "./AppointmentForm"
import { Reschedule } from "./reschedule/Reschedule"

import { useNavigate } from "react-router-dom"

const commonBreadcrumbs = [
  {
    title: 'Lịch hẹn',
    path: '/calendar/list'
  }]

export const AppointmentPage = () => {
  const { uid } = useParams()
  const navigate = useNavigate()
  const [appointment, setAppointment] = useState(undefined as any)
  const [fetchCounter, forceRefresh] = useReducer(x => x + 1, 0)

  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    axios.get(APPOINTMENT_DETAIL_API, { params: { appointment_id: uid } })
      .then(response => {
        return response.data.data
      })
      .then(data => {
        setAppointment(data)
        setInitialLoad(false);
      })
  }, [uid, fetchCounter, setInitialLoad])

  return (
    <Routes>
      <Route
        element={
          <>

            {appointment ? <><AppointmentHeader appointment={appointment} forceRefresh={forceRefresh} /><Outlet /></> : !initialLoad && <Result
              status="403"
              title="403"
              subTitle="Xin lỗi, bạn không được phép truy cập trang này."
              extra={<Button type="primary" onClick={() => navigate('/dashboard')}>Quay về màn hình Tổng quan</Button>}
            />}


          </>
        }
      >
        <Route
          index
          element={
            <>
              {appointment && <>
                <PageTitle
                  breadcrumbs={commonBreadcrumbs}
                  description="Thông tin lịch hẹn">
                  {`${appointment?.patient_info.fullname} (${dayjs(appointment?.start_time * 1000).format('DD/MM/YYYY')})`}
                </PageTitle>
                <AppointmentInfo appointment={appointment} />
              </>}
            </>
          }
        />
        <Route
          element={<>
            {appointment && <>
              <PageTitle
                breadcrumbs={commonBreadcrumbs}
                description="Hồ sơ hẹn">
                {`${appointment?.patient_info.fullname} (${dayjs(appointment?.start_time * 1000).format('DD/MM/YYYY')})`}
              </PageTitle>
              <AppointmentFiles appointment={appointment} />
            </>}
          </>}
          path="files"
        />
        <Route
          element={<>
            {appointment && <>
              <PageTitle
                breadcrumbs={commonBreadcrumbs}
                description="Tiền sử bệnh">
                {`${appointment?.patient_info.fullname} (${dayjs(appointment?.start_time * 1000).format('DD/MM/YYYY')})`}
              </PageTitle>
              <AppointmentMedicalHistory appointment={appointment} />
            </>}
          </>}
          path="medicalhistory"
        />
        <Route
          element={<>
            {appointment && <>
              <PageTitle
                breadcrumbs={commonBreadcrumbs}
                description="Lịch sử hoạt động">
                {`${appointment?.patient_info.fullname} (${dayjs(appointment?.start_time * 1000).format('DD/MM/YYYY')})`}
              </PageTitle>
              <AppointmentActivity appointment={appointment} />
            </>}
          </>}
          path="activity"
        />
        <Route
          element={<>
            {appointment && <>
              <PageTitle
                breadcrumbs={commonBreadcrumbs}
                description="Kết luận">
                {`${appointment?.patient_info.fullname} (${dayjs(appointment?.start_time * 1000).format('DD/MM/YYYY')})`}
              </PageTitle>
              <AppointmentConclusion appointment={appointment} forceRefresh={forceRefresh} />
            </>}
          </>}
          path="conclusion"
        />
        <Route
          element={<>
            {appointment && <>
              <PageTitle
                breadcrumbs={commonBreadcrumbs}
                description="Khai báo trước khám">
                {`${appointment?.patient_info.fullname} (${dayjs(appointment?.start_time * 1000).format('DD/MM/YYYY')})`}
              </PageTitle>
              <AppointmentForm appointment={appointment} />
            </>}
          </>}
          path="form"
        />
        <Route
          element={<>
            {appointment && <>
              <PageTitle
                breadcrumbs={commonBreadcrumbs}
                description="Thay đổi lịch khám">
                {`${appointment?.patient_info.fullname} (${dayjs(appointment?.start_time * 1000).format('DD/MM/YYYY')})`}
              </PageTitle>
              <Reschedule appointment={appointment} forceRefresh={forceRefresh} />
            </>}
          </>}
          path="reschedule"
        />
      </Route>

    </Routes>
  )
}