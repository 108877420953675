import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { DiseasePage } from './DiseasePage'
import { DiseaseSelectPage } from './DiseaseSelectPage'
import { NewDiseasePage } from './NewDiseasePage'
import { DiseaseListPage } from './disease-list/DiseaseListPage'
import { Permission } from 'app/modules/helpers/Permission'

import { useAuth } from 'app/modules/auth'
import { useLocation } from "react-router";
import { useEffect, useState } from "react";

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Nhóm bệnh',
    path: '/disease/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const DiseaseRoutes = () => {

  const { permission } = useAuth()
  const { pathname } = useLocation()

  const [pbc, setProfileBreadCrumbs] = useState(profileBreadCrumbs);

  useEffect(() => {

    if ([Permission.ShowSystemMenu, Permission.ShowOrganizationMenu].every(per => !permission(per))) {
      setProfileBreadCrumbs([
        {
          title: 'Nhóm bệnh',
          path: pathname,
          isSeparator: false,
          isActive: false,
        },
        {
          title: '',
          path: '',
          isSeparator: true,
          isActive: false,
        },
      ])
    }

  }, [pathname, permission, setProfileBreadCrumbs])



  return (
    <Routes>
      <Route
        element={<><PageTitle breadcrumbs={pbc} /><Outlet /></>}
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle>Danh sách nhóm bệnh</PageTitle>
              <DiseaseListPage />
            </>
          }
        />
        <Route
          path='id/:uid/*'
          element={
            <>
              <DiseasePage />
            </>
          }
        />
        <Route
          path='new'
          element={
            <>
              <PageTitle>Nhóm bệnh mới</PageTitle>
              <NewDiseasePage />
            </>
          }
        />
        <Route
          path='select'
          element={
            <>
              <PageTitle>Nhóm bệnh hỗ trợ</PageTitle>
              <DiseaseSelectPage />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
