import { Button, Card, Col, ConfigProvider, DatePicker, Divider, Empty, Form, Input, Row, Select, Space, Switch, Table, Tooltip, Typography } from "antd"
import { useForm } from "antd/es/form/Form"
import Column from "antd/es/table/Column"
import { CONCLUSION_API, DRUG_ROUTE_API, ICD10_LIST_API, getLongDateDescription } from "app/modules/helpers/Common"
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { useDebounce } from "use-debounce"
import { DrugInput } from "./component/DrugInput"
import { ServiceInput } from "./component/ServiceInput"

import { useAuth } from 'app/modules/auth'

const { Text, Paragraph } = Typography

const ConclusionEdit = ({ appointment, forceRefresh }) => {

  const { pathname } = useLocation()

  const [smallMode, setSmallMode] = useState(false);

  useEffect(() => {
    if (['telemedicine'].some(e => pathname.includes(e))) {
      setSmallMode(true);
    }
  }, [pathname, setSmallMode])

  const [form] = useForm()
  const [filter, setFilter] = useState('')
  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('')
  const [conclusion, setConclusion] = useState(undefined as any)
  const [isDraft, setIsDraft] = useState(false)

  const [icd10list, setIcd10List] = useState([] as any[])
  const [drugRoutes, setDrugRoutes] = useState([] as any[])
  const [selectedDiagnostic, setSelectedDiagnostic] = useState(appointment.conclusion.diagnostic ? appointment.conclusion.diagnostic.map(item => ({
    value: item.disease_code,
    label: `${item.vi_disease_name} (${item.disease_code})`,
    disease_name: item.vi_disease_name,
  })) : [])

  const canSuggest = appointment.status === 'WAITING_CONCLUSION';

  useEffect(() => {
    const conclusion = {
      ...appointment.conclusion,
      diagnostic: appointment.conclusion.diagnostic?.map(item => ({ value: item.disease_code, label: `${item.vi_disease_name} (${item.disease_code})` })),
      prescriptions: appointment.conclusion.prescriptions?.map(item => ({
        ...item,
        drug_name: { value: item.drug_code, label: item.drug_name }
      })),
      suggest_service: appointment.conclusion.suggest_service?.map(item => ({ value: item.code, label: item.name })),
    }
    setConclusion(conclusion)

  }, [appointment])


  useEffect(() => {
    axios.get(ICD10_LIST_API, { params: { page: 1, per_page: 10, code: searchTerm, vi_name: searchTerm } })
      .then(response => {
        // console.log(response.data.data)
        setIcd10List(response.data.data.items.map(item => ({
          value: item.disease_code,
          label: `${item.vi_disease_name} (${item.disease_code})`,
          disease_name: item.vi_disease_name
        })))
      })
  }, [searchTerm])

  useEffect(() => {
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  useEffect(() => {
    axios.get(DRUG_ROUTE_API).then(response => setDrugRoutes(response.data.data.map(item => ({ value: item.vi_drug_route_name, label: item.vi_drug_route_name }))))
  }, [])

  const handleSubmit = () => {
    form.validateFields()
      .then((values) => {
        console.log('selected', selectedDiagnostic)

        const conclusion = {
          ...values,
          appointment_id: appointment.uid,
          diagnostic: selectedDiagnostic?.map(item => ({ disease_code: item.value, vi_disease_name: item.disease_name })),
          prescriptions: values.prescriptions?.map(item => ({
            unit: item.unit,
            drug_name: item.drug_name.label,
            drug_code: item.drug_name.value,
            quantity: item.quantity,
            drug_route: item.drug_route,
            drug_frequence: item.drug_frequence,
            take_medicine: item.take_medicine
          })),
          suggest_service: values.suggest_service?.map(item => ({ code: item.value, name: item.label })),
          is_draft: isDraft,
          deadline_waiting: values.deadline_waiting ? values.deadline_waiting.endOf('day').format('YYYY/MM/DD HH:mm:ss') : undefined,
          remain_date: values.remain_date ? values.remain_date.endOf('day').format('YYYY/MM/DD HH:mm:ss') : undefined
        }
        console.log('conclusion', conclusion)
        // console.log('json', JSON.stringify(conclusion))

        axios.post(CONCLUSION_API, conclusion).then(
          response => {
            console.log(response.data)
            forceRefresh()
          }
        )
      })
  }

  return (
    conclusion && <Card title='Kết luận'
      actions={conclusion.updated_at ? [`Kết luận được lưu nháp lần cuối lúc ${dayjs(conclusion.updated_at * 1000).format('HH:mm [ngày] DD/MM/YYYY')}`] : []}>
      <Form
        form={form}
        layout={smallMode ? 'vertical' : 'horizontal'}
        labelCol={smallMode ? {} : { span: 4 }}
        wrapperCol={smallMode ? { span: 24 } : { span: 20 }}
        initialValues={{
          ...conclusion,
          deadline_waiting: conclusion.deadline_waiting ? dayjs(conclusion.deadline_waiting * 1000) : undefined,
          remain_date: conclusion.remain_date ? dayjs(conclusion.remain_date * 1000) : undefined,
        }}
      >
        <Form.Item label="Triệu chứng" name="symptom" rules={isDraft ? [] : [{ required: true, message: "Bắt buộc điền thông tin" }]}>
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>

        <Form.Item label="Chẩn đoán" name="diagnostic" rules={isDraft ? [] : [{ required: true, message: "Bắt buộc điền thông tin" }]} >
          <Select mode='multiple'
            options={icd10list}
            allowClear
            showSearch
            filterOption={false}
            placeholder='Chẩn đoán'
            labelInValue
            onSearch={(value) => setFilter(value)}
            onChange={(_, option) => setSelectedDiagnostic(option)}
          />
        </Form.Item>

        <Form.Item label="Giải thích chẩn đoán" name="explain_diagnostic">
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>

        <Divider />
        <Form.Item label="Đơn thuốc gợi ý" name="prescriptions">
          <Form.List name='prescriptions'>
            {(subFields, subOpt) => (
              <Row>
                {subFields.map((subField) => (
                  <DrugInput subField={subField} remove={() => subOpt.remove(subField.name)} key={subField.key} drugRoutes={drugRoutes} form={form} />
                ))}
                <Button type="dashed" onClick={() => subOpt.add()} >Thêm thuốc</Button>
              </Row>
            )}
          </Form.List>
        </Form.Item>
        <Divider />
        <Form.Item label='Lời dặn' name='note'>
          <Input.TextArea autoSize={{ minRows: 2 }} />
        </Form.Item>

        {canSuggest ?
          <>
            <Form.Item label="Chỉ định dịch vụ">
              <Form.List name='suggest_service' >
                {(subFields, subOpt) => (
                  <Col>
                    {subFields.map((subField) => (
                      <ServiceInput key={subField.key} subField={subField} remove={() => subOpt.remove(subField.name)} />
                    ))}
                    <Button type="dashed" onClick={() => subOpt.add()} >Thêm chỉ định</Button>
                  </Col>
                )}
              </Form.List>
            </Form.Item>

            <Form.Item dependencies={['suggest_service']} noStyle>
              {({ getFieldValue }) => {
                const services = getFieldValue('suggest_service')
                if (services && services.length > 0) {
                  return (
                    <Form.Item label="Thời hạn bổ sung" name="deadline_waiting" rules={isDraft ? [] : [{ required: true, message: "Bắt buộc điền thông tin" }]}>
                      <DatePicker placeholder="Chọn ngày" minDate={dayjs()} style={{ width: 200 }} format={'DD-MM-YYYY'} />
                    </Form.Item>
                  )
                }
                return null
              }}
            </Form.Item>
            {/* <DatePicker placeholder="Chọn ngày" minDate={dayjs()} style={{ width: 200 }} /> */}

            {/* <Form.Item label="Thời hạn bổ sung" name="deadline_waiting" rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (isDraft) return Promise.resolve()
                  const services = getFieldValue('suggest_service')
                  if (services && services.length > 0) {
                    if (!value) {
                      return Promise.reject(new Error('Bắt buộc điền thông tin'))
                    } else if (value.isBefore(dayjs())) {
                      return Promise.reject(new Error('Thời hạn không hợp lệ'))
                    }
                  }
                  return Promise.resolve()
                },
              }),
            ]} dependencies={['suggest_service']}>
              <DatePicker placeholder="Chọn ngày" minDate={dayjs()} style={{ width: 200 }} />
            </Form.Item> */}
          </>
          :
          <>
            <Tooltip title='Không thể bổ sung dịch vụ do đã chỉ định một lần' trigger={['hover']}>
              <Form.Item label="Chỉ định dịch vụ" status='success'>
                {conclusion.suggest_service?.map(item =>
                  <Input
                    key={item.value}
                    value={item.label}
                    suffix={<i className="fa-solid fa-lock"></i>}
                    disabled
                    style={{ marginBottom: 10 }}
                  />)}
              </Form.Item>
            </Tooltip>
            <Form.Item label="Thời hạn bổ sung">
              <Tooltip title='Không thể thay đổi thời hạn nộp kết quả do bệnh nhân đã nộp kết quả' trigger={['hover']}>
                <Space>
                  <Input
                    value={dayjs(conclusion.deadline_waiting * 1000).format('YYYY-MM-DD')}
                    suffix={<i className="fa-solid fa-lock"></i>}
                    disabled
                    style={{ width: 200 }}
                  />
                </Space>
              </Tooltip>
            </Form.Item>
          </>
        }

        <Form.Item label="Ngày hẹn tái khám" name="remain_date">
          <DatePicker placeholder="Chọn ngày" style={{ minWidth: 200 }} minDate={dayjs()} format={'DD-MM-YYYY'} />
        </Form.Item>

        <Form.Item label='Lưu nháp'>
          <Switch value={isDraft} onChange={(checked) => {
            setIsDraft(checked)
            form.validateFields()
          }} />
        </Form.Item>
      </Form>
      <Row>
        <Space className="ms-auto">
          {/* <Checkbox>Nháp</Checkbox> */}
          {/* <Button onClick={() => handleSubmit(false)}>Lưu bản nháp</Button> */}
          <Button type='primary' onClick={() => handleSubmit()}>{isDraft ? 'Lưu nháp' : 'Gửi kết luận'}</Button>
        </Space>
      </Row>
    </Card >
  )
}

export const AppointmentConclusion = ({ appointment, forceRefresh }) => {

  const { pathname } = useLocation()

  const [smallMode, setSmallMode] = useState(false);

  const { currentUser } = useAuth()

  const [isEdit, setIsEdit] = useState(false)
  const conclusion = appointment.conclusion;

  const [canWriteConclusion, setCanWriteConclusion] = useState(false);
  const [emptyConclusion, setEmptyConclusion] = useState(false);

  const [continueWriting, setContinueWriting] = useState(false);

  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    if (appointment?.uid) {

      const { conclusion } = appointment;
      const canWriteConclusion = (appointment.status === 'WAITING_CONCLUSION'
        || appointment.status === 'SUBMITTED_TEST_RESULT'
        || (appointment.status === 'WAITING_TEST_RESULT' && conclusion.deadline_waiting && dayjs().isAfter(dayjs(conclusion.deadline_waiting * 1000))))
        && ['DOCTOR'].some(e => e.includes(currentUser?.role || 'none'))

      setCanWriteConclusion(canWriteConclusion);

      const emptyConclusion = Object.keys(conclusion).length === 0;

      setEmptyConclusion(emptyConclusion);

      setContinueWriting(canWriteConclusion && !emptyConclusion);

      setIsLocked(appointment.status === 'FINISHED' || (appointment.status === 'WAITING_TEST_RESULT' && conclusion.deadline_waiting && dayjs().isBefore(dayjs(conclusion.deadline_waiting * 1000))))
    }
  }, [appointment, currentUser, setCanWriteConclusion, setContinueWriting, setEmptyConclusion, setIsLocked])

  useEffect(() => {
    if (['telemedicine'].some(e => pathname.includes(e))) {
      setSmallMode(true);
    }
  }, [pathname, setSmallMode])

  return (isEdit ? <ConclusionEdit appointment={appointment}
    forceRefresh={() => {
      setIsEdit(false)
      forceRefresh()
    }} /> :
    <Card title='Kết luận'
      extra={continueWriting ? <Button onClick={() => setIsEdit(true)}>Viết tiếp</Button> : isLocked ? <Button icon={<i className="fa-solid fa-lock"></i>} /> : undefined}
      actions={continueWriting ? [`Kết luận được lưu nháp lần cuối lúc ${dayjs(conclusion.updated_at * 1000).format('HH:mm [ngày] DD/MM/YYYY')}`] : []}
    >
      {emptyConclusion ?
        <Empty
          description={<Space>Chưa có kết luận cho ca khám tư vấn này</Space>}
        // image={<KTIcon iconName="tablet-delete" className="fs-6x text-primary" />}
        >
          {canWriteConclusion && <Button onClick={() => setIsEdit(true)}>Viết kết luận</Button>}
        </Empty>
        :

        <Row gutter={[10, 10]}>
          <Col span={4}><Text className="fs-6 text-gray-600">Triệu chứng</Text></Col>
          <Col span={20}><Text>{conclusion.symptom}</Text></Col>

          <Col span={4}><Text className="fs-6 text-gray-600">Chẩn đoán</Text></Col>
          <Col span={20}><Text>{conclusion.diagnostic?.map(item => `${item.vi_disease_name} (${item.disease_code})`).join(', ')}</Text></Col>

          <Col span={4}><Text className="fs-6 text-gray-600">Giải thích chẩn đoán</Text></Col>
          <Col span={20}><Text>{conclusion.explain_diagnostic}</Text></Col>

          <Col span={smallMode ? 24 : 4}><Text className="fs-6 text-gray-600">Đơn thuốc gợi ý</Text></Col>
          <Col span={smallMode ? 24 : 20}>{
            conclusion.prescriptions ?
              <ConfigProvider
                theme={{
                  components: {
                    Table: {
                      headerBg: '#fff',
                    }
                  },
                }}>
                <Table
                  dataSource={conclusion.prescriptions}
                  rowKey="drug_code"
                  pagination={false}
                  onHeaderRow={() => ({ className: 'fw-bold fs-7 text-uppercase', style: { borderRadius: 0 } })}
                  onRow={() => ({ className: 'fs-6' })}
                  size='small'
                  locale={{ emptyText: 'Không có đơn thuốc gợi ý' }}
                  bordered
                  style={{ marginInline: -8, paddingBottom: 20, borderRadius: 0 }}
                >
                  <Column title="Tên thuốc" dataIndex="drug_name" />
                  <Column title="Số lượng" render={(_, record: any) => `${record.quantity} ${record.unit.toLowerCase()}`} onCell={() => ({ style: smallMode ? {} : { minWidth: 100 } })} />
                  <Column title="Đường dùng" dataIndex="drug_route" onCell={() => ({ style: smallMode ? {} : { minWidth: 100 } })} />
                  <Column title="Liều dùng" dataIndex="drug_frequence" onCell={() => ({ style: smallMode ? {} : { minWidth: 100 } })} />
                  <Column title="Cách dùng" dataIndex="take_medicine" onCell={() => ({ style: smallMode ? {} : { minWidth: 100 } })} />
                </Table>
              </ConfigProvider> : <Text>Không có thông tin</Text>}
          </Col>

          <Col span={4}><Text className="fs-6 text-gray-600">Lời dặn</Text></Col>
          <Col span={20}><Paragraph style={{ whiteSpace: 'pre-line' }}>{conclusion.note}</Paragraph></Col>

          <Col span={4}><Text className="fs-6 text-gray-600">Chỉ định dịch vụ</Text></Col>
          <Col span={20}><Paragraph style={{ whiteSpace: 'pre-line' }}>{conclusion.suggest_service?.map(item => item.name).join('\n')}</Paragraph></Col>

          <Col span={4}><Text className="fs-6 text-gray-600">Ngày trả kết quả</Text></Col>
          <Col span={20}><Text>{conclusion.deadline_waiting ? getLongDateDescription(conclusion.deadline_waiting) : 'Không có thông tin'}</Text></Col>

          <Col span={4}><Text className="fs-6 text-gray-600">Ngày hẹn tái khám</Text></Col>
          <Col span={20}><Text>{conclusion.remain_date ? getLongDateDescription(conclusion.remain_date) : 'Không có thông tin'}</Text></Col>
        </Row>
      }
    </Card >
  )
}