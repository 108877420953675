import { KTIcon } from "_metronic/helpers"
import { Avatar, Card, Col, Rate, Row, Space, Tabs, Typography } from "antd"
import { DOCTOR_SUMMARY_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const { Text } = Typography

export const DoctorHeader = ({ doctor }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [summary, setSummary] = useState(undefined as any)

  useEffect(() => {
    axios.get(DOCTOR_SUMMARY_API, { params: { target_user_id: doctor.uid } })
      .then(response => setSummary(response.data.data))
  }, [doctor])

  return (
    <Card
      style={{ marginBottom: 30 }}
      styles={{ body: { paddingBottom: 0 } }}
    >
      <Row>
        <Col flex='auto'>
          <Space size={30} align='center'>
            <Avatar src={doctor.avatar ? doctor.avatar : '/media/avatars/blank.png'} size={128} />
            <Space direction="vertical" size={0}>
              <span style={{ display: 'flex', alignItems: 'center' }} >
                <Text className="fs-4 text-primary fw-bold me-1">{doctor.degree.length > 0 && `${doctor.degree}. `}{doctor.fullname}</Text>
                <KTIcon iconName="verify" className="fs-3 text-primary" />
              </span>
              <Text className="fs-7 text-gray-600 fw-bold">{doctor.title.join(', ')}</Text>
              <Text className="fs-7 text-gray-600">{doctor.organization.name}</Text>
              <Text className="fs-7 text-gray-600">{doctor.departments?.map(department => department.name).join(', ')}</Text>
              {summary?.rating_count > 0 &&
                <Space>
                  <Rate value={summary.avg_rating} className="fs-7" allowHalf disabled /><Text className="fs-7 text-gray-600">({summary.rating_count} đánh giá)</Text>
                </Space>
              }
            </Space>
          </Space >
        </Col>
        <Col>
          {/* <Button onClick={() => navigate(`/account/id/${doctor.uid}`)} icon={<i className="fa-solid fa-circle-user"></i>}>Tài khoản</Button> */}
        </Col>
      </Row>
      <Tabs items={[
        { key: `/doctor/id/${doctor.uid}`, label: 'Tổng quan' },
        { key: `/doctor/id/${doctor.uid}/report`, label: 'Báo cáo' },
        { key: `/doctor/id/${doctor.uid}/appointment`, label: 'Lịch hẹn' },
        { key: `/doctor/id/${doctor.uid}/transaction`, label: 'Giao dịch' },
        { key: `/doctor/id/${doctor.uid}/rating`, label: 'Đánh giá' },
        { key: `/account/id/${doctor.uid}`, label: 'Tài khoản hệ thống' },
      ]}
        onChange={(activeKey) => navigate(activeKey)}
        tabBarStyle={{ marginBottom: 0 }}
        activeKey={location.pathname}
        className="mt-2"
      />
    </Card >
  )
}
