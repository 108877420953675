/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useLayoutEffect, useState } from 'react';
import ShowMoreText from "react-show-more-text";
import { MenuComponent } from "../../../../_metronic/assets/ts/components";
import { KTIcon, toAbsoluteUrl } from '../../../../_metronic/helpers';
// import ReactQuill, { Quill } from "react-quill";
import axios from 'axios';
// import 'react-quill/dist/quill.snow.css';
import { ACCOUNT_SUMMARY_API } from '../../helpers/Common';
// type Props = {
//   className: string
//   onDeleteNote: (uid:string) => void
//   note:NoteModel
// }

// export function Dropdown({onDelete}) {
//   return (
//     <div
//       className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px'
//       data-kt-menu='true'
//     >
//       <div className='menu-item px-3'>
//         <a className='menu-link px-3' onClick={onDelete}>
//           Xoá ghi chú
//         </a>
//       </div>
//     </div>
//   )
// }


export function NoteCard({className, onDeleteNote, note}){
  const [doctorAvatar, setDoctorAvatar] = useState('/media/avatars/blank.png')
  // const [text, setText] = useState(note.text)
  // const indexOfEndOfLine = text.indexOf('\n');
  // console.log(indexOfEndOfLine); // 17
  let creator= `Bác sĩ ${note.doctor}`;  //tới ${note.patient}
  let getTimeString = (createdat:number) => {
    const date = new Date(createdat * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  }
  let createAtString = getTimeString(note.createdat);
  // let content = note.text;
  let onDelete = () => {
    onDeleteNote(note.uid);
  }
  useLayoutEffect(() => {
    MenuComponent.reinitialization();
  }, [])
  useEffect(() => {
    axios.get(ACCOUNT_SUMMARY_API, { params: { target_user_id: note.doctorid } })
      .then(response => response.data.data)
      .then(response => {
        setDoctorAvatar(response.avatar);
        // console.log("account: ", response);
      }
    )
  })
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center mb-5'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-45px me-5'>
              <img src={toAbsoluteUrl(doctorAvatar ? doctorAvatar : '/media/avatars/blank.png')} alt='' />
            </div>
            {/* end::Avatar */}
            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                {creator}
              </a>
              <span className='text-gray-400 fw-semibold'>{createAtString}</span>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}
          {/* begin::Menu */}
          <div className='my-0'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-125px'
              data-kt-menu='true'
            >
              <div className='menu-item px-3'>
                <a className='menu-link px-3' onClick={onDelete}>
                  Xoá ghi chú
                </a>
              </div>
            </div>
          </div>
          {/* end::Menu */}
        </div>
        {/* end::Header */}

        {/* begin::Post */}
        <div className='mb-5'>
          {/* begin::Text */}
          {/* <p className='text-gray-800 fw-normal mb-5'>
            {text}
          </p> */}
          <ShowMoreText
            /* Default options */
            className='text-gray-800 fw-large mb-5 fs-3'
            lines={3}
            more="Xem thêm"
            less="Thu gọn"
            anchorClass="show-more-less-clickable text-gray-500 fs-4"
            // onClick={executeOnClick}
            expanded={false}
            truncatedEndingComponent={"... "}
            // width={800}
          >
            <div className='text-gray-800 fs-3 '>
              {note.text.replace("\n", "<br>")}
            </div>
            
          </ShowMoreText>
          {/* end::Text */}
        </div>
        {/* end::Post */}
      </div>
      {/* end::Body */}
    </div>
  )
}
// export {NoteCard}
