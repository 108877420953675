import { Button, Form, Input, Row, Space } from "antd";
import { DEPARTMENT_API } from "app/modules/helpers/Common";
import axios from "axios";

export const DepartmentInfoEdit = ({ department, update }) => {

    const [form] = Form.useForm()

    const handleUpdate = () => {
        form.validateFields()
            .then((values) => {
                console.log(values);
                axios.put(DEPARTMENT_API, { ...values, department_id: department.uid })
                    .then(response => {
                        console.log(response)
                        update(true)
                    })
            });
    }

    return (
        <>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                form={form}
                initialValues={{
                    ...department,
                }}
            >
                <Form.Item label="Khoa" name="name" rules={[{ required: true, whitespace: true, message: "Bắt buộc điền thông tin" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                    <Input />
                </Form.Item>
            </Form>
            <Row justify="end">
                <Space>
                    <Button onClick={() => update(false)}>Huỷ</Button>
                    <Button onClick={handleUpdate}>Lưu thông tin</Button>
                </Space>
            </Row>
        </>
    )
}