import {run, runLayout, transformProgram} from 'utils/functional/BuildLayout'

import {useCalendar} from 'app/modules/apps/patient-management/calendar/CalendarProvider'

function* modalProgram(inject): Generator<any, any, any> {
  const {close} = inject

  yield {
    anchor: true,
    classes: ['modal-outlet'].join(' '),
  }

  yield {
    classes: 'modal-content tw-bg-white',
  }

  yield {
    classes: 'modal-dialog modal-dialog-centered',
  }

  yield {
    classes: 'modal fade show d-block',
    id: 'kt_modal_role_user',
    role: 'dialog',
    tabindex: -1,
    'aria-modal': true,

    attributes: {
      onClick: () => {
        close()
      },
    },
  }
  yield () => <div className='modal-backdrop fade show'></div>
}

const CalendarFileModal = () => {
  const {fileURL, setShowFile} = useCalendar()

  const close = () => {
    setShowFile(false)
  }

  const fileProgram = transformProgram(modalProgram({close}), (pl) => {
    let res = pl

    const outletIndex = pl.findIndex((e) => e.classes?.includes('modal-outlet'))

    if (outletIndex > -1) {
      const file = (
        <div>
          <img src={fileURL} alt='' />
        </div>
      )

      res.splice(outletIndex + 1, 0, file)
    }

    return res
  })

  const res = run(runLayout(fileProgram()))

  return <>{res}</>
}

export {CalendarFileModal}
