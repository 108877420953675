import { Route, Routes } from "react-router-dom"
import { NotePage } from "./NotePage"
// import { PatientListPage } from "./patients-list/PatientListPage"

// const notionBreadCrumbs: Array<PageLink> = [
//   {
//     title: 'Ghi chú',
//     path: '/notion',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

export const NoteRoutes = () => {
    return (
        <Routes>
          <Route
            path='id/:uid/*'
            element={
              <>
                <NotePage />
              </>
            }
          />
        </Routes>
    )
}