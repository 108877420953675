import { Card, Segmented } from "antd"
import { DASHBOARD_DISEASE_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"

const diseaseOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      type: 'category' as const,
      offset: true,
      stacked: true,
      grid: {
        offset: true,
      }
    },
    y2: {
      type: 'category' as const,
      position: 'right' as const,
      offset: true,
      grid: {
        offset: true,
        display: false,
      },
      labels: [],
    },
  }
}

export const DiseaseReportWidget = ({ currentDate, mode, doctor = undefined as any }) => {
  // const [data, setData] = useState([] as any[])
  const [series, setSeries] = useState({ datasets: [] as any[], labels: [] as any[] })
  const [series2, setSeries2] = useState({ datasets: [] as any[], labels: [] as any[] })
  const [overviewMode, setOverviewMode] = useState('revenue' as 'revenue' | 'appointment')
  const [seriesOption, setSeriesOption] = useState(diseaseOptions)
  const [seriesOption2, setSeriesOption2] = useState(diseaseOptions)

  useEffect(() => {
    axios.get(DASHBOARD_DISEASE_API, { params: { local_year: currentDate.year(), local_month: mode === 'month' ? currentDate.month() + 1 : undefined, doctor_id: doctor } })
      .then(response => response.data.data)
      .then(data => {
        // setData(data)
        setSeries({
          labels: data.map(item => item.disease_name),
          datasets: [{
            label: 'Đã thu',
            data: data.map(item => item.sum_price_received),
            backgroundColor: ['#1677ff'],
            // borderSkipped: false,
            borderRadius: 2,
            // barPercentage: 0.3,
            indexAxis: 'y',
          },
          {
            label: 'Huỷ',
            data: data.map(item => [item.sum_price_received, item.sum_price_received + item.sum_price_canceled]),
            backgroundColor: ['#d9d9d9'],
            // borderSkipped: false,
            borderRadius: 2,
            // barPercentage: 0.3,
            indexAxis: 'y',
          }]
        })
        setSeriesOption({
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: 'category' as const,
              offset: true,
              stacked: true,
              grid: {
                offset: true,
              }
            },
            y2: {
              type: 'category' as const,
              position: 'right' as const,
              offset: true,
              grid: {
                offset: true,
                display: false,
              },
              labels: data.map(item => `${item.global_percent_price}%`),
            },
          }
        })

        setSeries2({
          labels: data.map(item => item.disease_name),
          datasets: [{
            label: 'Hoàn thành',
            data: data.map(item => item.finished_count),
            backgroundColor: ['#52c41a'],
            // borderSkipped: false,
            borderRadius: 2,
            // barPercentage: 0.3,
            indexAxis: 'y',
          },
          {
            label: 'Đang thực hiện',
            data: data.map(item => [item.finished_count, item.finished_count + item.inprogress_count]),
            backgroundColor: ['#fadb14'],
            // borderSkipped: false,
            borderRadius: 2,
            // barPercentage: 0.3,
            indexAxis: 'y',
          },
          {
            label: 'Quá hẹn',
            data: data.map(item => [item.inprogress_count + item.finished_count, item.inprogress_count + item.finished_count + item.miss_call_count]),
            backgroundColor: ['#bfbfbf'],
            // borderSkipped: false,
            borderRadius: 2,
            // barPercentage: 0.3,
            indexAxis: 'y',
          },
          {
            label: 'Huỷ',
            data: data.map(item => [item.inprogress_count + item.finished_count + item.miss_call_count, item.inprogress_count + item.finished_count + item.miss_call_count + item.canceled_count]),
            backgroundColor: ['#ff4d4f'],
            // borderSkipped: false,
            borderRadius: 2,
            // barPercentage: 0.3,
            indexAxis: 'y',
          }]
        })
        setSeriesOption2({
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: 'category' as const,
              offset: true,
              stacked: true,
              grid: {
                offset: true,
              }
            },
            y2: {
              type: 'category' as const,
              position: 'right' as const,
              offset: true,
              grid: {
                offset: true,
                display: false,
              },
              labels: data.map(item => `${item.global_percent_count}%`)
            },
          }
        })
      })
  }, [currentDate, mode, doctor])

  return <Card
    title='Nhóm bệnh'
    extra={
      <Segmented options={[
        { label: 'Ca tư vấn', value: 'appointment' },
        { label: 'Doanh thu', value: 'revenue' }
      ]}
        value={overviewMode}
        onChange={(value) => setOverviewMode(value as any)}
      />}>
    {overviewMode === 'revenue' &&
      <Bar
        options={seriesOption}
        data={series}
        height={300}
      />}
    {overviewMode === 'appointment' &&
      <Bar
        options={seriesOption2}
        data={series2}
        height={300}
      />}
  </Card>
}