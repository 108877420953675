import axios from "axios"
import { useEffect, useState } from "react"
// import 'react-quill/dist/quill.snow.css'
import { useAuth } from "../../../auth"
import { NOTE_LIST_API } from "../../../helpers/Common"
import { InputNote } from "../../note/InputNote"
import { NoteCard } from "../../note/NoteCard"
import { Spin } from "antd"
// import { InputNote } from "./InputNote"
// import { NoteCard } from "./NoteCard"

export interface NoteModel {
  uid: string
  doctor: string
  doctorid: string
  patient: string
  patientid: string
  createdat: number
  text: string
}

export interface DeleteNoteModel{
  
}
export interface CurrentUserModel{
  fullname?: string
  avatar?: string
  role?: string
}
export const PatientNote = ({account}) => {

  enum ROLE {
    "DOCTOR" = "Bác sỹ",
    "SUPER_ADMIN_BRAND" = "Quản trị hệ thống",
    "NURSE" = "Điều dưỡng",
    "CUSTOMER_CARE" = "CSKH"
  }

  const { auth, currentUser } = useAuth();
  const roleMapped = auth?.role ? ROLE[auth.role.toUpperCase()] : 'N/A'
  const curUser: CurrentUserModel = {
    fullname: currentUser?.fullname,
    avatar: currentUser?.avatar,
    role: roleMapped,
  }
  const [noteList, setNoteList] = useState([] as any[])
  const [loading, setloading] = useState(false)
  const [addNoteError, setAddNoteError] = useState<string>('')
  // const [isShowLoadMore, setIsShowLoadMore] = useState(true)

  useEffect(() => {
    if (!auth || !account) return;
    // axios.get(ACCOUNT_SUMMARY_API, { params: { target_user_id: auth!.uid } })
    //   .then(response => response.data.data)
    //   .then(response => {
    //     setCurrentUser({
    //       fullname: response.fullname,
    //       avatar: response.avatar,
    //       role: response.role,
    //     })
    //     // console.log("account: ", response);
    //   }
    // )
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    axios.get(NOTE_LIST_API, { params: { created_at: currentTimestamp, target_user_id: account.uid}})
      .then(response => response.data.data)
      .then(response => {
        setNoteList(response);
      })
  }, [auth, account])
  const onLoadMoreNote = ()=>{
    setloading(true);
    try {
      let lastTimeStamp = noteList[noteList.length - 1].created_at
      axios.get(NOTE_LIST_API, { params: {created_at: lastTimeStamp, target_user_id: account.uid } })
      .then(response => response.data.data)
      .then(response => {
        // console.log("loadmore res: ", response)
        let oldItems = [...noteList];
        let fileredItems = response.filter((newItem) =>
          !noteList.some((oldItem:NoteModel) => oldItem.uid === newItem.uid)
        );
        setNoteList(oldItems.concat(fileredItems));
      })
      
    } catch (error) {
      console.log('load more error: ', error);
    }
    setloading(false);
  }
  
  const onAddNote = (text: string) =>{
    setAddNoteError('')
    axios.post(NOTE_LIST_API, {target_user_id: account.uid, text: text})
      .then(res => res.data)
      .then(res => {
        if (res) {
          if (res.success === true) {
            setNoteList([{...res.data,  creator_info: {fullname: curUser.fullname}}, ...noteList]);
          } else {
            setAddNoteError(res.error.message)
          }
        }
      })
  }
  const onDeleteNote = (uid:string) =>{
    try {
      axios.delete(NOTE_LIST_API, {data: {journal_id:uid}})
        .then(response => response.data)
        .then(response => {
          if(response.success){
            const updatedItems = noteList.filter((item) => item.uid !== uid);
            setNoteList(updatedItems);
          }
        })
    } catch (error) {
      console.log("delete note e: ", error);
    }

  }

  if (!account) {return <Spin size="large" spinning />}
  return (
    <>
      {currentUser && currentUser.role !== 'SUPER_ADMIN_SYS' && currentUser.role !== 'OWNER' && <InputNote
        className='mb-5 mb-xxl-8' 
        patient= {account}
        currentUser= {curUser}
        onAddNoteList={onAddNote}
        errorMessage={addNoteError}
      />}
      {noteList.length > 0 && noteList.map((item) => {
          return (
            <NoteCard key={item.uid}
              className='mb-5 mb-xxl-8'
              onDeleteNote={onDeleteNote}   
              note={{uid: item.uid,
                doctor: item.creator_info.fullname,
                doctorid: item.creator_id,
                patient: account.fullname,
                patientid: item.target_user_id,
                createdat: item.created_at,
                text: item.text,} }
            />
          )
        })
      }
      {<div className='d-grid mb-10'>
        <button
          id='kt_sign_in_submit'
          className='btn btn-primary'
          onClick={onLoadMoreNote}
        >
          {!loading && <span className='indicator-label'>Tải thêm ghi chú</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Đang xử lý...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>}
      {/* <NoteCard className='mb-5 mb-xxl-8' /> */}
    </>
  )
}

