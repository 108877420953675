import { Button, Card, Col, ConfigProvider, List, Row, Segmented, Space, Typography, theme } from "antd"
import { APPOINTMENT_API } from "app/modules/helpers/Common"
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"

const { Text, Title } = Typography
const { useToken } = theme
const pageSize = 3

export const AgendaWidget = () => {
    const { token } = useToken();
    const [appointments, setAppointments] = useState([] as any[])

    
    const daysInWeek = [1, 2, 3, 4, 5, 6, 0];

    const today = parseInt(dayjs().format('d'));
    const todayOffset = daysInWeek.findIndex(e => e===today);
    
    const [selectedDate, setSelectedDate] = useState(todayOffset);
    const dateList = useRef(daysInWeek.map(e => dayjs().add((e || 7) - today, 'day')))
    const [currentPage, setCurrentPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(APPOINTMENT_API, {
            params: {
                page: currentPage,
                per_page: 3,
                from_datetime: dateList.current[selectedDate].startOf('day').format('YYYY/MM/DD HH:mm:ss'),
                to_datetime: dateList.current[selectedDate].endOf('day').format('YYYY/MM/DD HH:mm:ss')
            }
        }).then(response => {
            console.log('agenda', response.data.data.items)
            setAppointments(response.data.data.items)
            setMaxPage(response.data.data.max_page)
        })
    }, [selectedDate, currentPage])

    const handleDateChange = (value) => {
        setSelectedDate(value)
        setCurrentPage(1)
        setMaxPage(1)
    }

    return (
        <Card style={{ height: '100%' }} title="Lịch hẹn" extra={<Button onClick={() => navigate('/calendar/appointment')}>Xem lịch đầy đủ</Button>}>
            <ConfigProvider
                theme={{
                    components: {
                        Segmented: {
                            trackBg: token.colorPrimaryBg,
                            itemSelectedBg: token.colorBgElevated,
                        },
                    },
                }}
            >
                <Segmented block options={dateList.current.map((day, index) => ({
                    label:
                        <Space direction="vertical" align="center" size={0} style={{ lineHeight: '18px', marginBottom: 5 }}>
                            <Text style={{ fontSize: 10 }} strong>{day.format('dddd').toUpperCase()}</Text>
                            <Text strong style={{ color: token.colorPrimaryHover }}>{day.format('DD')}</Text>
                        </Space>,
                    value: index
                }))}
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </ConfigProvider>
            <List
                style={{ marginTop: '16px' }}
                dataSource={appointments}
                pagination={ appointments.length? { position: 'bottom', align: 'end', pageSize: 3, total: maxPage * pageSize, current: currentPage, onChange: (page) => setCurrentPage(page) }: false }
                split={false}
                grid={{ column: 1 }}
                locale={{emptyText: 'Không có lịch hẹn'}}
                renderItem={(item, index) => (
                    <List.Item key={index}>
                        <Row style={{ width: 'auto' }} align="middle">
                            <Col flex="auto">
                                <Space size="middle">
                                    <div style={{ width: '4px', minHeight: '80px', background: item.end_time < dayjs().unix() ? 'repeating-linear-gradient(-45deg, #ccc, #ccc 5px, #aaa 5px, #aaa 10px)' : token.colorPrimary, borderRadius: '4px' }}></div>
                                    <Space direction="vertical" size={0}>
                                        <Title level={5} style={{ margin: 0 }}>
                                            {dayjs(item.start_time * 1000).format('HH:mm')} - {dayjs(item.end_time * 1000).format('HH:mm')}
                                        </Title>
                                        <Link to={``}>{item.title}</Link>
                                        <Space>
                                            <Text>Bệnh nhân: </Text>
                                            <Link to={`/patient/id/${item.patient_info.uid}`}>{item.patient_info.fullname}</Link>
                                        </Space>
                                    </Space>
                                </Space>
                            </Col>
                            <Col flex="100px">
                                <Button onClick={() => navigate(`/calendar/id/${item.uid}`)}>Xem chi tiết</Button>
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />
        </Card>
    )
}