import { Col, Row, Typography } from "antd"
import Link from "antd/es/typography/Link"
import { formatPhone } from "app/modules/helpers/Common"
import { Fragment } from "react"

const { Text } = Typography

export const OrganizationOverviewForm = ({ org }) => {
    return (
        <>
            <Row gutter={[10, 10]}>
                <Col span={6}><Text strong>Tên công ty:</Text></Col>
                <Col span={18}><Text >{org && org.name}</Text></Col>
                <Col span={6}><Text strong>Mô tả:</Text></Col>
                <Col span={18}><Text >{org && org.description}</Text></Col>
                <Col span={6}><Text strong>Địa chỉ:</Text></Col>
                <Col span={18}><Text >{org && org.address}</Text></Col>
                <Col span={6}><Text strong>Trang chủ:</Text></Col>
                <Col span={18}><Link href={org.homepage} target="_blank">{org && org.homepage}</Link></Col>
                <Col span={6}><Text strong>Email:</Text></Col>
                <Col span={18}><Link href={`mailto:${org.email}`}>{org && org.email}</Link></Col>
                {
                    org && org.hotline.map((hotline, index) =>
                        <Fragment key={index}>
                            <Col span={6}><Text strong>{index === 0 && 'Số điện thoại:'}</Text></Col>
                            <Col span={18}><Text >{formatPhone(hotline)}</Text></Col>
                        </Fragment>
                    )
                }
            </Row>
        </>
    )
}