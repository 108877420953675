import { Button, Card, Form, Input, Row, Space, Upload, notification } from "antd";
import {
  CloudUploadOutlined
} from '@ant-design/icons'
import { NEWSFEED_API } from "app/modules/helpers/Common";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  useState, useCallback
} from 'react'

export const NewPost = () => {

  const [showNotification, contextHolder] = notification.useNotification()

  const navigate = useNavigate()

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<any[]>([]);

  const beforeUpload = useCallback((file) => {
    setFileList([file])
    return false
  }, [setFileList])

  const onRemove = useCallback((file) => {
    setFileList([])

  }, [setFileList])

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e.slice(-1);
    }
    return e?.fileList.slice(-1);
  };

  const handleFormSubbmit = useCallback(() => {
    form.validateFields()
      .then((values) => {
        console.log(values);

        const { title, url, thumbnail } = values;

        const data = new FormData();

        data.append('title', title);
        data.append('url', url);
        data.append('thumbnail', thumbnail[0].originFileObj);

        axios.post(NEWSFEED_API, data)
          .then(response => {
            console.log(response);
            const { data: { success, error } } = response;

            if (success) {
              navigate(`/newsfeed/list`)
            } else {
              showNotification.open({
                message: 'Lỗi',
                description: error?.message,
                placement: 'topRight',
                icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
                
              })
            }
          })
      });
  }, [form, navigate, showNotification])

  return (

    <>
      {contextHolder}
      <Card title='Thông tin bài viết mới' actions={[
        <Row justify="end" style={{ paddingRight: 25 }}>
          <Space>
            <Button onClick={() => navigate(-1)}>Huỷ</Button>
            <Button type='primary' onClick={handleFormSubbmit}>Tạo bài viết</Button>
          </Space>
        </Row>
      ]} >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
        >
          <Form.Item label="Tiêu Đề" name="title" rules={[{ required: true, whitespace: true, message: "Bắt buộc điền thông tin" }, { max: 256, message: "Tiêu đề không quá 256 kí tự" }]}>
            <Input onPaste={e => {
              /* @ts-ignore */
              let paste = (e.clipboardData || window.clipboardData).getData('text');
              setTimeout(() => {
                form.setFieldValue('title', paste.slice(0, 256));
                form.validateFields(['title'])
              }, 200)

            }} />
          </Form.Item>
          <Form.Item label="URL" name="url" rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Thumbnail" name="thumbnail" rules={[{ required: true, message: 'Bắt buộc Chọn ảnh' }]} valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload beforeUpload={beforeUpload} fileList={fileList} accept={'image/*'} onRemove={onRemove}>
              <Button icon={<CloudUploadOutlined />}>Chọn ảnh</Button>
            </Upload>
          </Form.Item>

        </Form>
      </Card>

    </>

  )
}
