import { Button, Card, Space } from 'antd'
import { useAuth } from 'app/modules/auth'
import { Permission } from 'app/modules/helpers/Permission'
import { useState } from 'react'
import { DiseaseInfo } from './DiseaseInfo'
import { DiseaseInfoEdit } from './DiseaseInfoEdit'

export function DiseaseOverview({ disease, forceUpdate }) {
  const [isEditing, setIsEditing] = useState(false)
  // const [isDepartmentModalOpen, setIsDepartmentModalOpen] = useState(false)
  const { permission } = useAuth()

  const handleUpdate = (needRefresh) => {
    setIsEditing(false)
    if (needRefresh) forceUpdate()
  }

  // const closeModal = (needRefresh) => {
  //   setIsDepartmentModalOpen(false)
  //   if (needRefresh) forceUpdate()
  // }

  let canEdit = permission(Permission.UpdateDiseaseGroup)

  return (
    <>
      <Card
        title='Thông tin nhóm bệnh'
        extra={
          <Space>
            {/* {account.role === 'PATIENT' && <Button icon={<i className="fa-solid fa-hospital-user"></i>} onClick={() => navigate(`/patient/id/${account.uid}`)} >Bệnh nhân</Button>} */}
            {canEdit && !isEditing && <Button onClick={() => setIsEditing(true)}>Sửa thông tin</Button>}
          </Space>
        }
      >
        {isEditing ?
          <DiseaseInfoEdit disease={disease} update={handleUpdate} /> :
          <DiseaseInfo disease={disease} />}
      </Card>

      {/* <DepartmentAddModal
        org={org}
        open={isDepartmentModalOpen}
        close={closeModal}
      /> */}
      {/* <Card
        title='Thiết lập tài khoản'
        style={{ marginTop: 32 }}
        extra={
          canEdit && !isEditing &&
          <Space>
            {
              org.is_active ?
                <Popconfirm
                  title="Khoá tài khoản"
                  description="Bạn có chắc muốn khoá tài khoản này?"
                  onConfirm={handleAccountLock}
                  okText="Khoá"
                  okButtonProps={{ danger: true }}
                  cancelText="Bỏ qua"
                  placement='topRight'
                >
                  <Button danger={true}>Khoá tài khoản</Button>
                </Popconfirm>
                :
                <Popconfirm
                  title="Mở khoá tài khoản"
                  description="Bạn có chắc muốn mở khoá tài khoản này?"
                  onConfirm={handleAccountUnlock}
                  okText="Mở khoá"
                  cancelText="Bỏ qua"
                  placement='topRight'
                >
                  <Button>Mở khoá tài khoản</Button>
                </Popconfirm>
            }
          </Space>

        }
      >
        <Row gutter={[10, 10]}>
          <Col span={6}><Text strong>Trạng thái tài khoản</Text></Col>
          <Col span={18}><Text>{org.is_active ? 'Đang hoạt động' : 'Đã khoá'}</Text></Col>
        </Row>
      </Card> */}
    </>
  )
}