import Empty from "antd/es/empty"

export const PatientActivity = ({ account }) => {
    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9'>
                <Empty
                    description={<h3 className="text-muted">Dữ liệu đang cập nhật</h3>}
                    imageStyle={{ height: "300px" }} />
            </div>
        </div>
    )
}