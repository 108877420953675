import { Checkbox, Form, Input, Radio, Select, Space, Typography } from "antd"

const { Text } = Typography

const questionComponent = (question) => {
  switch (question.type) {
    case 'TEXT':
      return <Input />
    case 'DROPDOWN_LIST':
      return (
        <Select defaultValue={question.options[0]}>
          {question.options.map(option => (
            <Select.Option key={option} value={option}>{option}</Select.Option>
          ))}
        </Select>
      )
    case 'CHECKBOX':
      return (
        <Checkbox.Group>
          {question.options.map(option => (
            <Checkbox key={option} value={option}>{option}</Checkbox>
          ))}
        </Checkbox.Group>
      )
    case 'MULTIPLE_CHOICE':
      return (
        <Radio.Group defaultValue={question.options[0]}>
          {question.options.map(option => (
            <Radio key={option} value={option}>{option}</Radio>
          ))}
        </Radio.Group>
      )
    default:
      return <Text>{question.type}</Text>
  }
}

export const FormPreview = ({ form }) => {
  return (
    <>
      <Space direction='vertical' size={0} className="my-4">
        <Text strong>{form.title}</Text>
        <Text type='secondary'>{form.description || 'Không có mô tả'}</Text>
      </Space>
      <Form layout="vertical">
        {form.questions?.map((question, index) => (
          <Form.Item label={<Text strong>{`Câu hỏi ${index + 1}: ${question.name}`}</Text>} key={index} required={question.required}>
            {questionComponent(question)}
          </Form.Item>
        ))}
      </Form>
    </>
  )
}