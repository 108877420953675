import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { FormEditorPage } from './FormEditorPage'
import { FormListPage } from './FormListPage'
import { FormPage } from './FormPage'

const mainBreadCrumbs: Array<PageLink> = [
  {
    title: 'Biểu mẫu',
    path: '/form/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const FormRoutes = () => {
  return (
    <Routes>
      <Route
        element={<><PageTitle breadcrumbs={mainBreadCrumbs} /><Outlet /></>}
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle>Danh sách biểu mẫu</PageTitle>
              <FormListPage />
            </>
          }
        />
        <Route
          path='id/:uid/*'
          element={
            <>
              <FormPage />
            </>
          }
        />
        <Route
          path='edit/:uid/*'
          element={
            <>
              <FormEditorPage />
            </>
          }
        />
        <Route
          path='new'
          element={
            <>
              <FormEditorPage />
            </>
          }
        />
        <Route
          path='select'
          element={
            <>
              {/* <PageTitle>Nhóm bệnh hỗ trợ</PageTitle> */}
              {/* <DiseaseSelectPage /> */}
            </>
          }
        />
      </Route>
    </Routes>
  )
}
