import { PageTitle } from "_metronic/layout/core"
import { PROFILE_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { Outlet, Route, Routes, useParams, useNavigate } from "react-router-dom"
import { OrgReport } from "../dashboard/OrgReport"
import { PatientTransaction } from "../patient-management/patient/PatientTransaction"
import { DoctorAppointment } from "./DoctorAppoinment"
import { DoctorHeader } from "./DoctorHeader"
import { DoctorInfo } from "./DoctorInfo"
import { DoctorRating } from "./DoctorRating"

import { Button, Result } from 'antd';

const commonBreadcrumbs = [
  {
    title: 'Danh sách tài khoản',
    path: '/account/list'
  }]

export const DoctorPage = () => {
  const { uid } = useParams()

  const navigate = useNavigate()

  const [doctor, setDoctor] = useState(undefined as any)
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    axios.get(PROFILE_API, { params: { target_user_id: uid } }).then(response => response.data.data).then(
      data => {
        setDoctor(data);
        setInitialLoad(false)
      }
    )
  }, [uid, setInitialLoad])

  return (
    <Routes>
      <Route
        element={
          <>
            {doctor ? <><DoctorHeader doctor={doctor} />  <Outlet /></> : !initialLoad && <Result
              status="403"
              title="403"
              subTitle="Xin lỗi, bạn không được phép truy cập trang này."
              extra={<Button type="primary" onClick={() => navigate('/dashboard')}>Quay về màn hình Tổng quan</Button>}
            />}

          </>
        }
      >
        <Route
          index
          element={
            <>
              <PageTitle breadcrumbs={commonBreadcrumbs} description="Tổng quan tài khoản">{`Bác sỹ ${doctor?.fullname}`}</PageTitle>
              {doctor && <DoctorInfo doctor={doctor} />}
            </>
          }
        />
        <Route
          element={<>
            <PageTitle breadcrumbs={commonBreadcrumbs} description="Báo cáo tư vấn">{`Bác sỹ ${doctor?.fullname}`}</PageTitle>
            <OrgReport doctor={uid} />
          </>}
          path="report"
        />
        <Route
          element={<>
            <PageTitle breadcrumbs={commonBreadcrumbs} description="Lịch sử giao dịch">{`Bác sỹ ${doctor?.fullname}`}</PageTitle>
            {doctor && <PatientTransaction account={doctor} />}
          </>}
          path="transaction" />
        <Route
          element={<>
            <PageTitle breadcrumbs={commonBreadcrumbs} description="Lịch hẹn khách hàng">{`Bác sỹ ${doctor?.fullname}`}</PageTitle>
            {doctor && <DoctorAppointment doctor={doctor} />}
          </>}
          path="appointment" />
        <Route
          element={<>
            <PageTitle breadcrumbs={commonBreadcrumbs} description="Lịch sử đánh giá">{`Bác sỹ ${doctor?.fullname}`}</PageTitle>
            {doctor && <DoctorRating doctor={doctor} />}
          </>}
          path="rating" />
      </Route>

    </Routes>
  )
}