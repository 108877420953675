import { Button, Checkbox, DatePicker, Dropdown, Form, Modal, Select, Space, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import { WORK_PLAN_API } from "app/modules/helpers/Common";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";


const TimeRangePicker = (props) => {

  const { value, onChange, presets } = props
  const [startIndex, setStartIndex] = useState(value?.[0] ? dayjs(value[0]).hour() * 4 + dayjs(value[0]).minute() / 15 : undefined)
  const [endIndex, setEndIndex] = useState(value?.[1] ? dayjs(value[1]).hour() * 4 + dayjs(value[1]).minute() / 15 : undefined)

  const options = Array.from(Array(97).keys()).map(index => ({
    value: index, label: `${String(Math.floor(index / 4)).padStart(2, '0')}:${String((index % 4) * 15).padStart(2, '0')}`
  }))

  const triggerChange = (startIndex, endIndex) => {
    if (startIndex !== undefined && endIndex !== undefined) {
      if (endIndex === 96) {
        onChange([dayjs().startOf('day').minute(startIndex * 15), dayjs().startOf('day').minute(endIndex * 15).add(startIndex * 15, 'minutes')])
      } else {
        onChange([dayjs().startOf('day').minute(startIndex * 15), dayjs().startOf('day').minute(endIndex * 15)])
      }

    } else {
      if (startIndex !== undefined || endIndex !== undefined) {
        onChange(undefined)
      }
    }
  }

  return (
    <>
      <Space.Compact style={{ width: '50%' }}>
        <Select placeholder='Từ giờ' options={options} value={startIndex} onChange={(value) => {
          setStartIndex(value)
          if (endIndex !== undefined && value >= endIndex) {
            setEndIndex(undefined)
            triggerChange(value, undefined)
          } else {
            triggerChange(value, endIndex)
          }
        }}
        />
        <Select placeholder='Đến giờ' options={options} value={endIndex} onChange={(value) => {
          setEndIndex(value)
          if (startIndex !== undefined && value <= startIndex) {
            setStartIndex(undefined)
            triggerChange(undefined, value)
          } else {
            triggerChange(startIndex, value)
          }
        }} />
      </Space.Compact >
      <Dropdown
        menu={{
          items: presets.map((preset, index) => ({
            label: preset.label, key: index, onClick: () => {
              setStartIndex(preset.value[0] * 4)
              setEndIndex(preset.value[1] * 4)
              triggerChange(preset.value[0] * 4, preset.value[1] * 4)
            }
          })),
        }}
        trigger={['click']}>
        <Button icon={<i className="fa-regular fa-clock"></i>} style={{ marginLeft: 10 }} />
      </Dropdown>
    </>
  )
}

export const PlanModal = ({ open, close }) => {


  const [showNotification, contextHolder] = notification.useNotification()

  const [form] = useForm()

  const handleSubmit = () => {
    form.validateFields().then(values => {
      const dateRange = values.date as dayjs.Dayjs[]
      const timeRange = values.time as dayjs.Dayjs[]
      const allDay = timeRange[1].diff(timeRange[0], 'hour') === 24
      const plan = {
        days_of_week: values.days_of_week.map((day, index) => day ? index : null).filter(day => day !== null),
        start_date: dateRange[0].format('YYYY/MM/DD'),
        end_date: allDay ? dateRange[1].add(1, 'day').format('YYYY/MM/DD') : dateRange[1].format('YYYY/MM/DD'),
        start_time: timeRange[0].format('HH:mm:ss'),
        /* end_time: timeRange[1].format('HH:mm:ss'),*/
        /* start_time: allDay ? '00:00:00' : timeRange[0].format('HH:mm:ss'),*/
        end_time: allDay ? '00:00:00' : timeRange[1].format('HH:mm:ss'),
      }
      axios.post(WORK_PLAN_API, plan).then(response => {
        const { data: { error, success } } = response;

        if (success) {
          close(true)
        } else {
          const { message } = error;

          showNotification.open({
            message: 'Lỗi',
            description: message,
            placement: 'topRight',
            icon: <i className='fa-solid fa-circle-info tw-text-red-500'></i>,
          })

        }


      })
    }).catch(() => { })
  }

  useEffect(() => {
    if (open) {
      form.resetFields()
    }
  }, [open, form])

  return (
    <Modal
      open={open}
      title='Tạo kế hoạch làm việc định kỳ'
      style={{ minWidth: 700 }}
      onOk={handleSubmit}
      onCancel={() => close(false)}
      destroyOnClose
      okText='Lưu'
      cancelText='Hủy'
    >
      {contextHolder}
      <Form
        form={form}
        onFinish={() => { }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{ marginBlock: 40 }}
      >
        <Form.Item label='Lặp lại' name='checkedOne' rules={[
          ({ getFieldValue }) => ({
            validator() {
              const value = getFieldValue('days_of_week')
              if (value && value.length > 0 && value.some(day => day)) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('Cần chọn ít nhất 1 ngày trong tuần'));
            },
          }),
        ]}>
          <Space>
            {Array.from(Array(7).keys()).map((day) => (
              <Form.Item name={['days_of_week', day]} valuePropName='checked' noStyle key={day}>
                <Checkbox
                  key={day}
                  name="repeat"
                  style={{ background: '#fafafa', borderRadius: '0.5rem', paddingInline: 10, paddingBlock: 4 }}
                >
                  {dayjs().weekday(day).format('dd')}
                </Checkbox>
              </Form.Item>
            ))}
          </Space>
        </Form.Item>
        <Form.Item label='Chu kỳ' name='date' rules={[{ required: true, message: "Bắt buộc điền thông tin" }]}>
          <DatePicker.RangePicker placeholder={['Từ ngày', 'Đến ngày']} picker='date' style={{ width: '50%' }} minDate={dayjs()} allowClear={false} allowEmpty={false}
            presets={[
              { label: 'Hôm nay', value: [dayjs(), dayjs()] },
              { label: 'Tuần này', value: [dayjs(), dayjs().endOf('week')] },
              { label: 'Tháng này', value: [dayjs(), dayjs().endOf('month')] },
              { label: '30 ngày', value: [dayjs(), dayjs().add(30, 'day')] },
            ]}
          />
        </Form.Item>
        <Form.Item label="Khung giờ" name="time" rules={[{ required: true, message: "Bắt buộc điền thông tin" }]}>
          <TimeRangePicker presets={[
            { label: 'Giờ hành chính (7:00 - 18:00)', value: [7, 18] },
            { label: 'Sáng (7:00 - 12:00)', value: [7, 12] },
            { label: 'Chiều (13:00 - 18:00)', value: [13, 18] },
            { label: 'Tối (17:00 - 23:00)', value: [17, 23] },
            { label: '3 ca (7:00 - 23:00)', value: [7, 23] },
            { label: 'Cả ngày', value: [0, 24] },
          ]}
          />
        </Form.Item>
      </Form>
    </Modal >
  )
}