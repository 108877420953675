import { Button, Card, Form, Input, Row, Select, Space, Switch } from "antd"
import { FORM_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormPreviewModal } from "./FormPreviewModal"
import { PageTitle } from "_metronic/layout/core"

export const FormEditorPage = () => {
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewForm, setPreviewForm] = useState(undefined as any)
  const [title, setTitle] = useState('Biểu mẫu mới')
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { uid } = useParams()

  const handleUpdate = () => {
    console.log(JSON.stringify(form.getFieldsValue()))
    form.validateFields()
      .then((values) => {
        values.questions = values.questions.map(question => question.type === 'TEXT' ? { ...question, options: [] } : question)
        values.questions = values.questions.map(question => ({ ...question, layout: 'HORIZONTAL', other_enable: false, default_val: "" }))

        if (uid) {
          axios.put(FORM_API, { ...values, form_id: uid }).then(response => {
            if (response.data.success) {
              navigate(`/form/id/${response.data.data.uid}`)
            }
          })
        } else {
          axios.post(FORM_API, values).then(response => {
            if (response.data.success) {
              navigate(`/form/id/${response.data.data.uid}`)
            }
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handlePreview = () => {
    form.validateFields()
      .then((values) => {
        setPreviewForm(values)
        setShowPreviewModal(true)
      })
  }

  useEffect(() => {
    if (uid) {
      axios.get(FORM_API, { params: { form_id: uid } })
        .then(response => response.data.data)
        .then(response => {
          form.setFieldsValue(response)
          setTitle(response.title)
        })
    }
  }, [uid, form])

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <Card title={title} extra={
        <Space>
          <Button onClick={() => navigate(-1)}>Huỷ</Button>
          <Button onClick={handlePreview}>Xem trước</Button>
          <Button onClick={handleUpdate}>Lưu thông tin</Button>
        </Space>
      }
        actions={[
          <Row justify="end" className="me-8">
            <Space>
              <Button onClick={() => navigate(-1)}>Huỷ</Button>
              <Button onClick={handlePreview}>Xem trước</Button>
              <Button onClick={handleUpdate}>Lưu thông tin</Button>
            </Space>
          </Row>
        ]}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          form={form}
        >
          <Card
            title='Thông tin chung'
            size="small"
            styles={{ body: { marginTop: 20 } }}
          >
            <Form.Item label="Tên" name="title" rules={[{ required: true, message: "Bắt buộc điền thông tin" }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Mô tả" name="description">
              <Input />
            </Form.Item>
          </Card>
          <Form.List name="questions">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index, ...restField) => (
                  <Card
                    title={`Câu hỏi ${index + 1}`}
                    className="mt-6"
                    size='small'
                    key={field.key}
                    styles={{ body: { marginTop: 20 } }}
                    extra={
                      <Space size='small'>
                        <Button icon={<i className="fa-solid fa-arrow-up"></i>} size="small" />
                        <Button icon={<i className="fa-solid fa-arrow-down"></i>} size="small" />
                        <Button onClick={() => remove(field.name)} icon={<i className="fa-solid fa-xmark"></i>} size="small" />
                      </Space>
                    }
                  >
                    <Form.Item label='Câu hỏi' required={true} name={[field.name, 'name']} rules={[{ required: true, message: "Bắt buộc điền thông tin" }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label='Loại câu hỏi' required={true} name={[field.name, 'type']} initialValue="TEXT">
                      <Select style={{ maxWidth: '200px' }}>
                        <Select.Option value="TEXT">Trả lời ngắn</Select.Option>
                        <Select.Option value="MULTIPLE_CHOICE">Chọn một</Select.Option>
                        <Select.Option value="CHECKBOX">Chọn nhiều</Select.Option>
                        <Select.Option value="DROPDOWN_LIST">Menu chọn một</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate>
                      {({ getFieldValue }) => {
                        // console.log('getFieldValue', getFieldValue(['questions', field.name, 'type']))
                        return getFieldValue(['questions', field.name, 'type']) !== 'TEXT' ? (
                          <Form.Item label="Lựa chọn">
                            <Form.List name={[field.name, 'options']} initialValue={['', '']} >
                              {(subFields, subOpt) => (
                                // <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                <Row>
                                  {subFields.map((subField) => (
                                    <Space key={subField.key} style={{ minWidth: '100%' }}>
                                      <Form.Item {...subField} required rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]} key={subField.name}>
                                        <Input />
                                      </Form.Item>
                                      {subFields.length > 2 && <Button onClick={() => { subOpt.remove(subField.name) }} icon={<i className="fa-solid fa-trash-can"></i>} style={{ marginBottom: '24px' }} />}
                                    </Space>
                                  ))}
                                  <Space>
                                    <Button type="dashed" onClick={() => subOpt.add()} >Thêm lựa chọn</Button>
                                  </Space>
                                </Row>
                                // </div>
                              )}
                            </Form.List>
                          </Form.Item>
                        ) : null
                      }
                      }
                    </Form.Item>
                    <Form.Item label='Bắt buộc' required={true} name={[field.name, 'required']} initialValue={false}>
                      <Switch />
                    </Form.Item>
                  </Card>
                ))}
                {/* <Form.Item wrapperCol={{ offset: 6 }} className="pt-6"> */}
                <Card size="small" className="mt-6" styles={{ body: { padding: 0 } }}>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<i className="fa-solid fa-plus"></i>}
                  // style={{ maxWidth: '200px' }}
                  >
                    Thêm câu hỏi
                  </Button>
                </Card>
                <Form.ErrorList errors={errors} />
                {/* </Form.Item> */}
              </>
            )}
          </Form.List>
          <FormPreviewModal open={showPreviewModal} close={() => setShowPreviewModal(false)} form={previewForm} />
        </Form>
      </Card >
    </>
  )
}