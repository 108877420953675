import { Col, Row, Typography } from "antd"

const { Text } = Typography

export const DepartmentInfo = ({ department }) => {

    return (
        <>
            <Row gutter={[10, 10]}>
                <Col span={6}><Text strong>Khoa</Text></Col>
                <Col span={18}><Text >{department && department.name} {department && `(số nhân sự: ${department.count})`}</Text></Col>
                <Col span={6}><Text strong>Mô tả:</Text></Col>
                <Col span={18}><Text >{department && department.description}</Text></Col>
            </Row>
        </>
    )
}