/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import './Schedule.scss'
import {CalendarPlan} from './calendar/CalendarPlan'
import {Button, notification, Spin} from 'antd'
import type { NotificationArgsProps } from 'antd';
import {CreateScheduleModal} from './component/CreateScheduleModal'
import {
  AppointmentItem,
  AppointmentResponse,
  CreateWorkPlanRequestParam,
  CreateWorkPlanResponse,
  GetAppointmentRequestParam,
  GetWorkPlanRequestParam, RemoveWorkPlanRequestParam,
  WorkPlanData,
} from './core/Model'
import {createWorkplan, getAppointmentByDoctor, getWorkplan, removeWorkplan} from './core/Request'
import {getAuth, useAuth} from '../../auth'
import {AxiosResponse} from 'axios'
import moment from 'moment'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {RemoveScheduleModal} from './component/RemoveScheduleModal'

type NotificationPlacement = NotificationArgsProps['placement'];
const Context = React.createContext({ name: 'Default' });

const Scheduler = () => {
  const [openCreateModal, setOpenCreateModal] = React.useState(false)
  const [openRemoveModal, setOpenRemoveModal] = React.useState(false)
  const [calendarData, setCalendarData] = React.useState<WorkPlanData[]>([])
  const [calendarDateStart, setCalendarDateStart] = React.useState(moment(new Date()).startOf('week').toDate())
  const [calendarDateEnd, setCalendarDateEnd] = React.useState(moment(new Date()).endOf('week').toDate())
  const [appointment, setAppointment] = React.useState<AppointmentItem[]>([])
  const [appointmentCount, setAppointmentCount] = React.useState(0)
  const [showIndicator, setShowIndicator] = useState(false)
  const [createWorkplanError, setCreateWorkplanError] = React.useState('')
  const [removeWorkplanError, setRemoveWorkplanError] = React.useState('')

  const {currentUser} = useAuth()

  useEffect(() => {
    const auth = getAuth()
    if (auth && auth.uid && appointmentCount > 1) {
      handleGetAppointment({
        page: appointmentCount, target_doctor_id: auth.uid,
        per_page: 100,
        from_datetime: moment(calendarDateStart).format('YYYY/MM/DD HH:mm:ss'),
        to_datetime: moment(calendarDateEnd).subtract(1).endOf('date').format('YYYY/MM/DD HH:mm:ss'),
      })
    }
  }, [appointmentCount])
  useEffect(() => {
    const auth = getAuth()
    if (auth && auth.uid) {
      handleGetWorkplan({doctor_id: auth.uid, date: moment(calendarDateStart).format('YYYY/MM/DD')})
      handleGetAppointment({
        page: 1, target_doctor_id: auth.uid,
        per_page: 100,
        from_datetime: moment(calendarDateStart).format('YYYY/MM/DD HH:mm:ss'),
        to_datetime: moment(calendarDateEnd).subtract(1).endOf('date').format('YYYY/MM/DD HH:mm:ss'),
      })
    }
  }, [calendarDateStart])
  const auth = getAuth()
  if (!auth || !auth.uid) {
    return <div>403 - Need login first</div>
  }

  const toggleModal = (): void => {
    setOpenCreateModal(!openCreateModal)
    setCreateWorkplanError('')
  }
  const toggleRemoveModal = (): void => {
    setOpenRemoveModal(!openRemoveModal)
    setRemoveWorkplanError('')
  }

  const handleCreateWorkplan = (param: CreateWorkPlanRequestParam) => {
    createWorkplan(param).then((res: AxiosResponse<CreateWorkPlanResponse>) => {
      if (res.data.success && auth && auth.uid) {
        handleGetWorkplan({doctor_id: auth.uid, date: moment(calendarDateStart).format('YYYY/MM/DD')})
        toggleModal()
        openNotification('topRight', 'Lịch làm việc', 'Bạn đã tạo lịch làm việc thành công')
      } else {
        setCreateWorkplanError(res.data.error.message)
      }
    })
  }
  const handleRemoveWorkplan = (param: RemoveWorkPlanRequestParam) => {
    removeWorkplan(param).then((res: AxiosResponse<CreateWorkPlanResponse>) => {
      if (res.data.success && auth && auth.uid) {
        handleGetWorkplan({doctor_id: auth.uid, date: moment(calendarDateStart).format('YYYY/MM/DD')})
        toggleRemoveModal()
        openNotification('topRight', 'Lịch làm việc', 'Bạn đã xoá lịch làm việc thành công')
      } else {
        setRemoveWorkplanError(res.data.error.message)
      }
    })
  }
  const handleGetWorkplan = async (param: GetWorkPlanRequestParam) => {
    const workingPlan = await getWorkplan(param)
    if (workingPlan && workingPlan.success) {
      setCalendarData(workingPlan.data)
    }
  }
  const handleGetAppointment = async (param: GetAppointmentRequestParam) => {
    setShowIndicator(true)
    await getAppointmentByDoctor(param).then( (res: AxiosResponse<AppointmentResponse>) => {
      if (res.data.success) {
        if (res.data.data.page === 1) {
          setAppointment([...res.data.data.items])
        }
        if (res.data.data.page > 1) {
          setAppointment([...appointment, ...res.data.data.items])
        }
        if (res.data.data.page < res.data.data.max_page) {
          setAppointmentCount(res.data.data.page + 1)
        } else {
          setAppointmentCount(0)
          setShowIndicator(false)
        }
      }
    })
  }
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement, message: string, description: string) => {
    api.info({
      icon: <img style={{width: 25, height: 25, marginTop: -5}} src={toAbsoluteUrl('/media/icons/calendar_check.png')} alt="img" />,
      message: message || 'Lịch làm việc',
      description: <Context.Consumer>{() => description}</Context.Consumer>,
      placement,
    });
  };


  return (
    <div className="Schedule">
      {contextHolder}
      <Button className={'create-btn'} onClick={toggleModal}>Tạo lịch</Button>
      <Button className={'remove-btn'} onClick={toggleRemoveModal}>Xoá lịch</Button>
        <CalendarPlan
          appointment={appointment}
          calendarDateStart={calendarDateStart}
          calendarDateEnd={calendarDateEnd}
          setCalendarDateStart={setCalendarDateStart}
          setCalendarDateEnd={setCalendarDateEnd}
          calendarData={calendarData} />
      <CreateScheduleModal userName={currentUser?.fullname || 'No Name Doctor'}
                           open={openCreateModal} toggleModal={toggleModal} userId={auth.uid}
                           handleCreateWorkplan={handleCreateWorkplan}
                           createWorkplanError={createWorkplanError}
                           setCreateWorkplanError={setCreateWorkplanError}
      />
      <RemoveScheduleModal userName={currentUser?.fullname || 'No Name Doctor'}
                           open={openRemoveModal} toggleModal={toggleRemoveModal} userId={auth.uid}
                           handleRemoveWorkplan={handleRemoveWorkplan}
                           removeWorkplanError={removeWorkplanError}
                           setRemoveWorkplanError={setRemoveWorkplanError}
      />

      <Spin spinning={showIndicator} size={'large'} fullscreen>
      </Spin>
    </div>
  )
}

export default Scheduler
