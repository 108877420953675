import { Button, Card, Form, Input, Row } from "antd"
import { USER_RESET_PASSWORD_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useNavigate, useSearchParams } from "react-router-dom"

export const ResetPassword = () => {

  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      axios.post(USER_RESET_PASSWORD_API, {
        email: searchParams.get('email'),
        password: values.password,
        token: searchParams.get('token')
      }).then(() => {
        navigate('/auth/login')
      })
    })
  }

  return (
    <Card title={<span className="text-primary">Đặt lại mất khẩu</span>}>
      <Form
        form={form}
        layout='vertical'
        initialValues={{ email: searchParams.get('email') }}
      >
        <Form.Item
          label='Mật khẩu mới'
          name='password'
          className="fw-bold"
          rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới' }, { min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label='Nhập lại mật khẩu'
          name='confirm_password'
          className="fw-bold"
          dependencies={['password']}
          hasFeedback
          rules={[
            { required: true, message: 'Vui lòng nhập lại mật khẩu' },
            { min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Mật khẩu không khớp'));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
      <Row className="pt-4">
        <Button className="ms-auto" type="primary" onClick={handleSubmit}>Đổi mật khẩu</Button>
      </Row>
    </Card >
  )
}