import { Button, Col, Form, Input, InputNumber, Row, Select, Space } from "antd"
import { MEDICINE_LIST_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { useDebounce } from "use-debounce"

export const DrugInput = ({ subField, remove, drugRoutes, form }) => {
  const [filter, setFilter] = useState('')
  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('')

  const [drugList, setDrugList] = useState([] as any[])
  const [unit, setUnit] = useState(form.getFieldValue(['prescriptions', subField.name, 'unit']) || '')

  useEffect(() => {
    axios.get(MEDICINE_LIST_API, { params: { page: 1, per_page: 10, name_or_active_ingredient: searchTerm } })
      .then(response => {
        console.log(response.data.data)
        setDrugList(response.data.data.items.map(item => ({
          value: item.drug_code,
          label: `${item.drug_name} (${item.active_ingredient})`,
          // label: <Space size={0} direction="vertical">
          //   <Text>{item.drug_name}</Text>
          //   <Text>{item.active_ingredient}</Text>
          // </Space>,
          // unit: item.unit
          ...item,

        })))
      })
  }, [searchTerm])

  useEffect(() => {
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  // console.log(subField.key)

  return (
    <Row style={{ width: '100%', display: 'flex' }}>
      <Col style={{ width: 'calc(100% - 40px)' }}>
        <Row gutter={[10, 10]}>
          <Col span={18}>
            <Form.Item
              name={[subField.name, 'drug_name']}
              required rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]}
              key={subField.name}
              style={{ marginBottom: 0 }}
            // noStyle
            >
              <Select
                options={drugList}
                filterOption={false}
                showSearch
                allowClear
                labelInValue
                placeholder='Tên thuốc'
                onSearch={(value) => setFilter(value)}
                optionRender={(item) =>
                  <Space size={0} direction="vertical">
                    {item.data.drug_name}
                    {item.data.active_ingredient}
                  </Space>
                }
                onSelect={(_, option) => {

                  setUnit(option.unit)
                  // console.log('select', option);
                  console.log(subField)
                  // console.log(form.getFieldValue(['prescriptions']))
                  form.setFieldValue(['prescriptions', subField.name, 'unit'], option.unit)
                  // subField.setFieldsValue({ unit: option.unit })

                }}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[subField.name, 'quantity']} required rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]} style={{ marginBottom: 0 }}>
              <InputNumber
                addonAfter={unit}
                placeholder="Số lượng"
                style={{ width: '100%' }}
                min={0}
              />
            </Form.Item>
            <Form.Item name={[subField.name, 'unit']} hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[subField.name, 'drug_route']} required rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]} >
              <Select options={drugRoutes} placeholder="Đường dùng" showSearch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={[subField.name, 'drug_frequence']} required rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]} >
              <Input placeholder="Liều dùng" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name={[subField.name, 'take_medicine']} required rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]} >
              <Input placeholder="Cách dùng" />
            </Form.Item>
          </Col>

        </Row>
        {/* </div > */}

      </Col >
      <Col style={{ width: 40 }}>
        <Button onClick={remove} icon={<i className="fa-solid fa-trash-can"></i>} style={{ marginBottom: '24px', marginLeft: 8 }} />
      </Col>
    </Row >
  )
}