import { Form, Input, Modal } from "antd"
import axios from "axios"
import { CHANGE_PASSWORD_API } from "../../../helpers/Common"

export const ChangePasswordModal = ({ account, open, close }) => {
  const [form] = Form.useForm()

  const handleOk = () => {
    form.validateFields().then(
      values => {
        console.log(values)
        if (form.getFieldValue('new_password') !== form.getFieldValue('confirm_new_password')) {
          form.setFields([{
            name: 'confirm_new_password',
            errors: ['Mật khẩu mới không khớp']
          }])
        } else {
          axios.put(CHANGE_PASSWORD_API, {
            old_password: values.old_password,
            new_password: values.new_password,
            confirm_new_password: values.confirm_new_password
          }).then(response => {
            console.log(response.data)
            if (response.data.success) {
              close()
            } else if (response.data.error.code === 417) {
              form.setFields([{
                name: 'old_password',
                errors: ['Mật khẩu cũ không đúng']
              }])
            }
          })
        }
      }
    ).catch(err => {
      /* TODO: handle validation failures?  */
    })
  }

  const handleCancel = () => {
    close()
  }

  return (
    <Modal
      title='Đổi mật khẩu'
      onOk={handleOk}
      onCancel={handleCancel}
      open={open}
      bodyStyle={{ marginTop: 20, marginBottom: 35 }}
      okText="Đổi mật khẩu"
      cancelText='Huỷ'
      destroyOnClose={true}
    >
      <Form
        form={form}
        wrapperCol={{ span: 12 }}
        labelCol={{ span: 12 }}
        preserve={false}
      >
        <Form.Item name='old_password' label='Mật khẩu cũ' rules={[{ required: true, message: 'Bắt buộc điền' }]}>
          <input className="tw-hidden" type="text" autoComplete="username" name="username" id="username"></input>
          <Input.Password autoComplete="current-password" />
        </Form.Item>
        <Form.Item name='new_password' label='Mật khẩu mới' rules={[{ required: true, message: 'Bắt buộc điền' }]}>
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Form.Item name='confirm_new_password' label='Xác nhận mật khẩu mới' rules={[{ required: true, message: 'Bắt buộc điền' }]}>
          <Input.Password autoComplete="new-password" />
        </Form.Item>



      </Form>
    </Modal>
  )
}
