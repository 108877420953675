import { PageTitle } from "_metronic/layout/core"
import { Button, Card, Collapse, Descriptions, Row, Space, Typography } from "antd"
import { FORM_API, getQuestionDescription } from "app/modules/helpers/Common"
import axios from "axios"
import clsx from "clsx"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { FormPreviewModal } from "./FormPreviewModal"

const { Text } = Typography

export const FormPage = () => {
  const { uid } = useParams()
  const [form, setForm] = useState(undefined as any)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    axios.get(FORM_API, { params: { form_id: uid } })
      .then(response => response.data.data)
      .then(response => {
        setForm(response)
      })
  }, [uid])

  return (
    <>
      <PageTitle>{form?.title}</PageTitle>
      <Card
        title={form?.title}
        extra={
          <Space>
            <Button onClick={() => setShowPreviewModal(true)}>Xem trước</Button>
            <Button onClick={() => navigate(`/form/edit/${uid}`)}>Chỉnh sửa biểu mẫu</Button>
          </Space>
        }
      >
        <Row className="pb-7">
          <Text className="fs-4">{form?.description}</Text>
        </Row>
        <Collapse
          expandIcon={({ isActive }) => <i className={clsx("fa-solid fa-caret-right", { 'fa-rotate-90': isActive })}></i>}
          accordion
          items={
            form?.questions.map((question, index) => ({
              key: index,
              label: `Câu hỏi ${index + 1}: ${question.name}`,
              children: (
                <Descriptions bordered column={2} size='small'>
                  <Descriptions.Item label="Loại">{getQuestionDescription(question.type)}</Descriptions.Item>
                  <Descriptions.Item label="Lựa chọn">
                    <Space direction="vertical">
                      {question.options.map(option => <Text key={option}>{option}</Text>)}
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label="Bắt buộc">{question.required ? 'Có' : 'Không'}</Descriptions.Item>
                </Descriptions>)
            }))
          }
        />
      </Card >
      <FormPreviewModal open={showPreviewModal} close={() => setShowPreviewModal(false)} form={form} />
    </>
  )
}