/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { CurrentUserModel } from '../patient-management/patient/PatientNote';

type Props = {
  className: string
  patient: any
  currentUser: CurrentUserModel
  onAddNoteList: (newItem:any) => void
  errorMessage: string
}
export const CustomToolbar = () => { 
  // console.log(fontSize);
  const [fontSize, setFontSize] = useState('normal');
  const handleFontChange = (event) => {
    console.log(event.target.value);
    setFontSize(event.target.value);
  };
  return (
    <div className="ql-toolbar d-flex flex-stack py-2 ql-snow">
      <div className='me-2'>
          <select className="ql-size w-75px" value={fontSize} onChange={handleFontChange}>
            <option value="small">Small</option>
            {/* <option value="medium">Normal</option> */}
            <option value="large">Large</option>
            <option value="huge">Huge</option>
          </select>


          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
      </div>
    </div>
  );
};

interface InputNoteModel{
  target_user_id: string
  text: string
}
const InputNote: React.FC<Props> = ({className, patient, currentUser, onAddNoteList, errorMessage}) => {
  const [inputNoteModel, setInputNoteModel] = useState<InputNoteModel>({target_user_id: patient.uid, text: ''});
  const quillRef = useRef<any>(null);
  const Size = Quill.import("formats/size");
  Size.whitelist = ["large"];
  Quill.register(Size, true);
  
  let modules = {
    toolbar: false,
  };
  const handleChange = (value:any) => {
    setInputNoteModel({...inputNoteModel, text:value.substring(inputNoteModel.text)});
    // let textNote = '';
    // if (quillRef.current) {
    //   textNote = quillRef.current.getEditor().getText();
    // }
    // console.log(textNote);

  };


  const onAddNote = () => {
    try {
      let textNote = '';
      if (quillRef.current) {
        textNote = quillRef.current.getEditor().getText();
      }
      onAddNoteList(textNote)
      setInputNoteModel({...inputNoteModel, text:''})
    } catch (error) {
      console.log("add note e: ", error);
    }
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body pb-0'>
        {/* begin::Header */}
        <div className='d-flex align-items-center'>
          {/* begin::User */}
          <div className='d-flex align-items-center flex-grow-1'>
            {/* begin::Avatar */}
            <div className='symbol symbol-45px me-5'>
              <img src={toAbsoluteUrl(currentUser.avatar ? currentUser.avatar : '/media/avatars/blank.png')} alt='' />
            </div>
            {/* end::Avatar */}

            {/* begin::Info */}
            <div className='d-flex flex-column'>
              <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                {currentUser.fullname}
              </a>

              <span className='text-gray-400 fw-semibold'>{currentUser.role}</span>
            </div>
            {/* end::Info */}
          </div>
          {/* end::User */}

          {/* begin::Menu */}
          {/* <div className='my-0'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTIcon iconName='category' className='fs-2' />
            </button>
            <Dropdown2 />
          </div> */}
          {/* end::Menu */}
        </div>
        {/* end::Header */}

      <form id="kt_forms_widget_1_form" className="ql-quil ql-quil-plain pb-3">
        {/*begin::Editor*/}
        <div id="kt_forms_widget_1_editor" className="py-6 ql-container ql-snow">
          <div className='me-2'>
            <ReactQuill
              className='text-gray-800 fw-large fs-3'
              ref={quillRef}
              modules={modules}
              placeholder='Nhập ghi chú'
              value={inputNoteModel.text}
              onChange={handleChange}
              theme="snow"
            />
          </div>
        </div>
        {/*end::Editor*/}
        <div className="separator" />
        {/*begin::Toolbar*/}
        <div className="d-flex flex-stack align-content-end flex-row-reverse">
          <div className="mt-3">
            <button
              disabled={quillRef.current && quillRef.current.getEditor().getText().trim().length < 1}
              className="btn btn-primary"
              type="button"
              data-kt-element="send"
              onClick={onAddNote}
            > Gửi ghi chú
            </button>
          </div>
          <label style={{color: 'red', fontSize: 14}}>{errorMessage || ''}</label>
        </div>
        {/*end::Toolbar*/}
      </form>
        
      </div>
      {/* end::Body */}
    </div>
  )
}
export { InputNote };

