import { useEffect, useState } from 'react';
import { STRESS_SUMMARY_API } from '../../../helpers/Common';
import axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker, Button, Space } from 'antd';

import { Chart } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-dayjs-4';

import { Legend, TimeScale } from 'chart.js';

Chart.register(
    TimeScale,
    Legend,
);

const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                beforeLabel: function (context) { return ''; },
                label: function (context) {
                    let label = '';
                    switch (context.datasetIndex) {
                        case 0: {
                            label += `Stress level: ${context.raw.y}`;
                            break;
                        }
                    }
                    return label;
                },
                title: function (context) {
                    // console.log(context)
                    return ``;
                }
            }
        }
    },
    scales: {
        x: {
            type: 'time' as const,
            time: {
                unit: 'day' as const,
                displayFormats: {
                    minute: 'HH' as const,
                    hour: 'ddd HH:mm' as const,
                    day: 'DD'
                },
                round: 'hour' as const,
                // tooltipFormat: 'DD T'
            },
            title: {
                display: false,
            }
        },
        y: {
            min: 0,
        },
    },
}

const StressChart = ({ className, uid }) => {
    let initData: any[] = []
    const [series, setSeries] = useState({ datasets: initData })
    const [date, setDate] = useState(dayjs())
    const [optionChart, setOptionChart] = useState(options);

    const handleBack = () => {
        setDate(date.subtract(1, 'month'))
    }

    const handleNext = () => {
        setDate(date.add(1, 'month'))
    }

    const handleDateSelect = (date, dateString) => {
        setDate(date)
    }

    useEffect(() => {
        axios.get(STRESS_SUMMARY_API, { params: { local_year: date.year(), local_month: date.month() + 1, target_user_id: uid } })
            .then(response => response.data.data)
            .then(data => {
                let bgData = data.map(item => {
                    return {
                        x: item.start_datetime * 1000,
                        y: item.avg_stress,
                    }
                }).reverse().filter(item => item.y !== null)

                setOptionChart({
                    ...options,
                    scales: {
                        ...options.scales,
                        x: {
                            ...options.scales.x,
                            max: date.endOf('month').unix() * 1000,
                            min: date.startOf('month').unix() * 1000
                        }
                    }
                })
                setSeries({
                    datasets: [{
                        data: bgData,
                        // backgroundColor: '#75CC68',
                        borderColor: '#F1416C',
                        // pointBackgroundColor: '#FFF'
                        // pointBackgroundColor: markerData
                    }]
                })
            })
            .catch(error => console.log(error))
    }, [uid, date])

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Mức độ căng thẳng</span>

                    <span className='text-muted fw-semibold fs-7'></span>
                </h3>

                {/* begin::Toolbar */}
                <div className='card-toolbar' data-kt-buttons='true'>
                    <Space>
                        <Button type="default" onClick={handleBack}>Trước</Button>
                        <DatePicker value={date} picker="month" onChange={handleDateSelect} allowClear={false} />
                        <Button type="default" onClick={handleNext}>Sau</Button>
                    </Space>
                </div>
                {/* end::Toolbar */}
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className='card-body' style={{ height: "500px" }}>
                <>(%)</>
                <Line options={optionChart} data={series} />
                {/* {chart} */}
                {/* {JSON.stringify(optionChart)} */}
                {/* end::Chart */}
            </div>
            {/* end::Body */}
        </div>
    )
}

export { StressChart }