import { Avatar, Button, Card, ConfigProvider, Input, Space, Table, Typography } from "antd"
import Column from "antd/es/table/Column"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { DISEASE_GROUP_API, DISEASE_ORG_REGISTERED_API, getRelativeTime } from "../../../helpers/Common"
import { useAuth } from "app/modules/auth"
import { Permission } from "app/modules/helpers/Permission"

const { Text } = Typography

const pageSize = 10

export const DiseaseListPage = () => {
  const [orgList, setOrgList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [filter, setFilter] = useState('')

  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('')

  const navigate = useNavigate()
  const { auth, permission } = useAuth()

  const orgOnly = auth?.role === 'SUPER_ADMIN_BRANCH'

  useEffect(() => {
    setPage(1)
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  useEffect(() => {
    let params = {
      page: page,
      name: searchTerm ? searchTerm : undefined,
    }
    axios.get(orgOnly ? DISEASE_ORG_REGISTERED_API : DISEASE_GROUP_API, { params: params })
      .then(response => response.data.data)
      .then(response => {
        setOrgList(response.items)
        setMaxPage(response.max_page)
      })
  }, [page, searchTerm, orgOnly])

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  return (
    <Card
      extra={
        <Space>
          {permission(Permission.UpdateDiseaseGroup) && <Button className="text-hover-primary" icon={<i className="fa-solid fa-folder-plus"></i>} onClick={() => navigate('/disease/new')} >Thêm nhóm bệnh</Button>}
          {permission(Permission.UpdateSupportDisease) && <Button className="text-hover-primary" icon={<i className="fa-solid fa-right-left"></i>} onClick={() => navigate('/disease/select')} >Điều chỉnh</Button>}
        </Space>
      }
      title={
        < Space >
          <Input style={{ maxWidth: '200pt' }} suffix={<i className="fa-solid fa-magnifying-glass text-muted"></i>} placeholder="Tên nhóm bệnh..." value={filter} onChange={handleFilter} />
        </Space >
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
              colorText: '#78829d',
            }
          },
        }}>
        <Table
          dataSource={orgList}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fw-bolder fs-7' })}
          size='middle'
        >
          <Column title='Nhóm bệnh' dataIndex="fullname" render={
            (_, record: any) =>
              <Space role="button" onClick={() => navigate(`/disease/id/${record.uid}`)}>
                <Avatar src={record.logo ? record.logo : undefined} size={48} style={{ marginRight: 5 }} icon={<i className="fa-solid fa-disease"></i>} />
                <Space.Compact direction='vertical' size='small'>
                  <Text strong>{record.name}</Text>
                </Space.Compact>
              </Space>
          } />
          <Column title="Đánh giá" dataIndex="rating" render={(value) => <Space size='small'><i className="fa-solid fa-star" style={{ color: '#FFAD0F' }}></i>{value || '-'}</Space>} />
          <Column title="Hoàn thành" dataIndex="completed_count" />
          <Column title="Ngày tạo" dataIndex="created_at" render={(value) => getRelativeTime(value)} />
          <Column title="Ngày chỉnh sửa" dataIndex="updated_at" render={(value) => getRelativeTime(value)} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              <Button className="text-hover-primary" icon={<i className="fa-regular fa-pen-to-square fs-5"></i>} onClick={() => navigate(`/disease/id/${record.uid}`)} />
            </Space>
          }
          />
        </Table>
      </ConfigProvider>
    </Card >
  )
}