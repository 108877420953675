import { Button, Card, Col, ConfigProvider, Input, Pagination, Row, Space, Table, Typography } from "antd"
import Column from "antd/es/table/Column"
import { DISEASE_ORG_REGISTERED_API, DISEASE_ORG_UNREGISTERED_API, DISEASE_ORG_UPDATE_API } from "app/modules/helpers/Common"
import axios from "axios"
import clsx from "clsx"
import { useEffect, useReducer, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"

const { Text } = Typography

const pageSize = 10

const DiseaseTable = ({ registered = false, requestUpdate, needUpdate, searchTerm }) => {
  const [itemList, setItemList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    let params = {
      page: page,
      name: searchTerm ? searchTerm : undefined,
    }
    axios.get(registered ? DISEASE_ORG_REGISTERED_API : DISEASE_ORG_UNREGISTERED_API, { params: params })
      .then(response => response.data.data)
      .then(response => {
        setItemList(response.items)
        setMaxPage(response.max_page)
      })
  }, [page, searchTerm, needUpdate, registered])

  return (
    <Card style={{ height: '100%', marginBottom: 20 }} size="small">
      <Table
        dataSource={itemList}
        rowKey="uid"
        pagination={false}
        onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
        showHeader={false}
        onRow={() => ({ className: 'fw-bolder fs-7' })}
        size='small'
        title={() =>
          <Row style={{ width: '100%' }}>
            {registered ? "Đã đăng ký" : "Chưa đăng ký"}
            <Pagination current={page} total={maxPage * pageSize} onChange={(page) => setPage(page)} size="small" className="text-right ms-auto" />
          </Row>
        }
        locale={{ emptyText: "Không có dữ liệu" }}
        style={{ height: '100%' }}
      >
        <Column title='Nhóm bệnh' dataIndex="fullname" render={
          (_, record: any) =>
            <Space role="button">
              <Space.Compact direction='vertical' size='small'>
                <Text strong className="text-hover-primary">{record.name}</Text>
              </Space.Compact>
            </Space>
        } />
        <Column title="Thao tác" align="end" render={(_, record: any) =>
          <Space>
            <Button icon={<i className="fa-regular fa-file-lines"></i>} onClick={() => navigate(`/disease/id/${record.uid}`)}></Button>
            <Button
              icon={<i className={clsx("fa-solid fs-5", registered ? "fa-minus" : "fa-plus")}></i>}
              danger={registered}
              onClick={() => {
                axios.request({ method: registered ? 'DELETE' : 'POST', url: DISEASE_ORG_UPDATE_API, data: { disease_id: record.uid } })
                  .then(() => requestUpdate())
              }} >
              {registered ? 'Xoá' : 'Bổ sung'}
            </Button>
          </Space>
        }
        />
      </Table>
    </Card >
  )
}

export const DiseaseSelectPage = () => {
  const [filter, setFilter] = useState('')
  const [updateCounter, setNeedUpdate] = useReducer((x) => x + 1, 0)

  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  return (
    <Card
      title={
        <Space>
          <Input style={{ maxWidth: '200pt' }} suffix={<i className="fa-solid fa-magnifying-glass text-muted"></i>} placeholder="Tên nhóm bệnh..." value={filter} onChange={handleFilter} />
        </Space>
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
              colorText: '#78829d',
            }
          },
        }}>
        <Row gutter={20}>
          <Col span={12}><DiseaseTable registered requestUpdate={setNeedUpdate} needUpdate={updateCounter} searchTerm={searchTerm} /></Col>
          <Col span={12}><DiseaseTable requestUpdate={setNeedUpdate} needUpdate={updateCounter} searchTerm={searchTerm} /></Col>
        </Row>
      </ConfigProvider>
    </Card>
  )
}