import axios from 'axios'
import { useEffect, useReducer, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { useLocation } from 'react-router'
import { ORG_API } from '../../../helpers/Common'
import { OrganizationHeader } from './OrganizationHeader'
import { OrganizationOverview } from './OrganizationOverview'
import { DepartmentPage } from '../../department/DepartmentPage'
import { PageLink, PageTitle } from '_metronic/layout/core'

import { usePageData } from '_metronic/layout/core/PageData'

import { useAuth } from 'app/modules/auth'
import { Permission } from 'app/modules/helpers/Permission'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tổ chức',
    path: '/organization/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const meBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tổ chức',
    path: '/organization/id/me',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const OrganizationPage = () => {

  const { setPageBreadcrumbs, setPageTitle } = usePageData()
  const { pathname } = useLocation()
  const { permission } = useAuth()
    
  const [org, setOrg] = useState(undefined as any)
  const [counter, forceUpdate] = useReducer(x => x + 1, 0);
  const { uid } = useParams()

  useEffect(() => {
    axios.get(ORG_API, { params: { target_org_id: uid === 'me' ? undefined : uid } }).then(response => response.data.data).then(
      data => {
        setOrg(data)
      }
    )
  }, [uid, counter])

    useEffect(() => {
        if(org && ['department', 'overview'].every(segment => !pathname.includes(segment))) {

          if(permission(Permission.ShowOrganizationMenu)) {
            setPageBreadcrumbs(meBreadCrumbs)
          } else {
            setPageBreadcrumbs(profileBreadCrumbs)
          }
          
          setPageTitle(org.name)
        }
    }, [org, pathname, setPageBreadcrumbs, setPageTitle, permission])

  return (
    <Routes>
      <Route
        element={
          <>
            {org && <PageTitle breadcrumbs={profileBreadCrumbs}>{org.name}</PageTitle>}
            {org && <OrganizationHeader org={org} />}
          </>
        }
      >
        <Route
          // path='overview'
          index
          element={
            <>
              <OrganizationOverview org={org} forceUpdate={forceUpdate} />
            </>
          }
        />
        <Route
          path='department/:department_id'
          element={<DepartmentPage org={org} />}
        />
      </Route>
    </Routes>
  )
}
