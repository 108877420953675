import { Avatar, Button, Card, ConfigProvider, Input, Space, Table, Typography } from "antd"
import Column from "antd/es/table/Column"
import { useAuth } from "app/modules/auth"
import { FORM_LIST_API, getRelativeTime } from "app/modules/helpers/Common"
import { Permission } from "app/modules/helpers/Permission"
import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDebounce } from "use-debounce"

const { Text } = Typography

const pageSize = 10

export const FormListPage = () => {
  const [orgList, setOrgList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [filter, setFilter] = useState('')

  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('')

  const navigate = useNavigate()
  const { permission } = useAuth()

  useEffect(() => {
    setPage(1)
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  useEffect(() => {
    let params = {
      page: page,
      title: searchTerm ? searchTerm : undefined,
    }
    axios.get(FORM_LIST_API, { params: params })
      .then(response => response.data.data)
      .then(response => {
        setOrgList(response.items)
        setMaxPage(response.max_page)
      })
  }, [page, searchTerm])

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  return (
    <Card
      extra={
        <Space>
          {permission(Permission.UpdateForm) && <Button className="text-hover-primary" icon={<i className="fa-solid fa-folder-plus"></i>} onClick={() => navigate('/form/new')} >Thêm biểu mẫu</Button>}
        </Space>
      }
      title={
        < Space >
          <Input style={{ maxWidth: '200pt' }} suffix={<i className="fa-solid fa-magnifying-glass text-muted"></i>} placeholder="Tên biểu mẫu..." value={filter} onChange={handleFilter} />
        </Space >
      }
    >
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
              colorText: '#78829d',
            }
          },
        }}>
        <Table
          dataSource={orgList}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fw-bolder fs-7' })}
          size='middle'
        >
          <Column title='Nhóm bệnh' dataIndex="fullname" render={
            (_, record: any) =>
              <Space role="button" onClick={() => navigate(`/form/id/${record.uid}`)}>
                <Avatar src={record.logo ? record.logo : undefined} size={48} style={{ marginRight: 5, backgroundColor: '#F1FAFF', color: '#0095e8' }} icon={<i className="fa-regular fa-file-lines"></i>} />
                <Space.Compact direction='vertical' size='small'>
                  <Text strong>{record.title}</Text>
                </Space.Compact>
              </Space>
          } />
          <Column title="Đang sử dụng" dataIndex="disease_attach" render={(value) => value.length} />
          <Column title="Ngày tạo" dataIndex="created_at" render={(value) => getRelativeTime(value)} />
          <Column title="Ngày chỉnh sửa" dataIndex="last_updated" render={(value) => getRelativeTime(value)} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              <Button className="text-hover-primary" icon={<i className="fa-regular fa-pen-to-square fs-5"></i>} onClick={() => navigate(`/form/id/${record.uid}`)} />
            </Space>
          }
          />
        </Table>
      </ConfigProvider>
    </Card >
  )
}