import { Button, Card, ConfigProvider, Space, Table } from "antd"
import Column from "antd/es/table/Column"
import { APPOINTMENT_API, getRelativeTime } from "app/modules/helpers/Common"
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

export const DoctorAppointment = ({ doctor }) => {
  const [appointments, setAppointments] = useState([] as any)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const navigate = useNavigate()
  const pageSize = 10

  useEffect(() => {
    axios.get(APPOINTMENT_API, { params: { target_doctor_id: doctor.uid, page: page, per_page: pageSize } })
      .then(response => response.data.data)
      .then(data => {
        setAppointments(data.items)
        setMaxPage(data.max_page)
      })
  }, [doctor, page])

  return (
    <Card title='Lịch hẹn khách hàng'>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              headerBg: '#fff',
            }
          },
        }}>
        <Table
          dataSource={appointments}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fs-7 text-gray-600' })}
          size='small'
          locale={{ emptyText: 'Không có dữ liệu' }}
        >
          <Column title="Khách hàng" dataIndex="patient_info" render={value => value.fullname} />
          <Column title="Thời gian" dataIndex="start_time" render={(value) => dayjs(value * 1000).format('DD/MM/YYYY HH:mm')} />
          <Column title="Thời lượng" render={(_, record: any) => `${(record.end_time - record.start_time) / 60} phút`} />
          <Column title="Nhóm bệnh" dataIndex={['disease_info', 'name']} />
          <Column title="Ngày tạo" dataIndex="created_at" render={(value) => getRelativeTime(value)} />
          <Column title="Số tiền" align="end" render={(_, record: any) => `${record.price.toLocaleString()} ${record.currency_type}`} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              <Button className="text-hover-primary" icon={<i className="fa-regular fa-pen-to-square fs-5"></i>}
                onClick={() => {
                  navigate(`/calendar/id/${record.uid}`)
                }} />
            </Space>
          }
          />
        </Table>
      </ConfigProvider>
    </Card>
  )
}