import { Modal } from "antd"
import { FormPreview } from "./FormPreview"

export const FormPreviewModal = ({ open, close, form }) => {
  return (
    <Modal
      open={open}
      title='Xem trước biểu mẫu'
      onCancel={close}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={close}
      okText='Đóng'
      destroyOnClose
      width={600}
    >
      {form && <FormPreview form={form} />}
    </Modal>
  )
}