import { Button, Card, Space } from "antd"
import { DEPARTMENT_API, DEPARTMENT_DETAIL_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useReducer, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { DepartmentEmployeeList } from "./DepartmentEmployeeList"
import { DepartmentInfo } from "./DepartmentInfo"
import { DepartmentEmployeeAddModal } from "./DepartmentEmployeeAddModal"
import { DepartmentInfoEdit } from "./DepartmentInfoEdit"
import { PageLink, PageTitle } from "_metronic/layout/core"

import { useAuth } from 'app/modules/auth'
import { Permission } from 'app/modules/helpers/Permission'

const deptBreadCrumbs: Array<PageLink> = [
    {
        title: 'Tổ chức',
        path: '/organization/list',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const deptMeBreadCrumbs: Array<PageLink> = [
    {
        title: 'Tổ chức',
        path: '/organization/id/me',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export const DepartmentPage = ({ org }) => {
    const { permission } = useAuth()
    const { department_id } = useParams()
    const [department, setDepartment] = useState(undefined as any)
    const [isEditing, setIsEditing] = useState(false)
    const [showAddEmployeeModal, setShowAddEmployeeModal] = useState(false)
    const [updateCounter, forceUpdate] = useReducer(x => x + 1, 0);
    const navigate = useNavigate()

    const canEdit = true

    useEffect(() => {
        axios.get(DEPARTMENT_DETAIL_API, { params: { target_department_id: department_id } })
            .then(response => response.data.data)
            .then(data => {
                setDepartment(data)
                console.log(data)
            })
    }, [department_id, org, updateCounter])

    const closeModal = (needRefresh) => {
        console.log('closeModal', needRefresh)
        setShowAddEmployeeModal(false)
        if (needRefresh) {
            forceUpdate()
        }
    }

    const handleDelete = () => {
        axios.delete(DEPARTMENT_API, { data: { department_id: department_id } })
            .then(() => {
                navigate(`/organization/id/${org.uid}`)
            })
    }

    const handleUpdate = (needRefresh) => {
        setIsEditing(false)
        console.log('handleUpdate', needRefresh)
        if (needRefresh) {
            forceUpdate()
        }
    }

    return (
        <>
            {department && <PageTitle breadcrumbs={[
                ...(permission(Permission.ShowOrganizationMenu)? deptMeBreadCrumbs: deptBreadCrumbs),
                {
                    title: org.name,
                    path: `/organization/id/${org.uid}`,
                    isSeparator: false,
                    isActive: false,
                },
                {
                    title: '',
                    path: '',
                    isSeparator: true,
                    isActive: false,
                },
            ]}>
                {department.name}
            </PageTitle >
            }
            <Card
                title='Thông tin khoa'
                extra={
                    <Space>
                        {canEdit && !isEditing &&
                            <>
                                <Button onClick={() => setIsEditing(true)}>Sửa thông tin</Button>
                                <Button danger onClick={handleDelete}>Xoá khoa</Button>
                            </>
                        }
                    </Space>
                }
            >
                {isEditing ?
                    <DepartmentInfoEdit department={department} update={handleUpdate} /> :
                    <DepartmentInfo department={department} />}
            </Card>
            <Card
                title='Nhân sự khoa'
                style={{ marginTop: 32 }}
                extra={<Button onClick={() => setShowAddEmployeeModal(true)}>Thêm nhân sự</Button>}
            >
                {department && <DepartmentEmployeeList department={department} update={updateCounter} />}
            </Card>
            <DepartmentEmployeeAddModal
                open={showAddEmployeeModal}
                close={closeModal}
                department={department}

            />
        </>
    )

}
