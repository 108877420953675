import { Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { OrganizationListPage } from './organization-list/OrganizationListPage'
import { OrganizationPage } from './organization/OrganizationPage'
import { NewOrganizationPage } from './organization/NewOrganizationPage'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tổ chức',
    path: '/organization/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const OrganizationRoutes = () => {
  return (
    <Routes>
      <Route
        path='list'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Danh sách tổ chức</PageTitle>
            <OrganizationListPage />
          </>
        }
      />
      <Route
        path='id/:uid/*'
        element={
          <>
            <OrganizationPage />
          </>
        }
      />
      <Route
        path='new'
        element={
          <>
            <PageTitle breadcrumbs={profileBreadCrumbs}>Tổ chức mới</PageTitle>
            <NewOrganizationPage />
          </>
        }
        />
    </Routes>
  )
}
