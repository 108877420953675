import { KTIcon } from "_metronic/helpers"
import { Button, ConfigProvider, Form, Input, Modal, Table } from "antd"
import { useForm } from "antd/es/form/Form"
import Column from "antd/es/table/Column"
import { MEDICAL_FILES_API } from "app/modules/helpers/Common"
import axios from "axios"
import { useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { v4 as uuidv4 } from 'uuid'

export const PatientFileUploadModal = ({ open, close, account }) => {
  const [fileList, setFileList] = useState([] as any[])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [form] = useForm()

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        acceptedFiles.forEach((element) => {
          element.uuid = uuidv4()
        })
        setFileList([...fileList, ...acceptedFiles])
      }
    },
    [fileList]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleUpload = () => {
    form.validateFields().then((values) => {
      console.log(fileList)
      let formData = new FormData()
      formData.append('title', values.title)
      for (let i = 0; i < fileList.length; i++) {
        formData.append('media', fileList[i])
      }
      formData.append('target_user_id', account)
      setConfirmLoading(true)
      axios.post(MEDICAL_FILES_API, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          console.log(response)
          // form.resetFields()
          setFileList([])
          setConfirmLoading(false)
          close(true)
        })
    })
  }

  const handleCancelUpload = () => {
    // form.resetFields()
    setFileList([])
    close(false)
  }

  return (
    <Modal
      open={open}
      title='Tải hồ sơ lên'
      style={{ minWidth: 700 }}
      destroyOnClose
      onClose={handleCancelUpload}
      onCancel={handleCancelUpload}
      onOk={handleUpload}
      confirmLoading={confirmLoading}
      okText='Tải lên'
      cancelText='Hủy'
    >
      <Form form={form} layout='vertical' clearOnDestroy>
        <Form.Item label='Tên hồ sơ' name='title' rules={[{ required: true, message: 'Bắt buộc điền thông tin' }]}>
          <Input />
        </Form.Item>
      </Form>
      <ConfigProvider theme={{ components: { Table: { headerBg: '#fff' } } }}>
        <Table
          dataSource={fileList}
          rowKey='uuid'
          pagination={false}
          size="small"
          style={{ marginBottom: 40 }}
          onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
          onRow={() => ({ className: 'fs-7 text-gray-600' })}
          locale={{ emptyText: 'Chưa có file tải lên' }}>
          <Column title='Tên file' dataIndex='name' key='name' />
          <Column title='Thao tác' align="end" render={(_, record) => (
            <Button icon={<KTIcon iconName='trash' className='fs-3 text-danger' />} onClick={() => {
              let newFileList = [...fileList]
              newFileList.splice(newFileList.indexOf(record), 1)
              setFileList(newFileList)
            }} />
          )} />
        </Table>
      </ConfigProvider>

      <div {...getRootProps()} style={{paddingBottom: 20}}>
        <input {...getInputProps()} />
        <div
          className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-6'
          style={{ height: '200px' }}
        >
          <div className='d-flex flex-stack flex-grow-1'>
            <KTIcon iconName="folder-up" className="fs-4tx text-primary me-4" />
            <div>
              <h4 className='text-gray-800'>{isDragActive ? 'Thả file vào đây...' : 'Tải lên'}</h4>
              <div className='fs-6 text-gray-600'>
                Kéo thả hoặc chọn tệp để tải lên. Hỗ trợ file ảnh định dạng (JPG) và file tài
                liệu định dạng (PDF).)
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  )
}