import axios from 'axios'
import dayjs from 'dayjs';
import { useEffect, useReducer, useState } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { PROFILE_API } from '../../../helpers/Common'
import { AccountHeader } from './AccountHeader'
import { AccountOverview } from './AccountOverview'
import { AccountSecurity } from './AccountSecurity'
import { AccountSettings } from './AccountSettings'

// const accountBreadCrumbs: Array<PageLink> = [
//   {
//     title: 'Danh sách tài khoản',
//     path: '/account/list',
//     isSeparator: false,
//     isActive: false,
//   },
//   {
//     title: '',
//     path: '',
//     isSeparator: true,
//     isActive: false,
//   },
// ]

export const AccountPage = () => {
  const [account, setAccount] = useState()
  const [counter, forceUpdate] = useReducer(x => x + 1, 0);
  const { uid } = useParams()
  // const { currentUser } = useAuth()
  // console.log('current',currentUser)

  useEffect(() => {
    axios.get(PROFILE_API, { params: { target_user_id: uid } }).then(response => response.data.data).then(
      data => {
        console.log('pro', data)
        if (data.birthday) {
          data.birthday = dayjs(data.birthday, 'YYYY/MM/DD').format('DD/MM/YYYY').replaceAll('/', '-')
        }
        if (data.gender === '') {
          data.gender = undefined
        }
        setAccount(data)
      }
    )
  }, [uid, counter])

  return (
    <Routes>
      <Route
        element={
          <>
            {account && <AccountHeader account={account} />}
            {/* <Outlet /> */}
          </>
        }
      >
        <Route
          index
          element={
            <>
              {/* <PageTitle breadcrumbs={accountBreadCrumbs}>Tổng quan</PageTitle> */}
              <AccountOverview account={account} forceUpdate={forceUpdate} />
            </>
          }
        />
        <Route
          path='settings'
          element={
            <>
              {/* <PageTitle breadcrumbs={accountBreadCrumbs}>Settings</PageTitle> */}
              <AccountSettings account={account} />
              <AccountSecurity account={account} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
