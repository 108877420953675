import React from 'react'
import './WeekBubble.scss'
import moment from 'moment'

// @ts-ignore
import vi from "moment/locale/vi";
import {Flex} from 'antd'
moment.updateLocale('vi', {
  ...vi,
  week : {
    dow: 1
  }
})
interface Props {
  workingDays: string[]
  onChangeWorkingDays: (_: string) => void
}

const WeekBubble = (props: Props) => {
  const days = moment.weekdaysShort(true)
  return (
    <Flex justify="start" gap={'10px'}>
      {
        days.map((day: string) => {
          const className = props.workingDays.indexOf(day) === -1 ? 'week-bubble inactive' : 'week-bubble'
          return (
            <span className={className} onClick={() => props.onChangeWorkingDays(day)}>
             {day}
            </span>
          )
        })
      }
    </Flex>
  )
}

export default WeekBubble
