import axios, {AxiosResponse} from 'axios'
import {APPOINTMENT_API, REMOVE_WORK_PLAN_API, WORK_PLAN_API} from '../../../helpers/Common'
import {
  CreateWorkPlanRequestParam, GetAppointmentRequestParam,
  GetWorkPlanRequestParam,
  GetWorkPlanRequestResponse, RemoveWorkPlanRequestParam,
} from './Model'

const createWorkplan = (param: CreateWorkPlanRequestParam) => {
  return axios.post(WORK_PLAN_API, param)
}
const removeWorkplan = (param: RemoveWorkPlanRequestParam) => {
  return axios.delete(REMOVE_WORK_PLAN_API, {
    data: param,
  })
}

const getWorkplan = async (param: GetWorkPlanRequestParam): Promise<GetWorkPlanRequestResponse | undefined> => {
  return await axios.get(WORK_PLAN_API, {params: param})
    .then((response: AxiosResponse<GetWorkPlanRequestResponse>) => {
      const resData: GetWorkPlanRequestResponse = response.data
      if (resData.success) {
        return resData
      }
    })
}

const getAppointmentByDoctor = async (param: GetAppointmentRequestParam) => {
  return await axios.get(APPOINTMENT_API +
    '?status=[%22PAYMENT_SUCCESS%22%2C%20%22WAITING_CONCLUSION%22%2C%20%22WAITING_TEST_RESULT%22%2C%20%22SUBMITTED_TEST_RESULT%22%2C%20%22FINISHED%22%2C%20%22MISSING_CALL%22]', {params: param})
}


export {
  createWorkplan,
  removeWorkplan,
  getWorkplan,
  getAppointmentByDoctor,
}
