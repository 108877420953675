import { Button, Card, Col, Divider, Dropdown, Row, Skeleton, Space, Typography, Flex } from 'antd'
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons'
import TextArea from 'antd/es/input/TextArea'
import axios from 'axios'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useAuth } from '../../auth'
import {
  AI_SUGGESTION_API,
  ASSIGN_SUPPORT,
  CHAT_ADD_MESSAGE_V2,
  CHAT_USER_CONVERSATION,
  GPT_COMPLETIONS_API,
  GPT_RESULT_API,
} from '../../helpers/Common'
import { ChartPickerModal } from './ChartPickerModal'
import { MessageBox } from './MessageBox'

import { MyUpload } from 'app/modules/apps/chatv3/ChatPage'

const { Text, Paragraph } = Typography

const preloadSize = 20
const messageInputClassName = 'message-input'
// const OA_ID = '3466082490265949587'

export const MessageInput = ({ onEnter, copyMessage }) => {
  const [messageValue, setMessageValue] = useState('')

  useEffect(() => {
    if (copyMessage) {
      setMessageValue(copyMessage)
    }
  }, [copyMessage])

  const handleMessageChange = (e) => {
    setMessageValue(e.target.value)
  }

  return (
    <TextArea
      className={messageInputClassName}
      rows={4}
      placeholder='Nhập nội dung tin nhắn...'
      bordered={false}
      style={{ resize: 'none' }}
      onPressEnter={(e) => {
        if (e.shiftKey || e.altKey || e.ctrlKey) {
          if (!e.shiftKey) {
            setMessageValue(`${messageValue}\r\n`)
          }
          return
        }

        e.preventDefault()

        onEnter(e)
        setMessageValue('')
      }}
      value={messageValue}
      onChange={handleMessageChange}
    ></TextArea>
  )
}

const SelectedMediaItem = ({ item, onClear = () => { } }) => {
  const [isHover, setIsHover] = useState(false)

  //const {uid, name, size, type}= item;

  const { name } = item

  return (
    <Flex
      align='center'
      gap='0.5rem'
      className='hover:tw-bg-gray-100 tw-w-full '
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <PaperClipOutlined style={{ opacity: 0.5 }} />
      <div>{name}</div>

      <Flex justify='end' style={{ flexGrow: 1 }}>
        <DeleteOutlined
          style={{ opacity: 0.5 }}
          onClick={onClear}
          className={clsx({ 'tw-hidden': !isHover })}
        />
      </Flex>
    </Flex>
  )
}

export const ChatBox = ({ user, fetchCounter }) => {
  const messageList = useRef([] as any[])
  const hasMoreMessage = useRef(true)
  const [showIndicator, setShowIndicator] = useState(false)
  const [copyMessage, setCopyMessage] = useState('' as string)
  const [showChartPicker, setShowChartPicker] = useState(false)

  const jobList = useRef([] as any[])
  const [, setNeedUpdate] = useReducer((x) => x + 1, 0)

  const { auth } = useAuth()

  const lastMessageTime = useRef(dayjs().unix() + 3600)
  const endOfMessage = useRef({} as any)
  const loaderRef = useRef({} as any)

  const [imageList, setImageList] = useState([])
  const [mediaList, setMediaList] = useState([])

  const clearMedia = useCallback(
    (file) => {
      const { uid } = file

      if (uid) {
        const imageIndex = imageList.findIndex((e: any) => e?.uid === uid)

        if (imageIndex > -1) {
          const left = imageList.slice(0, imageIndex)
          const right = imageList.slice(imageIndex + 1)

          setImageList([...left, ...right])
        }

        const mediaIndex = mediaList.findIndex((e: any) => e?.uid === uid)

        if (mediaIndex > -1) {
          const left = mediaList.slice(0, mediaIndex)
          const right = mediaList.slice(mediaIndex + 1)

          setMediaList([...left, ...right])
        }
      }
    },
    [imageList, mediaList]
  )

  const clearInput = useCallback(() => {
    setImageList([])
    setMediaList([])
  }, [setImageList, setMediaList])

  const suggest = useCallback(() => {
    const requestSuggestion = (uid, message) => {
      console.log('request suggestion', uid, message, user)
      console.log('current job', jobList.current)
      if (jobList.current.findIndex((job) => job.uid === uid) !== -1) return

      let messages = messageList.current
      let logHistory = [] as any
      let index = messages.findIndex((element) => element.uid === uid)

      let question = message
      if (question.length === 0) {
        if (messages[index].message.attachments.url && messages[index].ocr_result) {
          let metadata = messages[index].ocr_result
          if (metadata?.type === 'BLOOD_PRESSURE') {
            question = `Huyết áp của tôi là ${metadata.tam_thu}/${metadata.tam_truong}, nhịp tim ${metadata.nhip_tim}`
          }
        }
      }
      if (question) {
        axios
          .post(AI_SUGGESTION_API, { question: question, source: 'ZALO_OA', sender_id: user.uid })
          .then((response) => {
            // console.log('metadoc', response)
            if (response.data.data.job_id) {
              let a = jobList.current.push({
                job_id: response.data.data.job_id,
                result: undefined,
                agent: 'METADOC',
                uid: uid,
              })
              console.log(a)
            }
          })
      }

      let startIndex = index < 10 ? 0 : index - 10
      for (let i = startIndex; i <= index; i++) {
        if (messages[i].role === 'SYSTEM') continue
        if (messages[i].role === 'LOG_HISTORY') continue
        let role = messages[i].role.toLowerCase()

        if (messages[i].message.text) {
          logHistory.push({ role: role.toLowerCase(), content: messages[i].message.text })
        } else if (messages[i].message.attachments.url && messages[i].ocr_result) {
          console.log(i)
          console.log(messages[i])
          let metadata = messages[i].ocr_result
          if (metadata?.type === 'BLOOD_PRESSURE') {
            logHistory.push({
              role: role.toLowerCase(),
              content: `Huyết áp của tôi là ${metadata.tam_thu}/${metadata.tam_truong}, nhịp tim ${metadata.nhip_tim}`,
            })
          }
        }
      }

      if (messages[index].role === 'USER') {
        console.log('logHistory', messages[index])
        logHistory.push({ role: 'user', content: '' })
      }
      // console.log("logHistory",logHistory)

      axios.post(GPT_COMPLETIONS_API, { messages: logHistory }).then((response) => {
        // console.log('gpt', response)
        if (response.data.data.job_id) {
          jobList.current.push({
            job_id: response.data.data.job_id,
            result: undefined,
            agent: 'GPT',
            uid: uid,
          })
        }
      })
    }

    // if (messages.length > 0) {
    //   requestSuggestion(messages[messages.length - 1].uid, messages[messages.length - 1].message.text)
    // }

    let lastMsg = [...messageList.current].reverse().find((element) => element.role === 'USER')
    if (lastMsg) {
      requestSuggestion(lastMsg.uid, lastMsg.message.text)
    }
  }, [user])

  const onLoad = useCallback(() => {
    /* console.log('s scroll', messageList?.current?.length)*/
    if (
      endOfMessage?.current &&
      messageList?.current?.filter((e) => e.conversation_id === user.uid).length <= preloadSize
    ) {
      /* console.log('s inner scroll', messageList?.current?.length)*/
      endOfMessage.current.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' })
    }
  }, [endOfMessage, messageList, user])

  useEffect(() => {
    let interval = setInterval(() => {
      if (jobList.current.length > 0) {
        jobList.current.forEach((job) => {
          if (!job.result) {
            axios.get(GPT_RESULT_API, { params: { job_id: job.job_id } }).then((response) => {
              if (response.data.success) {
                if (response.data.data.content) {
                  job.result = response.data.data.content
                } else {
                  job.result =
                    'Xin lỗi trợ lý ảo không thể hỗ trợ yêu cầu của bạn do trong nội dung có chứa từ ngữ không phù hợp.'
                }
                setNeedUpdate()
              }
            })
          }
        })
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const updateOlder = () => {
    hasMoreMessage.current &&
      updateMessage(lastMessageTime.current).then(({ msg: oldMessage, hasMore }) => {
        console.log('old message', oldMessage)
        hasMoreMessage.current = hasMore
        if (oldMessage.length > 0) {
          lastMessageTime.current = Math.ceil(oldMessage[0].created_at)
          messageList.current = [...oldMessage, ...messageList.current]
          console.log('total messsage', messageList.current.length)
          setNeedUpdate()
          setTimeout(() => {
            if (loaderRef.current) {
              document.getElementById(loaderRef.current)?.scrollIntoView()
            }
          }, 100)
        }
      })
  }

  const loadMore = () => {
    if (messageList.current.length > 0) {
      loaderRef.current = messageList.current[0].uid
    }
    updateOlder()
  }

  const updateMessage = useCallback(
    (timestamp) => {
      return axios
        .get(CHAT_USER_CONVERSATION, {
          params: { conversation_id: user.uid, limit: preloadSize, timestamp: timestamp },
        })
        .then((response) => {
          let items = response.data.data
          let newMessage = items.filter(
            (item) => messageList.current.findIndex((message) => message.uid === item.uid) === -1
          )
          messageList.current.forEach((message, index) => {
            let updatedItem = items.find((item) => item.uid === message.uid)
            if (updatedItem) {
              messageList.current[index] = updatedItem
            }
          })
          console.log(items.length === preloadSize)
          return { msg: newMessage.reverse(), hasMore: items.length === preloadSize }
        })
    },
    [user]
  )

  const updateNewer = useCallback(
    (updateIndicator = false) => {
      return updateMessage(dayjs().unix() + 60).then(({ msg: newMessage }) => {
        if (newMessage.length > 0) {
          messageList.current = [...messageList.current, ...newMessage]
          setNeedUpdate()
          /* suggest()*/
          if (updateIndicator) {
            setShowIndicator(true)
          }
          setTimeout(() => {
            if (endOfMessage.current) {
              endOfMessage.current.scrollIntoView()
              // console.log('scroll')
            }
          }, 200)
        }
      })
    },
    /*       eslint-disable-next-line*/
    [suggest, updateMessage]
  )

  useEffect(() => {
    messageList.current = []
    jobList.current = []
    hasMoreMessage.current = true
    try {
      updateNewer().then(() => {
        if (messageList.current.length > 0) {
          lastMessageTime.current = Math.ceil(messageList.current[0].created_at)
          console.log('last message time', lastMessageTime.current)
        }
      })
    } catch (error) {
      console.log('error: ', error)
    }
  }, [updateNewer, user])

  useEffect(() => {
    if (fetchCounter !== 0) {
      updateNewer(true)
    }
  }, [fetchCounter, user, updateNewer])

  const onEnter = async (e) => {
    let message = e.target.value.trim()
    console.log(message, user)
    if (e.target.value.trim().length > 0 || imageList.length || mediaList.length) {
      const formData = new FormData()

      formData.append('conversation_id', user.uid)
      formData.append('text', message)

      imageList.forEach((img) => formData.append('image', img))
      mediaList.forEach((media) => formData.append('file', media))

      await axios({
        method: 'post',
        data: formData,

        url: CHAT_ADD_MESSAGE_V2,
      })

      clearInput()
    }

    setTimeout(updateNewer, 200)
  }

  const assignMe = () => {
    axios.put(ASSIGN_SUPPORT, { conversation_id: user.uid, target_user_id: (auth as any).uid })
  }

  const onScroll = (e) => {
    if (e.currentTarget.scrollTop === 0 && messageList.current.length > 0) {
      loadMore()
    }
    // e.preventDefault();
  }

  const gotoNewMessage = () => {
    setShowIndicator(false)
    endOfMessage.current.scrollIntoView()
  }

  const focusInputUponFileAttached = (selector = `.${messageInputClassName}`) => {
    const input = document.querySelector(selector)

    /* @ts-ignore*/
    input?.focus()

    setTimeout(() => {
      endOfMessage?.current?.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'nearest' })
    }, 500)
  }

  return (
    <Card bordered={true} style={{ marginLeft: 20, marginBlock: 20, flexGrow: 1 }} bodyStyle={{ padding: 0 }}>
      <Row>
        <Col flex='auto'>
          <Row style={{ padding: 10, height: '50px' }} align='middle'>
            <Col flex='auto'>
              <Space>
                {/* <Avatar size={32} style={{ backgroundColor: '#1677FF', verticalAlign: 'middle' }} gap={2}>
            {user?.user_fullname.charAt(0)}
            </Avatar> */}
                <Col style={{ paddingRight: 40 }}>
                  <Text
                    role='button'
                    className='fs-6 fw-bolder text-gray-900 text-hover-primary me-1 lh-1'
                  >
                    {user?.user_fullname}
                  </Text>
                  <div className='mb-0 lh-1'>
                    <span className='badge badge-success badge-circle w-8px h-8px me-1'></span>
                    <span className='fs-8 fw-bold text-gray-400'>Đang online</span>
                  </div>
                </Col>
                {showIndicator && (
                  <Button
                    icon={<i className='fa-regular fa-envelope'></i>}
                    onClick={gotoNewMessage}
                  >
                    Tin nhắn mới
                  </Button>
                )}
              </Space>
            </Col>
            <Col flex='none'>
              <Space>
                <Button onClick={assignMe}>Chuyển cho tôi</Button>
                <Dropdown.Button
                  menu={{
                    items: [
                      { key: '1', label: 'Liên kết tài khoản' },
                      { key: '2', label: 'Yêu cầu cung cấp thông tin' },
                    ],
                  }}
                >
                  Xem hồ sơ
                </Dropdown.Button>
              </Space>
            </Col>
          </Row>
          <Divider style={{ margin: 0 }} />

          <Col
            style={{
              paddingInline: 10,
              height: 'calc(100vh - 260px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Row style={{ flexGrow: 1, overflow: 'auto', width: '100%' }} onScroll={onScroll}>
              <div style={{ paddingBottom: 40, width: '100%' }}>
                {!hasMoreMessage.current && (
                  <MessageBox
                    key='no-more'
                    message={{
                      role: 'FROM_SYSTEM',
                      message: { text: 'Không còn tin nhắn nào' },
                      uid: 'no-more',
                    }}
                    userInfo={undefined}
                    lastMessage={undefined}
                  />
                )}
                {messageList.current
                  .filter((e) => e.conversation_id === user.uid)
                  .map((message, index) => {
                    const isCustomer = message.role === 'USER'

                    let showAvatar = false
                    if (isCustomer && index < messageList.current.length - 1) {
                      const nextIndex = index + 1
                      const nextMessage = messageList.current[nextIndex]

                      const nextIsCustomer = nextMessage.role === 'USER'

                      if (!nextIsCustomer) {
                        showAvatar = true
                      }
                    } else if (isCustomer) {
                      showAvatar = true
                    }

                    return (
                      <MessageBox
                        key={message.uid}
                        message={message}
                        userInfo={
                          message.role === 'ASSISTANT' || message.role === 'LOG_HISTORY'
                            ? {
                              name: 'Metadoc',
                              avatar: 'avatars/bear.jpg',
                            }
                            : {
                              name: user?.user_fullname,
                              avatar: user?.avatar || 'avatars/blank.png',
                            }
                        }
                        showAvatar={showAvatar}
                        lastMessage={index > 0 ? messageList.current[index - 1] : undefined}
                        onLoad={onLoad}
                      />
                    )
                  })}
                <div ref={endOfMessage} />
              </div>
            </Row>

            <Divider style={{ margin: 0 }} />
            <Row style={{ paddingInline: 10, width: '100%' }}>
              <Col className='tw-w-full'>
                <Space size='small' align='start'>
                  <MyUpload
                    fileList={imageList}
                    setFileList={setImageList}
                    accept='image/*'
                    beforeUpload={focusInputUponFileAttached}
                  >
                    <Button type='text' icon={<i className='fa-solid fa-image'></i>}></Button>
                  </MyUpload>

                  <MyUpload
                    fileList={mediaList}
                    setFileList={setMediaList}
                    accept='.pdf, video/*'
                    beforeUpload={focusInputUponFileAttached}
                  >
                    <Button type='text' icon={<i className='fa-solid fa-paperclip'></i>}></Button>
                  </MyUpload>

                  <Button
                    type='text'
                    icon={<i className='fa-solid fa-chart-simple'></i>}
                    onClick={() => setShowChartPicker(true)}
                  ></Button>
                  <Button type='text' icon={<i className='fa-solid fa-star'></i>}></Button>
                </Space>
                <ChartPickerModal
                  isOpen={showChartPicker}
                  hideModal={() => setShowChartPicker(false)}
                  user={user}
                />

                <Col className='tw-max-w-max  tw-max-h-32 tw-overflow-scroll'>
                  {[...imageList, ...mediaList].map((e) => {
                    return (
                      <SelectedMediaItem item={e} onClear={() => clearMedia(e)}></SelectedMediaItem>
                    )
                  })}
                </Col>
                <MessageInput onEnter={onEnter} copyMessage={copyMessage} />
              </Col>
            </Row>
          </Col>
        </Col>
        <Divider type='vertical' style={{ height: 'calc(100vh - 185px)', margin: 0 }} />
        <Col
          flex='400px'
          style={{ display: 'none', margin: 10, height: 'calc(100vh - 205px)', overflow: 'auto' }}
        >
          {jobList.current.slice(-2).map((job) => (
            <Card
              title={`Trợ lý ${job.agent}`}
              extra={
                <Button
                  type='link'
                  size='small'
                  icon={<i className='fa-solid fa-copy' />}
                  onClick={() => setCopyMessage(job.result)}
                ></Button>
              }
              size='small'
              key={job.job_id}
              style={{ width: '400px', marginBottom: 10 }}
            >
              {job.result ? (
                <Paragraph className='fs-7'>{job.result}</Paragraph>
              ) : (
                <Skeleton active paragraph={{ rows: 2 }} />
              )}
            </Card>
          ))}
        </Col>
      </Row>
    </Card>
  )
}
