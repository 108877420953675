import {useState, useEffect, useCallback, useRef} from 'react'
import axios from 'axios'
import moment from 'moment'

import clsx from 'clsx'

import {useDebounce} from 'use-debounce'

import {
  List,
  Input,
  Button,
  Flex,
  Col,
  Divider,
  Typography,
  Avatar,
  Card,
  Space,
  Upload,
  UploadProps,
} from 'antd'

import {UserOutlined} from '@ant-design/icons'

import {run, runLayout, transformProgram} from 'utils/functional/BuildLayout'

import {useChat} from 'app/modules/apps/chatv3/ChatProvider'
import {useFirebase} from 'app/Firebase'

import {
  CHAT_LIST_CONVERSATION,
  CHAT_USER_CONVERSATION,
  CHAT_ADD_MESSAGE_V2,
} from 'app/modules/helpers/Common'

import {callGeneric} from 'app/modules/apps/patient-management/calendar/core/_requests'

import {MessageInput} from 'app/modules/apps/chatv2/ChatBox'
import {MessageBox} from 'app/modules/apps/chatv2/MessageBox'

const {Text} = Typography

function* conversationProgram(inject): Generator<any, any, any> {
  const {data, onClick, currentConversation} = inject

  yield {
    anchor: true,
    classes: ['search-anchor'].join(' '),
  }

  yield <Input.Search key={'search'}></Input.Search>

  yield {
    classes: ['search-wrapper'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['list-wrapper-anchor'].join(' '),
  }

  yield {
    anchor: true,
    classes: ['list-anchor'].join(' '),
  }

  let i = 0
  for (const e of data) {
    const {avatar_url, user_fullname, updated_at, uid, last_message: {text = ''} = {}} = e

    yield (
      <List.Item
        key={i}
        className={clsx({
          'tw-bg-blue-100': uid === currentConversation?.uid,
        })}
      >
        <Button
          type='link'
          onClick={() => {
            onClick(e)
          }}
          className={clsx('tw-w-full tw-px-0')}
        >
          <Flex justify='space-between'>
            <Flex gap='0.5rem' className='tw-w-full'>
              <Avatar size='large' src={avatar_url} icon={<UserOutlined />}>
                {' '}
              </Avatar>

              <Flex vertical={true} align='start'>
                <Text strong>{user_fullname}</Text>
                <Text className='tw-truncate' style={{maxWidth: '12rem'}}>
                  {text}
                </Text>
              </Flex>
            </Flex>

            <Text>{moment(updated_at).format('HH:mm')}</Text>
          </Flex>
        </Button>
      </List.Item>
    )

    i += 1
  }

  yield {
    type: List,

    /* Setup Height based on Parent, not Children */
    classes: ['list-widget', 'tw-h-0'].join(' '),
  }

  /* Wrapper for List */
  yield {
    classes: ['conversation-list', 'tw-grow', 'tw-overflow-scroll'].join(' '),
  }

  yield {
    type: Flex,
    attributes: {
      vertical: true,
    },
    classes: ['tw-h-full'].join(' '),
  }

  yield {
    type: Card,
    classes: ['tw-h-full', 'tw-grid', 'tw-cols-1', 'tw-p-0'].join(' '),
  }
}

const ConversationList = () => {
  const {conversationList, setMessageList, currentConversation, setCurrentConversation} = useChat()

  const getMessageList = async (c) => {
    const {uid} = c

    const ml = await callGeneric(
      axios({
        method: 'get',
        params: {
          conversation_id: uid,
          limit: 20,
          timestamp: moment().unix(),
        },
        url: CHAT_USER_CONVERSATION,
      })
    )
    /* debugger;*/

    /* const d: any = []

       * Array.from({length: 140}).forEach((e) => d.push(ml[0] || {}))*/

    setMessageList(ml, true)
  }

  const res = run(
    runLayout(
      conversationProgram({
        data: conversationList,
        currentConversation,
        onClick: (c) => {
          setCurrentConversation(c)
          getMessageList(c)
        },
        key: 'CHAT_USER_CONVERSATION',
      })
    )
  )

  return <>{res}</>
}

export const MyUpload = ({
  fileList,
  setFileList,
  children,
  accept = 'image/*',
  multiple = true,
  beforeUpload = () => {},
}) => {
  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file, fl) => {
      setFileList([...fileList, ...fl])

      beforeUpload()

      return false
    },
    fileList,
  }

  return (
    <Upload {...props} accept={accept} multiple={multiple} showUploadList={false}>
      {children}
    </Upload>
  )
}

const MessageList = () => {
  const {messageList, currentConversation, setMessageList} = useChat()

  const {lastMessage} = useFirebase()

  const [copyMessage] = useState('')

  const [imageList, setImageList] = useState([])
  const [mediaList, setMediaList] = useState([])

  const loading = useRef(false)

  const endOfData = useRef<any>()

  const [lastScrollMessage, setLastScrollMessage] = useState<any>({})

  const [debouncedLastScrollMessage] = useDebounce(lastScrollMessage, 500)

  useEffect(() => {
    if (lastMessage?.data) {
      const {
        data: {message, meta_data, ocr_result, created_at, conversation_id},
      } = lastMessage

      if (conversation_id !== currentConversation.uid) return

      const newMessage = {
        ...lastMessage.data,
        message: JSON.parse(message),
        meta_data: JSON.parse(meta_data),
        ocr_result: JSON.parse(ocr_result),
        created_at: JSON.parse(created_at + 60),
      }

      setMessageList([newMessage])

      setTimeout(() => {
        endOfData?.current?.scrollIntoView({behavior: 'smooth', block: 'end', inline: 'nearest'})
      }, 200)
    }
  }, [endOfData, lastMessage, currentConversation, setMessageList])

  const syncMessageList = useCallback(async () => {
    /* console.log('debouncedLastScrollMessage ', debouncedLastScrollMessage)*/
    if (debouncedLastScrollMessage?.uid && !loading.current) {
      loading.current = true
      const newML = await getMessageList({
        ...currentConversation,
        requestTimestamp: debouncedLastScrollMessage.created_at,
      })
      setMessageList(newML)

      setTimeout(() => {
        loading.current = false
      }, 200)
    }
  }, [setMessageList, currentConversation, debouncedLastScrollMessage])

  useEffect(() => {
    syncMessageList()
  }, [syncMessageList, debouncedLastScrollMessage])

  const onEnter = useCallback(
    async (e: any) => {
      const value = e?.target?.value

      if (currentConversation?.uid) {
        const formData = new FormData()

        formData.append('conversation_id', currentConversation.uid)
        formData.append('text', value)

        imageList.forEach((img) => formData.append('image', img))
        mediaList.forEach((media) => formData.append('file', media))

        await callGeneric(
          axios({
            method: 'post',
            data: formData,

            url: CHAT_ADD_MESSAGE_V2,
          })
        )

        const newML = await getMessageList(currentConversation)

        setMessageList(newML)

        setTimeout(
          () => {
            endOfData?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
              inline: 'nearest',
            })
          },
          imageList.length || mediaList.length ? 500 : 200
        )
      }
    },
    [currentConversation, setMessageList, imageList, mediaList]
  )

  /* Reuse the Content Overflow Mechanism */
  const program = transformProgram(
    conversationProgram({data: messageList, key: 'CHAT_USER_CONVERSATION'}),
    (pl) => {
      let res = pl

      const listAnchorIndex = res.findIndex((e) => e.classes?.includes('list-anchor'))

      const listWrapperIndex = res.findIndex((e) => e.classes?.includes('list-widget'))

      const conversationIndex = res.findIndex((e) => e.classes?.includes('conversation-list'))

      const conversationWidget = {
        type: List,
        classes: ['conversation-list', 'tw-grow', 'tw-overflow-scroll'].join(' '),

        attributes: {
          onWheel: async (e) => {
            const {currentTarget: element, deltaY} = e

            if (element) {
              const startScrollRatio = Math.abs(element.scrollTop) / element.scrollHeight
              const endScrollRatio =
                (Math.abs(element.scrollTop) + element.clientHeight) / element.scrollHeight

              const scrollRatio = deltaY < 0 ? startScrollRatio : endScrollRatio

              const scrollMessageIndex = Math.min(
                Math.round(messageList.length * scrollRatio),
                messageList.length - 1
              )

              const currentMessage = messageList[scrollMessageIndex]

              /* console.log('scrollRatio ', scrollRatio, scrollMessageIndex,currentMessage, deltaY);*/

              setLastScrollMessage(currentMessage)
            }
          },
        },
      }

      /*
        / Replace widget to add Scroll handler
        /*/
      res.splice(conversationIndex, 1, conversationWidget)

      if (listAnchorIndex > -1 && listWrapperIndex > -1) {
        const left = res.slice(0, listAnchorIndex + 1)
        const right = res.slice(listWrapperIndex)

        const data = messageList
          .filter((d) => {
            const {conversation_id} = d

            return conversation_id === currentConversation.uid
          })
          .map((d, i) => {
            /* return <div key={i}>{d?.message?.text}</div>*/

            /* const {message, created_at, sender_id} = d*/

            /* const m = {
             *   ...d,
             *   message: {
             *     ...message,
             *     text: `${moment.unix(created_at).format('YYYY/MM/DD HH:mm:ss')} ${sender_id} ${
             *       message.text
             *     }`,
             *   },
             * }*/

            return (
              <MessageBox
                key={i}
                userInfo={{...currentConversation, name: currentConversation.user_fullname}}
                message={d}
              />
            )
          })

        const endOfDataDiv = <div ref={endOfData}></div>

        res = [...left, ...data, endOfDataDiv, ...right]

        if (true) {
          const conversationIndex = res.findIndex((e) => e.classes?.includes('conversation-list'))
          res.splice(
            conversationIndex + 1,
            0,
            <Divider style={{margin: 0}} />,

            <Space size='small'>
              <MyUpload fileList={imageList} setFileList={setImageList} accept='image/*'>
                <Button type='text' icon={<i className='fa-solid fa-image'></i>}></Button>
              </MyUpload>

              <MyUpload fileList={mediaList} setFileList={setMediaList} accept='.pdf, video/*'>
                <Button type='text' icon={<i className='fa-solid fa-paperclip'></i>}></Button>
              </MyUpload>
            </Space>,

            <MessageInput onEnter={onEnter} copyMessage={copyMessage}></MessageInput>
          )
        }
      }

      return res
    }
  )

  const res = run(runLayout(program()))

  return <>{res}</>
}

const ChatPageV3 = () => {
  const {messageList, setConversationList} = useChat()

  const {lastMessage} = useFirebase()

  const convRes = ConversationList()
  const detailRes = MessageList()

  const getData = useCallback(async () => {
    const cList = await callGeneric(
      axios({
        method: 'get',
        params: {
          limit: 20,
          timestamp: moment().unix() + 60,
        },

        url: CHAT_LIST_CONVERSATION,
      })
    )

    if (cList?.length) {
      /* const l: any[] = []*/

      /* Array.from({length: 40}).forEach((e) => l.push(cList[0]))*/

      setConversationList(cList)
    }
  }, [setConversationList])

  useEffect(() => {
    getData()
  }, [getData, messageList, lastMessage])

  return (
    <Flex className='tw-grow'>
      <Col style={{minWidth: '265px', maxWidth: '300px'}} className=''>
        {convRes}
      </Col>

      <Divider type='vertical'></Divider>

      <Col className='tw-grow'>{detailRes}</Col>
    </Flex>
  )
}

const getMessageList = async (c) => {
  const {uid, requestTimestamp = moment().unix() + 60} = c

  const ml = await callGeneric(
    axios({
      method: 'get',
      params: {
        conversation_id: uid,
        limit: 20,
        timestamp: requestTimestamp,
      },
      url: CHAT_USER_CONVERSATION,
    })
  )
  /* debugger;*/

  /* const d: any = []

         * Array.from({length: 140}).forEach((e) => d.push(ml[0] || {}))*/

  return ml
}

export default ChatPageV3
