import { Button, Card, Col, Divider, Empty, Row, Space, Typography, theme } from 'antd'
import {
  APPOINTMENT_API,
  formatPhone,
  getGenderDescription,
  getUpcomingTimeDescription
} from 'app/modules/helpers/Common'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const { Text, Title } = Typography
const { useToken } = theme

export const UpcomingWidget = () => {
  const { token } = useToken()
  const [nextAppointment, setNextAppointment] = useState(undefined as any)
  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get(APPOINTMENT_API, {
        params: {
          page: 1,
          per_page: 1,
        },
      })
      .then((response) => {
        console.log('today', response.data.data.items)
        if (response.data.data.items.length > 0) {
          setNextAppointment(response.data.data.items[0])
        }
      })
  }, [])

  return (
    <Card
      style={{ height: '100%' }}
      styles={{body: {height: '100%'}}}
      title='Tiếp theo'
      extra={
        nextAppointment &&
        <Space>
          <Button
            icon={<i className='fa-solid fa-id-card'></i>}
            onClick={() => navigate(`/calendar/id/${nextAppointment.uid}`)}
          >
            Chi tiết
          </Button>
          <Button
            icon={<i className='fa-solid fa-message'></i>}
            onClick={() =>
              navigate(`/chat/id/${nextAppointment?.conversation_id}`)
            }
          >
            Nhắn tin
          </Button>
          <Button
            icon={<i className='fa-solid fa-video'></i>}
            onClick={() => navigate(`/telemedicine/${nextAppointment.uid}`)}
          >
            Gọi video
          </Button>
        </Space>
      }
    >
      {nextAppointment ? (
        <Row>
          <Col span={24}>
            <Row justify='center' className='rounded' style={{ backgroundColor: token.colorPrimaryBg }}>
              <Title level={1} style={{ margin: 20 }}>
                {dayjs(nextAppointment.start_time * 1000).format('HH:mm')}{' '}
                {getUpcomingTimeDescription(nextAppointment.start_time)}
              </Title>
            </Row>
            <Divider />
            <Row gutter={[10, 10]}>
              <Col span={8}>
                <Text strong>Chuyên khoa:</Text>
              </Col>
              <Col span={16}>
                <Text>{nextAppointment.title}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Lý do khám:</Text>
              </Col>
              <Col span={16}>
                <Text>{nextAppointment.reason}</Text>
              </Col>
            </Row>
            <Divider />
            {/* <div style={{ width: '4px', minHeight: '200px', background: token.colorPrimary, borderRadius: '4px' }}></div> */}
            <Row gutter={[10, 10]}>
              <Col span={8}>
                <Text strong>Họ và tên:</Text>
              </Col>
              <Col span={16}>
                <Link to={`/patient/id/${nextAppointment.patient_info.uid}`}>
                  {nextAppointment.patient_info.fullname}
                </Link>
              </Col>
              <Col span={8}>
                <Text strong>Ngày sinh:</Text>
              </Col>
              <Col span={16}>
                <Text>
                  {nextAppointment.patient_info.birthday
                    ? dayjs(nextAppointment.patient_info.birthday, 'YYYY/MM/DD').format(
                      'DD/MM/YYYY'
                    )
                    : ''}
                </Text>
              </Col>
              <Col span={8}>
                <Text strong>Email:</Text>
              </Col>
              <Col span={16}>
                <Text>{nextAppointment.patient_info.email}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Số điện thoại:</Text>
              </Col>
              <Col span={16}>
                <Text>{formatPhone(nextAppointment.patient_info.phone)}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Địa chỉ:</Text>
              </Col>
              <Col span={16}>
                <Text>{nextAppointment.patient_info.address}</Text>
              </Col>
              <Col span={8}>
                <Text strong>Giới tính:</Text>
              </Col>
              <Col span={16}>
                <Text>{getGenderDescription(nextAppointment.patient_info.gender)}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      )
        : <Empty description='Chưa có lịch hẹn tiếp theo' className='mt-5'/>
      }
    </Card>
  )
}
