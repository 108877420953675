import axios from "axios"
import { useEffect, useState } from "react"
// import 'react-quill/dist/quill.snow.css'
import { useLocation } from "react-router-dom"
import { useAuth } from "../../auth"
import { ACCOUNT_SUMMARY_API, NOTE_LIST_API } from "../../helpers/Common"
import { InputNote } from "./InputNote"
import { NoteCard } from "./NoteCard"

export interface NoteModel {
  uid: string
  doctor: string
  doctorid: string
  patient: string
  patientid: string
  createdat: number
  text: string
}

export interface DeleteNoteModel{
  
}
export interface CurrentUser{
  fullname: string
  avatar: string
  role: string
}
export const NotePage = () => {
  const [account, setAccount] = useState<CurrentUser>()
  const { auth } = useAuth();
  const [noteList, setNoteList] = useState([] as any[])
  const [loading, setloading] = useState(false)
  const [addNoteError, setAddNoteError] = useState<string>('')
  // const [isShowLoadMore, setIsShowLoadMore] = useState(true)
  const patient:any = useLocation().state;
  console.log(patient)
  useEffect(() => {
    if (auth === undefined) return;
    axios.get(ACCOUNT_SUMMARY_API, { params: { target_user_id: auth!.uid } })
      .then(response => response.data.data)
      .then(response => {
        setAccount({
          fullname: response.fullname,
          avatar: response.avatar,
          role: response.role,
        })
        // console.log("account: ", response);
      }
    )
    const currentTimestamp = Math.floor(new Date().getTime() / 1000);
    console.log(currentTimestamp)
    axios.get(NOTE_LIST_API, { params: { created_at: currentTimestamp, target_user_id: patient.uid}})
      .then(response => response.data.data)
      .then(response => {
        setNoteList(response);
      })
  }, [auth, patient.uid])
  const onLoadMoreNote = ()=>{
    setloading(true);
    try {
      console.log(noteList);
      let lastTimeStamp = noteList[noteList.length - 1].created_at
      axios.get(NOTE_LIST_API, { params: {created_at: lastTimeStamp, target_user_id: patient.uid } })
      .then(response => response.data.data)
      .then(response => {
        console.log("loadmore res: ", response)
        let oldItems = [...noteList];
        let fileredItems = response.filter((newItem) =>
          !noteList.some((oldItem:NoteModel) => oldItem.uid === newItem.uid)
        );
        setNoteList(oldItems.concat(fileredItems));
      })
      
    } catch (error) {
      console.log('load more error: ', error);
    }
    setloading(false);
  }
  
  const onAddNote = (text: string) =>{
    setAddNoteError('')
    axios.post(NOTE_LIST_API, {target_user_id: patient.uid, text: text})
      .then(res => res.data)
      .then(res => {
        if (res.success) {
          setNoteList([res.data, ...noteList]);
        } else {
          setAddNoteError(res.error.message)
        }
      })
  }
  const onDeleteNote = (uid:string) =>{
    try {
      axios.delete(NOTE_LIST_API, {data: {journal_id:uid}})
        .then(response => response.data)
        .then(response => {
          console.log(response);
          if(response.success){
            const updatedItems = noteList.filter((item) => item.uid !== uid);
            setNoteList(updatedItems);
          }
        })
    } catch (error) {
      console.log("delete note e: ", error);
    }

  }
  return (
    <>
      {account && <InputNote 
        className='mb-5 mb-xxl-8' 
        patient= {patient}
        currentUser= {account}
        onAddNoteList={onAddNote}
        errorMessage={addNoteError}
      />}
      {noteList && noteList.map((item) => {
          return (
            <NoteCard key={item.uid}
              className='mb-5 mb-xxl-8'
              onDeleteNote={onDeleteNote}   
              note={{uid: item.uid,
                doctor: item.creator_fullname,
                doctorid: item.creator_id,
                patient: patient.fullname,
                patientid: item.target_user_id,
                createdat: item.created_at,
                text: item.text,} }
            />
          )
        })
      }
      {<div className='d-grid mb-10'>
        <button
          id='kt_sign_in_submit'
          className='btn btn-primary'
          onClick={onLoadMoreNote}
        >
          {!loading && <span className='indicator-label'>Tải thêm ghi chú</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Đang xử lý...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>}
      {/* <NoteCard className='mb-5 mb-xxl-8' /> */}
    </>
  )
}

/*
  ** ADD
  when can load more?
  * maxPage = 1 => alway false
  page === maxPage
    * totalItems = 11-19, page = 2, maxPages = 2, maxItems = 11-19
      => false
    * totalItems = 11, page = 2, maxPages = 2, maxItems = 
      =>

  page < maxPage  
    * totalItems = 10, page = 1, maxPages = 2, maxItems = 20
      => totalItems = 11, page = 1, maxPages = 3, maxItems = 21 => true
    * totalItems = 10, page = 1, maxPages = 2, maxItems = 11-19
      => totalItems = 11, page = 1, maxPages = 2, maxItems = 12-20 => true
    * totalItems = 10, page = 1, maxPages = 2, maxItems = 20
      => totalItems = 20, page = 2, maxPages = 3, maxItems = 30
    * totalItems = 10, page = 1, maxPages = 2, maxItems = 14
      => totalItems = 16, page = 1, maxPages = 2, maxItems = 20
        => totalItems = 17, page = 1, maxPages = 3, maxItems = 21
*/
