import { Button, Form, Input, Row, Space } from "antd";
import { DISEASE_GROUP_DETAIL_API } from "app/modules/helpers/Common";
import axios from "axios";

export const DiseaseInfoEdit = ({ disease, update }) => {

    const [form] = Form.useForm()

    const handleUpdate = () => {
        form.validateFields()
            .then((values) => {
                console.log(values);
                axios.put(DISEASE_GROUP_DETAIL_API, { ...values, disease_id: disease.uid })
                    .then(() => {
                        update(true)
                    })
            });
    }

    return (
        <>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                form={form}
                initialValues={{
                    ...disease,
                }}
            >
                <Form.Item label="Nhóm bệnh" name="name" rules={[{ required: true, whitespace: true, message: "Bắt buộc điền thông tin" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                    <Input />
                </Form.Item>
            </Form>
            <Row justify="end">
                <Space>
                    <Button onClick={() => update(false)}>Huỷ</Button>
                    <Button onClick={handleUpdate}>Lưu thông tin</Button>
                </Space>
            </Row>
        </>
    )
}