import {
  useState,
  useCallback
} from 'react'

import axios from "axios"

import { useNavigate } from "react-router-dom"

import { Avatar, Button, Space, Image } from "antd"
import Column from "antd/es/table/Column"

import {
  CommonTable
} from 'app/modules/helpers/CommonTable'

import {
  NEWSFEED_API,
} from 'app/modules/helpers/Common'

const NewsfeedListPage = () => {

  const navigate = useNavigate();

  const [forceReload, setForceReload] = useState(0);

  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const delPost = useCallback(async (uid) => {

    const res = await axios({
      method: 'delete',
      data: {
        uid
      },
      url: NEWSFEED_API
    });

    setForceReload(x => x + 1)

    return res
  }, [setForceReload]);

  const getData = useCallback(async ({ page, searchTerm }) => {

    let res = await axios({
      method: 'get',

      params: {
        page
      },
      /* url: DISEASE_GROUP_API,*/
      url: NEWSFEED_API
    });

    const data = res.data.data;

    /*
    / API does not support Pagination yet
    /*/
    res = {
      ...res,
      data: {
        ...res.data,
        data: {
          items: data,
          max_page: 1
        }
      }
    }
    return res
  }, []);

  const title = <>
    < Space >
      <div>Danh sách bài viết</div>
    </Space >
  </>;

  const extra = <Space>
    {<Button className="text-hover-primary" icon={<i className="fa-solid fa-folder-plus"></i>} onClick={() => navigate('/newsfeed/new')} >Thêm bài viết</Button>}
  </Space>;

  const columnList = <>
    <Column title='Thumbnail' dataIndex="fullname" render={
      (_, record: any) =>
        <Space role="button" onClick={() => {
          setPreviewImage(record.thumbnail)
          setPreviewVisible(true)

        }}>
          <Avatar src={record.thumbnail ? record.thumbnail : undefined} size={48} style={{ marginRight: 5 }} icon={<i className="fa-solid fa-disease"></i>} />
        </Space>
    } />

    <Column title="TIÊU ĐỀ" dataIndex="title" render={(value) => <Space size='small'>{value}</Space>} />

    <Column title="URL" dataIndex="url" render={
      (url) => {
        return <a href={url} target="_blank" rel='noreferrer'>{url}</a>
      }

    } />
    <Column title="Thao tác" align="end" render={(_, record: any) =>
      <Space>
        <Button className="" icon={<i className="fa-solid fa-trash fs-5" style={{ color: 'red' }}></i>} onClick={() => delPost(record.uid)} />
      </Space>
    }
    />

  </>

  return (
    <>
      <CommonTable
        getData={getData}
        extra={extra}
        title={title}
        columnList={columnList}
        forceReload={forceReload}
      />

      <Image
        style={{ display: 'none' }}
        preview={{
          visible: previewVisible,
          src: previewImage,
          onVisibleChange: (value) => {
            setPreviewVisible(value)
          },
        }}
      />

    </>
  )

}

export { NewsfeedListPage }
