import {Button, Col, Empty, Row, Select, SelectProps, Space} from 'antd'
import { useEffect, useReducer, useState } from 'react';
import { CareplanForm } from './CareplanForm';
import { CAREPLAN_API, CAREPLAN_SUMMARY_API, getCareplanTypeIcon, getReferenceDescription } from '../../../helpers/Common';
import axios from 'axios';
import { Doughnut } from 'react-chartjs-2';
import clsx from 'clsx';
import { CareplanDetailModal } from './CareplanDetailModal';
import {useAuth} from '../../../auth'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {Plugin} from 'chart.js'
import {AnyObject} from 'chart.js/dist/types/basic'

// ChartJS.register(ArcElement, Tooltip, Legend, Colors);

export const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right' as const,
      },
      datalabels: {
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderColor: 'white' as const,
        borderRadius: 50,
        borderWidth: 2,
        color: 'white' as const,
        display: function (context) {
          var dataset = context.dataset;
          // var count = dataset.data.length;
          var value = dataset.data[context.dataIndex];
          return value > 0;
        },
        font: {
          weight: 'bold' as const,
        },
        padding: 7,
        formatter: Math.round
      },
    },
    layout: {
      padding: 20
    },
  }

export const CareplanOverview = ({ account }) => {
  let initialData: any[] = []
  const SelectOptions : SelectProps['options'] = [
    { value: 'ALL', label: 'Tất cả' },
    { value: 'ACTIVE', label: 'Đang thực hiện' },
    { value: 'STOPPED', label: 'Ngừng thực hiện' },
    { value: 'COMPLETED', label: 'Hoàn Thành' },
  ]

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [careplanData, setCareplanData] = useState(initialData);
  const [series, setSeries] = useState({ datasets: initialData, labels: initialData })

  const [isCareplanDetailModalOpen, setIsCareplanDetailModalOpen] = useState(false);
  const [currentCareplan, setCurrentCareplan] = useState({} as any);

  const [counter, forceUpdate] = useReducer(x => x + 1, 0);
  const [selectedStatus, setSelectedStatus] = useState(SelectOptions[0]);

  const setModal = (visible: boolean) => {
    setIsModalOpen(visible);
  };
const {currentUser} = useAuth()

  useEffect(() => {
    account && axios.get(CAREPLAN_SUMMARY_API, { params: { target_user_id: account.uid } })
      .then(response => response.data.data)
      .then(data => {
        console.log(data)
        setSeries({
          labels: ['Đang thực hiện', 'Ngừng thực hiện', 'Hoàn thành'],
          datasets: [{
            data: [data.ACTIVE, data.STOPPED, data.COMPLETED],
            datalabels: {
              anchor: 'end'
            }
          }],
        })
      })

    account && axios.get(CAREPLAN_API, { params: { page: 1, target_user_id: account.uid } })
      .then(response => response.data.data.items)
      .then(items => {
        setCareplanData(items)
      })
  }, [account, counter])

  const getSelectedLabel = (allLabel: string) : string => selectedStatus.value === SelectOptions[0].value ? allLabel : selectedStatus.label as string
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Kế hoạch sức khoẻ</h3>
        </div>
        <Space wrap>
          <Select
            defaultValue="ALL"
            defaultActiveFirstOption={true}
            style={{ width: 180 }}
            onChange={(value, option) => setSelectedStatus(option)}
            options={SelectOptions}
          />
          {currentUser &&
            (currentUser.role !== 'SUPER_ADMIN_SYS' &&
            currentUser.role !== 'SUPER_ADMIN_BRANCH' &&
            currentUser.role !== 'OWNER') &&
            <Button type="primary" className='align-self-center' onClick={() => setModal(true)}>
              Thêm kế hoạch mới
            </Button>
          }
        </Space>
        <CareplanForm isModalOpen={isModalOpen} setModal={setModal} account={account} forceUpdate={forceUpdate} />
      </div>

      <div className='card-body p-9'>
        {careplanData.length > 0 ? <div className='row mb'>
          <div className='col-xl-5 align-self-center' style={{ height: "300px" }}>
            <Doughnut
              options={options}
              data={series}
              plugins={[ChartDataLabels as Plugin<"doughnut", AnyObject>]}
            />
          </div>
          <div className='col-xl-7'>
            <div className='card-xxl-stretch mb-5 mb-xl-10'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-3 mb-1'>{getSelectedLabel('Tổng quan')}</span>
                  <span className='text-muted fw-semibold fs-7'>{`Tổng cộng ${careplanData.filter(item => item.status === selectedStatus.value || selectedStatus.value === SelectOptions[0].value).length} nhiệm vụ ${getSelectedLabel('').toLowerCase()}`}</span>
                </h3>
              </div>
              <div className='card-body py-3'>
                <CareplanDetailModal isModalOpen={isCareplanDetailModalOpen} setIsModalOpen={setIsCareplanDetailModalOpen} careplan={currentCareplan} forceUpdate={forceUpdate} />
                <Row gutter={[24, 24]}>
                  {careplanData && careplanData.filter(item => item.status === selectedStatus.value || selectedStatus.value === SelectOptions[0].value).map(item => {
                    return (
                      <Col span={12} key={item.uid} >
                        <Row>
                          <Col span={4}>
                            <div className='symbol symbol-50px me-2'>
                              <span className='symbol-label'>
                                <i className={clsx('fa-solid', getCareplanTypeIcon(item.plan_type), 'fs-2 text-primary')}></i>
                              </span>
                            </div>
                          </Col>
                          <Col span={16}>
                            <Button
                              type='link'
                              size='small'
                              className='text-dark fw-bold text-hover-primary fs-6'
                              style={{ paddingLeft: 0 }}
                              onClick={() => {
                                setCurrentCareplan(item)
                                setIsCareplanDetailModalOpen(true)
                              }}
                            >
                              {getReferenceDescription(item.plan_type)}
                            </Button>
                            <span className='text-muted fw-semibold d-block fs-7'>{item.plan_name}</span>
                          </Col>
                          <Col span={4}>
                            <div className='d-flex flex-column w-100 me-2'>
                              <div className='d-flex flex-stack mb-2'>
                                <span className='text-muted me-2 fs-7 fw-semibold'>{item.percent_value}%</span>
                              </div>
                              <div className='progress h-6px w-100'>
                                <div
                                  className='progress-bar bg-primary'
                                  role='progressbar'
                                  style={{ width: `${item.percent_value}%` }}
                                ></div>
                              </div>
                            </div>
                          </Col>
                          {/* <Col span={4} className='text-end'>
                            <Button type='link' className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'>
                              <KTIcon iconName='arrow-right' className='fs-2' />
                            </Button>
                          </Col> */}
                        </Row>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </div>
          </div>
        </div> : <Empty description={<h3 className="text-muted">Chưa có dữ liệu sức khoẻ</h3>} imageStyle={{ height: "300px" }} />}
      </div>
    </div>
  )
}
