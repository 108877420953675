import { Avatar, Button, Card, ConfigProvider, Input, Segmented, Space, Table, Tag, Typography } from "antd"
import Column from "antd/es/table/Column"
import axios from "axios"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import {useLocation, useNavigate} from 'react-router-dom'
import { useDebounce } from "use-debounce"
import { FOLLOW_API, PATIENT_API, formatPhone, getGenderDescription, getRelativeTime } from "../../../helpers/Common"
import { useAuth } from "app/modules/auth"
import { Permission } from "app/modules/helpers/Permission"

const { Text } = Typography

const pageSize = 10
enum FILTER {
  ALL= 'ALL',
  FOLLOW= 'FOLLOW'
}

export const PatientListPage = () => {
  const [accountList, setAccountList] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [filter, setFilter] = useState('')
  const { permission } = useAuth()

  const [filterDebounce] = useDebounce(filter, 500)
  const [searchTerm, setSearchTerm] = useState('')

  const location = useLocation()
  const locationState = location.state as {filter: FILTER}
  const [filterFollow, setFilterFollow] = useState(locationState && locationState.filter ? locationState.filter : FILTER.ALL)

  const navigate = useNavigate()
  useEffect(() => {
    if (locationState && locationState.filter) {
      setFilterFollow(locationState.filter)
    }
  }, [locationState])

  useEffect(() => {
    setPage(1)
    setSearchTerm(filterDebounce)
  }, [filterDebounce])

  useEffect(() => {
    if (filterFollow === 'ALL') {
      let params = {
        page: page,
        fullname: searchTerm ? searchTerm : undefined,
        contact: searchTerm ? searchTerm : undefined,
        role: '["PATIENT"]'
      }
      axios.get(PATIENT_API, { params: params })
        .then(response => response.data.data)
        .then(response => {
          setAccountList(response.items)
          setMaxPage(response.max_page)
        })
    } else {
      axios.get(FOLLOW_API, { params: { page: page, fullname: searchTerm ? searchTerm : undefined, phone: searchTerm ? searchTerm : undefined } })
        .then(response => response.data.data)
        .then(response => {
          setAccountList(response.items)
          setMaxPage(response.max_page)
        })
    }
  }, [page, searchTerm, filterFollow])

  const handleFilter = (e) => {
    setFilter(e.target.value)
  }

  const handleFilterFollow = (value) => {
    setFilterFollow(value)
    setPage(1)
  }

  const genderStyle = (value) => {
    let gender = getGenderDescription(value)
    switch (gender) {
      case 'Nam': return <Tag color='blue' bordered={false}>Nam</Tag>
      case 'Nữ': return <Tag color='red' bordered={false}>Nữ</Tag>
      default: return null
    }
  }

  return (
    <Card
      extra={permission(Permission.ChangePatientAccount) && <Button className="text-hover-primary" icon={<i className="fa-solid fa-user-plus"></i>} onClick={() => navigate('/patient/new')} >Thêm tài khoản</Button>}
      title={
        <Space>
          <Input style={{ maxWidth: '200pt' }} suffix={<i className="fa-solid fa-magnifying-glass text-muted"></i>} placeholder="Tên hoặc số điện thoại..." value={filter} onChange={handleFilter} />
          <Segmented options={[{ label: 'Tất cả', value: 'ALL' }, { label: 'Đang theo dõi', value: 'FOLLOW' }]} value={filterFollow} onChange={handleFilterFollow} />
        </Space>
      }
    >
      <ConfigProvider theme={{ components: { Table: { headerBg: '#fff' } } }}>
        <Table
          dataSource={accountList}
          rowKey="uid"
          pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
          onHeaderRow={() => ({ className: 'fs-7 text-uppercase' })}
          onRow={() => ({ className: 'text-gray-600 fs-7' })}
          size='small'
        >
          <Column title='Tài khoản' dataIndex="fullname" render={
            (_, record: any) =>
              <Space role="button" onClick={() => navigate(`/patient/id/${record.uid}`)}>
                <Avatar src={record.avatar ? record.avatar : '/media/avatars/blank.png'} size={48} style={{ marginRight: 5 }} />
                <Space.Compact direction='vertical' size='small'>
                  <Text className="fs-7 text-primary">{record.fullname}</Text>
                  <Text className="fs-7 text-gray-600">{record.phone ? formatPhone(record.phone) : 'Không có thông tin'}</Text>
                </Space.Compact>
              </Space>
          } />
          <Column title="Giới tính" dataIndex="gender" render={(value) => genderStyle(value)} />
          <Column title="Tuổi" dataIndex="birthday" render={(value) => value ? dayjs().diff(dayjs(value), 'year') : ''} />
          {/* <Column title="Bệnh lý" dataIndex="health" render={(value) => value?.pathological_medical_history && shortListPathologies(value.pathological_medical_history.split(';')).map((pathology) => <Tag key={pathology} color='blue' bordered={false}>{pathology}</Tag>)} /> */}
          <Column title="Kế hoạch sức khoẻ" dataIndex='careplan' render={(value) => value?.existed_careplan ? 'Đã thiết lập' : 'Chưa thiết lập'} />
          <Column title="Truy cập" dataIndex="last_online_at" render={(value) => getRelativeTime(value)} />
          <Column title="Thao tác" align="end" render={(_, record: any) =>
            <Space>
              {/* <Button className="text-hover-primary" icon={<i className="fa-solid fa-comments fs-5"></i>} disabled={!record.livechat.is_connected}/> */}
              <Button className="text-hover-primary" icon={<i className="fa-regular fa-pen-to-square fs-5"></i>} onClick={() => navigate(`/patient/id/${record.uid}`)} />
              {/* <Button 
                className="text-hover-primary" 
                icon={<i className="fa-regular fa-note-sticky fs-5"></i>} 
                onClick={() => navigate(
                  `/note/id/${record.uid}`,
                  { 
                    state: {
                      'uid': `${record.uid}`,
                      'fullname': `${record.fullname}`
                    },
                  }
                )} 
              /> */}
            </Space>
          }
          />
        </Table>
      </ConfigProvider>
    </Card >
  )
}
