import { useOutletContext, useParams } from "react-router-dom"
import { ChatWidget } from "./widget/ChatWidget"

export const ChatWidgetWrapper = () => {
  const { uid } = useParams()
  const [fetchCounter] = useOutletContext() as any[]

  return (
    <ChatWidget conversation={uid} fetchCounter={fetchCounter} />
  )
}