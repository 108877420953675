import { Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import {
  BannerListPage
} from 'app/modules/apps/banner/BannerListPage'

import {
  NewBanner
} from 'app/modules/apps/banner/NewBanner'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Banner',
    path: '/banner/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const BannerRoutes = () => {
  return (
    <Routes>
      <Route
        element={<><PageTitle breadcrumbs={profileBreadCrumbs} /><Outlet /></>}
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle>Danh sách banner</PageTitle>
              <BannerListPage />
            </>
          }
        />

        <Route
          path='new'
          element={
            <>
              <PageTitle>Thêm mới banner</PageTitle>
              <NewBanner />

            </>
          }
        />


      </Route>


    </Routes >
  )
}
