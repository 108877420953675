import { Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { AppointmentListPage } from './AppointmentListPage'
import { AppointmentPage } from './AppointmentPage'
import { CalendarPage } from './CalendarPage'
import { PendingTaskPage } from './PendingTaskPage'
import { WorkCalendar } from './workcalendar/WorkCalendar'

export const CalendarRoutes = () => {
  return (
    <Routes>
      <Route
        path='appointment'
        element={
          <>
            <PageTitle>Lịch hẹn bệnh nhân</PageTitle>
            <CalendarPage />
          </>
        }
      />
      <Route
        path='pending'
        element={
          <>
            <PageTitle>Công việc cần thực hiện</PageTitle>
            <PendingTaskPage />
          </>
        }
      />
      <Route
        path='list'
        element={
          <>
            <PageTitle>Danh sách lịch hẹn</PageTitle>
            <AppointmentListPage />
          </>
        }
      />
      <Route
        path='work'
        element={
          <>
            <PageTitle>Lịch làm việc</PageTitle>
            <WorkCalendar />
          </>
        }
      />
      <Route
        path='id/:uid/*'
        element={<AppointmentPage />}
      />
    </Routes >
  )
}
