import {FC, useState, createContext, useContext, useCallback, useEffect} from 'react'

import {WithChildren} from '_metronic/helpers/react18MigrationHelpers'

import {callGeneric} from 'app/modules/apps/patient-management/calendar/core/_requests'

import axios from 'axios'

import {
  SHARE_DATA_PERMISSION_STATUS,
  SHARE_DATA_REQUEST_LIST,
  /* SHARE_DATA_REQUEST_LIST,
   * SHARE_DATA_PERMISSION_REQUEST,
   * SHARE_DATA_PERMISSION_CANCEL,*/
} from 'app/modules/helpers/Common'

const PatientContext = createContext<any>({})

const usePatient = () => useContext(PatientContext)

const PatientProvider: FC<WithChildren> = ({children}) => {
  const [shareDataAllowed, setShareDataAllowed] = useState(undefined)

  const [account, setAccount] = useState<any>()

  const [requestStatus, setRequestStatus] = useState({})

  const reloadShareDataPermission = useCallback(
    async ({account}) => {
      if (!account?.uid) return

      const res = await callGeneric(
        axios({
          method: 'get',
          params: {
            target_user_id: account.uid,
          },

          url: SHARE_DATA_PERMISSION_STATUS,
        })
      )

      const {is_read} = res

      if (is_read !== undefined) {
        /* debugger;*/
        setShareDataAllowed(is_read)
      }
    },
    [setShareDataAllowed]
  )

  const getRequestedList = useCallback(
    async ({account}) => {
      if (!account?.uid) return

      const res = await callGeneric(
        axios({
          method: 'get',
          params: {
            target_user_id: account.uid,
          },

          url: SHARE_DATA_REQUEST_LIST,
        })
      )

      setRequestStatus(res.find((e) => e.status === 'REQUESTING'))

      return res
    },
    [setRequestStatus]
  )

  const reload = useCallback(async () => {
    if (account?.uid) {
      getRequestedList({account})
      reloadShareDataPermission({account})
    }
  }, [reloadShareDataPermission, getRequestedList, account])

  useEffect(() => {
    if (account?.uid) {
      reloadShareDataPermission({account})
      getRequestedList({account})
    }
  }, [account, reloadShareDataPermission, getRequestedList])

  return (
    <PatientContext.Provider
      value={{
        shareDataAllowed,
        setShareDataAllowed,

        reload,
        account,
        setAccount,

        requestStatus,
        setRequestStatus,

        reloadShareDataPermission,
      }}
    >
      {children}
    </PatientContext.Provider>
  )
}

export {PatientContext, usePatient, PatientProvider}
