import { Form, Input, Modal } from "antd"
import { DEPARTMENT_API } from "app/modules/helpers/Common"
import axios from "axios"

export const DepartmentAddModal = ({ org, open, close }) => {
    const [form] = Form.useForm()

    const handleOk = () => {
        form.validateFields().then(
            values => {
                axios.post(DEPARTMENT_API, {
                    ...values,
                    target_org_id: org.uid
                }).then(response => {
                    console.log(response.data)
                    if (response.data.success) {
                        close(true)
                    }
                })
            })
    }

    const handleCancel = () => {
        close(false)
    }

    return (
        <Modal
            title='Thêm chuyên khoa mới'
            open={open}
            centered style={{ maxWidth: '500pt' }}
            onOk={handleOk}
            onCancel={handleCancel}
            okText='Thêm'
            cancelText='Huỷ'
            destroyOnClose={true}
            styles={{ body: { marginTop: 20, marginBottom: 35 } }}
        >
            <Form
                form={form}
                wrapperCol={{ span: 18 }}
                labelCol={{ span: 6 }}
                preserve={false}
            >
                <Form.Item name='name' label='Tên khoa' rules={[{ required: true, whitespace: true, message: 'Bắt buộc điền' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name='description' label='Mô tả'>
                    <Input.TextArea />
                </Form.Item>
            </Form>
        </Modal>
    )
}
