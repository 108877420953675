import {FC, createContext, useContext, useState, useEffect, useCallback} from 'react'
// import {useQuery} from 'react-query'

import {
  // createResponseContext,
  // initialQueryResponse,
  // initialQueryState,
  // PaginationState,
  // QUERIES,
  // stringifyRequestQuery,
  WithChildren,
} from '_metronic/helpers'

import {useQueryRequest} from 'app/modules/apps/patient-management/calendar/core/QueryRequestProvider'

import {getAppointmentWithExtraInfo} from 'app/modules/apps/patient-management/calendar/core/_requests'

// @ts-ignore
/* const QueryResponseContext = createResponseContext({});*/
const QueryResponseContext = createContext({})
const QueryResponseProvider: FC<WithChildren> = ({children}) => {
  const {state = {}} = useQueryRequest()

  const [query] = useState<string>(new URLSearchParams(state as any).toString())

  const [response, setResponse] = useState<any>({})

  /* const [query, useQuery] = useState<string>('');*/

  /* const response = getGeneric(GET_APPOINTMENT_LIST_URL, state);*/

  const refetch = useCallback(async () => {
    if (state?.page) {
      const res = await getAppointmentWithExtraInfo({state})
      setResponse(res)
    }
  }, [state])

  useEffect(() => {
    refetch()
  }, [state, refetch])

  /* debugger;*/

  return (
    <QueryResponseContext.Provider value={{refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = (): any => useContext(QueryResponseContext)

export {QueryResponseProvider, useQueryResponse}
