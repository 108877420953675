import {useEffect, useState} from 'react'

import {Card, Image, Typography, Flex, Button} from 'antd'
import clsx from 'clsx'

import axios from 'axios'

import {MEDIA_CONVERT_URL} from 'app/modules/helpers/Common'

import {callGeneric} from 'app/modules/apps/patient-management/calendar/core/_requests'

import {FilePreview} from 'app/modules/apps/patient-management/calendar/calendar'

import {guessFileMime} from 'utils/functional/misc'

const {Paragraph} = Typography

export const MessageContent = ({message, color}) => {
  let isImageOnly = message.message.text.length === 0 && message.message.attachments.url
  // let enterHealthResult = () => {
  //   console.log("enterHealth result")
  // }
  return (
    <Card
      className={clsx('rounded', `bg-light-${color}`, 'text-dark mw-lg-500px mb-1')}
      size='small'
      bodyStyle={{paddingBlock: 5, display: message.message.text.length > 0 ? 'block' : 'none'}}
      // hoverable={message.source === 'USER_TO_OA' ? true : false}
      style={{overflow: 'hidden'}}
      cover={
        message.message.attachments.url ? (
          <Image
            alt='Pic'
            src={
              message.message.attachments.thumb
                ? message.message.attachments.thumb
                : message.message.attachments.url
            }
            preview={{src: message.message.attachments.url}}
            style={{
              maxHeight: isImageOnly ? 160 : 0,
              maxWidth: isImageOnly ? 160 : 0,
              overflow: 'hidden',
            }}
          />
        ) : null
      }
    >
      {/* {message.message.attachments.url ?
            <Image
              alt='Pic'
              src={message.message.attachments.thumb}
              preview={{ src: message.message.attachments.url }}
            /> : null
          } */}
      {message.message.text.length > 0 && (
        <Paragraph style={{marginBottom: 0}} className='fs-7'>
          {message.message.text}
          {/* {(Object.keys(message.ocr_result).length !== 0) && <a style={{ marginBottom: 0, marginLeft: 8}} className="fs-7" onClick={enterHealthResult} href="#">
          Ghi nhận chỉ số()
        </a>} */}
        </Paragraph>
      )}
    </Card>
  )
}

export const MessageContentV2 = ({message, color, onLoad = () => {}}) => {
  const [urlList, setURLList] = useState([])

  let isImageOnly = message.message.text.length === 0 && message.message.attachments.url.length

  const linePattern = /(\r\n|\n)/

  const textList = message.message.text
    .split(linePattern)
    .filter((e) => !e.match(linePattern))
    .map((e) => {
      return <div>{e}</div>
    })

  // let enterHealthResult = () => {
  //   console.log("enterHealth result")
  // }

  useEffect(() => {
    const getURL = async () => {
      const ul = message?.message?.attachments?.url
      if (ul?.length) {
        const res = await callGeneric(
          axios({
            method: 'get',
            params: {
              list_path: JSON.stringify(ul),
            },
            url: MEDIA_CONVERT_URL,
          })
        )

        if (res?.length) {
          setURLList(res)

          setTimeout(() => {
            onLoad()
          }, 200)
        }
      }
    }
    getURL()
    setTimeout(() => {
      onLoad()
    }, 200)
  }, [message, onLoad])

  return (
    <Card
      className={clsx('rounded', `bg-light-${color}`, 'text-dark mw-lg-300px mb-1')}
      size='small'
      bodyStyle={{paddingBlock: 5, display: message.message.text.length > 0 ? 'block' : 'none'}}
      // hoverable={message.source === 'USER_TO_OA' ? true : false}
      style={{overflow: 'hidden'}}
      cover={
        urlList?.length ? (
          <Flex
            style={{display: 'grid', gap: '0.5rem'}}
            className={clsx('tw-grid tw-items-start', {
              'tw-grid-cols-1': urlList?.length === 1,
              'tw-grid-cols-2': urlList?.length === 2,
              'tw-grid-cols-3': urlList?.length > 2,
            })}
          >
            {urlList.map((url) => {
              const fmime = guessFileMime(url)

              let text = (
                <Image
                  alt='Pic'
                  src={url}
                  preview={{}}
                  style={{
                    height: isImageOnly ? '100%' : 0,
                    width: isImageOnly ? 160 : 0,
                    overflow: 'hidden',
                  }}
                  onLoad={() => {
                    setTimeout(() => {
                      onLoad()
                    }, 200)
                  }}
                />
              )

              ;<Button></Button>
              if (fmime.includes('video')) {
                text = (
                  <Button>
                    <i className='bi bi-file-play'></i>
                  </Button>
                )
              }

              if (fmime.includes('file')) {
                text = (
                  <Button>
                    <i className='bi bi-file-earmark-text'></i>
                  </Button>
                )
              }

              return (
                <>
                  {fmime.includes('image') ? (
                    text
                  ) : (
                    <FilePreview url={url} text={text}></FilePreview>
                  )}
                </>
              )
            })}
          </Flex>
        ) : null
      }
    >
      {message.message.text.length > 0 && (
        <Paragraph style={{marginBottom: 0}} className='fs-7'>
          {textList}
        </Paragraph>
      )}
    </Card>
  )
}
