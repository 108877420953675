import { Button, Card, ConfigProvider, List, Row, Tabs } from "antd"
import { NotificationItem } from "app/modules/apps/notification/NotificationItem"
import {
  NOTIFICATION_API,
  NOTIFICATION_MARK_SEEN_ALL_API,
  NOTIFICATION_MARK_SEEN_API,
  NOTIFICATION_UNREAD_LIST_API,
  getRelativeTime,
  APPOINTMENT_DETAIL_API,
} from 'app/modules/helpers/Common'
import axios from "axios"
import clsx from "clsx"
import { useEffect, useReducer, useState } from "react"
import { useNavigate } from "react-router-dom"

export const NotificationMenu = ({ close }) => {
  const navigate = useNavigate()
  const [notificationList, setNotificationList] = useState([] as any[])
  const [mode, setMode] = useState('all' as 'all' | 'unread')
  const [fetchCounter, forceRefresh] = useReducer(x => x + 1, 0);
  // const [page, setPage] = useState(1)
  // const [maxPage, setMaxPage] = useState(1)

  useEffect(() => {
    axios.get(mode === 'all' ? NOTIFICATION_API : NOTIFICATION_UNREAD_LIST_API, { params: { page: 1 } })
      .then(response => response.data.data)
      .then(data => {
        setNotificationList(data.items)
        // setMaxPage(data.max_page)
      })

  }, [mode, fetchCounter])

  const handleSeenAll = () => {
    axios.put(NOTIFICATION_MARK_SEEN_ALL_API)
      .then(() => {
        forceRefresh()
      })
  }

  return (
    <Card
      bordered={false}
      actions={[<Row><Button className="ms-auto me-8" onClick={() => {
        navigate('/notification/list')
        close()
      }
      }>Tất cả thông báo</Button></Row>]}
      style={{ minWidth: 500 }}
      styles={{ body: { padding: 0 } }}
    >
      <ConfigProvider
        theme={{
          components: {
            List: {
              titleMarginBottom: 0,
              metaMarginBottom: 4,
            },
          },
        }}
      >
        <Tabs
          tabBarStyle={{ paddingInline: 24, marginBottom: 0 }}
          tabBarExtraContent={<Button onClick={handleSeenAll}>Đã đọc tất cả</Button>}
          defaultActiveKey="all"
          items={[{
            key: 'all',
            label: 'Tất cả',
          }, {
            key: 'unread',
            label: 'Chưa đọc',
          }]}
          onChange={(key) => setMode(key as 'all' | 'unread')}
        />
        <List
          itemLayout="vertical"
          dataSource={notificationList}
          style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto' }}
          // size='small'
          locale={{ emptyText: 'Không có thông báo nào' }}
          renderItem={(item) =>
            <List.Item
              role="button"
              onClick={() => {
                if (item.entity_action === 'BOOKING_SUCCESS' || item.entity_action === 'UPLOAD_TEST_RESULT' || item.entity_action === 'BOOKING_CHANGE_TIME') {
                  axios.put(NOTIFICATION_MARK_SEEN_API, { notification_ids: [item.uid] })
                    .then(() => {
                      axios.get(APPOINTMENT_DETAIL_API, { params: { appointment_id: item.entity_id } }).then(appointmentRes => {
                        if (appointmentRes.data.success) {
                          close()
                          navigate(`/calendar/id/${item.entity_id}`)
                        }
                      })
                    })
                } else if (item.entity_action === 'COMPLETED_CAREPLAN') {

                  const { actor_user_profile: { uid } } = item;

                  navigate(`patient/id/${uid}/careplan`)
                }
              }}
              className={clsx({ "bg-light": item.is_seen })}
              styles={{ actions: { marginTop: 0 } }}
              actions={[
                <span className="fs-8 text-gray-600">{getRelativeTime(item.created_at)}</span>,
                <span className="fs-8 text-gray-600">Hệ thống</span>,
                item.is_seen ? <span className="fs-8 text-gray-600">Đã xem</span> :
                  <Button className="fs-8" size="small" type='default' icon={<i className="fa-solid fa-check"></i>} iconPosition='end'
                    onClick={(e) => {
                      axios.put(NOTIFICATION_MARK_SEEN_API, { notification_ids: [item.uid] })
                        .then(() => {
                          forceRefresh()
                        })
                      e.stopPropagation()
                    }}
                  >Đánh dấu đã xem</Button>
              ]}
              style={{ paddingInline: 24, paddingBlock: 8 }}>
              <NotificationItem item={item} />
            </List.Item>
          }
        />
      </ConfigProvider>
    </Card>
  )
}
