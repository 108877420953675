const unique = (arr: any[], key = (e: any) => e) => {
  const s = new Set()
  const res: any[] = []

  arr.forEach((e: any) => {
    const k = key(e)
    if (!s.has(k)) {
      res.push(e)
      s.add(k)
    }
  })

  return res
}

const group = (arr, size) => {
  let res: any[] = []
  let current = arr

  while (current.length) {
    const g = current.slice(0, size)

    current = current.slice(size)
    res.push(g)
  }

  return res
}

const guessFileMime = (fn) => {
  let res = 'file'

  const imgPattern = /\.(jpg|png|jpeg|gif)/i
  const vidPattern = /\.(mp4)/i

  if (!fn?.match) debugger

  if (fn.match(imgPattern)) res = 'image'
  if (fn.match(vidPattern)) res = 'video'

  return res
}

export {unique, group, guessFileMime}
