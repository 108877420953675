import { DatePicker, Form, Input, InputNumber, Modal, Select, TimePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import dayjs from "dayjs";
import { EMPLOYEE_API, CAREPLAN_API, getReferenceDescription, getReferenceType, mapReferenceType } from "../../../helpers/Common";
import { useEffect, useState } from "react";
import updateLocale from "dayjs/plugin/updateLocale";
import Vietnamese from 'dayjs/locale/vi';

const { Option } = Select;
const { RangePicker } = DatePicker;

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { ...Vietnamese })

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const planTypeOptions = ["EXERCISE", "SLEEP", "NUTRITION", "DRUG", "MEASUREMENT", "OTHER"].map(item => ({ label: getReferenceDescription(item), value: item }))

export const CareplanForm = ({ isModalOpen, setModal, account, forceUpdate }) => {
  const [doctorList, setDoctorList] = useState([] as any[]);
  const [filterTerm, setFilterTerm] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    axios.get(EMPLOYEE_API, { params: { page: 1, role: '["DOCTOR"]', fullname: filterTerm, contact: filterTerm, status: 'ACTIVE' } })
      .then(response => response.data.data.items)
      .then(data => {
        let doctors = data.map(item => {
          return {
            value: item.uid,
            label: `${item.fullname} (${item.email})`,
          }
        })
        setDoctorList(doctors);
      })
  }, [filterTerm])

  const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const onFinish = (values: any) => {
    console.log(values);
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
    setFilterTerm(value)
  };

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        console.log(values);
        axios.post(CAREPLAN_API, {
          plan_name: values.plan_name,
          plan_type: values.plan_type,
          description: values.description,
          period: values.period,
          reference_type: values.reference_type,
          target_value: values.target_value,
          target_user_id: account.uid,
          frequency: 1,
          notice_at: values.notice_at.format('HH:mm:ss'),
          local_start_date: values.period === 'WEEK' ? values.duration[0].day(1).format('YYYY/MM/DD') : values.period === 'MONTH' ? values.duration[0].date(1).format('YYYY/MM/DD') : values.duration[0].format('YYYY/MM/DD'),
          local_end_date: values.period === 'WEEK' ? values.duration[1].day(0).format('YYYY/MM/DD') : values.period === 'MONTH' ? values.duration[1].date(values.duration[1].daysInMonth()).format('YYYY/MM/DD') : values.duration[1].format('YYYY/MM/DD'),
          mentor_id: values.mentor,
        }).then(response => {
          console.log(response);
          forceUpdate();
        })
        form.resetFields();
        setModal(false);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });

  };

  const handleCancel = () => {
    form.resetFields();
    setModal(false);
    setFilterTerm('');
  };

  return (
    <Modal title="Thêm kế hoạch mới" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} okText="Tạo kế hoạch" cancelText="Huỷ">

      <Form
        {...layout}
        initialValues={{
          period: 'WEEK',
          plan_type: 'EXERCISE',
          // reference_type: 'STEP',
          target_value: 1,
          notice_at: dayjs(9 * 60 * 60 * 1000),
          description: '',
        }}
        form={form}
        // name="control-hooks"
        onFinish={onFinish}
      >
        <Form.Item name="plan_name" label="Tên kế hoạch" rules={[{ required: true, message: "Bắt buộc điền tên kế hoạch" }]}>
          <Input />
        </Form.Item>

        <Form.Item name="mentor" label="Bác sỹ hỗ trợ" rules={[{ required: true, message: "Bắt buộc điền bác sỹ hỗ trợ" }]}>
          <Select options={doctorList} showSearch filterOption={filterOption} onSearch={onSearch} />
        </Form.Item>

        <Form.Item name="plan_type" label="Loại kế hoạch" rules={[{ required: true, message: "Bắt buộc điền loại kế hoạch" }]}>
          <Select placeholder="Chọn loại kế hoạch" onChange={() => { form.setFieldValue('reference_type', null) }} options={planTypeOptions} />
        </Form.Item>

        <Form.Item name="description" label="Mô tả kế hoạch">
          <TextArea rows={4} />
        </Form.Item>

        <Form.Item name="period" label="Lặp lại" rules={[{ required: true, message: "Bắt buộc điền chu kỳ" }]}>
          <Select placeholder="Chọn chu kỳ">
            <Option value="DAY">Hàng ngày</Option>
            <Option value="WEEK">Hàng tuần</Option>
            <Option value="MONTH">Hàng tháng</Option>
          </Select>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.period !== currentValues.period}>
          {({ getFieldValue }) =>
            <Form.Item name="duration" label="Thời gian áp dụng" rules={[{ required: true, message: "Bắt buộc điền thời gian" }]}>
              <RangePicker picker={getFieldValue('period').toLowerCase().replace('day', 'date')} />
            </Form.Item>
          }
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.plan_type !== currentValues.plan_type}>
          {({ getFieldValue }) =>
            <Form.Item name="reference_type" label="Lấy thông tin" rules={[{ required: true, message: "Bắt buộc điền thông tin" }]}>
              <Select placeholder="Chọn thông tin">
                {getReferenceType(getFieldValue('plan_type')).map(item => <Option key={item} value={item}>{mapReferenceType(item)}</Option>)}
              </Select>
              {/* <Select placeholder="Chọn thông tin">

            <Option value="OTHER">Thủ công</Option>
            <Option value="STEP">Bước chân</Option>
            <Option value="BLOOD_GLUCOSE">Đường huyết</Option>
            <Option value="SLEEP">Thời gian ngủ</Option>
          </Select> */}
            </Form.Item>}
        </Form.Item>

        <Form.Item name="target_value" label="Giá trị mục tiêu" rules={[{ required: true, message: "Bắt buộc điền giá trị mục tiêu" }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item name="notice_at" label="Nhắc nhở" rules={[{ required: true, message: "Bắt buộc điền thời gian nhắc nhở" }]}>
          <TimePicker minuteStep={15} showSecond={false} showNow={false} />
        </Form.Item>
      </Form>
    </Modal>

  )
}