import moment from 'moment'

// Trie data structure for searching
//
//

export const trie = (value?: any, children?: any): any => {
  return {
    value,
    children: children ? children : {},
  }
}

// const  lookup(trie(undefined, children), []) = raise NotFound
// | lookup(undefined, []) = raise NotFound
// | lookup(trie(value,  children), []) = value
// | lookup(trie(value, children), k::ks) = lookup(children[k], ks)
//
//
//

export const lookup = (trie: any, ...path: any[]): any => {
  if (!trie) {
    // throw 'not found'
    return null
  }

  if (path.length === 0) return trie
  else {
    return lookup(trie.children[path[0]], ...path.slice(1))
  }
}

export const lookupValue = (trie: any, ...path: any[]): any => {
  const res = lookup(trie, ...path)

  if (res?.value) return res.value
  else return res
}

// const insert(trie(_, children), x, []) = trie(x, children)
// | insert(trie(v, children), x, k::ks) =
//     let t = children[k] handle null => empty :: trie(undefined)
//     insert(t, x, ks)
//
//

export const insert = (t: any, value: any, ...path: any[]): any => {
  if (path.length === 0) return trie(value, t.children)
  else {
    const k = path[0]
    const ks = path.slice(1)

    let node = t.children[k]
    if (!node) node = trie(null)

    return trie(t.value, {
      ...t.children,
      [k]: insert(node, value, ...ks),
    })
  }
}

// var a = trie(12)
// var b = insert(a, 23, 'user-management', 'form', 'phone')
// console.log(JSON.stringify(b, null, '\t'))

export const getObjValue = (obj: any, ...keyList: any[]): any => {
  if (!obj) return obj

  if (keyList.length === 0) return obj

  const k = keyList[0]
  const ks = keyList.slice(1)

  return getObjValue(obj[k], ...ks)
}

// Patch value to object given key list/dataPath and new value
// patchObjValue:: obj -> keyList -> value -> patchedObj
//
// const patchObjValue(obj, k, x) -> k x
// | patchObjValue(undefined, k::ks, x) -> undefined
// | patchObjValue(obj, k::ks, x) -> merge obj + patchObjValue(obj[k], ks, x)
//

export const patchObjValue = (obj: any, value: any, ...keyList: any[]): any => {
  // console.log('debug ', value, keyList, obj);
  if (!obj) return value

  // if(keyList.length === 1) return { ...obj,
  //                                   [keyList[0]]: value};

  if (keyList.length === 0) return value

  const k = keyList[0]
  const ks = keyList.slice(1)

  // const t = obj[k]
  const next = patchObjValue(obj[k], value, ...ks)

  // Merge patch
  //
  //
  if (Array.isArray(obj)) {
    // const res = patchObjValue(obj[k], value, ks)

    // console.log('debug ', value, keyList)

    return [...obj.slice(0, k), next, ...obj.slice(k + 1)]
  } else {
    return {...obj, [k]: next}
  }
}

// Patch value in patchObj to original data
//
//
export const patchObjectValueWithStore = (pathStore: any, patchObj: any, data: any) => {
  let update = false
  let patchedData = data

  for (const k in patchObj) {
    let value = patchObj[k]

    if (k === 'password') continue
    if (value === 'nop') continue

    const keyList = pathStore[k]
    console.log('debug ', k, keyList, value, data)
    let currentValue = getObjValue(data, ...keyList) || ''

    if (k.toLowerCase().includes('dob')) {
      value = moment(value, 'DD/MM/YYYY').format('YYYY/MM/DD')
      currentValue = moment(currentValue).format('YYYY/MM/DD')
    }

    if (value !== currentValue) {
      console.log('debug updated ', value, '_ ', currentValue, keyList)

      patchedData = patchObjValue(patchedData, value, ...keyList)
      update = true
    }

    if (value === currentValue && k.toLowerCase().includes('dob'))
      patchedData = patchObjValue(patchedData, value, ...keyList)
  }

  return {update, patchedData}
}
