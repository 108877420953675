import { Route, Routes } from 'react-router-dom'
import { DoctorReportPage } from './DoctorReportPage'

export const ReportRoutes = () => {
  return (
    <Routes>
      <Route
        path='/doctor/id/:uid/*'
        element={<DoctorReportPage />}
      />
    </Routes >
  )
}
