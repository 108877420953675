import { Button, Card, DatePicker, Segmented, Space } from "antd"
import { APPOINTMENT_API } from "app/modules/helpers/Common"
import axios from "axios"

import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { CalendarDay } from "./CalendarDay"
import { CalendarWeek } from "./CalendarWeek"


export const CalendarPage = () => {
  const [appointments, setAppointments] = useState([] as any[])
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [mode, setMode] = useState('week' as 'week' | 'date')
  const [zoomMode, setZoomMode] = useState('large' as 'small' | 'large')

  useEffect(() => {
    let from_date = currentDate.startOf('week').format('YYYY/MM/DD HH:mm:ss')
    let to_date = currentDate.endOf('week').format('YYYY/MM/DD HH:mm:ss')

    if (mode === 'date') {
      from_date = currentDate.startOf('day').format('YYYY/MM/DD HH:mm:ss')
      to_date = currentDate.endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }

    axios.get(APPOINTMENT_API, {
      params: {
        page: 1,
        per_page: 1000,
        from_datetime: from_date,
        to_datetime: to_date
      }
    }).then(response => {
      setAppointments(response.data.data.items)
    })
  }, [currentDate, mode])

  useEffect(() => {
    console.log(appointments)
  }, [appointments])

  return (
    <Card style={{ padding: 0 }}
      styles={{
        body: {
          padding: 0,
          maxHeight: '100%',
          height: 'calc(100vh - 255px)',
          display: 'flex',
          flexDirection: 'column',
        }
      }}
      title={
        <Space>
          <Button onClick={() => setCurrentDate(dayjs())}>Hôm nay</Button>
          <Button icon={<i className="fa-solid fa-angle-left"></i>} onClick={() => {
            if (mode === 'date') {
              setCurrentDate(currentDate.add(-1, 'day'))
            } else if (mode === 'week') {
              setCurrentDate(currentDate.add(-1, 'week'))
            }
          }} />
          <DatePicker picker={mode} value={currentDate} onChange={(date) => setCurrentDate(date)} allowClear={false} />
          <Button icon={<i className="fa-solid fa-angle-right"></i>} onClick={() => {
            if (mode === 'date') {
              setCurrentDate(currentDate.add(1, 'day'))
            } else if (mode === 'week') {
              setCurrentDate(currentDate.add(1, 'week'))
            }
          }} />
          {mode === 'week' &&
            <Button
              icon={(zoomMode === 'small' ? <i className="fa-solid fa-up-right-and-down-left-from-center"></i> : <i className="fa-solid fa-down-left-and-up-right-to-center"></i>)}
              onClick={() => setZoomMode(zoomMode === 'small' ? 'large' : 'small')}
            ></Button>
          }
        </Space>
      }
      extra={
        <Segmented
          options={[
            { label: 'Ngày', value: 'date' },
            { label: 'Tuần', value: 'week' },
          ]}
          value={mode}
          onChange={(value) => {
            setMode(value as any)
            setCurrentDate(dayjs())
          }}
        />}
    >
      {mode === 'date' && <CalendarDay appointments={appointments} />}
      {mode === 'week' && <CalendarWeek appointments={appointments} currentDate={currentDate} zoomMode={zoomMode} />}
    </Card >
  )
}