import { KTIcon } from "_metronic/helpers"
import { List } from "antd"
import clsx from "clsx"
import dayjs from "dayjs"

export const NotificationItem = ({ item }) => {
  let title = ""
  let description = <></>
  let icon = ""

  switch (item.entity_action) {
    case 'BOOKING_SUCCESS':
      icon = "calendar-add"
      title = 'Lịch hẹn mới'
      // <span style={{ display: 'flex', alignItems: 'center' }}><KTIcon iconName="calendar-add" className="fs-4 me-1 text-primary" /> Lịch hẹn mới</span>
      description = <>
        <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
        <span>{`đã đặt lịch hẹn tư vấn bệnh `}</span>
        <span className="text-primary">{item.data_changed.disease_name}</span>
        <span>{` vào`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.start_time * 1000).format('HH:mm')}</span >
        <span>{` ngày`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.start_time * 1000).format('DD/MM/YYYY')}</span >
      </>
      break
    case 'UPLOAD_TEST_RESULT':
      icon = "folder-added"
      title = 'Kết quả xét nghiệm mới'
      // <span style={{ display: 'flex', alignItems: 'center' }}><KTIcon iconName="folder-added" className="fs-4 me-1 text-primary" /> Kết quả xét nghiệm mới</span>
      description = <>
        <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
        <span>{`đã cập nhật kết quả dịch vụ cận lâm sàng cho cuộc hẹn tư vấn bệnh `}</span>
        <span className="text-primary">{item.data_changed.disease_name}</span>
        <span>{` ngày`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.start_time * 1000).format('DD/MM/YYYY')}</span >
      </>
      break
    case 'BOOKING_CHANGE_TIME':
      icon = "calendar-edit"
      title = 'Thay đổi lịch hẹn'
      // <span style={{ display: 'flex', alignItems: 'center' }}><KTIcon iconName="folder-added" className="fs-4 me-1 text-primary" /> Kết quả xét nghiệm mới</span>
      description = <>
        <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
        <span>{`đã thay đổi lịch hẹn tư vấn bệnh `}</span>
        <span className="text-primary">{item.data_changed.disease_name}</span>
        <span>{` lúc`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.old_start_time * 1000).format('HH:mm')}</span >
        <span>{` ngày`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.old_start_time * 1000).format('DD/MM/YYYY')}</span >
        <span>{` sang`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.start_time * 1000).format('HH:mm')}</span >
        <span>{` ngày`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.start_time * 1000).format('DD/MM/YYYY')}</span >
      </>
      break
    case 'BOOKING_CHANGE_DOCTOR':
      icon = "calendar-edit"
      title = 'Thay đổi bác sỹ'
      // <span style={{ display: 'flex', alignItems: 'center' }}><KTIcon iconName="folder-added" className="fs-4 me-1 text-primary" /> Kết quả xét nghiệm mới</span>
      description = <>
        <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
        <span>{`đã thay đổi lịch hẹn tư vấn bệnh `}</span>
        <span className="text-primary">{item.data_changed.disease_name}</span>
        <span>{` lúc`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.start_time * 1000).format('HH:mm')}</span >
        <span>{` ngày`}</span>
        <span className="text-primary" > {dayjs(item.data_changed.start_time * 1000).format('DD/MM/YYYY')}</span >
        <span>{` từ bác sỹ `}</span>
        <span className='text-primary'>{item.data_changed.old_doctor_name}</span>
        <span>{` sang bác sỹ `}</span>
        <span className='text-primary'>{item.data_changed.doctor_name}</span>
        <span>{`. Lý do thay đổi bác sỹ: `}</span>
        <span className='text-primary'>{item.data_changed.reason_change || 'Không'}</span>
      </>
      break

    case 'COMPLETED_CAREPLAN':
      icon = "questionnaire-tablet"
      title = 'Hoàn thành kế hoạch sức khoẻ'
      // <span style={{ display: 'flex', alignItems: 'center' }}><KTIcon iconName="questionnaire-tablet" className="fs-4 me-1 text-primary" /> Kết quả xét nghiệm mới</span>
      description = <>
        <span className="text-primary">{`${item.actor_user_profile.fullname} `}</span>
        <span>{`đã hoàn thành kế hoạch `}</span>
        <span className="text-primary">{item.data_changed.plan_name}</span>

      </>
      break
  }

  return <List.Item.Meta
    // avatar={<Avatar src={item.actor_user_profile.avatar ? item.actor_user_profile.avatar : undefined} shape='square' size={64} icon={<i className="fa-solid fa-user fa-fw fs-5"></i>} />}
    avatar={
      <div style={{ width: 64, height: 64, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <KTIcon iconName={icon} className={clsx("fs-1 fs-4x", item.is_seen ? "text-gray-600" : "text-primary")} />
      </div>}
    title={<span className="fs-6">{title}</span>}
    description={<span className="fs-6">{description}</span>
    }
  />
}