import {FC, useState, createContext, useContext, useEffect} from 'react'

import {WithChildren} from '_metronic/helpers/react18MigrationHelpers'

import {useAuth} from 'app/modules/auth/core/Auth'

import dayjs from 'dayjs'
import {useIntl} from 'react-intl'

const CalendarContext = createContext<any>({})

const CalendarProvider: FC<WithChildren> = ({children}) => {
  const intl = useIntl()

  const translate = (id, extra = {}) => intl.formatMessage({id, ...extra})

  const {currentUser} = useAuth()

  const [showFile, setShowFile] = useState(false)

  const [fileURL, setFileURL] = useState('/media/avatars/blank.png')

  const [appointmentListPage, setAppointmentListPage] = useState(1)

  const [detailBookingInfo, setDetailBookingInfo] = useState({})

  const [appointmentStatusList, setAppointmentStatusList] = useState([
    {value: 'PAYMENT_PROCESS', label: translate('PAYMENT_PROCESS')},
    {value: 'PAYMENT_FAIL', label: translate('PAYMENT_FAIL')},
    {value: 'PAYMENT_SUCCESS', label: translate('PAYMENT_SUCCESS')},
    {value: 'WAITING_CONCLUSION', label: translate('WAITING_CONCLUSION')},
    {value: 'WAITING_TEST_RESULT', label: translate('WAITING_TEST_RESULT')},
    {value: 'SUBMITTED_TEST_RESULT', label: translate('SUBMITTED_TEST_RESULT')},
    {value: 'MISSING_CALL', label: translate('MISSING_CALL')},
    {value: 'FINISHED', label: translate('FINISHED')},
  ])

  const [statusSelectedList, setStatusSelectedList] = useState<any[]>([])

  const [organizationFilterList, setOrganizationFilterList] = useState([])
  const [organizationSelectedList, setOrganizationSelectedList] = useState([])

  const [doctorFilterList, setDoctorFilterList] = useState([])
  const [doctorSelectedList, setDoctorSelectedList] = useState([])

  const [patientFilterList, setPatientFilterList] = useState([])
  const [patientSelectedList, setPatientSelectedList] = useState([])

  const [icdFilterList, setIcdFilterList] = useState([])
  const [icdSelectedList, setIcdSelectedList] = useState([])

  const [medicineListFilterList, setMedicineListFilterList] = useState([])
  const [medicineListSelectedList, setMedicineListSelectedList] = useState([])

  const [serviceListFilterList, setServiceListFilterList] = useState([])
  const [serviceListSelectedList, setServiceListSelectedList] = useState([])

  const start = dayjs(dayjs().format('YYYY/MM/DD 00:00:00'))
  const end = start.add(61, 'days').subtract(1, 'seconds')

  const [timeSelectedList, setTimeSelectedList] = useState([start, end])

  const [initialLoad, setInitialLoad] = useState(false)

  useEffect(() => {
    if (currentUser?.role && ['CUSTOMER_CARE', 'NURSE', 'DOCTOR'].includes(currentUser.role)) {
      const nl: any[] = appointmentStatusList.filter(
        (e) => !['PAYMENT_PROCESS', 'PAYMENT_FAIL'].includes(e.value)
      )

      if (nl.length !== appointmentStatusList.length) {
        setAppointmentStatusList(nl)
        setStatusSelectedList(nl.map((e) => e.value))
        setInitialLoad(true)
      }
    } else if (currentUser?.role) {
      setInitialLoad(true)
    }
  }, [currentUser, setStatusSelectedList, setAppointmentStatusList, appointmentStatusList])

  return (
    <CalendarContext.Provider
      value={{
        showFile,
        setShowFile,
        fileURL,
        setFileURL,
        detailBookingInfo,
        setDetailBookingInfo,

        appointmentStatusList,
        setAppointmentStatusList,
        statusSelectedList,
        setStatusSelectedList,

        doctorFilterList,
        setDoctorFilterList,
        doctorSelectedList,
        setDoctorSelectedList,
        timeSelectedList,
        setTimeSelectedList,

        organizationFilterList,
        setOrganizationFilterList,
        organizationSelectedList,
        setOrganizationSelectedList,

        patientFilterList,
        setPatientFilterList,
        patientSelectedList,
        setPatientSelectedList,

        icdFilterList,
        setIcdFilterList,
        icdSelectedList,
        setIcdSelectedList,

        medicineListFilterList,
        setMedicineListFilterList,
        medicineListSelectedList,
        setMedicineListSelectedList,

        serviceListFilterList,
        setServiceListFilterList,
        serviceListSelectedList,
        setServiceListSelectedList,

        initialLoad,
        setInitialLoad,

        appointmentListPage,
        setAppointmentListPage,
      }}
    >
      {children}
    </CalendarContext.Provider>
  )
}

const useCalendar = () => useContext(CalendarContext)

export {CalendarProvider, useCalendar}
