import { Route, Routes } from 'react-router-dom'
import { DoctorPage } from './DoctorPage'

export const DoctorRoutes = () => {
  return (
    <Routes>
      <Route
        path='id/:uid/*'
        element={<DoctorPage />}
      />
    </Routes>
  )
}
