import { Card, ConfigProvider, Input, Space, Table, Typography } from "antd"
import Column from "antd/es/table/Column"
import { PAYMENT_HISTORY_API, getPaymentMethodDescription, getPaymentStatusColor, getPaymentStatusDescription, getRelativeTime, getServiceDescription } from "app/modules/helpers/Common"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const pageSize = 10
const { Text } = Typography

export const PatientTransaction = ({ account }) => {

    const [page, setPage] = useState(1)
    const [transactionList, setTransactionList] = useState([] as any[])
    const [maxPage, setMaxPage] = useState(1)

    useEffect(() => {
        account && axios.get(PAYMENT_HISTORY_API, { params: { user_id: account.uid, page: page } })
            .then(response => response.data.data)
            .then(response => {
                setTransactionList(response.items)
                setMaxPage(response.max_page)
            })
    }, [account, page])

    return (
        account &&
        <Card
            title={
                <Space>
                    <Input style={{ maxWidth: '200pt' }} suffix={<i className="fa-solid fa-magnifying-glass text-muted"></i>} placeholder="Mã giao dịch..." />
                </Space>
            }
        >
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            headerBg: '#fff',
                            colorText: '#78829d',
                        }
                    },
                }}>
                <Table
                    dataSource={transactionList}
                    rowKey="uid"
                    pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
                    onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
                    onRow={() => ({ className: 'fs-7' })}
                    size='middle'
                >
                    <Column title="Mã giao dịch" dataIndex="uid" render={(value) => <Link to={``}>{value.slice(0, 10).toUpperCase()}</Link>} />
                    <Column title="Thời gian" dataIndex="transaction_time" render={(value) => getRelativeTime(value)} />
                    <Column title="Dịch vụ" dataIndex="type" render={(value) => getServiceDescription(value)} />
                    <Column title="Phương thức" dataIndex="method" render={(value) => getPaymentMethodDescription(value)} />
                    <Column title="Trạng thái" dataIndex="status" render={(value) => <Text type={getPaymentStatusColor(value)}>{getPaymentStatusDescription(value)}</Text>} />
                    <Column title="Số tiền" align="end" render={(_, record: any) => `${record.amount.toLocaleString()} ${record.currency}`} />
                </Table>
            </ConfigProvider>
        </Card>
    )
}