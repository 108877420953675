import React, {useEffect, useState} from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import viLocale from '@fullcalendar/core/locales/vi'
import './CalendarPlan.scss'
import {BusinessHoursInput, EventClickArg, EventInput} from '@fullcalendar/core'
import {AppointmentItem, WorkPlanData} from '../core/Model'
import dayjs from 'dayjs'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'

interface CalendarProps {
  calendarData: WorkPlanData[]
  appointment: AppointmentItem[]
  setCalendarDateStart: (date: Date) => void
  setCalendarDateEnd: (date: Date) => void
  calendarDateStart: Date
  calendarDateEnd: Date
}

export const CalendarPlan = (props: CalendarProps) => {
  const [businessHours, setBusinessHours] = useState<BusinessHoursInput>([])
  const [eventData, setEventData] = useState<EventInput[]>([])
  const navigate = useNavigate()
  const convertAppointmentToEventInput = (appointment: AppointmentItem) => {
    let localDay = dayjs(appointment.start_time * 1000).day()
    const event =  {
      title: appointment.title,
      startTime: moment(appointment.start_time * 1000).local().format('HH:mm'), // a start time (10am in this example)
      endTime: moment(appointment.end_time * 1000).local().format('HH:mm').replace('00:00', '23:59'),
      daysOfWeek: [localDay],
      extendedProps: {
        patientName: appointment.patient_info.fullname,
        appointmentUid: appointment.uid
      }
    } as EventInput
    return event
  }
  const patientName = 'patientName'

  useEffect(() => {
    const busHours: BusinessHoursInput = [{
      daysOfWeek: ['T2'], // Monday - Thursday
      startTime: '00:00', // a start time (10am in this example)
      endTime: '00:00', // an end time (6pm in this example)
    }]
    props.calendarData.forEach(workingHour => {
      workingHour.working_time.forEach(timer => {
        const utcTimeStart = moment.utc(timer.start * 1000).toDate()
        let localDay = dayjs(utcTimeStart).day()
        const utcTimeEnd = moment.utc(timer.end * 1000).toDate()
        busHours.push({
          daysOfWeek: [localDay], // Monday - Thursday
          startTime: moment(utcTimeStart).local().format('HH:mm'), // a start time (10am in this example)
          endTime: moment(utcTimeEnd).local().format('HH:mm').replace('00:00', '23:59'), // an end time (6pm in this example)
        })
      })
    })
    setBusinessHours(busHours)
    setEventData(props.appointment.map(item => convertAppointmentToEventInput(item)))
  }, [props.calendarData, props.appointment])
  const handleEventClick = (eventClickInfo: EventClickArg) => {
    console.log(eventClickInfo.event._def.extendedProps['appointmentUid'])
    navigate('/appointment/list/' + eventClickInfo.event._def.extendedProps['appointmentUid'])
  }
  return (
    <div className={'Calender'}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev, today, next',
          center: 'title',
          right: '',
        }}
        initialView="timeGridWeek"
        initialDate={props.calendarDateStart}
        locale={viLocale}
        editable={false}
        selectable={false}
        selectMirror={false}
        dayMaxEvents={false}
        allDaySlot={false}
        slotDuration={'00:15:00'}
        slotLabelInterval={'00:15:00'}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'short',
        }}
        datesSet={(dateInfo) => {
          if (dateInfo.start.toDateString() !== props.calendarDateStart.toDateString()) {
            props.setCalendarDateStart(dateInfo.start)
            props.setCalendarDateEnd(dateInfo.end)
          }
        }}
        nowIndicator={true}
        scrollTime={moment().add(-30, 'minute').format("HH:mm:ss")}
        eventContent={
          function(arg) {
            return (
              <>
                <h5>{arg.event._def.extendedProps[patientName]}</h5>
                <p><i>{arg.event.title}</i></p>
              </>
            )
            // return {domNodes: arrayOfDomNodes}
          }
        }

        // weekends={this.state.weekendsVisible}
        events={eventData}
        // initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
        // select={this.handleDateSelect}
        // eventContent={renderEventContent} // custom render function
        eventClick={handleEventClick}
        // eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
        /* you can update a remote database when these fire:
        eventAdd={function(){}}
        eventChange={function(){}}
        eventRemove={function(){}}
        */
        titleFormat={(arg) => {
          return ''
        }}
        businessHours={
          businessHours
        }
        contentHeight={'30px'}
        height={1000}
        dayHeaderContent={
          (args) => {
            return(
              <div>
                <p>
                  {moment(args.date).format('ddd').replace('T', 'Thứ ')}
                  </p>
                <p>{moment(args.date).format('DD/MM/YYYY').replace('T', 'Thứ ')}</p>
              </div>
            )
          }
        }
      />
    </div>
  )
}

