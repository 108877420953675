import { Button, Card, Col, DatePicker, Row, Segmented, Space } from "antd"
import { DASHBOARD_GENERAL_API } from "app/modules/helpers/Common"
import axios from "axios"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Bar, Doughnut } from "react-chartjs-2"
import { DiseaseReportWidget } from "./DiseaseReportWidget"

import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Plugin } from "chart.js"
import { AnyObject } from "chart.js/dist/types/basic"

const barOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  }
}

const doughnutOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    datalabels: {
      backgroundColor: function (context) {
        return context.dataset.backgroundColor;
      },
      borderColor: 'white' as const,
      borderRadius: 50,
      borderWidth: 2,
      color: 'white' as const,
      display: function (context) {
        var dataset = context.dataset;
        // var count = dataset.data.length;
        var value = dataset.data[context.dataIndex];
        return value > 0;
      },
      font: {
        weight: 'bold' as const,
      },
      padding: 7,
      formatter: Math.round
    },
  },
  layout: {
    padding: 32
  },
}

const overviewOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      stacked: true
    },
    x: {
      stacked: true
    }
  },
  plugins: {
    legend: {
      // display: false,
    },
  }
}

export const OrgReport = ({ doctor = undefined as any }) => {
  const [currentDate, setCurrentDate] = useState(dayjs())
  const [mode, setMode] = useState('month' as 'month' | 'year')
  const [overviewMode, setOverviewMode] = useState('revenue' as 'revenue' | 'appointment')
  // const [data, setData] = useState([] as any[])
  // const [total, setTotal] = useState(undefined as any)
  const [series, setSeries] = useState({ datasets: [] as any[], labels: [] as any[] })
  const [series2, setSeries2] = useState({ datasets: [] as any[], labels: [] as any[] })
  const [series3, setSeries3] = useState({ datasets: [] as any[], labels: [] as any[] })
  const [series4, setSeries4] = useState({ datasets: [] as any[], labels: [] as any[] })

  useEffect(() => {
    axios.get(DASHBOARD_GENERAL_API, { params: { local_year: currentDate.year(), local_month: mode === 'month' ? currentDate.month() + 1 : undefined, doctor_id: doctor }, })
      .then(response => response.data.data)
      .then(data => {
        // setData(data)
        const totalData = data.reduce((sum, item) => ({
          canceled_count: sum.canceled_count + item.canceled_count,
          finished_count: sum.finished_count + item.finished_count,
          missing_call_count: sum.missing_call_count + item.missing_call_count,
          inprogress_count: sum.inprogress_count + item.inprogress_count,
          payment_process_count: sum.payment_process_count + item.payment_process_count,
          payment_success_count: sum.payment_success_count + item.payment_success_count,
          waiting_conclusion_count: sum.waiting_conclusion_count + item.waiting_conclusion_count,
          submitted_test_result_count: sum.submitted_test_result_count + item.submitted_test_result_count,
          waiting_test_result_count: sum.waiting_test_result_count + item.waiting_test_result_count,
        }), {
          canceled_count: 0,
          finished_count: 0,
          missing_call_count: 0,
          inprogress_count: 0,
          payment_process_count: 0,
          payment_success_count: 0,
          waiting_conclusion_count: 0,
          submitted_test_result_count: 0,
          waiting_test_result_count: 0,
        })
        // setTotal(totalData)
        setSeries({
          labels: ['Đang thanh toán', 'Đã đặt hẹn', 'Chờ tư vấn', 'Chờ kết quả CLS', 'Chờ kết luận'],
          datasets: [{
            data: [totalData.payment_process_count, totalData.payment_success_count, totalData.waiting_conclusion_count, totalData.waiting_test_result_count, totalData.submitted_test_result_count],
            backgroundColor: ['#13c2c2', '#2f54eb', '#faad14', '#eb2f96', '#722ed1'],
            datalabels: {
              anchor: 'end'
            }
          }]
        })
        setSeries2({
          labels: ['Huỷ', 'Đang thực hiện', 'Hoàn thành', 'Quá hẹn'],
          datasets: [{
            data: [totalData.canceled_count, totalData.inprogress_count, totalData.finished_count, totalData.missing_call_count],
            backgroundColor: ['#ff4d4f', '#fadb14', '#52c41a', '#bfbfbf'],
            borderRadius: 2,
            indexAxis: 'y',
          }]
        })

        if (mode === 'month') {
          let receivedArray = Array(dayjs().daysInMonth()).fill(0)
          let canceledArray = Array(dayjs().daysInMonth()).fill(0)


          let appFinishedArray = Array(dayjs().daysInMonth()).fill(0)
          let appInprogressArray = Array(dayjs().daysInMonth()).fill(0)
          let appMissedArray = Array(dayjs().daysInMonth()).fill(0)
          let appCanceledArray = Array(dayjs().daysInMonth()).fill(0)

          data.forEach(item => {
            receivedArray[dayjs(item.ref_time, 'YYYY/MM/DD').date() - 1] = item.sum_price_received
            canceledArray[dayjs(item.ref_time, 'YYYY/MM/DD').date() - 1] = item.sum_price_canceled
            appFinishedArray[dayjs(item.ref_time, 'YYYY/MM/DD').date() - 1] = item.finished_count
            appInprogressArray[dayjs(item.ref_time, 'YYYY/MM/DD').date() - 1] = item.inprogress_count
            appMissedArray[dayjs(item.ref_time, 'YYYY/MM/DD').date() - 1] = item.missing_call_count
            appCanceledArray[dayjs(item.ref_time, 'YYYY/MM/DD').date() - 1] = item.canceled_count
          })
          setSeries3({
            labels: Array.from(Array(currentDate.daysInMonth()).keys()).map(item => item + 1),
            datasets: [{
              data: receivedArray,
              label: 'Đã thu',
              backgroundColor: '#1677ff',
              borderRadius: 2,
            }, {
              data: canceledArray,
              label: 'Huỷ',
              backgroundColor: '#d9d9d9',
              borderRadius: 2,
            }]
          })
          setSeries4({
            labels: Array.from(Array(currentDate.daysInMonth()).keys()).map(item => item + 1),
            datasets: [{
              data: appFinishedArray,
              label: 'Hoàn thành',
              backgroundColor: '#52c41a',
              borderRadius: 2,
            },
            {
              data: appInprogressArray,
              label: 'Đang thực hiện',
              backgroundColor: '#fadb14',
              borderRadius: 2,
            },
            {
              data: appMissedArray,
              label: 'Quá hẹn',
              backgroundColor: '#bfbfbf',
              borderRadius: 2,
            },
            {
              data: appCanceledArray,
              label: 'Huỷ',
              backgroundColor: '#ff4d4f',
              borderRadius: 2,
            }]
          })
        }
        else {
          let receivedArray = Array(12).fill(0)
          let canceledArray = Array(12).fill(0)
          let appFinishedArray = Array(12).fill(0)
          let appCanceledArray = Array(12).fill(0)
          let appMissedArray = Array(12).fill(0)
          let appInprogressArray = Array(12).fill(0)

          data.forEach(item => {
            receivedArray[parseInt(item.ref_time) - 1] = item.sum_price_received
            canceledArray[parseInt(item.ref_time) - 1] = item.sum_price_canceled
            appFinishedArray[parseInt(item.ref_time) - 1] = item.finished_count
            appCanceledArray[parseInt(item.ref_time) - 1] = item.canceled_count
            appMissedArray[parseInt(item.ref_time) - 1] = item.missing_call_count
            appInprogressArray[parseInt(item.ref_time) - 1] = item.inprogress_count
          })
          setSeries3({
            labels: Array.from(Array(12).keys()).map(item => item + 1),
            datasets: [{
              data: receivedArray,
              label: 'Đã thu',
              backgroundColor: '#1677ff',
              borderRadius: 2,
            }, {
              data: canceledArray,
              label: 'Huỷ',
              backgroundColor: '#d9d9d9',
              borderRadius: 2,
            }]
          })
          setSeries4({
            labels: Array.from(Array(12).keys()).map(item => item + 1),
            datasets: [{
              data: appFinishedArray,
              label: 'Hoàn thành',
              backgroundColor: '#52c41a',
              borderRadius: 2,
            },
            {
              data: appInprogressArray,
              label: 'Đang thực hiện',
              backgroundColor: '#fadb14',
              borderRadius: 2,
            },
            {
              data: appMissedArray,
              label: 'Quá hẹn',
              backgroundColor: '#bfbfbf',
              borderRadius: 2,
            },
            {
              data: appCanceledArray,
              label: 'Huỷ',
              backgroundColor: '#ff4d4f',
              borderRadius: 2,
            }]
          })
        }
      })
  }, [currentDate, mode, doctor])

  return (
    <Card
      className="mb-8"
      title={
        <Space>
          <Button onClick={() => setCurrentDate(dayjs())}>Hôm nay</Button>
          <Button icon={<i className="fa-solid fa-angle-left"></i>} onClick={() => {
            if (mode === 'month') {
              setCurrentDate(currentDate.add(-1, 'month'))
            } else if (mode === 'year') {
              setCurrentDate(currentDate.add(-1, 'year'))
            }
          }} />
          <DatePicker picker={mode} value={currentDate} onChange={(date) => setCurrentDate(date)} allowClear={false} />
          <Button icon={<i className="fa-solid fa-angle-right"></i>} onClick={() => {
            if (mode === 'month') {
              setCurrentDate(currentDate.add(1, 'month'))
            } else if (mode === 'year') {
              setCurrentDate(currentDate.add(1, 'year'))
            }
          }} />
        </Space>
      }
      extra={
        <Segmented
          options={[
            { label: 'Tháng', value: 'month' },
            { label: 'Năm', value: 'year' },
          ]}
          value={mode}
          onChange={(value) => {
            setMode(value as any)
            setCurrentDate(dayjs())
          }}
        />}
    >
      <Row gutter={[10, 10]}>
        <Col span={12} >
          <Card title='Ca tư vấn'>
            <Bar
              options={barOptions}
              data={series2}
              height={300}
              width={200}
            />
          </Card>
        </Col>
        <Col span={12} >
          <Card title='Đang thực hiện'>
            <Doughnut
              options={doughnutOptions}
              data={series}
              height={300}
              width={200}
              plugins={[ChartDataLabels as Plugin<"doughnut", AnyObject>]}
            />
          </Card>
        </Col>
        <Col span={24} >
          <Card title='Tổng quan' extra={
            <Segmented options={[
              { label: 'Ca tư vấn', value: 'appointment' },
              { label: 'Doanh thu', value: 'revenue' }
            ]}
              value={overviewMode}
              onChange={(value) => setOverviewMode(value as any)}
            />}>
            {overviewMode === 'revenue' &&
              <Bar
                options={overviewOptions}
                data={series3}
                height={300}
                width={200}
              />}
            {overviewMode === 'appointment' &&
              <Bar
                options={overviewOptions}
                data={series4}
                height={300}
                width={200}
              />}
          </Card>
        </Col>
        <Col span={24} >
          <DiseaseReportWidget currentDate={currentDate} mode={mode} doctor={doctor} />
        </Col>
      </Row>
    </Card>
  )
}