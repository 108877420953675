import { Avatar, Button, Card, Col, Row, Space, Steps, Tabs, Typography } from 'antd'
import { useAuth } from 'app/modules/auth'
import { getAppointmentStatusClass, getAppointmentStatusLabel } from 'app/modules/helpers/Common'
import { Permission } from 'app/modules/helpers/Permission'
import dayjs from 'dayjs'
import { useState, useEffect } from "react"
import { useLocation, useNavigate } from 'react-router-dom'

const { Text } = Typography

export const AppointmentHeader = ({ appointment, forceRefresh }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { permission } = useAuth()

  const [tabList, setTabList] = useState([
    { key: `/calendar/id/${appointment.uid}`, label: 'Thông tin lịch hẹn' },
    { key: `/calendar/id/${appointment.uid}/files`, label: 'Hồ sơ' },
    { key: `/calendar/id/${appointment.uid}/medicalhistory`, label: 'Tiền sử bệnh' },
    { key: `/calendar/id/${appointment.uid}/form`, label: 'Khai báo' },
    { key: `/calendar/id/${appointment.uid}/activity`, label: 'Hoạt động' },
    { key: `/calendar/id/${appointment.uid}/conclusion`, label: 'Kết luận' },
  ]);

  useEffect(() => {
    const { pathname } = location;

    if (pathname && appointment?.uid && pathname.includes('telemedicine')) {
      setTabList([
        /* { key: `/telemedicine/${appointment.uid}`, label: 'Thông tin lịch hẹn' },*/
        { key: `/telemedicine/${appointment.uid}/files`, label: 'Hồ sơ' },
        { key: `/telemedicine/${appointment.uid}/medicalhistory`, label: 'Tiền sử bệnh' },
        /* { key: `/telemedicine/${appointment.uid}/form`, label: 'Khai báo' },*/
        /* { key: `/telemedicine/${appointment.uid}/activity`, label: 'Hoạt động' },*/
        { key: `/telemedicine/${appointment.uid}/conclusion`, label: 'Kết luận' },
      ]);
    }
  }, [location, setTabList, appointment])

  /* const [tabList] = useState();*/

  // const [summary, setSummary] = useState(undefined as any)

  // useEffect(() => {
  //   axios.get(DOCTOR_SUMMARY_API, { params: { target_user_id: doctor.uid } })
  //     .then(response => setSummary(response.data.data))
  // }, [doctor])
  let current = 0
  let appointmentSteps: any[] = [
    {
      title: <span className='text-primary'>Đặt lịch hẹn</span>,
    },
    {
      title: <span className='text-primary'>Xác nhận</span>,
    },
    {
      title: <span className='text-primary'>Tư vấn</span>,
    },
    {
      title: <span className='text-primary'>Kết luận</span>,
    },
    {
      title: <span className='text-primary'>Hoàn tất</span>,
    },
  ]

  if (appointment.status === 'WAITING_CONCLUSION') {
    current = 3
  } else if (appointment.status === 'WAITING_TEST_RESULT') {
    appointmentSteps.splice(3, 0, { title: <span className='text-primary'>Xét nghiệm</span> })
    current = 3
  } else if (appointment.status === 'SUBMITTED_TEST_RESULT') {
    appointmentSteps.splice(3, 0, { title: <span className='text-primary'>Xét nghiệm</span> })
    current = 4
  } else if (appointment.status === 'FINISHED') {
    current = 4
  } else if (appointment.status === 'MISSING_CALL') {
    current = 2
    appointmentSteps[2].status = 'error'
    appointmentSteps[2].description = <span className='fs-7 text-gray-600'>Quá giờ hẹn</span>
  } else if (appointment.status === 'PAYMENT_PROCESS') {
    current = 1
  } else if (appointment.status === 'PAYMENT_SUCCESS') {
    current = 2
  } else if (appointment.status === 'PAYMENT_FAIL') {
    current = 1
    appointmentSteps[1].status = 'error'
    appointmentSteps[1].description = (
      <span className='fs-7 text-gray-600'>Thanh toán thất bại</span>
    )
  }

  return (
    <Card style={{ marginBottom: 30 }} styles={{ body: { paddingBottom: 0 } }}>
      <Row className='tw-flex tw-flex-nowrap tw-flex-row-reverse tw-justify-between '>

        <Col className=''>

          {!location?.pathname?.includes('telemedicine') &&
            <Card
              className="tw-w-max"
              size='small'
              title={
                <Text className={`fs-7 ${getAppointmentStatusClass(appointment.status)}`}>
                  {getAppointmentStatusLabel(appointment.status)}
                </Text>
              }
            >
              <Steps
                current={current}
                items={appointmentSteps}
                labelPlacement='vertical'
                className='mt-0 '
                size='small'
                type='inline'
              />
            </Card>


          }

          {/* <Button onClick={() => navigate(`/account/id/${doctor.uid}`)} icon={<i className="fa-solid fa-circle-user"></i>}>Tài khoản</Button> */}
        </Col>

        <Col flex=''  >
          <Space size={30} align='start'>
            <Avatar
              src={appointment.patient_info.avatar || '/media/avatars/blank.png'}
              size={128}
              shape='square'
            />
            <Space direction='vertical' size={0}>
              <Text className='fs-7 text-gray-600'>Khách hàng</Text>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Text className='fs-7 text-primary'>{appointment.patient_info.fullname}</Text>
                {/* <KTIcon iconName="verify" className="fs-3 text-primary" /> */}
              </span>
              <Text className='fs-7 text-gray-600'>Chuyên khoa</Text>
              <Text className='fs-7 text-primary'>{appointment.disease_info.name}</Text>
              <Text className='fs-7 text-gray-600'>Thời gian</Text>
              <Text className='fs-7 text-primary'>
                {dayjs(appointment.start_time * 1000).format('DD/MM/YYYY HH:mm')} -{' '}
                {(appointment.end_time - appointment.start_time) / 60} phút
              </Text>

              <Text className='fs-7 text-gray-600'>Lý do khám</Text>
              <Text className='fs-7 text-primary'>{appointment.reason}</Text>
              {/* <Text className="fs-7 text-gray-600">{doctor.departments?.map(department => department.name).join(', ')}</Text> */}
              {/* {summary?.rating_count > 0 &&
                <Space>
                  <Rate value={summary.avg_rating} className="fs-7" allowHalf disabled /><Text className="fs-7 text-gray-600">({summary.rating_count} đánh giá)</Text>
                </Space>
              } */}
            </Space>
          </Space>
        </Col>

      </Row>
      <Tabs
        items={tabList}
        tabBarExtraContent={
          <Space>
            {permission(Permission.ChangeAppointment) &&
              appointment.status === 'PAYMENT_SUCCESS' && (
                <Button onClick={() => navigate(`/calendar/id/${appointment.uid}/reschedule`)}>
                  Đổi lịch hẹn
                </Button>
              )}
            {permission(Permission.Telehealth) && !location?.pathname?.includes('telemedicine') && (
              <>
                <Button
                  icon={<i className='fa-solid fa-message'></i>}
                  onClick={() => {
                    navigate(`/chat/id/${appointment.conversation_id}`)
                  }}
                >
                  Nhắn tin
                </Button>
                {(appointment.status === 'PAYMENT_SUCCESS' ||
                  (appointment.status === 'WAITING_CONCLUSION' &&
                    dayjs(appointment.end_time * 1000).isAfter(dayjs()))) && !location?.pathname?.includes('telemedicine') && (
                    <Button
                      icon={<i className='fa-solid fa-video'></i>}
                      onClick={() => navigate(`/telemedicine/${appointment.uid}`)}
                    >
                      Gọi video
                    </Button>
                  )}
              </>
            )}
          </Space>
        }
        onChange={(activeKey) => {
          forceRefresh();
          navigate(activeKey)
        }}
        tabBarStyle={{ marginBottom: 0 }}
        activeKey={location.pathname}
        className='mt-2'
      />
    </Card>
  )
}
