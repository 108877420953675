import { Button, Card, ConfigProvider, Descriptions, Divider, Space, Table, Typography } from 'antd'
import Column from 'antd/es/table/Column'
import { WithShareDataPage } from 'app/modules/apps/patient-management/patient/ShareDataPage'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers/components/KTIcon'
import { MAKE_S3_API, MEDICAL_FILES_API, formatPhone, getFileTypeDescription, getGenderDescription } from '../../../helpers/Common'

const { Text } = Typography

const PatientFiles = ({ account }) => {
  const [page, setPage] = useState(1)
  const [files, setFiles] = useState([] as any[])
  const [maxPage, setMaxPage] = useState(1)
  const [pageSize, setPageSize] = useState(5)

  useEffect(() => {
    axios.get(MEDICAL_FILES_API, { params: { page: page, target_user_id: account } })
      .then((response) => {
        setFiles(response.data.data.items)
        setMaxPage(response.data.data.max_page)
        setPageSize(response.data.data.per_page)
      })
  }, [account, page])

  const handleS3 = (path) => {
    axios.get(MAKE_S3_API, { params: { list_path: JSON.stringify([path]) } })
      .then(response => {
        window.open(response.data.data[0], '_blank')
      })
  }

  const fileList = files.map(item => {
    const subFiles = item.media.map(subItem => ({ ...subItem, title: subItem.title || subItem.origin_name || 'Không tên' }))
    return {
      title: item.title || 'Không tên',
      children: subFiles,
      file_type: 'folder',
      path: item.uid,
    }
  })

  console.log(fileList)

  return (
    <ConfigProvider
      theme={{ components: { Table: { headerBg: '#fff' } } }}>
      <Table
        dataSource={fileList}
        expandable={{ expandRowByClick: true }}
        rowKey="path"
        onHeaderRow={() => ({ className: 'fw-bolder fs-7 text-uppercase' })}
        onRow={() => ({ className: 'fs-7 text-gray-600' })}
        size='small'
        locale={{ emptyText: 'Không có dữ liệu' }}
        pagination={{ defaultCurrent: 1, total: maxPage * pageSize, showSizeChanger: false, onChange: (page) => setPage(page), current: page }}
      >
        <Column title="Tên hồ sơ" dataIndex="url"
          render={(_, record: any) => <Space size={1}><KTIcon iconName={record.file_type === 'folder' ? "folder" : 'file'} className="fs-1 me-1 text-primary" /><Text className="text-primary fs-7">{record.title || 'Không tên'}</Text></Space>} />
        <Column title="Loại" dataIndex="file_type" render={(value) => getFileTypeDescription(value)} />
        <Column title="Thao tác" align="end" render={(_, record: any) => {
          return record.file_type !== 'folder' &&
            <Button className="text-hover-primary" icon={<i className="fa-solid fa-download"></i>}
              onClick={(e) => {
                handleS3(record.path)
                e.stopPropagation()
              }} />
        }} />
      </Table>
    </ConfigProvider>
  )
}

export function PatientWidget({ account }) {
  return (
    account && (
      <>
        <Card
          title='Hồ sơ khách hàng'
          styles={{
            body: { height: '100%', overflow: 'auto' },
          }}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeftWidth: 0,
          }}
        >
          <Descriptions
            layout='vertical'
            size='small'
          >
            <Descriptions.Item label='Tên đầy đủ'>{account.fullname}</Descriptions.Item>
            <Descriptions.Item label='Địa chỉ'>{account.address}</Descriptions.Item>
            <Descriptions.Item label='Ngày sinh'>
              {account.birthday
                ? `${dayjs(account.birthday).format('DD-MM-YYYY')} (${dayjs().diff(
                  account.birthday,
                  'year'
                )} tuổi)`
                : 'Không có thông tin'}
            </Descriptions.Item>
            <Descriptions.Item label='Giới tính'>
              {getGenderDescription(account.gender)}
            </Descriptions.Item>
            <Descriptions.Item label='Số điện thoại'>
              {formatPhone(account.phone)}
            </Descriptions.Item>
            <Descriptions.Item label='Email'>{account.email}</Descriptions.Item>
            <Descriptions.Item label='Quốc tịch'>{account.nationality}</Descriptions.Item>
            {/* <Descriptions.Item label='Nhóm bệnh'> */}
            {/* {account.health.pathological_medical_history.replaceAll(';', ' - ')} */}
            {/* </Descriptions.Item> */}
          </Descriptions>
          <Divider />

          <div className='table-responsive'>
            <WithShareDataPage
              account={account}
              extra={<PatientFiles account={account.uid} />}
            />
          </div>
        </Card>
      </>
    )
  )
}
