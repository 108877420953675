import {useState, createContext, useContext} from 'react'

const QueryRequestContext = createContext<any>({})

const QueryRequestProvider = ({children}) => {
  const [state, setState] = useState({per_page: 10})

  const updateState = (patch) => {
    setState({...state, ...patch})
  }

  const putState = (patch) => {
    setState({...patch})
  }

  return (
    <QueryRequestContext.Provider value={{state, updateState, putState}}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)

export {QueryRequestProvider, useQueryRequest}
