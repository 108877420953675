export enum Permission {
    ChangePassword,
    SelfChangePassword,
    ChangeAccountInfo,
    ChangeOrganizationInfo,
    ShowSystemMenu,
    ShowOrganizationMenu,
    UpdateDiseaseGroup,
    UpdateSupportDisease,
    UpdateForm,
    CreateNewAccount,
    ChangePatientAccount,
    ViewAccountList,
    SwichOrganization,
    ManageOrganization,
    ViewCalendar,
    Telehealth,
    ChangeAppointment,
}

export const getAvailableRoles = (role: string, targetRole: string) => {
    if (targetRole === 'PATIENT') return ['PATIENT']

    if (role === 'OWNER') {
        return ['SUPER_ADMIN_BRANCH', 'SUPER_ADMIN_SYS', 'DOCTOR', 'NURSE', 'CUSTOMER_CARE']
    } else if (role === 'SUPER_ADMIN_BRANCH') {
        return ['DOCTOR', 'NURSE', 'CUSTOMER_CARE']
    } else if (role === 'CUSTOMER_CARE') {
        return ['DOCTOR', 'NURSE']
    }
    return [targetRole]
}

export const getAvailableRolesForNewAccount = (role: string) => {
    if (role === 'OWNER' || role === 'SUPER_ADMIN_SYS') {
        return ['SUPER_ADMIN_BRANCH', 'DOCTOR', 'NURSE', 'CUSTOMER_CARE']
    } else if (role === 'SUPER_ADMIN_BRANCH') {
        return ['DOCTOR', 'NURSE', 'CUSTOMER_CARE']
    }
    return []
}