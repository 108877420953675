import { Button, Card, Descriptions } from "antd"
import { getGenderDescription } from "app/modules/helpers/Common"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"

import {
  formatPhone
} from 'app/modules/helpers/Common'

export const DoctorInfo = ({ doctor }) => {
  const navigate = useNavigate()

  return (
    <Card
      title='Thông tin cá nhân'
      extra={<Button onClick={() => navigate(`/account/id/${doctor.uid}`)}>Sửa thông tin</Button>}
      style={{ marginBottom: 30 }}
      bordered={false}
    >
      <Descriptions contentStyle={{ fontWeight: 'bolder' }} layout='vertical' size="small">
        <Descriptions.Item label='Tên đầy đủ'>{doctor.fullname}</Descriptions.Item>
        <Descriptions.Item label='Địa chỉ'>{doctor.address}</Descriptions.Item>
        <Descriptions.Item label='Ngày sinh'>
          {doctor.birthday
            ? `${dayjs(doctor.birthday).format('DD-MM-YYYY')} (${dayjs().diff(
              doctor.birthday,
              'year'
            )} tuổi)`
            : ''}
        </Descriptions.Item>
        <Descriptions.Item label='Giới tính'>
          {getGenderDescription(doctor.gender)}
        </Descriptions.Item>
        <Descriptions.Item label='Email'>{doctor.email}</Descriptions.Item>
        <Descriptions.Item label='Số điện thoại'>{formatPhone(doctor.phone) || ''}</Descriptions.Item>
        <Descriptions.Item label='Chứng chỉ hành nghề'>{doctor.practising_cert}</Descriptions.Item>
        <Descriptions.Item label='Chức danh'>{doctor.title.join(", ") || ''}</Descriptions.Item>
        <Descriptions.Item label='Học hàm, học vị'>{doctor.degree}</Descriptions.Item>
        <Descriptions.Item label="Quốc tịch">{doctor.nationality}</Descriptions.Item>
      </Descriptions>
    </Card>
  )
}
