/* eslint-disable jsx-a11y/anchor-is-valid */
import { SleepChart } from '../health/SleepChart'
import { HeartRateDayChart } from '../health/HeartRateDayChart'
import { RespirationRateChart } from '../health/RespirationRateChart'
import { StepMonthChart } from '../health/StepMonthChart'
import { PulseOxChart } from '../health/PulseOxChart'
import { ComboChart } from '../health/ComboChart'
import { BloodPressureChart } from '../health/BloodPressureChart'
import { BloodGlucoseChart } from '../health/BloodGlucoseChart'
import { StressChart } from '../health/StressChart'

import { WithShareDataPage } from 'app/modules/apps/patient-management/patient/ShareDataPage'

export function PatientHealth({ account }) {
  return (
    <>
      {account && <WithShareDataPage
        account={account}
        extra={
          <>
            <ComboChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <BloodPressureChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <BloodGlucoseChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <StressChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <StepMonthChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <PulseOxChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <SleepChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <HeartRateDayChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
            <RespirationRateChart className='card-xxl-stretch mb-5 mb-xl-10' uid={account.uid} />
          </>
        }
      />}
    </>
  )
}
