import { Button, Card, Form, Input, Row, Space } from "antd"
import { ORG_API } from "app/modules/helpers/Common";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const NewOrganizationPage = () => {
    const navigate = useNavigate()

    const [form] = Form.useForm()

    const handleFormSubbmit = () => {
        form.validateFields()
            .then((values) => {
                console.log(values);
                axios.post(ORG_API, { ...values })
                    .then(response => {
                        console.log(response)
                        navigate(`/organization/id/${response.data.data.uid}`)
                    })
            });
    }

    return (
        <Card title='Thông tin tổ chức mới' actions={[
            <Row justify="end" style={{ paddingRight: 25 }}>
                <Space>
                    <Button onClick={() => navigate(-1)}>Huỷ</Button>
                    <Button type='primary' onClick={handleFormSubbmit}>Tạo tổ chức</Button>
                </Space>
            </Row>
        ]} >
            <Form
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
            >
                <Form.Item label="Tên tổ chức" name="name" rules={[{ required: true, whitespace: true, message: "Bắt buộc điền thông tin" }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Mô tả" name="description">
                    <Input />
                </Form.Item>
                <Form.Item label="Địa chỉ" name="address">
                    <Input />
                </Form.Item>
                <Form.Item label="Trang chủ" name="homepage">
                    <Input />
                </Form.Item>
                <Form.Item label="Email" name="email">
                    <Input />
                </Form.Item>
                <Form.List
                    name="hotline"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    wrapperCol={{ offset: (index === 0 ? 0 : 8) }}
                                    label={index === 0 ? 'Số điện thoại' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Space>
                                        <Form.Item
                                            {...field}
                                            validateTrigger={['onChange', 'onBlur']}
                                            rules={[
                                                {
                                                    required: true,
                                                    whitespace: true,
                                                    message: "Điền số điện thoại",
                                                },
                                            ]}
                                            noStyle
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Button onClick={() => remove(field.name)}>Xoá</Button>
                                    </Space>
                                </Form.Item>
                            ))}
                            <Form.Item wrapperCol={{ offset: 8 }}>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                >
                                    Thêm số điện thoại
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Card>
    )
}