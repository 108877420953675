import { useEffect, useState } from "react"
import { FOLLOW_RELATION_API } from "../../../helpers/Common"
import axios from "axios"
import { Avatar, Tooltip } from "antd"

export const FollowerList = ({ account }) => {

  const [followerList, setFollowerList] = useState([] as any[])

  useEffect(() => {
    axios.get(FOLLOW_RELATION_API, { params: { target_user_id: account.uid } })
      .then(response => response.data.data)
      .then(response => {
        setFollowerList(response.items)
      })
  }, [account])

  return (
    <Avatar.Group size={48} style={{ padding: 0 }}>
      {followerList.map((follower) => (
        <Tooltip title={follower.fullname} placement="top" key={follower.uid}>
          <Avatar src={follower.avatar} style={{ backgroundColor: '#1677ff' }}>{follower.fullname.charAt(0)}</Avatar>
        </Tooltip>
      ))}
    </Avatar.Group>
  )
}